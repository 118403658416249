import { Component, HostListener, Input, ViewChild, ElementRef, Renderer2 } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BaseComponent } from '../../base/base.component';
import { TranslationService } from 'src/app/services/utilities/translation.service';
import { PostService } from 'src/app/services/repositories/post.service';
import { UiStateService } from 'src/app/services/utilities/ui-state.service';
import { SocialMediaAPIService } from 'src/app/services/utilities/socialMediaApi.service';
import { ICON } from 'src/app/interfaces/ModalIcon';
import { Debug } from 'src/app/utils/debug';
import { AuthService } from 'src/app/services/utilities/auth.service';
import { WebSocketService } from 'src/app/services/utilities/websocket.service';
import { Subject ,Subscription} from 'rxjs';
import { debounceTime } from 'rxjs/operators';

@Component({
  selector: 'app-posts-library',
  templateUrl: './posts-library.component.html',
  styleUrls: ['./posts-library.component.scss'],
})
export class PostsLibraryComponent extends BaseComponent {
  @Input() dashboard: boolean = false;
  @Input() dashboardSource: string | null = null;
  windowWidth: number = window.innerWidth;
  isLargeView: boolean = false;
  isMediumView: boolean = false;
  isSmallView: boolean = false;
  isVerySmallView: boolean = false;
  currentDateTime: string;

	@ViewChild('postContainer') postContainer!: ElementRef;

  constructor(
    translationService: TranslationService,
    protected postService: PostService,
    protected uiStateService: UiStateService,
    private socialMediaApiService: SocialMediaAPIService,
    protected authService: AuthService,
    private webSocketService: WebSocketService,
		private route: ActivatedRoute
  ) {
    super(translationService);
    this.currentDateTime = new Date().toString();
    this.checkWindowSize();
  }
  posts: any = [];
  plannedPosts: any = [];

  @Input() currentPlaTformsFilters: any = { // delete Input
    isFacebookON: false,
    isLinkedinON: false,
    isGoogleON: false,
    isInstagramON: false,
  };

  @Input()currentPostsTypeFilter:any={
    isPlanned:false,
    isPosted:false,
    isDraft:false,
  }

  isLoading: boolean = false;
  doneLoadingPosts: boolean = false;
  previousScrollPosition = 0;

  isPublished: boolean = true;
  

  dateRange= { startDate:new Date(new Date().getFullYear() - 2, 0, 1) ,endDate:new Date(new Date().getFullYear(), 11, 31) };
  groupScopes: number[] = [];

  user!: any;
  navigation: any = {
    itemsPerPage: 20,
    page: 0,
  };
  subscriptions: Subscription[] = [];
	private deletionJobCounts = new Map<number, number>(); // keep track of which post was deleted and how many times (i.e. on how many pages)
  platformsSubscription!:Subscription
  typesSubscription!:Subscription
  dateRangeSubscription!:Subscription
  isRouteOnDashboard(){
    return(document.location.href.split('/').includes('dashboard'))
  }
  
  
  private filterUpdate$ = new Subject<string>();
  ngOnInit(): void {
    this.user = this.authService.loadUserfromLs();
   
    // Debounce and call getPosts
    this.subscriptions.push(this.filterUpdate$.pipe(debounceTime(300)).subscribe((reason) => {
      this.navigation.offset = 0;
      this.posts = [];
      this.getPosts(reason);
    }));

    this.subscriptions.push( this.postService.currentGroups.subscribe((groups) => {
      this.groupScopes = groups;
      this.uiStateService.updateGroupScopes(groups)
      this.triggerPostUpdate("group scopes");
    }));

    if (!this.isRouteOnDashboard()) {
      this.subscriptions.push(this.uiStateService.platformsFilterUpdated$.subscribe((data) => {
        this.currentPlaTformsFilters = data;
        this.triggerPostUpdate("platforms");
      }));

      this.subscriptions.push(this.uiStateService.dateRangeUpdate$.subscribe((data) => {
        this.dateRange = data;
        this.triggerPostUpdate("dateRange");
      }));
    }

    this.subscriptions.push(this.uiStateService.postTypesUpdated$.subscribe((data) => {
      this.currentPostsTypeFilter = data;
      this.triggerPostUpdate("post type");
    }))

    // get this month posts

		//Listend To post events via Websocket (deletion, creation started and creation completed)
		
		this.setupWebSocketListeners()

  }
	
	private setupWebSocketListeners(): void {
		const events = [
			'postCreationCompleted',
			'postCreationStarted',
			'postDeletionStarted',
			'postDeletionCompleted',
			'postUpdateStarted',
			'postUpdateCompleted',
			'postUpdateFailure'
		];
	
		events.forEach((eventType) => {
			this.webSocketService.listen(eventType, (data: any) => {
				this.handlePostEvent(eventType, data);
			});
		});
	}

	// Function to trigger updates
  private triggerPostUpdate(reason: string): void {
    this.filterUpdate$.next(reason);
  }
  override ngOnDestroy(): void {
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }

	private handlePostEvent(eventType: string, data: any): void {
		console.log(`${eventType}:`, data);

		const { post } = data;
		const postId = parseInt(post.id);

		if (eventType === 'postDeletionCompleted') {
			const { fullyDeleted } = data;

			// Handle deletion-specific logic
			const remainingJobs = (this.deletionJobCounts.get(postId) || 0) - 1;
			console.log('remainingJobs', remainingJobs);

			if (remainingJobs <= 0) {
				// Remove the element if remainingJobs is 0 or less
				this.deletionJobCounts.delete(postId);
				post.status = null;
			} else {
				// Otherwise, update the count
				this.deletionJobCounts.set(postId, remainingJobs);
			}

			// If fully deleted, remove the post from the list
			if (fullyDeleted) {
				this.posts = this.posts.filter((p: any) => p.id !== postId);
				return;
			}
		}

		// Find the post in the list and update the UI
		const postIndex = this.posts.findIndex((p: any) => p.id === postId);

		if (postIndex !== -1) {
			// Update or replace the post in the list
			this.posts[postIndex] = post;
		}
	}


  activePlatforms: string[] = [];
  SetActivePlatforms(platformList:any) {
    const result:any = [];
    for (let platform in platformList) {
      if (platformList[platform]) {
        // console.log(platform.substring(2,platform.length-2).toLowerCase());
        result.push(
          platform.substring(2, platform.length - 2).toLowerCase()
        );
      }
    }
    return result
  }
  activePostTypes:string[]=[]
  SetActivePostTypes(postTypes:any){
  const results=[]
    for (let postType in postTypes){
      if(postTypes[postType as keyof typeof this.uiStateService.postTypes]){

        if(postType=="isDraft"){
          results.push("draft")
        }
        if(postType=="isPlanned"){
          results.push("planned")
        }
        if(postType=="isPosted"){
          results.push("published")
        }
      }
    }
    return results
  }
  
  infinitScroll(event: any) {
    const element = event.target;
    const isScrollingDown = element.scrollTop > this.previousScrollPosition;
    const isBottomReached =
      element.scrollHeight - element.scrollTop <= element.clientHeight + 10;
  
    if (isScrollingDown && isBottomReached) {
      if (this.doneLoadingPosts && !this.isLoading) {
        this.isLoading = true;
        this.getPosts("scroll"); // Load more posts
        // console.log("You've reached the end of this chunk");
      }
    }
  
    // Update the previous scroll position
    this.previousScrollPosition = element.scrollTop;
  }


  getPosts(label:string): void {
    // console.log("Getting posts...");
    console.log("✅✅✅✅caller",label)
    const lsData = this.postService.getGroupScopesFromLocalStorage();
    console.log(this.groupScopes,)
    this.groupScopes = lsData.length > 0 ? lsData : this.groupScopes;
    this.activePlatforms=[]
    // console.log({...this.currentPlaTformsFilters})
    // console.log({...this.currentPostsTypeFilter})

    this.activePlatforms = [...this.SetActivePlatforms({...this.currentPlaTformsFilters})];    
    this.activePostTypes = [...this.SetActivePostTypes({...this.currentPostsTypeFilter})];

    // console.log('getPost platforms',this.activePlatforms,"types",this.activePostTypes,"dateRange",this.dateRange);
    if(this.activePlatforms.length ==0 || this.activePostTypes.length==0) {
      return
    }

    if (this.groupScopes.length > 0) {
      if (this.dateRange) {
                
        this.doneLoadingPosts = false;
        this.isLoading = true;
        this.dateRange.startDate??= new Date(new Date().getFullYear() - 2, 0, 1 )
        this.dateRange.endDate??= new Date(new Date().getFullYear(), 11, 31)
        // console.log("-------------(loading posts)----------------")
        // console.log("loading postst of:");
        // console.log(" range ",JSON.stringify(this.dateRange))
        // console.log(" types ",JSON.stringify(this.activePostTypes))
        // console.log(" platforms ",JSON.stringify(this.activePlatforms))
        if(this.activePlatforms.length > 0) {
          this.postService
          .getByDateRange(
            this.dateRange.startDate,
            this.dateRange.endDate,
            this.groupScopes,
            this.activePlatforms,
            this.activePostTypes,
            this.navigation.limit,
            this.navigation.offset,
          )
          .subscribe((res) => {
						// remove duplicates as temp fix for overlapping requests
						const newPosts = res.data.filter((post: any) => {
              return !this.posts.some((existingPost: any) => existingPost.id === post.id);
            });

            // Append only the new, unique posts
            this.posts = [...this.posts, ...newPosts];
            
            this.navigation = res.navigation;
            this.navigation.limit = Number(this.navigation.limit);
            this.navigation.offset = Number(this.navigation.offset);
            if (res.data.length > 0) {
              this.navigation.offset += this.navigation.limit;
            }
            // console.log('new navigation', this.navigation);
            this.doneLoadingPosts = true;
            this.chekcloading();

          });
        }
      }
    }
  }
  doneLoadingDrafts = false;
  chekcloading() {
    if (this.doneLoadingPosts) {
      this.isLoading = false;
    } else {
      this.isLoading = true;
    }
  }

  //modal data
  modalIcon = ICON.delete;
  modalHeading = '';
  modalSubHeading = '';
	modalIsEdit = false
	
  currentPost: any = null;
	
	openDeleteModal(post: any, action: any) {
		if (action == 'update') {
			console.log("action", action)
			this.modalIcon = ICON.edit;
			this.modalIsEdit = true
			this.modalHeading = 'posts.editInstagram';
			this.modalSubHeading = '';
		} else {
			this.modalIcon = ICON.delete;
			this.modalHeading = 'generic.confirmDeletion';
			this.modalSubHeading = 'generic.confirmPostDeletionMsg';
		}
		this.confirmDeleteModalVisible = true; 
		this.currentPost = post
	}

  confirmPostRemoval(event: any) {
		console.log("confirmPostRemoval", event);
	
		// Mark the post as "deletion in progress"
		// this.currentPost.deletionInProgress = true;

		// const index = this.posts.findIndex((post:any) => post.id === this.currentPost.id);
    // if (index !== -1) {
    //   this.posts.splice(index, 1);
    // }
		// if (index !== -1)
		// 	this.posts[index].deletionInProgress = true;
	
		// Call the delete function
		this.deletePost(this.currentPost, event?.selected || null);
	}

  confirmDeleteModalVisible: boolean = false;

  deletePost(post: any, selectedSocialMediasIds: any[] | null) {
    console.log(`deleting post `, post);

		// Initialize the job count for the post
    if (selectedSocialMediasIds) {
      this.deletionJobCounts.set(post.id, selectedSocialMediasIds.length);
    }

    this.postService.deletePost(post, selectedSocialMediasIds).subscribe((res) => {
      if (res.success) {
        //change modal icon and heading and hide them after 1.5 s
        this.modalIcon = ICON.success;
        this.modalHeading = 'generic.successDeletePost';
        this.modalSubHeading = 'generic.mayTakeMinutes';
				if (res.data.draft) { // if draft, remove from the page
					const postIndex = this.posts.findIndex((p: any) => p.id === post.id);
          console.log("posts before delete: " + this.posts.length);
					if (postIndex !== -1) 
						this.posts.splice(postIndex, 1);
          console.log("posts aftere delete: " + this.posts.length);
				}
        setTimeout(() => {
          this.confirmDeleteModalVisible = false;
          //  reset them back for the next post deletion"
          this.modalIcon = ICON.delete;
          this.modalHeading = 'generic.confirmDeletion';
          this.modalSubHeading = 'generic.confirmPostDeletionMsg';
        }, 1500);
      }
    });
  }

	isDeletionInProgress(postId: number): boolean {
		return (this.deletionJobCounts.get(postId) || 0) > 0;
	}

  togglePost() {
    this.currentPostsTypeFilter.isPosted = !this.currentPostsTypeFilter.isPosted
  }

  toggleDraft() {
    this.currentPostsTypeFilter.isDraft = !this.currentPostsTypeFilter.isDraft
  }

  togglePlanned() {
    this.currentPostsTypeFilter.isPlanned = !this.currentPostsTypeFilter;
  }

  checkVisibility(post:any){
    //get display as
    if(new Date(post.expectedPublishingDatetime) > new Date()){
      // console.log("planned")
      return this.currentPostsTypeFilter.isPlanned
    }
    if(post.publishingDatetime == null && post.expectedPublishingDatetime==null){
      // console.log("draft",post)
      return this.currentPostsTypeFilter.isDraft
    }
    if (new Date(post.expectedPublishingDatetime) < new Date() || new Date(post.publishingDatetime) < new Date())
    {
      // console.log("post")
      return this.currentPostsTypeFilter.isPosted
    }
    //compare display as 
    return false
  }

  sortPosts(posts: any[]): any[] {
    posts.sort((a: any, b: any) => {
      const dateA = new Date(a.publishingDatetime || a.expectedPublishingDatetime || a.createdAt).getTime();
      const dateB = new Date(b.publishingDatetime || b.expectedPublishingDatetime || b.createdAt).getTime();
      
      return dateB - dateA;
    });
    
    return posts;
  }

  private checkWindowSize() {
    this.windowWidth = window.innerWidth;
  }

  userHasPermission(permission: string): boolean {
    return (
      this.user?.isAdmin || 
      this.user?.accreditations[0]?.profile?.permissions.some((profilePermission: any) => profilePermission.action == permission)
    )
  }

  isFetchingForFacebookPosts: boolean = false;
  isFetchingForInstagramPosts: boolean = false;
  fetchPosts(source: string) {
    switch (source) {
      // case 'linkedin':
      //   this.isFetchingForLinkedinPosts = true;
      //   this.linkedinFetchData.show = false;
      //   break;
      // case 'google':
      //   this.isFetchingForGooglePosts = true;
      //   break;
      case 'facebook':
        this.isFetchingForFacebookPosts = true;
        break;
      case 'instagram':
        this.isFetchingForInstagramPosts = true;
        break;
    }

    const pagesIds = this.user.accreditations[0].group.socialMedia
      .filter(
        (sm: any) => sm.source.toLowerCase() == source.toLocaleLowerCase()
      )
      .map((socialMedia: any) => socialMedia.id);

      // console.log("pagesIds", pagesIds)
    this.socialMediaApiService.fetchPagePosts(pagesIds).subscribe((res) => {
      const syncData = res.data.data[0].fetchingResults;

      switch (source) {
        // case 'linkedin':
        //   this.linkedinFetchData.show = true;
        //   this.linkedinPostsCount =
        //     syncData.updatedPosts + syncData.insertedPost;
        //   this.linkedinFetchData.fetched =
        //     syncData.updatedPosts + syncData.insertedPosts;
        //   this.linkedinFetchData.added = syncData.insertedPosts;
        //   this.linkedinFetchData.updated = syncData.updatedPosts;
        //   this.isFetchingForLinkedinPosts = false;
        //   break;
        // case 'google':
        //   this.googleFetchData.show = true;
        //   this.googleFetchData.fetched =
        //     syncData.updatedPosts + syncData.insertedPosts;
        //   this.googleFetchData.added = syncData.insertedPosts;
        //   this.googleFetchData.updated = syncData.updatedPosts;
        //   this.isFetchingForGooglePosts = false;
        //   break;

        case 'facebook':
          // this.facebookFetchData.show = true;
          // this.facebookFetchData.fetched =
          //   syncData.updatedPosts + syncData.insertedPosts;
          // this.facebookFetchData.added = syncData.insertedPosts;
          // this.facebookFetchData.updated = syncData.updatedPosts;
          this.isFetchingForFacebookPosts = false;
          // console.log('res.data.data FB', res.data.data)
          window.location.reload();
          break;
        case 'instagram':
          // this.instagramFetchData.show = true;
          // this.instagramFetchData.fetched =
          //   syncData.updatedPosts + syncData.insertedPosts;
          // this.instagramFetchData.added = syncData.insertedPosts;
          // this.instagramFetchData.updated = syncData.updatedPosts;
          this.isFetchingForInstagramPosts = false;
          // console.log('res.data.data insta', res.data.data)
          window.location.reload();
          break;
      }
    });
  }
	
	// extractSocialMediaInfo(post: any): { id: number; name: string; source: string, postUrl: string }[] | null {
	// 	if (post) {
	// 		let displayAs;
	// 		if (
	// 			post.publishingDatetime == null &&
	// 			post.expectedPublishingDatetime == null
	// 		) {
	// 			displayAs = 'draft';
	// 		} else if (new Date(post.expectedPublishingDatetime) > new Date()) {
	// 			displayAs = 'planned';
	// 		} else {
	// 			displayAs = 'post';
	// 		}
	
	// 		// If draft, return null
	// 		if (displayAs == 'draft') return null;
	
	// 		if (!post?.SocialPlatforms || !Array.isArray(post.SocialPlatforms)) {
	// 			return [];
	// 		}
	
	// 		return post.SocialPlatforms
	// 			.filter((platform: any) => platform.id && platform.pageName && platform.source) // Filter to ensure id, name, and source exist
	// 			.map((platform: any) => ({
	// 				id: platform.id,
	// 				name: platform.pageName,
	// 				source: platform.source,
	// 				postUrl: platform.postUrl
	// 			}));
	// 	} else {
	// 		return null;
	// 	}
	// }

	handleDuplicatePost(event: any) {

		const newPost = event.data
		
		// Add the post at the top and mark it as new
    newPost.isNew = true;
    this.posts = [newPost, ...this.posts];
		
    // Scroll back to top
    setTimeout(() => {
			this.postContainer.nativeElement.scrollTo({ top: 0, behavior: 'smooth' });
    }, 50);

    // Remove the 'isNew' flag after animation
    setTimeout(() => {
      newPost.isNew = false;
    }, 4000);
	}

	displayAs(post: any): string {
		if (post) {
			if (
				post.publishingDatetime == null &&
				post.expectedPublishingDatetime == null
			) {
				return 'draft';
			} else if (new Date(post.expectedPublishingDatetime) > new Date()) {
				return 'planned';
			} else {
				return 'post';
			}
		} else {
			return ''
		}

	}
	
}
