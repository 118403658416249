<div class="w-[97%] mx-auto mt-2">

	<!-- <div class="filters grid grid-rows-1 grid-cols-8 md:grid-cols-6 sm:grid-cols-1 xs:grid-cols-1 gap-6">
		<div class="col-span-1">
			<span class="">
				<app-generic-drop-down (itemSelected)="onCalendarModeChanged($event)" [data]="['date.year','date.month','date.week','date.day']"/>
			</span>

		</div>
		<div class="col-span-4 md:col-span-3 xs:grid-cols-1 sm:grid-cols-1">
			<app-platform-filter />
		</div>

		<div class="col-span-2 xs:grid-cols-1 sm:grid-cols-1">
			<div class="py-2 px-3 bg-white border border-[2px] border-slate-500 rounded-xl" >
				<span class="flex justify-between text-center">
					<span *ngIf="isVerySmallView" class="font-semibold text-sm">{{'generic.published'|translate}} :</span> 
					<span *ngIf="!isVerySmallView" class="font-semibold text-sm">{{'posts.type'|translate}} :</span> 

					<button 
						type="button" 
						[ngClass]="{
								'text-white c-b-accent-4': isPlanned,
								'bg-gray-100 text-gray-400': !isPlanned, 
								'px-1': isSmallView,
								'px-3': isMediumView,
								'px-4': isLargeView, 
								'px-5': !isSmallView && !isMediumView && !isLargeView
							}"
						(click)="togglePlanned()" 
						class="w-16 mlg:w-20 xl:w-24 2xl:w-[8rem] rounded-full text-sm flex justify-center items-center"
					>
						{{ 'generic.planned' | translate }}
					</button>
					
					<button type="button" 
						[ngClass]="{
							'text-white c-b-accent-3': isPublished, 
							'bg-gray-100 text-gray-400': !isPublished, 
							'px-1': isSmallView,
							'px-3': isMediumView,
							'px-4': isLargeView, 
							'px-5': !isSmallView && !isMediumView && !isLargeView
						}" 
						(click)="togglePublished()" 
						class="w-16 mlg:w-20 xl:w-24 2xl:w-[8rem] rounded-full text-sm flex justify-center items-center"
					>
						{{ 'generic.published' | translate }}
					</button>
					
				</span>
			</div>
		</div>
	</div> -->

	<div class="calender-container  mt-5">
        <div *ngIf ="mode=='dates.timeUnits.year'" >
            <app-calendar-year-view [screenHeight]="screenHeight" [screenWidth]="screenWidth" (switchToMnthView)="switchToMonthView($event)" (switchToDayView)="switchToDayView($event)"/>
        </div>
        
        <div *ngIf ="mode=='dates.timeUnits.month'" >
            <app-calendar-month-view [screenHeight]="screenHeight" [screenWidth]="screenWidth" (switchToYearView)="switchToYearView($event)" (switchToDayView)="switchToDayView($event)"/>
        </div>
        
        <div *ngIf ="mode=='dates.timeUnits.week'" >
            <app-calendar-week-view [screenHeight]="screenHeight" [screenWidth]="screenWidth" (switchToYearView)="switchToYearView($event)" (switchToMnthView)="switchToMonthView($event)"/>
        </div>
        
        <div *ngIf ="mode=='dates.timeUnits.day'" >
            <app-calendar-day-view [screenHeight]="screenHeight" [screenWidth]="screenWidth"/>
        </div>
    </div>
</div>