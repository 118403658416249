import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Pipe({
  name: 'highlightSlice',
})
export class HighlightSlicePipe implements PipeTransform {
  constructor(private sanitizer: DomSanitizer) {}

  transform(text: string, start: number | null = null, end: number | null = null): SafeHtml {
    if (!text) return text;

		// Slice the text
		let slicedText;
		if (start && end) {
			slicedText = text.slice(start, end);
		} else {
			slicedText = text
		}


    // Regex to match URLs
		const urlRegex = /\b((https?:\/\/|www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*))\b/g;


   	// Convert newlines to <br> and highlight links
		const processedText = slicedText.replace(urlRegex, (match) => {
			const url = match.startsWith('http') ? match : `https://${match}`;
			return `<a href="${url}" target="_blank" style="color: #0064d1; text-decoration: none;">${match}</a>`;
		}).replace(/\n/g, '<br>'); // Preserve newlines

    // Return sanitized HTML
    return this.sanitizer.bypassSecurityTrustHtml(processedText);
  }
}
