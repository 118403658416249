import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Subscription } from 'rxjs';
import { UserService } from 'src/app/services/repositories/user.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-change-password-modal',
  templateUrl: './change-password-modal.component.html',
  styleUrls: ['./change-password-modal.component.scss']
})
export class ChangePasswordModalComponent {
  @Input() userId!: number | null;
  @Input() userEmail!: string | null;
  @Input() isOpen!: boolean;
  @Output() closeModal = new EventEmitter<void>();

  newPassword: string = '';
  confirmPassword: string = '';
  showNewPassword: boolean = false;
  showConfirmPassword: boolean = false;
  subscriptions: Subscription[] = [];
  constructor(private userService: UserService) {}

  close() {
    this.closeModal.emit();
  }

  toggleShowNewPassword() {
    this.showNewPassword = !this.showNewPassword;
  }

  toggleShowConfirmPassword() {
    this.showConfirmPassword = !this.showConfirmPassword;
  }

  changePassword() {
    if (this.newPassword !== this.confirmPassword) {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Passwords do not match!'
      });
      return;
    }

    if (this.userId) {
      this.subscriptions.push(this.userService.changePassword(this.userId, this.newPassword).subscribe(
        (response) => {
          Swal.fire({
            icon: 'success',
            title: 'Success',
            text: 'Password changed successfully'
          }).then(() => {
            this.newPassword = ''; // Reset the password input
            this.confirmPassword = ''; // Reset the confirm password input
            this.close();
          });
        },
        (error:any) => {
          console.error('Error changing password', error);
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'Error changing password'
          });
        }
      ));
    }
  }
  ngOnDestroy() {
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }
}
