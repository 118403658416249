<div class="col-span-3 row-span-2 w-full h-full">
  <fieldset class="rounded-3xl relative h-full">
    <!-- Tab Headers -->
    <div role="tablist" class="flex w-full gap-1">
      <div
        *ngFor="let platform of platformsWithStatus; let i = index"
        role="tab"
        [attr.aria-selected]="platform.isActive && selectedPlatformSource === platform.source"
        tabindex="0"
				(click)="platform.isActive ? selectPlatform(platform.source) : null"
				(keydown.enter)="platform.isActive ? selectPlatform(platform.source) : null"
        class="flex items-center justify-center overflow-hidden transition-all duration-300 ease-in-out rounded-t-3xl border p-2"
        [ngClass]="getTabClasses(platform)"
      >
			<div [ngClass]="[
				platform.isActive && selectedPlatformSource === platform.source ? platform.bg_class : '',
				platform.isActive && selectedPlatformSource === platform.source ? 'rounded-full' : ''
				]">
        <img
          [src]="
           platform.isActive
              ? selectedPlatformSource === platform.source
                ? platform.logoPath
                : platform.simpleLogoPath
              : platform.simpleLogoDisabledPath
          "
          [alt]="platform.name"
          class="h-8"
        />
			</div>
      </div>
    </div>

    <!-- Tab Content -->
    <div 
			class="pb-4 h-[98%] bg-white rounded-b-xl"
			[ngClass]="{'border border-t-0': origin && origin == 'show-post', 'h-[98%]': calendarView, 'h-[90%]': !calendarView}"
		>
      <app-post-create-preview
				class="overflow-auto flex"
        [ngClass]="{'h-[52vh]': calendarView, 'h-full': !calendarView}"
        *ngIf="selectedPlatformSource"
        [data]="preparePostDataForPreview()"
        [screenHeight]="screenHeight * 0.9"
        [screenWidth]="screenWidth * 0.9"
        [selectedPlateform]="selectedPlatformSource"
        [googleAdditionalData]="googleAdditionalData"
        [postIsCreated]="data.postIsCreated"
      ></app-post-create-preview>
    </div>
  </fieldset>
</div>
