import {
  Component,
  ElementRef,
  Input,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { Subscription } from 'rxjs';
import { UiPermissionsService } from 'src/app/services/utilities/ui-permissions.service';
import { Debug } from 'src/app/utils/debug';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-ui-permissions-mapper',
  templateUrl: './ui-permissions-mapper.component.html',
  styleUrls: ['./ui-permissions-mapper.component.scss'],
})
export class UiPermissionsMapperComponent {
  constructor(private uiPermissionsService: UiPermissionsService) {}
  @Input() systemPermissions!: any;
  @Input() appProfiles!: any;
  uiPermissions: any[] = [];
  newMapPressed: boolean = false;
  @ViewChild('newMapName') mapNameInput!: ElementRef<HTMLInputElement>;

  ngOnChanges(changes: SimpleChanges) {
    if (changes['displayMode']) {
      Debug.log('displaymode updated to ', this.displayMode);
    }
  }

  selectedUIPermission: any;
  subscriptions: Subscription[] = [];
  ngOnInit(): void {
    this.subscriptions.push(this.uiPermissionsService.getPermissions().subscribe((permissions) => {
      this.uiPermissions = permissions;
    }));
  }

  getCurrentSelectedGroup(): string {
    return this.uiPermissionsService.getGroup();
  }

  toggleUIPermissions(uiPermission: any): void {
    Debug.log('ui permission verification...');
    // Debug.log(this.uiPermissionsService.isActionsOnUserAccreditations(uiPermission))

    this.selectedUIPermission = uiPermission;
    if (this.displayMode == '') {
      this.displayMode = 'selected';
    }
    this.SetDisplayModeTo(this.displayMode);
  }

  isActionInUiPermissions(action: string): boolean {
    if (this.selectedUIPermission && this.selectedUIPermission.actions)
      return this.selectedUIPermission.actions?.includes(action);
    return false;
  }

  toggleAction(action: string): void {
    if (this.isActionInUiPermissions(action)) {
      this.selectedUIPermission.actions =
        this.selectedUIPermission.actions.filter((a: string) => a != action);
    } else {
      this.selectedUIPermission.actions.push(action);
    }
    //update the ui permissions json
    this.SetDisplayModeTo(this.displayMode);
    this.subscriptions.push(this.uiPermissionsService.updatePermissions(this.uiPermissions).subscribe());
  }

  toggleSelectAll() {
    if (this.isAllSelected()) {
      Debug.log('clearing the selection');
      // remove any action from  sysAcionSource that selectedUIpermission.actions currently have
      Debug.log('before clearing selection', this.selectedUIPermission.actions);
      Debug.log(
        'filterd',
        this.selectedUIPermission.actions.filter((action: any) =>
          this.sysActionSource.includes(action)
        )
      );

      this.selectedUIPermission.actions = [
        ...new Set(
          this.selectedUIPermission.actions.filter(
            (action: any) => !this.sysActionSource.includes(action)
          )
        ),
      ];
      Debug.log('after cleariung');
    } else {
      Debug.log('selecting all');
      // make sure every action in sysAcionSource
      this.selectedUIPermission.actions = [
        ...new Set([
          ...this.selectedUIPermission.actions,
          ...this.sysActionSource,
        ]),
      ];
    }
    Debug.log(
      'is All selected',
      this.isAllSelected(),
      'results : ',
      this.selectedUIPermission.actions
    );

   this.subscriptions.push( this.uiPermissionsService.updatePermissions(this.uiPermissions).subscribe());
    this.SetDisplayModeTo(this.displayMode);
    this.filtredValue = ''; // reset filter value when importing from filter
  }

  isAllSelected() {
    return (
      [...new Set(this.selectedUIPermission.actions)].length ==
      [...new Set(this.sysActionSource)].length
    );
  }

  renderToggleAllText() {
    if (this.displayMode == 'selected' && this.sysActionSource.length > 1)
      return 'Empty selection';
    if (this.displayMode == 'nonSelected' && this.sysActionSource.length > 1)
      return 'Select All';
    if (
      this.displayMode == 'filter' &&
      this.sysActionSource.length ==
        [...new Set(this.selectedUIPermission.actions)].length
    )
      return 'Empty selection';
    if (
      this.displayMode == 'filter' &&
      this.sysActionSource.length !=
        [...new Set(this.selectedUIPermission.actions)].length
    )
      return 'Select All';
    return '';
  }

  tmpMap = { name: '', actions: [] };
  newMap() {
    this.newMapPressed = true;
    setTimeout(() => this.mapNameInput.nativeElement.focus(), 0);
  }
  isMapAlreadyExists: boolean = false;
  validate() {
    const val = this.tmpMap.name.trim().replaceAll(' ', '.');
    // Debug.log(this.uiPermissions)
    this.isMapAlreadyExists =
      this.uiPermissions.filter((ui: any) => {
        return ui.name == val;
      }).length > 0;
  }
  insert() {
    this.tmpMap.name = this.tmpMap.name.trim().replaceAll(' ', '.');
    this.uiPermissions.unshift(this.tmpMap);
    this.newMapPressed = false;
    this.tmpMap = { name: '', actions: [] };
  }

  targetUiPermission: any = null;
  remove(uiPermission: any) {
    Swal.fire({
      title: 'Are you sure?',
      text: "You sure aboute removing '" + uiPermission.name + "' ?",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!',
    }).then((result) => {
      if (result.isConfirmed) {
        this.uiPermissions = this.uiPermissions.filter((uip: any) => {
          return uip.name != uiPermission.name;
        });
       this.subscriptions.push( this.uiPermissionsService
          .updatePermissions(this.uiPermissions)
          .subscribe());
        this.selectedUIPermission = null;
      }
    });
  }

  displayMode: string = 'selected';
  sysActionSource: any[] = [];
  unUsedAtions: string[] = [];
  sysActions: any[] = [];
  SetDisplayModeTo(mode: string): void {
    this.displayMode = mode;

    this.sysActions = [];
    Debug.log('SETTING DISPLAY ', this.systemPermissions);
    for (let permission of this.systemPermissions) {
      this.sysActions.push(permission.action);
    }
    Debug.log(
      'sysActions: ',
      this.sysActions,
      'this.selectedUIPermission',
      this.selectedUIPermission
    );

    const uiPermissionsNonSelectedActions = []; // non-selected actions for current map
    for (let uiAction of this.sysActions) {
      if (!this.selectedUIPermission.actions.includes(uiAction)) {
        uiPermissionsNonSelectedActions.push(uiAction);
      }
    }

    this.unUsedAtions = this.getUnusedActionsCount();

    if (mode == 'unused') {
      this.sysActionSource = this.unUsedAtions;
    }

    if (mode == 'selected') {
      this.sysActionSource = this.selectedUIPermission.actions;
    }

    if (mode == 'nonSelected') {
      this.sysActionSource = uiPermissionsNonSelectedActions;
    }

    if (mode == 'filter') {
      this.sysActionSource = this.sysActions;
    }

    //assuring the actions list has no duplicates
    this.sysActionSource = [...new Set(this.sysActionSource)];
  }

  getUnusedActionsCount() {
    const usedActions: any[] = [];
    for (let UImap of this.uiPermissions) {
      usedActions.push(...UImap.actions);
    }
    //convert the used actions into set
    const usedActionsSet = new Set(usedActions);
    const unusedActions = this.sysActions.filter(
      (action) => !usedActionsSet.has(action)
    );
    return unusedActions;
  }

  filtredValue: string = '';
  filterActions() {
    this.sysActionSource = [];
    Debug.log(this.filtredValue);
    if (this.filtredValue == '') {
      this.SetDisplayModeTo(this.displayMode);
    }

    const regex = new RegExp(this.filtredValue, 'i');
    this.sysActionSource = this.sysActions.filter((action: string) => {
      return action.toLowerCase().includes(this.filtredValue.toLowerCase());
    });
    // this.data = this.initialData.filter(d => regex.test(d.name));
    // this.sysActionSource = this.sysActions.filter((action:string)=>{regex.test(action)})
  }

  selectedProfile: any = '';
  loadSelectedProfileActions() {
    Debug.log(
      'loading actions of ',
      this.selectedProfile,
      'in',
      this.appProfiles
    );
    const profileActions = [];
    for (let profile of this.appProfiles) {
      if (profile.name === this.selectedProfile) {
        for (let permission of profile.permissions) {
          profileActions.push(permission.action);
        }
      }
    }

    this.selectedUIPermission.actions = profileActions;
    Debug.log(this.uiPermissions);
    this.SetDisplayModeTo(this.displayMode);
    this.uiPermissionsService.updatePermissions(this.uiPermissions).subscribe();
  }
  ngOnDestroy() {
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }
}
