import { Component } from '@angular/core';
import { GroupService } from 'src/app/services/repositories/group.service';
import { UserService } from 'src/app/services/repositories/user.service';
import { SocialMediaAPIService } from 'src/app/services/utilities/socialMediaApi.service';
import { TranslationService } from 'src/app/services/utilities/translation.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-queue-manager',
  templateUrl: './queue-manager.component.html',
  styleUrls: ['./queue-manager.component.scss']
})
export class QueueManagerComponent {
  jobs: any = [];
  subscriptions: any = [];
   constructor(
     private userService: UserService,
     private groupService: GroupService,
     private translationService: TranslationService,
     private socialMediaAPI: SocialMediaAPIService
   ) {}
   env: string = environment.env;

   ngOnInit(): void {
    console.log("loading jobs...")
    this.fetchJobs()
   }
  fetchJobs() {
    // Fetch jobs from your API
    this.jobs = [];
    this.subscriptions.push(this.socialMediaAPI.fetchBullJsQueues().subscribe((res: any) => {
      // Add jobs to the array
      this.jobs.push({
        ...res.postQueue,
        label: '[API] save created Post on socialmedia page',
        name: 'postQueue',
      });
      this.jobs.push({
        ...res.syncPostQueue,
        label: '[API] get All post and page insights',
        name: 'syncPostQueue',
      });
      this.jobs.push({
        ...res.postAnalyticsQueue,
        label: '[API] get posts individual insights',
        name: 'postAnalyticsQueue',
      });
      this.jobs.push({
        ...res.passwordResetTokenQueue,
        label: '[Email] Password reset request deleter',
        name: 'passwordResetTokenQueue',
      });
      this.jobs.push({
        ...res.connectedUsersQueue,
        label: '[Token] Delete user from connected table if token is expired',
        name: 'connectedUsersQueue',
      });
    }));
  }

  
  clearJobs(queueName: string, group: string) {
    console.log(queueName, group);
   this.subscriptions.push( this.socialMediaAPI
      .clearJob(queueName, group)
      .subscribe((response: any) => {
        // Handle the response, like updating the UI or displaying a success message
        console.log(`${queueName} jobs cleared for ${queueName}`);
        this.fetchJobs(); // Optionally refresh job data
      }));
  }
}
