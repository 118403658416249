import { Injectable } from '@angular/core';
import { BehaviorSubject,Subject, Observable, catchError, throwError } from 'rxjs';
import { Debug } from 'src/app/utils/debug';
import { environment } from 'src/environments/environment';
@Injectable({
  providedIn: 'root'
})
export class UiStateService {
  lsData:any
  platformsFilter :any={}
  postTypes={}
  dateRange!:{}
  constructor() {
    //load localstorage data
    this.lsData = localStorage.getItem(environment.sessionData)||{}
    this.lsData = JSON.parse(this.lsData)
    // console.log(this.lsData)
    if(!this.lsData?.filterData){
      this.lsData.filterData={}
      this.lsData.filterData.postTypes = {
        isPlanned:true,
        isPosted:true,
        isDraft:true,
      }
      this.lsData.filterData.platformsFilter={
        isFacebookON: true,
        isLinkedinON: true,
        isGoogleON: true,
        isInstagramON: true,
      }
      const currentYear = new Date().getFullYear();
      const startDate = new Date(currentYear - 2, 0, 1); // January 1st, two years ago
      const endDate = new Date(currentYear, 11, 31); // December 31st of the current year
      this.lsData.filterData.dateRange={
        startDate:startDate,
        endDate:endDate
      }
    }
    this.updatePostTypeFilter(this.lsData?.filterData.postTypes);
    this.updatePlatformsFilter(this.lsData?.filterData.platformsFilter);
    this.updateDateRange(this.lsData?.filterData.dateRange);
   }
  //topbar first selected
  private currentRootGroup = new BehaviorSubject<any>(null)
  currentRootGroup$ = this.currentRootGroup.asObservable();
  public dateRangeUpdateSubject= new BehaviorSubject<any>({startDate:Date,endDate:Date});
  dateRangeUpdate$ = this.dateRangeUpdateSubject.asObservable();
  private groupScopes = new BehaviorSubject<any>(null)
  groupScopes$ = this.groupScopes.asObservable()
  public platformsFilterUpdated = new BehaviorSubject<any>(null);
  platformsFilterUpdated$ = this.platformsFilterUpdated.asObservable();
  updateCurrentRootGroup(group: any) {
    // console.log("updateCurrentRootGroup on UI service",group)
    this.currentRootGroup.next(group);
  }
  emitEvent(data: any) {
    this.platformsFilter = data
    this.platformsFilterUpdated.next(data)
  }
  public postStateFilterUpdated = new BehaviorSubject<any>(null);
  postStateFilterUpdated$ = this.postStateFilterUpdated.asObservable();
  public postTypesUpdated = new  BehaviorSubject<any>(null)
  postTypesUpdated$ = this.postTypesUpdated.asObservable();
  updateDateRange(data:any){
    this.dateRangeUpdateSubject.next(data)
    this.lsData.filterData.dateRange=data
    //save date range to localstorage
    // console.log("saving platforms...",(this.lsData.filterData))
    if(data.startDate && data.endDate){
      // console.log("saving dateranges...",this.lsData)
      localStorage.setItem(environment.sessionData, JSON.stringify(this.lsData));
    }
  }
  updateGroupScopes(data:any){
    this.lsData.groupScopes = data
    this.groupScopes.next(data)
    console.log("saving group Scopes...",this.lsData)
    localStorage.setItem(environment.sessionData, JSON.stringify(this.lsData));
  }
  updatePlatformsFilter(newFilter: any){
    this.platformsFilterUpdated.next(newFilter)
    this.platformsFilter = newFilter
    this.lsData.filterData.platformsFilter = newFilter
    //save the updatedpost types to local storage
    // console.log("saving platforms...",this.lsData)
    localStorage.setItem(environment.sessionData, JSON.stringify(this.lsData));
  }
  updatePostTypeFilter(newFilter: any) {
    this.postTypesUpdated.next(newFilter);
    this.postTypes = newFilter;
    //save the updatedpost types to local storage
    // console.log("saving postTypes...",this.lsData)
    this.lsData.filterData.postTypes=newFilter;
    localStorage.setItem(environment.sessionData, JSON.stringify(this.lsData));
  }
}