<div class="bg-white rounded-3xl h-[81.5vh]">
	<!-- <div class="grid grid-rows-1 grid-cols-12 h-16 items-center">
		<span class="text-center col-span-3 grid grid-cols-6 row-span-1 text-gray-600 font-semibold cursor-pointer" [title]="getTranslation('generic.showTheYear')+' '+currentDate.getFullYear()"> 
			<span class="col-span-1 cursor-pointer" (click)="prevMonth()">{{'<'}}</span>
			<span class="col-span-4 mx-auto">
				{{toTitleCase(getTodayMonthString())+" "+currentDate.getFullYear()   }}
			</span>
			<span class="col-span-1 cursor-pointer" (click)="nextMonth()">{{'>'}}</span>
		</span>
		<span class="col-span-3">
			<span class="flex space-x-4">
				<app-generic-drop-down (itemSelected)="onCalendarModeChanged($event)"  [data]="['date.year','date.month','date.week','date.day']"/>
				<app-drop-down-list-items 
				[label]="'generic.platform'" 
				[tailWindClasses]="'relative border-2 p-2 text-cliking_blue text-sm font-semibold border-cliking_blue rounded-lg'" 
				[items]="['Linkedin','Google','Facebook','Instagram']" (itemToggled)="updatePlatforms($event)"
				[checkedItemsStatus]="currentPlaTformsFilters"
				/>
			</span>
		</span>
	</div> -->
	<app-calendar-filter [screenHeight]="screenHeight" [month]="toTitleCase(getTodayMonthString())" [year]="currentDate.getFullYear()" (previewDate)="prevMonth()" (nextDate)="nextMonth()"/>
	<div class="grid grid-cols-7">
		<span [style.font-size.px]="screenWidth*0.65/100 + screenHeight*0.65/100" class="col-span-1 text-gray-800 font-medium text-center">
		<!-- <span class="cursor-pointer" [style.font-size.px]="screenWidth*0.65/100 + screenHeight*0.65/100" [style.margin-right.px]="screenWidth*2/100" (click)="prevMonth()">←</span> -->
		{{'dates.days.full.MONDAY'|translate}}</span>
		<span [style.font-size.px]="screenWidth*0.65/100 + screenHeight*0.65/100" class="col-span-1 text-gray-800 font-medium text-center">{{'dates.days.full.TUESDAY'|translate}}</span>
		<span [style.font-size.px]="screenWidth*0.65/100 + screenHeight*0.65/100" class="col-span-1 text-gray-800 font-medium text-center">{{'dates.days.full.WEDNESDAY'|translate}}</span>
		<span [style.font-size.px]="screenWidth*0.65/100 + screenHeight*0.65/100" class="col-span-1 text-gray-800 font-medium text-center">{{'dates.days.full.THURSDAY'|translate}}</span>
		<span [style.font-size.px]="screenWidth*0.65/100 + screenHeight*0.65/100" class="col-span-1 text-gray-800 font-medium text-center">{{'dates.days.full.FRIDAY'|translate}}</span>
		<span [style.font-size.px]="screenWidth*0.65/100 + screenHeight*0.65/100" class="col-span-1 text-gray-800 font-medium text-center">{{'dates.days.full.SATURDAY'|translate}}</span>
		<span [style.font-size.px]="screenWidth*0.65/100 + screenHeight*0.65/100" class="col-span-1 text-gray-800 font-medium text-center">{{'dates.days.full.SUNDAY'|translate}} 
			<!-- <span class="cursor-pointer" [style.font-size.px]="screenWidth*0.7/100 + screenHeight*0.7/100" [style.margin-left.px]="screenWidth*1/100" (click)="nextMonth()">→</span> -->
		</span>
	</div>
	<div class="calender-days grid grid-cols-7 grid-rows-5" [style.height.px]="screenHeight*70/100">
		<span class="border-[1px] border-[#eaecef] w-full col-span-1 row-span-" 
			(click)="showPostsDay(i, d)"
			[ngClass]="{
				'rounded-bl-2xl':i==28 && monthData.length==35 ||i==35 && monthData.length==42,
				'rounded-br-2xl':i==34 && monthData.length==35 ||i==41 && monthData.length==42,
				'text-[#777171] bg-[#fafafa]':  idDayFromThisMonth(d.day,i),
				'text-[#544e61] bg-white':  !idDayFromThisMonth(d.day,i),
				'cursor-pointer day-preview' : !idDayFromThisMonth(d.day,i) && d.data?.length>0,
				'border-cliking_blue' : i==selectedDay && d.data?.length>0
			}"
			[style.height]="monthData.length == 35 ? screenHeight*14/100+'px' : screenHeight*11.5/100+'px'"
			*ngFor="let d of monthData; let i = index"
		>
			<span
				class="font-medium day-preview"
			>
				<span class="flex justify-between day-preview mx-2">
					<span (click)="goToDay(d,i)" 
						[style.font-size.px]="screenHeight*0.6/100+screenWidth*0.6/100"
						class="cursor-pointer text-center"
						[ngClass]="{
							' text-cliking_blue':isCurrentDay(d),
							'hover:bg-[#e6e6e6] hover:rounded-xl p-[1px]':!idDayFromThisMonth(d.day,i),
						}"
					>
						{{d.day.toString().padStart(2,'0')}}
					</span>
					<span *ngIf="d.data?.length>2" class="text-cliking_blue text-[0.8vw] day-preview"> +{{(monthData.length > 35 || screenHeight<810 || screenWidth<1201) ? d?.data?.length-1 : d?.data?.length-2}} posts</span>
					<span 
						*ngIf="d?.data && d.data.length > 0 && d.data[0]?.NbOfPosts > 2" 
						class="text-cliking_blue text-[0.8vw] day-preview">
						+{{ monthData.length > 35 || screenHeight<1000 || screenWidth<1201
							? d.data[0].NbOfPosts - 1 
							: d.data[0].NbOfPosts - 2 }} posts
					</span>
				</span>
				<div>
					<!-- post label -->
					<div class="-mt-[6px] mx-1 day-preview text-[0.9vw]" *ngIf="d.data?.length>0">
						<div
							[ngClass]="{
								'bg-[#e9faf8] text-white': d.data[0].publishingDatetime!=null,
								'bg-[#fff8e9] text-black': d.data[0].publishingDatetime==null
							}"
							class="px-2 pb-1 rounded-md w-full h-full m-auto mt-2 day-preview" >
							<h3 class="truncate text-[0.9vw] w-[95%] text-[#544e61] day-preview" >
								{{d.data[0].title}}
							</h3>
							<div *ngIf="d.data?.length==1 || monthData.length > 35 || screenHeight<1000 || screenWidth<1201" class="text-[0.8vw] text-gray-400 line-clamp-2 day-preview" [ngClass]="{'truncate':monthData.length > 35 || screenHeight<1000 || screenWidth<1201}" >
								{{d.data[0].text}}
							</div>
							<h3>
							<!-- post target platforms -->
								<ul>
									<li 
										class="grid grid-cols-10 day-preview"
									>
									<span class="col-span-5 grid grid-cols-4 day-preview" >
										<app-social-media-round-icon *ngFor="let platform of getUniquePlateforms(d.data[0].SocialPlatforms)" class="col-span-1 day-preview" [calendar]="true" [smallScreen]="screenHeight<810 || screenWidth<1201" [source]="platform" [greyedOut]="!(platform == 'facebook' && currentPlaTformsFilters.isFacebookON || platform == 'google' && currentPlaTformsFilters.isGoogleON || platform == 'linkedin' && currentPlaTformsFilters.isLinkedinON || platform == 'instagram' && currentPlaTformsFilters.isInstagramON)"  ></app-social-media-round-icon>
									</span>
										<span class="col-span-3 day-preview"></span>
										<span class="col-span-2 text-black text-[0.8vw] day-preview">{{extractHour(d.data[0])}}</span>
									</li>
								</ul>
							</h3>
						</div>
						<div *ngIf="d.data?.length>=2 && (monthData.length == 35 && screenHeight>1000 && screenWidth>1201)"
							[ngClass]="{
								'bg-[#e9faf8] text-white': d.data[1].publishingDatetime!=null,
								'bg-[#fff8e9] text-black': d.data[1].publishingDatetime==null
							}"
							class="px-2 pb-1 rounded-md w-full h-full m-auto mt-2 day-preview" >
							<h3 class="truncate text-[0.9vw] w-[95%] text-[#544e61] day-preview">
								{{d.data[1].title}}
							</h3>
							<div *ngIf="d.data?.length==1" class="text-[0.8vw] text-gray-400 line-clamp-2 day-preview">
								{{d.data[1].text}}
							</div>
							<h3>
							<!-- post target platforms -->
								<ul>
									<li 
										class="grid grid-cols-10 day-preview"
									>
										<span class="col-span-5 grid grid-cols-4 day-preview">
											<app-social-media-round-icon *ngFor="let platform of getUniquePlateforms(d.data[1].SocialPlatforms)" class="col-span-1 day-preview" [calendar]="true" [source]="platform" [greyedOut]="!(platform == 'facebook' && currentPlaTformsFilters.isFacebookON || platform == 'google' && currentPlaTformsFilters.isGoogleON || platform == 'linkedin' && currentPlaTformsFilters.isLinkedinON || platform == 'instagram' && currentPlaTformsFilters.isInstagramON)"  ></app-social-media-round-icon>
										</span>
										<span class="col-span-3 day-preview"></span>
										<span class="col-span-2 text-black text-[0.8vw] day-preview">{{extractHour(d.data[1])}}</span>
									</li>
								</ul>
							</h3>
						</div>
					</div>
				</div>
			</span>
		</span>
		<div *ngIf="isDayPostsPannelVisible">
			<app-day-posts-preview
			[data]="temporary? [] : postsOfDay"
			[previewHeight]="calculatedHeight"
			[xpos]="pannelPositions.x"
			[ypos]="pannelPositions.y"
			[selectedDate]="temporary? selectedDayDate: ''"
			[isVisible]="true"
			(visibilityChange)="popoverPostsVisibility($event)"
			[user]="user"
			[currentPlaTformsFilters]="currentPlaTformsFilters"
			[groupScopes]="groupScopes"
			[selectedDayDate]="temporary? '' : selectedDayDate"
			/>
		</div>
	</div>   
</div>
<app-loader [isLoading]="isLoading"/>
