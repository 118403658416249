import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-social-media-round-icon',
  templateUrl: './social-media-round-icon.component.html',
  styleUrls: ['./social-media-round-icon.component.scss']
})
export class SocialMediaRoundIconComponent {

	@Input() source!: string;
	@Input() greyedOut: boolean | null = null ;
	@Input() calendar: boolean = false ;
	@Input() smallScreen: boolean = false ;
  dimension: string = '16';

  ngOnInit(){
    this.defineDimension();
  }

  defineDimension(){
    if(this.calendar){
      this.dimension = '14'
    }
    if(this.smallScreen)
      this.dimension = '12'
  }
}
