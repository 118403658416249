import { Component ,HostListener} from '@angular/core';
import { Subscription } from 'rxjs';
import { IResponse } from 'src/app/interfaces/IResponse';
import { GroupService } from 'src/app/services/repositories/group.service';
import { PostService } from 'src/app/services/repositories/post.service';

@Component({
  selector: 'app-instagram-stats',
  templateUrl: './instagram-stats.component.html',
  styleUrls: ['./instagram-stats.component.scss']
})
export class InstagramStatsComponent {
  data: any[] = [];
  skippedInsights:string[]=[
    "id",
    "profile_picture_url",

  ]
  private subscriptions :Subscription[]=[]
  constructor(protected postService: PostService,private groupService: GroupService,){}
  socialMedias: any[] = [];

  ngOnInit(){
    this.data = [
      
    ];
    this.subscriptions.push(
      this.postService.currentGroups.subscribe((g: number[]) => {
        if (g.length == 0)
          g = this.postService.getGroupScopesFromLocalStorage();
        
        if (g.length > 0) {
          this.groupService.getGroupByIds(g).subscribe((res: IResponse) => {

             this.getRecursiveSocialMediaPages(res.data);
             
            if(this.socialMedias.length > 0){
              
              this.socialMedias.forEach((page: any)=>{
                if(page.source == 'instagram'){

                  if(page.analytics!== undefined){
                    page.analytics.forEach((insight:any) =>{
                      //check if this.data has the insight
                      const savedInsights = this.data.map((i:any)=>i.label)
                     
                      if(!savedInsights.includes(insight.name) && !this.skippedInsights.includes(insight.name)){
    
                        this.data.push({label:insight.name,value:insight.value})
                      }
                      else{
                        this.data.forEach((savedItem:any) =>{
                          
                          if(savedItem.label === insight.name){
                            savedItem.value = Number(savedItem.value||0) + Number(insight.value);
                          }
                        });
                      }
                    })
                  }
                }
              })
            }
          });
        }
      })
    );

  }

  getRecursiveSocialMediaPages(groups: any[]): any[] {
    groups = !Array.isArray(groups) ? [groups] : groups;
    let socialMediaPages: any[] = [];
    groups.forEach((group: any) => {
      // Add the group's social media pages if any
      if (group.socialMedia && group.socialMedia.length > 0) {
        socialMediaPages = [...socialMediaPages, ...group.socialMedia];
      }

      // Recursively add social media pages from children
      if (group.children && group.children.length > 0) {
        for (let child of group.children) {
          socialMediaPages = [
            ...socialMediaPages,
            ...this.getRecursiveSocialMediaPages(child),
          ];
        }
      }
    });
    //removing any duplicates
    const pagesArrayString: string[] = socialMediaPages.map((sm: any) =>
      JSON.stringify(sm)
    );
    const uniquePagesArrayString = [...new Set(pagesArrayString)];
    this.socialMedias = uniquePagesArrayString.map((sm: any) =>
      JSON.parse(sm)
    );
    return uniquePagesArrayString.map((sm: any) => JSON.parse(sm));
  }

  gVal(data: any){
    return data && Number(data)? Number(data): 0
  }


  // delete this part 
  isPlanned: boolean = true; 
  isPost: boolean = true; 
  isDraft: boolean = true; 
  windowWidth: number = window.innerWidth;
  isLargeView: boolean = false;
  isMediumView: boolean = false;
  isSmallView: boolean = false;
  isVerySmallView: boolean = false;

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.windowWidth = window.innerWidth;

    if (1232 < this.windowWidth && this.windowWidth < 1375) {
      // Medium view
      this.isLargeView = false;
      this.isMediumView = true;
      this.isSmallView = false;
      this.isVerySmallView = false;
    } else if (1375 <= this.windowWidth && this.windowWidth < 1515) {
      // Large view
      this.isLargeView = true;
      this.isMediumView = false;
      this.isSmallView = false;
      this.isVerySmallView = false;
    } else if (this.windowWidth >= 1515) {
      // Extra large view
      this.isLargeView = false;
      this.isMediumView = false;
      this.isSmallView = false;
      this.isVerySmallView = false;
    } else if (this.windowWidth >= 1100 && this.windowWidth < 1232) {
      // Small view (windowWidth <= 1232)
      this.isLargeView = false;
      this.isMediumView = false;
      this.isSmallView = true;
      this.isVerySmallView = false;
    } else {
      this.isLargeView = false;
      this.isMediumView = false;
      this.isSmallView = false;
      this.isVerySmallView = true;
    }
  }

  togglePost() {
    this.isPost = !this.isPost;
  }
  togglePlanned() {
    this.isPlanned = !this.isPlanned;
  }
  // end delete
}
