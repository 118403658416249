import { Component } from '@angular/core';
import { Subscription } from 'rxjs';
import { GroupService } from 'src/app/services/repositories/group.service';
import { PostService } from 'src/app/services/repositories/post.service';
import { AuthService } from 'src/app/services/utilities/auth.service';
import { TranslationService } from 'src/app/services/utilities/translation.service';
import { UiPermissionsService } from 'src/app/services/utilities/ui-permissions.service';
import { Debug } from 'src/app/utils/debug';
import { ScreenSizeService } from 'src/app/services/utilities/screen-size.service';

import Swal from 'sweetalert2';

@Component({
  selector: 'app-main-layout',
  templateUrl: './main-layout.component.html',
  styleUrls: ['./main-layout.component.scss'],
})
export class MainLayoutComponent {
  constructor(
    private authService: AuthService,
    private uiPermissionService: UiPermissionsService,
    private postService: PostService,
    private groupService: GroupService,
    private translation: TranslationService,
    private screenSizeService: ScreenSizeService
  ) {}
  authenticatedUser!: any;
  selectedGroups!: any;
  subscriptions:Subscription[] = [];
  screenHeight: number = 0;
  ngOnInit() {
    let tmpGroup: any = {};
    this.subscriptions.push(this.screenSizeService.screenHeight$.subscribe(
      (height: number) => (this.screenHeight = height)
    ));
    this.subscriptions.push(this.authService.loadCurrentUser().subscribe((res) => {
      // this.authService.currentUserSubject.next(res.data);
      // console.log('main layout', res);
      
      let userAccreditations = res?.data?.accreditations;
      
      if (res && res.data) {
        this.authenticatedUser = res.data;
        //get first group of from user accreditations and set it to active group

      
        if (this.authenticatedUser.isAdmin || userAccreditations.length == 1) {
          this.subscriptions.push(this.groupService.getGroups().subscribe((groups: any) => {
            if (groups && groups.data && groups.data.length > 0) {
              // Debug.log('user is Admin setting currentGroup to :',groups.data[0])
              this.authenticatedUser.accreditations = [];
              for (let group of groups.data) {
                this.authenticatedUser.accreditations.push({ group });
              }

              tmpGroup = groups.data[0];
              {
                if (this.postService.currentGroupSource.getValue().id == 0)
                  this.postService.updateGroup(tmpGroup);
              }
            }
          }));
        } else {
          //if user has no accreditation we show message NO access to the application and log him out

          tmpGroup = this.authenticatedUser.accreditations[0].group;

          if (
            this.postService.currentGroupSource.getValue().id != tmpGroup.id
          ) {
            this.postService.updateGroup(tmpGroup);
          }
          Debug.log('non Admin User  accreditations', userAccreditations);
        }

       this.subscriptions.push( this.postService.currentGroups.subscribe((groupIds: number[]) => {
          this.selectedGroups = groupIds;

        }));
      }
    }));
  }

  ngOnDestroy() {
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }
}
