import { Injectable } from '@angular/core';
import { Subscription } from 'rxjs';
import { TranslationService } from 'src/app/services/utilities/translation.service';

@Injectable()
export class BaseComponent {
  constructor(protected translationService: TranslationService) {}
  // Common properties
  groupSubscription!: Subscription;

  getTranslation(key: string): string {
    return this.translationService.t(key);
  }

  utcToCurrentTimeZone(date: Date) {
    // Check if the date is valid
    // Debug.log(date)
    const dateObj = new Date(date);
    if (isNaN(dateObj.getTime())) {
      console.error('Invalid date provided:', date);
      return 'Invalid date'; // Or handle this case as needed
    }

    const options: Intl.DateTimeFormatOptions = {
      year: 'numeric', // 'numeric' or '2-digit'
      month: '2-digit', // 'numeric' or '2-digit'
      day: '2-digit', // 'numeric' or '2-digit'
      hour: '2-digit', // 'numeric' or '2-digit'
      minute: '2-digit', // 'numeric' or '2-digit'
      second: '2-digit', // 'numeric' or '2-digit'
      hour12: false, // boolean
      timeZoneName: 'short', // 'short' or 'long'
    };

    return new Intl.DateTimeFormat('default', options).format(dateObj);
  }

  toTitleCase(str: string) {
    return str
      .toLowerCase()
      .split(' ')
      .map((word) => {
        return word.charAt(0).toUpperCase() + word.slice(1);
      })
      .join(' ');
  }

  ngOnDestroy(): void {
    if (this.groupSubscription) {
      this.groupSubscription.unsubscribe();
    }
  }
}
