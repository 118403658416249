import { Component, ElementRef, EventEmitter, Input, Output } from '@angular/core';
import { TranslationService } from 'src/app/services/utilities/translation.service';
import { CalenderBaseComponent } from 'src/app/components/base/calendar.component';
import { UiStateService } from 'src/app/services/utilities/ui-state.service';
import { PostService } from 'src/app/services/repositories/post.service';
import { CalendarService } from 'src/app/services/utilities/calendar.service';
import { Subscription } from 'rxjs';
import { ICON } from 'src/app/interfaces/ModalIcon';
import { WebSocketService } from 'src/app/services/utilities/websocket.service';

@Component({
  selector: 'app-post-day-label',
  templateUrl: './post-day-label.component.html',
  styleUrls: ['./post-day-label.component.scss'],
})

export class PostDayLabelComponent extends CalenderBaseComponent{
  constructor(
    protected override uiStateService: UiStateService,
    protected override postService: PostService,
    protected override translationService: TranslationService,
    protected override calendarService: CalendarService,
    private webSocketService: WebSocketService,
    private elementRef: ElementRef
  ) {
    super(translationService, calendarService, postService, uiStateService);
  }
  linkedinData: any = null;
  googleData: any = null;
  facebookData: any = null;
  instagramData: any = null;
  mediaAllpreview: any =  null;
  postHour: string = '00';
  postMinute: string = '00';
  isGroupNamesToolTip: boolean = false;
  isTruncated: boolean = false;
  time: string = "";
  confirmDeleteModalVisible: boolean = false;
  modalIcon: ICON = ICON.delete;
  modalHeading: string = '';
  modalSubHeading: string = '';
  modalHideElements: boolean = false;
  modalShowLoadingDots: boolean = false;
  isOpen: boolean = false;
  subscriptions: Subscription[] = [];
  @Output() hidedPost = new EventEmitter<any>();
  @Output() showedPost = new EventEmitter<any>();
  @Input() currentGroups: any;
  @Input() post: any;
  @Input() isSelected: boolean = false;

  override ngOnInit(){
    super.ngOnInit();
    this.choiceMediaPreview();
    
    const date = new Date(this.post.publishingDatetime ? this.post.publishingDatetime : this.post?.expectedPublishingDatetime);
    this.postHour = String(date.getUTCHours()).padStart(2, '0');
    this.postMinute = String(date.getUTCMinutes()).padStart(2, '0');
    this.calculatePastTimeFromNow();
    this.webSocketService.connect();
    this.webSocketService.listen('postDeletionCompleted', (data: any) => {
      
      const { post, fullyDeleted } = data;


      const postId = parseInt(post.id);
      
      const remainingJobs = (this.deletionJobCounts.get(postId) || 0) - 1;
      console.log("remainingJobs", remainingJobs)
      if (remainingJobs <= 0) {
        // Remove the element if remainingJobs is 0 or less
        this.deletionJobCounts.delete(postId);
        this.modalIcon = ICON.success;
        this.modalShowLoadingDots = false;
        this.modalHeading = 'generic.deleted';
        this.modalSubHeading = ' ';
        setTimeout(() => {
          // this.confirmDeleteModalVisible = false;
          // this.getDayPosts();
        }, 1500);
      } else {
        // Otherwise, update the count
        this.deletionJobCounts.set(postId, remainingJobs);
      }
    });
  }

  choiceMediaPreview(){
    if(this.post?.googleMedias.length > 0){
      this.mediaAllpreview = this.post?.googleMedias[0];
      return
    }
    
    if(this.post?.linkedinMedias.length > 0){
      this.mediaAllpreview = this.post?.linkedinMedias[0];
      return
    }
    
    if(this.post?.facebookMedias.length > 0){
      this.mediaAllpreview = this.post?.facebookMedias[0];
      return
    }
    
    if(this.post?.instagramMedias.length > 0){
      this.mediaAllpreview = this.post?.instagramMedias[0];
      return
    }
  }

  calculatePastTimeFromNow() {
    let postTime = '';
    let futurePost = false;
    postTime = this.post.publishingDatetime ? this.post.publishingDatetime : this.post?.expectedPublishingDatetime;
    const postTimeUtc = new Date(postTime);

    const now = new Date(); // Current time

    let delta = Math.abs(now.getTime() - postTimeUtc.getTime()) / 1000; // Delta in seconds

    if (postTimeUtc > now) {
      // Post is in the future
      futurePost = true;
    }

    // Calculate differences and round up if necessary
    let unit = '';
    let value = 0;

    if (delta >= 3600 * 24 * 365) {
      value = Math.floor(delta / (3600 * 24 * 365));
      unit = 'year';
    } else if (delta >= 3600 * 24 * 30) {
      value = Math.floor(delta / (3600 * 24 * 30));
      unit = 'month';
    } else if (delta >= 3600 * 24) {
      value = Math.floor(delta / (3600 * 24));
      unit = 'day';
    } else if (delta >= 3600) {
      value = Math.floor(delta / 3600);
      unit = 'hour';
    } else if (delta >= 60) {
      value = Math.floor(delta / 60);
      unit = 'minute';
    } else {
      value = Math.floor(delta);
      unit = 'second';
    }

    // Format output
    let output = `${this.getTranslation(
      futurePost ? 'generic.PostedIn' : 'dates.relative.hasBeen'
    )} ${value}${this.getTranslation(
      `dates.timeUnits.short.${unit}`
    )} ${this.getTranslation('generic.on')} `;
    this.time = output.trim();
  }

  ngAfterViewInit() {
    this.checkTruncation();
  }

  checkTruncation() {
    const element = this.elementRef.nativeElement.querySelector('.truncate-text');
    if (element) {
      this.isTruncated = element.scrollWidth > element.clientWidth;
    }
  }

  useAsNewPostTemplate() {
       const postId = this.post.id
       const mediaQuery = `/posts?useAs=update&postId=${postId}`;
       window.location.href = mediaQuery;
  }

  duplicatePost() {
    this.subscriptions.push(this.postService.duplicatePost(this.post.id).subscribe({
      next: (newPost) => {
        // const mediaQuery = `/library`;
        // window.location.href = mediaQuery;
        // this.getDayPosts();
      },
      error: (err) => {
        console.error("Error duplicating post:", err);
      },
    }));
  }
  
  private deletionJobCounts = new Map<number, number>(); // keep track of which post was deleted and how many times (i.e. on how many pages)
  
  deletePost(post: any, selectedSocialMediasIds: any[] | null) {
    console.log(`deleting post `, post);

    // Initialize the job count for the post
    if (selectedSocialMediasIds) {
      this.deletionJobCounts.set(post.id, selectedSocialMediasIds.length);
    }

    this.subscriptions.push(this.postService.deletePost(post, selectedSocialMediasIds).subscribe((res) => {
      if (res.success) {
        //change modal icon and heading and hide them after 1.5 s
        this.modalIcon = ICON.delete;
        this.modalHeading = 'posts.postBeingDeleted';
        this.modalSubHeading = ' ';
      }
    }));
  }

  override ngOnDestroy() {
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }
  
  confirmPostRemoval(event: any) {
    console.log("confirmPostRemoval", event);
    this.modalHideElements = true
    this.modalShowLoadingDots = true
    this.modalIcon = ICON.delete;
    this.modalHeading = 'posts.postBeingDeleted';
    this.modalSubHeading = ' ';
    this.deletePost(this.post, event?.selected || null);
  }
}
