<div *ngIf="isVisible" class="h-60 overflow-y-auto bg-white border border-[3px] border-cliking_blue p-2 rounded-lg text-xs">
	<div role="tablist" class="tabs tabs-lifted">
		<!-- Tab Headers -->
		<input
			*ngFor="let item of getGroupHeads(); let i = index"
			type="radio"
			name="emojiTab"
			role="tab"
			class="tab"
			[checked]="i === currentTabIndex"
			[attr.aria-label]="item"
			(change)="updateGroupHead(item, i)"
		/>
	</div>

	<!-- Tab Contents -->
	<div class="p-4 bg-gray-50 rounded-lg shadow-lg">
		<div *ngFor="let group of groups; let i = index" class="mb-4" [hidden]="i !== currentTabIndex">
			<div>
				<span *ngFor="let emoji of group" class="text-xl m-2 p-1 cursor-pointer hover:bg-cliking_blue  rounded-md">
					<span class="grid-cols-1 my-2" (click)="picked(emoji)">{{emoji}}</span>
				</span>
			</div>
		</div>
	</div>
</div>