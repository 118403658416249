import {
  Component,
  EventEmitter,
  Input,
  Output,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { Debug } from 'src/app/utils/debug';

@Component({
  selector: 'app-mini-media-browser',
  templateUrl: './mini-media-browser.component.html',
  styleUrls: ['./mini-media-browser.component.scss'],
})
export class MiniMediaBrowserComponent {
  @Input() files: any[] = [];
  @Input() isGooglePageSelected: boolean = false;
  @Input() metaData: any[] = [];
  @Input() isVisible: boolean = false;
  @Input() onlyImages: boolean = false;
  @Input() currentMediaBrowserContext: any = null;
  @Input() mediaType: string = 'all'; // Default to 'all'
  @Output() onfileConfirmed = new EventEmitter();
  @Output() closed = new EventEmitter();

  isSkipped: boolean = false;
  BrowsableFiles = this.files;
  selectedFiles: any = [];
  currentSelectedFile: any = null;
  label: string = '';

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['isVisible'] && changes['isVisible'].currentValue) {
      //  Debug.log('Context label',this.currentMediaBrowserContext.label,'component label ',this.label);
      //  Debug.log('Context selected',this.currentMediaBrowserContext.data?.selected,'component selected',this.currentSelectedFile);
      //  Debug.log('Context isSkipped',this.currentMediaBrowserContext.data?.isSkipped,'component isSkipped',this.isSkipped);
      //  Debug.log('Context selection',this.currentMediaBrowserContext.data?.selection,'component selection',this.selectedFiles);

      this.label = this.currentMediaBrowserContext.label;
      this.currentSelectedFile = this.currentMediaBrowserContext.data?.selected
        ? this.currentMediaBrowserContext.data?.selected
        : null;
      this.isSkipped = this.currentMediaBrowserContext.data?.isSkipped
        ? this.currentMediaBrowserContext.data?.isSkipped
        : false;
      this.selectedFiles = this.currentMediaBrowserContext.data?.selection
        ? this.currentMediaBrowserContext.data?.selection
        : [];

      if (this.metaData.length != this.files.length) {
        this.metaData = this.files;
      }
    }
  }

  select(file: any) {
    // Assign name to the current selected file if it doesn't have one
		// console.log("SELECT INSIDE MINI MEDIA file", file)
    let fileIndex = this.files.findIndex((f) => f === file);
    if (!('name' in file) && fileIndex !== -1 && this.metaData[fileIndex]) {
      file.name = this.metaData[fileIndex].name;
    }

    this.currentSelectedFile = file;

    if (
      this.isMultiselect() &&
      this.isImage(file) &&
      this.selectedFiles.filter((f: any) => f.type?.includes('video') || f.mimeType?.includes('video')).length == 0
    ) {
      const existingIndex = this.selectedFiles.findIndex(
        (f: any) => f === file
      );

      if (existingIndex !== -1) {
        // Remove the file if it's already in the selected files
        this.selectedFiles.splice(existingIndex, 1);
        this.currentSelectedFile = null;
      } else {
        // Add name property to the file before adding it to selectedFiles
        if (!('name' in file) && fileIndex !== -1 && this.metaData[fileIndex]) {
          file.name = this.metaData[fileIndex].name;
        }
        // Add the file to the selection
        this.selectedFiles.push(file);
      }
    } else {
      // When not in multi-select mode or the selected file is not an image
      this.selectedFiles = [];
      if (this.isImage(file) || this.isVideo(file)) {
        if (!('name' in file) && fileIndex !== -1 && this.metaData[fileIndex]) {
          file.name = this.metaData[fileIndex].name;
        }
        this.selectedFiles.push(file);
      }
    }

    this.isSkipped = false;
  }

  isFileSelected(file: any): boolean {
    let answer: boolean = false;
    if (this.selectedFiles.includes(file) || this.currentSelectedFile == file)
      answer = true;
    return answer;
  }

  getFileName(index: number): string {
    return this.metaData[index].name?.length > 10
      ? this.metaData[index].name.substring(0, 10) + '...'
      : this.metaData[index].name;
  }

  confirmFile() {
		// console.log("this.currentMediaBrowserContext", this.currentMediaBrowserContext)
    // this.isVisible=false
    let data = {};
    if (
      this.isVideo(this.currentSelectedFile) ||
      (this.isImage(this.currentSelectedFile) &&
        this.currentMediaBrowserContext.label == 'google')
    ) {
      data = { selected: this.currentSelectedFile, isSkipped: this.isSkipped };
    } else {
      data = {
        selected: this.selectedFiles[0],
        isSkipped: this.isSkipped,
        selection: this.selectedFiles,
      };
    }
    this.currentMediaBrowserContext.data = data;
    this.onfileConfirmed.emit(data);
  }

  toggleSkipMedia() {
    this.isSkipped = !this.isSkipped;
    this.currentSelectedFile = null;
    this.selectedFiles = [];
    this.currentMediaBrowserContext.data = null;
    //  Debug.log('toggling no media',this.currentSelectedFile,);
  }

  close() {
    this.isVisible = false;
    // Debug.log('closing....',this.isVisible);
    this.closed.emit();
  }
  browsableFiles() {
    let browsableFiles = this.files;

    // if (this.currentMediaBrowserContext?.label == 'google') {
    //   browsableFiles.filter((file) => {
    //     return file.type.includes('image');
    //   });
    // }
    if (this.mediaType === 'images') {
      browsableFiles = browsableFiles.filter((file) =>
        file.type?.includes('image') || file.mimeType?.includes('image')
      );
    } else if (this.mediaType === 'videos') {
      browsableFiles = browsableFiles.filter((file) =>
				file.type?.includes('video') || file.mimeType?.includes('video')
      );
    }

    return browsableFiles;
  }

  isMultiselect(): boolean {
    return this.currentMediaBrowserContext?.label != 'google';
  }

  isVideo(file: any): boolean {
    if (this.isSkipped) return false;
		const fileType = file.type || file.mimeType
    return fileType.type?.includes('video');
  }

  isImage(file: any): boolean {
    if (this.isSkipped) return false;
		const fileType = file.type || file.mimeType
    return fileType?.includes('image');
  }

  fileTooSmall(file: any): boolean {
		const fileType = file.type || file.mimeType
    if (!this.isGooglePageSelected || !fileType?.includes('image'))
      return false;
    // Split the dimensions string to extract width and height
    const [width, height] = file.dimensions
      .split('x')
      .map((dim: any) => parseInt(dim.trim()));

    // Google's minimum resolution is 400x300
    const MIN_WIDTH = 400;
    const MIN_HEIGHT = 300;

    // Return true if either width or height is smaller than the minimum required
    return width < MIN_WIDTH || height < MIN_HEIGHT;
  }
}
