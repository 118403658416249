<div *ngIf="isNotEmptyObject(data)" class="-mt-5  h-[85vh]">
	<div class="ml-5">
		<div class="join">
			<button *ngIf="all" (click)="selectPlateform('all')"
				class="text-xl font-semibold h-[50px] rounded-t-lg flex justify-center items-center mt-6 w-[10%]" [ngClass]="{
          'text-gray-400': selectedPlateform != 'all',
          'bg-cliking_blue': selectedPlateform == 'all',
          'text-white': selectedPlateform == 'all',
					'cursor-wait': isCommentsLoading
        }">
				<span class="mb-1">{{ "generic.all" | translate }}</span>
			</button>
			<button *ngIf="platformFlags['linkedin']" (click)="selectPlateform('linkedin')"
				class="flex h-[50px] rounded-t-lg justify-center items-center w-[10%] mt-[24px]" [ngClass]="{
          'bg-cliking_blue': selectedPlateform == 'linkedin',
          'cursor-not-allowed': !platformFlags['linkedin'],
					'cursor-wait': isCommentsLoading
        }">
				<div class="flex justify-center items-center">
					<img [src]="
              selectedPlateform == 'linkedin'
                ? 'assets/img/linkedin-logo.png'
                : 'assets/img/linkedin-logo-disabled.png'
            " alt="Linkedin Logo" class="w-[60%] h-[60%] mb-1" 
						[ngClass]=""
						/>
				</div>
			</button>
			<button *ngIf="platformFlags['google']" (click)="selectPlateform('google')"
				class="flex h-[50px] rounded-t-lg justify-center items-center w-[10%] mt-[24px]" [ngClass]="{
          'bg-cliking_blue': selectedPlateform == 'google',
          'cursor-not-allowed': !platformFlags['google'],
					'cursor-wait': isCommentsLoading
        }">
				<div class="flex justify-center items-center">
					<img [src]="
              selectedPlateform == 'google'
                ? 'assets/img/google-logo.png'
                : 'assets/img/google-logo-disabled.png'
            " alt="Google Logo" class="w-[50%] h-[50%]" />
				</div>
			</button>
			<button *ngIf="platformFlags['facebook']" (click)="selectPlateform('facebook')"
				class="flex h-[50px] rounded-t-lg justify-center items-center w-[10%] mt-[24px]" [ngClass]="{
          'bg-cliking_blue': selectedPlateform == 'facebook',
          'cursor-not-allowed': !platformFlags['facebook'],
					'cursor-wait': isCommentsLoading
        }">
				<div class="flex justify-center items-center">
					<img [src]="
              selectedPlateform == 'facebook'
                ? 'assets/img/facebook-logo.png'
                : 'assets/img/facebook-logo-disabled.png'
            " alt="Facebook Logo" class="w-[60%] h-[60%]" />
				</div>
			</button>
			<button *ngIf="platformFlags['instagram']" (click)="selectPlateform('instagram')"
				class="flex h-[50px] rounded-t-lg justify-center items-center w-[10%] mt-[24px]" [ngClass]="{
          'bg-cliking_blue': selectedPlateform == 'instagram',
          'cursor-not-allowed': !platformFlags['instagram'],
					'cursor-wait': isCommentsLoading
        }">
				<div class="flex justify-center items-center">
					<img [src]="
              selectedPlateform == 'instagram'
                ? 'assets/img/instagram-logo.png'
                : 'assets/img/instagram-logo-disabled.png'
            " alt="Instagram Logo" class="w-[60%] h-[60%]" />
				</div>
			</button>
		</div>
		<div class="h-[5px] w-[98%] bg-cliking_blue mb-5"></div>
	</div>
	<div class="overflow-auto h-[80vh]" [ngClass]="{'pb-12': screenHeight<=800}">
		<div class="flex justify-between h-[620px]">
			<div class="w-[47.5%] relative bg-white h-full ml-5 rounded-3xl flex flex-col pb-2">
				<!-- Header Section -->
				<div class="flex justify-between mb-2">
					<!-- Title -->
					<div class="font-bold text-xl ml-5 mt-4">
						{{ "posts.preview" | translate }}
					</div>
			
					<!-- Dots Menu -->
					<div class="dropdown dropdown-end mr-5 mt-4">
						<div tabindex="0" role="button" class="hover:bg-slate-200 rounded-md p-1">      
							<svg width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-sliders-vertical"> <line x1="4" x2="4" y1="21" y2="14" /> <line x1="4" x2="4" y1="10" y2="3" /> <line x1="12" x2="12" y1="21" y2="12" /> <line x1="12" x2="12" y1="8" y2="3" /> <line x1="20" x2="20" y1="21" y2="16" /> <line x1="20" x2="20" y1="12" y2="3" /> <line x1="2" x2="6" y1="14" y2="14" /> <line x1="10" x2="14" y1="8" y2="8" /> <line x1="18" x2="22" y1="16" y2="16" />
							</svg>
						</div>
						<ul tabindex="0" class="dropdown-content menu bg-base-100 rounded-box border z-[1] w-52 p-2 shadow text-xs text-cliking_green">
		
							<!-- edit -->
							<li>
								<a (click)="useAsNewPostTemplate()">
									<svg  width="18" height="18" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-pencil"><path d="M21.174 6.812a1 1 0 0 0-3.986-3.987L3.842 16.174a2 2 0 0 0-.5.83l-1.321 4.352a.5.5 0 0 0 .623.622l4.353-1.32a2 2 0 0 0 .83-.497z"/><path d="m15 5 4 4"/></svg><span class="text-black"  [title]="'generic.comingSoon' | translate">{{'generic.edit'|translate}}</span>
								</a>
							</li>
							<!-- reschedule -->
							<!-- <li *ngIf="displayAs=='planned'">
								<a>
									<svg  width="18" height="18" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-history"><path d="M3 12a9 9 0 1 0 9-9 9.75 9.75 0 0 0-6.74 2.74L3 8"/><path d="M3 3v5h5"/><path d="M12 7v5l4 2"/></svg><span class="text-black"  [title]="'generic.comingSoon' | translate">{{'posts.reschedule'|translate}}</span>
								</a>
							</li> -->
							
							<!--cancel publication-->
							
							<!--delete-->
							<li>
								<a (click)="confirmDeleteModalVisible = true" class="text-cliking_red">
									<svg  width="18" height="18" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-trash-2"><path d="M3 6h18"/><path d="M19 6v14c0 1-1 2-2 2H7c-1 0-2-1-2-2V6"/><path d="M8 6V4c0-1 1-2 2-2h4c1 0 2 1 2 2v2"/><line x1="10" x2="10" y1="11" y2="17"/><line x1="14" x2="14" y1="11" y2="17"/></svg> <span class="text-black"  [title]="'generic.comingSoon' | translate">{{'generic.delete'|translate}}</span>
								</a>
							</li>
		
						</ul>
					</div>
				</div>
			
				<!-- App Post Preview -->
				<app-post-create-preview 
					[data]="preparePostDataForPreview(data)" 
					[postIsCreated]="true" 
					[selectedPlateform]="selectedPlateform" 
					class="overflow-auto flex-grow"
				></app-post-create-preview>

				<div class="date mt-2 text-xs text-[#1C1B1F] text-center font-light">
					{{ 'generic.posted' | translate }} {{dateFormatingPost(data.publishingDatetime ? data.publishingDatetime : data.createdAt)}}
				</div>
			</div>
			
			<div class="w-[47.5%] mr-5">
				<div class="h-[22%] mb-4">
					<div class="bg-white h-[33%] rounded-t-3xl pt-3">
						<span class="font-bold text-xl ml-5">{{
							"generic.relevantPages" | translate
							}}</span>
					</div>
					<div class="bg-white h-[60%] rounded-b-3xl overflow-auto" >
						<app-list-item
							*ngIf="selectedPlateform != ''"
							[type]="pages.length > 1
									? 'generic.selectedPages'
									: 'generic.selectedPage'
							" [list]="getPages()" />

					</div>
				</div>
				<div class="bg-white mb-2 rounded-3xl h-[75%]" [ngClass]="{'bg-[#ffffff]': selectedPlateform=='google'}">
					<div class="font-bold text-xl ml-5 pb-3 pt-4" [ngClass]="{'text-gray-400': selectedPlateform=='google'}">
						{{ "generic.statistics" | translate }}
					</div>
					<div class="h-[82.5%] overflow-y-auto">
						<div *ngIf="selectedPlateform == 'all'" class="flex flex-wrap ml-3">
							<app-post-stats-widgets class="w-[48%] h-32 mx-[1%]" [screenHeight]="screenHeight"
								[icon]="SetIconImpression()"
								[value]="renderVal(allData?.impression)"
								[tooltipText]="'tooltip.allStats.impressions'"
								[widgetLabel]="'generic.impressions'"
								[downLeft]="true"
							/>	
							<app-post-stats-widgets class="w-[48%] h-32 mx-[1%]" [screenHeight]="screenHeight"
								[icon]="SetIconReactions()"
								[value]="renderVal(allData?.reactions)"
								[tooltipText]="'tooltip.allStats.reactions'"
								[widgetLabel]="'generic.reactions'"
								[downRight]="true"
							/>
							<app-post-stats-widgets class="w-[48%] h-32 mx-[1%]" [screenHeight]="screenHeight"
								[icon]="SetIconChatBubble()"
								[value]="renderVal(allData?.comments)"
								[tooltipText]="'tooltip.allStats.comments'"
								[widgetLabel]="'generic.comments'"
								[right]="true"
							/>
							<app-post-stats-widgets class="w-[48%] h-32 mx-[1%]" [screenHeight]="screenHeight"
								[icon]="SetIconShare()"
								[value]="renderVal(allData?.shares)"
								[tooltipText]="'tooltip.allStats.shares'"
								[widgetLabel]="'generic.shares'"
								[left]="true"
							/>
							<app-post-stats-widgets class="w-[48%] h-32 mx-[1%]" [screenHeight]="screenHeight"
								[icon]="SetIconEngagement()"
								[value]="renderVal(allData?.AffectedMembers)"
								[tooltipText]="'tooltip.allStats.AffectedMembers'"
								[widgetLabel]="'generic.AffectedMembers'"
								[right]="true"
							/>	
							<app-post-stats-widgets class="w-[48%] h-32 mx-[1%]" [screenHeight]="screenHeight"
								[icon]="SetIconInteraction()"
								[value]="renderVal(allData?.interactions)"
								[tooltipText]="'tooltip.allStats.interactions'"
								[widgetLabel]="'generic.interactions'"
								[left]="true"
							/>
						</div>
						<div
							*ngIf="selectedPlateform == 'linkedin'"
							class="flex flex-wrap ml-3 "
						>
							<app-post-stats-widgets class="w-[48%] h-32 mx-[1%]" [screenHeight]="screenHeight"
								[icon]="SetIconImpression()"
								[value]="renderVal
								(linkedinInsights?.postAnalytics?.impressionCount)"
								[tooltipText]="'tooltip.linkedin.impressionCount'"
								[widgetLabel]="'insights.linkedin.impressionCount'"
								[downLeft]="true"
							/>
							<app-post-stats-widgets class="w-[48%] h-32 mx-[1%]" [screenHeight]="screenHeight"
								[icon]="SetIconReactions()"
								[value]="renderVal(linkedinInsights?.postAnalytics?.likeCount)"
								[tooltipText]="'tooltip.linkedin.likeCount'"
								[widgetLabel]="'insights.linkedin.likeCount'"
								[downRight]="true"
							/>
							<app-post-stats-widgets class="w-[48%] h-32 mx-[1%]" [screenHeight]="screenHeight"
								[icon]="SetIconChatBubble()"
								[value]="renderVal(linkedinInsights?.postAnalytics?.commentCount)"
								[tooltipText]="'tooltip.linkedin.commentCount'"
								[widgetLabel]="'insights.linkedin.commentCount'"
								[right]="true"
							/>
							<app-post-stats-widgets class="w-[48%] h-32 mx-[1%]" [screenHeight]="screenHeight"
								[icon]="SetIconShare()"
								[value]="renderVal(linkedinInsights?.postAnalytics?.shareCount)"
								[tooltipText]="'tooltip.linkedin.shareCount'"
								[widgetLabel]="'insights.linkedin.shareCount'"
								[left]="true"
							/>
							<app-post-stats-widgets class="w-[48%] h-32 mx-[1%]" [screenHeight]="screenHeight"
								[icon]="SetIconEngagement()"
								[value]="renderVal(linkedinInsights?.postAnalytics?.totalEngagement)"
								[tooltipText]="'tooltip.linkedin.totalEngagement'"
								[widgetLabel]="'insights.linkedin.totalEngagement'"
								[right]="true"
							/>
							<app-post-stats-widgets class="w-[48%] h-32 mx-[1%]" [screenHeight]="screenHeight"
								[icon]="SetIconEngagementRate()"
								[value]="renderVal(linkedinInsights?.postAnalytics?.engagementRate)+'%'"
								[tooltipText]="'tooltip.linkedin.engagementRate'"
								[widgetLabel]="'insights.linkedin.engagementRate'"
								[left]="true"
							/>
							<app-post-stats-widgets class="w-[48%] h-32 mx-[1%]" [screenHeight]="screenHeight"
								[icon]="SetIconClick()"
								[value]="renderVal(linkedinInsights?.postAnalytics?.clickCount)"
								[tooltipText]="'tooltip.linkedin.clickCount'"
								[widgetLabel]="'insights.linkedin.clickCount'"
								[right]="true"
							/>
							<app-post-stats-widgets class="w-[48%] h-32 mx-[1%]" [screenHeight]="screenHeight"
								[icon]="SetIconClickRate()"
								[value]="renderVal(linkedinInsights?.postAnalytics?.clickRate)+'%'"
								[tooltipText]="'tooltip.linkedin.clickRate'"
								[widgetLabel]="'insights.linkedin.clickRate'"
								[left]="true"
							/>
						</div>
						<div
							*ngIf="selectedPlateform == 'facebook'"
							class="flex flex-wrap ml-3"
						>
							<!-- <app-post-stats-widgets [value]="renderVal(facebookData?.postAnalytics?.clickCount)" [type]="'views'"/> -->
							<app-post-stats-widgets class="w-[48%] h-32 mx-[1%]" [screenHeight]="screenHeight"
							[icon]="SetIconImpression()"
								[value]="renderVal(facebookInsights?.postAnalytics?.metrics[0]?.value)"
								[tooltipText]="'tooltip.facebook.impressions'"
								[widgetLabel]="'generic.impressions'"
								[downLeft]="true"
							/>
							<app-post-stats-widgets class="w-[48%] h-32 mx-[1%]" [ngClass]="{'h-48': reactionsView}" [screenHeight]="screenHeight"
							[icon]="isFbReaction()"
								[value]="renderVal(facebookInsights?.postAnalytics?.reactions)"
								[reactions]="renderVal(facebookInsights?.postAnalytics?.metrics[4]?.value)"
								[tooltipText]="'tooltip.facebook.reactions'"
								[widgetLabel]="'insights.facebook.reactions'"
								(dataEvent)="onDataReceived($event)"
								[downRight]="true"
							/>
							<app-post-stats-widgets class="w-[48%] h-32 mx-[1%]" [screenHeight]="screenHeight"
							[icon]="SetIconChatBubble()"
								[value]="renderVal(facebookInsights?.postAnalytics?.comments)"
								[tooltipText]="'tooltip.facebook.comments'"
								[widgetLabel]="'insights.facebook.comments'"
								[right]="true"
							/>
							<app-post-stats-widgets class="w-[48%] h-32 mx-[1%]" [screenHeight]="screenHeight"
							[icon]="SetIconShare()"
								[value]="renderVal(facebookInsights?.postAnalytics?.shares)"
								[tooltipText]="'tooltip.facebook.shares'"
								[widgetLabel]="'insights.facebook.shares'"
								[left]="true"
							/>	
							<app-post-stats-widgets class="w-[48%] h-32 mx-[1%]" [screenHeight]="screenHeight"
							[icon]="SetIconEngagement()"
								[value]="renderVal(facebookInsights?.postAnalytics?.metrics[1]?.value)"
								[tooltipText]="'tooltip.facebook.post_impressions_unique'"
								[widgetLabel]="'insights.facebook.post_impressions_unique'"
								[right]="true"
							/>
							<app-post-stats-widgets class="w-[48%] h-32 mx-[1%]" [screenHeight]="screenHeight"
							[icon]="SetIconView()"
								[value]="renderVal(facebookInsights?.postAnalytics?.metrics[5]?.value)"
								[tooltipText]="'tooltip.facebook.uniqueViews'"
								[widgetLabel]="'insights.facebook.uniqueViews'"
								[left]="true"
							/>
							<app-post-stats-widgets class="w-[48%] h-32 mx-[1%]" [screenHeight]="screenHeight"
							[icon]="SetIconClick()"
								[value]="renderVal(facebookInsights?.postAnalytics?.metrics[2]?.value)"
								[tooltipText]="'tooltip.facebook.post_clicks'"
								[widgetLabel]="'insights.facebook.post_clicks'"
								[right]="true"
							/>	
							<app-post-stats-widgets class="w-[48%] h-32 mx-[1%]" [screenHeight]="screenHeight"
							[icon]="SetIconInteraction()"
								[value]="renderVal(facebookInsights?.postAnalytics?.Interactions)"
								[tooltipText]="'tooltip.facebook.engagements'"
								[widgetLabel]="'insights.facebook.engagements'"
								[left]="true"
							/>
							<app-post-stats-widgets class="w-[48%] h-32 mx-[1%]" [screenHeight]="screenHeight"
							[icon]="linkClics()"
								[value]="renderVal(facebookInsights?.postAnalytics?.metrics[3]?.value['link clicks'])"
								[tooltipText]="'tooltip.facebook.linkClics'"
								[widgetLabel]="'insights.facebook.linkClics'"
								[right]="true"
							/>
						</div>
						<div *ngIf="selectedPlateform == 'google' ||selectedPlateform == 'instagram'" class="flex flex-col justify-center items-center h-[80%]">
							<img src="/assets/img/no-stats-google.png" alt="" class="mb-2">
							<div *ngIf="selectedPlateform == 'google'" class="text-center text-gray-500 px-3">{{'insights.google.googleNoStatMsg' | translate}}</div>
							<div *ngIf="selectedPlateform == 'instagram'" class="text-center text-gray-500 px-3">{{'insights.instagram.noStatMsg' | translate}}</div>
						</div>
						<!-- <div
							*ngIf="selectedPlateform == 'instagram'"
							class="flex flex-wrap ml-3"
						>
							<app-post-stats-widgets class="w-[48%] h-32 mx-[1%]" [screenHeight]="screenHeight"
							[icon]="SetIconView()"
								[value]="renderInstaVal('impressions')"
								[tooltipText]="'tooltip.instagram.impressions'"
								[widgetLabel]="'insights.instagram.impressions'"
								[downLeft]="true"
							/>
							<app-post-stats-widgets class="w-[48%] h-32 mx-[1%]" [screenHeight]="screenHeight"
							[icon]="SetIconHeart()"
								[value]="renderInstaVal('likes')"
								[tooltipText]="'tooltip.instagram.likes'"
								[widgetLabel]="'insights.instagram.likes'"
								[downRight]="true"
							/>
							<app-post-stats-widgets class="w-[48%] h-32 mx-[1%]" [screenHeight]="screenHeight"
							[icon]="SetIconChatBubble()"
								[value]="renderInstaVal('comments')"
								[tooltipText]="'tooltip.instagram.comments'"
								[widgetLabel]="'insights.instagram.comments'"
								[right]="true"
							/>
							<app-post-stats-widgets class="w-[48%] h-32 mx-[1%]" [screenHeight]="screenHeight"
							[icon]="SetIconShare()"
								[value]="renderInstaVal('shares')"
								[tooltipText]="'tooltip.instagram.shares'"
								[widgetLabel]="'insights.instagram.shares'"
								[left]="true"
							/>
							<app-post-stats-widgets class="w-[48%] h-32 mx-[1%]" [screenHeight]="screenHeight"
							[icon]="SetIconEngagement()"
								[value]="renderInstaVal('reach')"
								[tooltipText]="'tooltip.instagram.reach'"
								[widgetLabel]="'insights.instagram.accounts_engaged'"
								[right]="true"
							/>
							<app-post-stats-widgets class="w-[48%] h-32 mx-[1%]" [screenHeight]="screenHeight"
							[icon]="SetIconProfilVisit()"
								[value]="renderInstaVal('profile_visits')"
								[tooltipText]="'tooltip.instagram.profile_visits'"
								[widgetLabel]="'insights.instagram.profile_visits'"
								[left]="true"
							/>
							<app-post-stats-widgets class="w-[48%] h-32 mx-[1%]" [screenHeight]="screenHeight"
							[icon]="SetIconActivityProfil()"
								[value]="renderInstaVal('profile_activity')"
								[tooltipText]="'tooltip.instagram.profile_activity'"
								[widgetLabel]="'insights.instagram.profile_activity'"
								[right]="true"
							/>
							<app-post-stats-widgets class="w-[48%] h-32 mx-[1%]" [screenHeight]="screenHeight"
							[icon]="SetIconInteraction()"
								[value]="renderInstaVal('total_interactions')"
								[tooltipText]="'tooltip.instagram.total_interactions'"
								[widgetLabel]="'insights.instagram.total_interactions'"
								[left]="true"
							/>
							<app-post-stats-widgets class="w-[48%] h-32 mx-[1%]" [screenHeight]="screenHeight"
							[icon]="SetIconNewFans()"
								[value]="renderInstaVal('follows')"
								[tooltipText]="'tooltip.instagram.follows'"
								[widgetLabel]="'insights.instagram.follows'"
								[right]="true"
							/>
							<app-post-stats-widgets class="w-[48%] h-32 mx-[1%]" [screenHeight]="screenHeight"
							[icon]="SetIconSaved()"
								[value]="renderInstaVal('saved')"
								[tooltipText]="'tooltip.instagram.saved'"
								[widgetLabel]="'insights.instagram.saved'"
								[left]="true"
							/>
						</div> -->
					</div>
				</div>

			</div>

			
		</div>
		<!-- COMMENTS -->
		<div *ngIf="selectedPlateform != 'all'"
			class="w-[97.5%%] my-10 bg-white mx-5 rounded-3xl" [ngClass]="{'animate-pulse': isCommentsLoading}">
			<h2 class="font-bold text-xl ml-5 pt-4 pb-2"  *ngIf="selectedPlateform != 'google'">
				<span *ngIf="!isCommentsLoading">
					<span class="text-lg font-semibold text-black">
						{{ "generic.comments" | translate }}
					</span>
				</span>
				<span *ngIf="isCommentsLoading">
					<span class="text-lg font-semibold text-gray-700 animate-pulse">
						{{'insights.commentsLoading' | translate}}
					</span>
			</span>
			</h2>

			<img *ngIf="!user?.isAdmin" src="/assets/img/coming soon commentaires.png"
				alt="{{ 'generic.comingSoon' | translate }}" class="w-32 mx-auto" />
			<div *ngIf="!user?.isAdmin" class="mx-auto mt-5 mb-2 text-center">
				{{ ("generic.comingSoon" | translate) + " !" }}
			</div>
			<div *ngIf="!user?.isAdmin" class="mx-auto mb-5 pb-10 text-center text-sm text-gray-400">
				{{ "generic.featureUnderDevelopment" | translate }}
			</div>
			<div *ngIf="user?.isAdmin" class="mx-auto bg-white rounded-3xl p-2">
				<div *ngIf="selectedPlateform == 'google'">
					<div class="mx-auto w-[301px] h-[107px] mb-4 flex justify-center">
						<img src="/assets/img/no-stats-google.png" alt="" class="mb-2">
					</div>


					<div class="text-center text-[#544E61] mb-5"> 
						{{"insights.google.googleNoCommentsMsg" | translate}}
					</div>
				</div>
				<div *ngIf="selectedPlateform != 'google' && platformPages?.length == 0" class="">  

					<div class="mx-auto w-[301px] h-[107px] mb-6">
						<svg width="301" height="107" viewBox="0 0 301 107" fill="none" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
							<rect x="0.5" width="300" height="106.785" fill="url(#pattern0_6121_33068)" />
							<defs>
								<pattern id="pattern0_6121_33068" patternContentUnits="objectBoundingBox" width="1" height="1">
									<use xlink:href="#image0_6121_33068" transform="scale(0.00111235 0.003125)" />
								</pattern>
								<image id="image0_6121_33068" width="899" height="320" xlink:href="/assets/images/no-comments.png" />
							</defs>
						</svg>
					</div>


					<div class="text-center text-[#544E61]"> 
						{{"insights.noComments" | translate}}
					</div>
					<div class="text-center font-light text-[#A4A8B7] text-sm"> 
						{{"insights.noCommentsSubText" | translate}}
					</div>

				</div>
				<div *ngIf="selectedPlateform != 'google' && platformPages && platformPages.length > 0" class="flex text-sm">
					<div class="w-1/2">
						<div *ngFor="let pageComment of platformPages ;let i = index " class="bg-white p-2 rounded-lg">
							<div>
								<app-post-plateform-comments class="overflow-auto" [isPostOnOnePage]="platformPages.length==1"  [platform]="selectedPlateform" [isCommentsLoading]="isCommentsLoading" [currentComment]="currentComment"  (itemSelected)="reply($event)"  [data]="pageComment"  />
								<hr *ngIf="pageComment.length>0 && i<platformPages.length-1" class="my-2">
							</div>
						</div>
						<div *ngIf="noCommentsOnCurrentPlatform()" class="text-center text-gray-500">
							<span class="mb-3 text-xl">
								{{'insights.noComments' | translate}}
							</span>
							<img src="assets/img/no-comments.png" class="w-64 p-2 m-auto">
						</div>
					</div>
					<div class="w-[1px] rounded-3xl ml-4 bg-[#F4F4FA]"></div>

					<div class="w-1/2">
						<div class="bg-white p-4 rounded-lg" *ngIf="currentComment!=null">
							<!-- Current comment (right-side) -->

							<div class="flex items-start space-x-4 mb-4" [ngClass]="{'animate-pulse': isCommentsLoading}">
								<!-- User Avatar  -->
								<div class="relative m-2">
									<div>
										<svg width="40px" xmlns="http://www.w3.org/2000/svg" fill="#ffffff" viewBox="-2.4 -2.4 28.80 28.80"
											stroke="#ffffff">
											<g id="SVGRepo_bgCarrier" stroke-width="0">
												<rect x="-2.4" y="-2.4" width="28.80" height="28.80" rx="14.4" fill="#bdbfc5" strokewidth="0">
												</rect>
											</g>
											<g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
											<g id="SVGRepo_iconCarrier">
												<path
													d="M12 12c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm0 2c-2.67 0-8 1.34-8 4v2h16v-2c0-2.66-5.33-4-8-4z">
												</path>
											</g>
										</svg>
									</div>
									<img width="20px" class="rounded-full absolute -bottom-[4px] -right-[4px] bg-white"
										[src]="getSRCByPlatform()" alt="">
								</div>

								<div *ngIf="writeReply" class="w-full relative">
									<div class="p-4 rounded-lg border-2 border-cliking_blue" [ngClass]="{
											'bg-[#1DC9B766]':writeReply.isCommentByPage,
											'bg-[#F4F4FA]':!writeReply.isCommentByPage,
										}">
										<div class="flex justify-between pb-4">
											<span class="user-name font-bold">{{writeReply.isCommentByPage ? linkedinPages[0]?.name :
												writeReply.authorName }} </span>
											<span class="date font-light text-xs"> {{formatCommentTime(writeReply.createdAt)}}</span>
										</div>
										<p class="">{{writeReply.comment}}</p>
									</div>
								</div>
							</div>
							<!-- <div id="comment-container" class="bg-[#465086] mx-auto rounded-2xl w-[50%] h-1 my-4"></div> -->
							<!-- this page profile (Avatar + Name) -->
							<div class="flex m-2 items-center ">
								<div class="relative m-2">
									<img width="40px" class="rounded-full border"
										src="{{environment.apiUrl}}/assets/mma-assurance-icone.png" alt="">
									<img width="20px" class="rounded-full absolute -bottom-[4px] -right-[4px] bg-white"
										[src]="getSRCByPlatform()" alt="">
								</div>
								<span class="font-semibold">
									<!-- {{linkedinPages[0]?.name}} -->
								</span>
							</div>
							<div class="relative">

								<textarea #textarea type="text" id="comment"
									class="w-full h-32 border rounded-lg p-2 border-gray-500 resize-none relative"
									placeholder="Répondre au commentaire" [(ngModel)]="message"></textarea>
								<button class="bg-blue-600 text-white p-2 my-2 rounded-md absolute bottom-2 right-2"
									(click)="processComment()"  [ngClass]="{'cursor-not-allowed':isCommentsLoading}">
									<!-- <svg width="30" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" id="send" class="mx-auto" fill="currentColor"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"><path d="M21.66,12a2,2,0,0,1-1.14,1.81L5.87,20.75A2.08,2.08,0,0,1,5,21a2,2,0,0,1-1.82-2.82L5.46,13H11a1,1,0,0,0,0-2H5.46L3.18,5.87A2,2,0,0,1,5.86,3.25h0l14.65,6.94A2,2,0,0,1,21.66,12Z"></path></g></svg> -->
									{{getSendButtonLabel() | translate}}
								</button>
								<span class="absolute bottom-2 left-2 my-2 cursor-pointer">
									<svg   (click)="toggleImojiPicker()" width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
										<path
											d="M22.25 12.8171C22.25 10.2313 21.2228 7.75133 19.3943 5.92285C17.5658 4.09437 15.0859 3.06714 12.5 3.06714C9.91414 3.06714 7.43419 4.09437 5.60571 5.92285C3.77723 7.75133 2.75 10.2313 2.75 12.8171C2.75 15.403 3.77723 17.8829 5.60571 19.7114C7.43419 21.5399 9.91414 22.5671 12.5 22.5671C15.0859 22.5671 17.5658 21.5399 19.3943 19.7114C21.2228 17.8829 22.25 15.403 22.25 12.8171ZM0.5 12.8171C0.5 9.63454 1.76428 6.58229 4.01472 4.33186C6.26516 2.08142 9.3174 0.817139 12.5 0.817139C15.6826 0.817139 18.7348 2.08142 20.9853 4.33186C23.2357 6.58229 24.5 9.63454 24.5 12.8171C24.5 15.9997 23.2357 19.052 20.9853 21.3024C18.7348 23.5529 15.6826 24.8171 12.5 24.8171C9.3174 24.8171 6.26516 23.5529 4.01472 21.3024C1.76428 19.052 0.5 15.9997 0.5 12.8171ZM6.62656 15.5312C6.42969 14.8937 6.95937 14.3171 7.625 14.3171H17.5859C18.2516 14.3171 18.7812 14.8984 18.5844 15.5312C17.7969 18.0859 15.4156 19.9421 12.6031 19.9421C9.79063 19.9421 7.40937 18.0859 6.62656 15.5312ZM7.26875 9.81714C7.26875 9.41931 7.42679 9.03778 7.70809 8.75648C7.98939 8.47517 8.37092 8.31714 8.76875 8.31714C9.16657 8.31714 9.54811 8.47517 9.82941 8.75648C10.1107 9.03778 10.2687 9.41931 10.2687 9.81714C10.2687 10.215 10.1107 10.5965 9.82941 10.8778C9.54811 11.1591 9.16657 11.3171 8.76875 11.3171C8.37092 11.3171 7.98939 11.1591 7.70809 10.8778C7.42679 10.5965 7.26875 10.215 7.26875 9.81714ZM16.2687 8.31714C16.6666 8.31714 17.0481 8.47517 17.3294 8.75648C17.6107 9.03778 17.7687 9.41931 17.7687 9.81714C17.7687 10.215 17.6107 10.5965 17.3294 10.8778C17.0481 11.1591 16.6666 11.3171 16.2687 11.3171C15.8709 11.3171 15.4894 11.1591 15.2081 10.8778C14.9268 10.5965 14.7687 10.215 14.7687 9.81714C14.7687 9.41931 14.9268 9.03778 15.2081 8.75648C15.4894 8.47517 15.8709 8.31714 16.2687 8.31714Z"
											fill="#9CA3AF" />
									</svg>
								</span>

							</div>
							<app-emoji-picker [isVisible]="isEmojiPickerOpen" (emojiPicked)="insertEmoji($event)"/>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
<!-- <div *ngIf="!isNotEmptyObject(data)">pas de data dans ce groupe</div> -->
 <div *ngIf="!isNotEmptyObject(data)" >
	<div class="ml-5">
		<div class="join">
			<button class="text-xl font-semibold h-[50px] rounded-t-lg flex justify-center items-center mt-6 w-[10%] text-gray-400">
				<span class="mb-1">{{ "generic.all" | translate }}</span>
			</button>
			<button class="flex h-[50px] rounded-t-lg justify-center items-center w-[10%] mt-[24px]">
				<div class="flex justify-center items-center">
					<img src="assets/img/linkedin-logo-disabled.png" alt="Linkedin Logo" class="w-[60%] h-[60%] mb-1" />
				</div>
			</button>
			<button 
				class="flex h-[50px] rounded-t-lg justify-center items-center w-[10%] mt-[24px]">
				<div class="flex justify-center items-center">
					<img src="assets/img/google-logo-disabled.png" alt="Google Logo" class="w-[50%] h-[50%]" />
				</div>
			</button>
			<button 
				class="flex h-[50px] rounded-t-lg justify-center items-center w-[10%] mt-[24px]">
				<div class="flex justify-center items-center">
					<img src="assets/img/facebook-logo-disabled.png" alt="Facebook Logo" class="w-[60%] h-[60%]" />
				</div>
			</button>
			<button 
				class="flex h-[50px] rounded-t-lg justify-center items-center w-[10%] mt-[24px]" >
				<div class="flex justify-center items-center">
					<img src="assets/img/instagram-logo-disabled.png" alt="Instagram Logo" class="w-[60%] h-[60%]" />
				</div>
			</button>
		</div>
		<div class="h-[5px] w-[98%] bg-cliking_blue mb-5"></div>
	</div>
	<div class="bg-cover w-[98%] overflow-y-auto ml-5 flex pt-[10%]" [style.height.px]="screenHeight" style="background-image: url('/assets/img/post-detail-no-data-bg.png');">
		<!-- Contenu optionnel ici -->
		 <div *ngIf="noData" class="mx-auto w-[50%] space-y-2">
			 <img src="/assets/img/post-detail-no-data.png" class="mx-auto w-32 h-32" alt="">
			 <div class="font-bold text-center">{{'generic.postNotAvailableInGroup'|translate}}</div>
			 <div class="text-xs text-center">{{'generic.postGroupNotSelected'|translate}}</div>
			 <div class="flex">
				<button class="bg-cliking_blue w-[50%] mx-auto rounded-2xl shadow-md text-white" (click)="addGroupsToNavBar()">
					<span class="join flex items-center space-x-2 m-2 text-lg">  <!-- Flexbox avec un petit espace entre les éléments -->
					  {{'generic.selectThisGroup'| translate}} 
					  <!-- {{groupsNames()}} -->
					  <span class="w-[40px]">
						  <svg width="40" height="40" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path d="M16.21 13.44C16.301 13.3449 16.3724 13.2328 16.42 13.11C16.52 12.8666 16.52 12.5935 16.42 12.35C16.3724 12.2273 16.301 12.1151 16.21 12.02L13.21 9.02002C13.0217 8.83171 12.7663 8.72592 12.5 8.72592C12.2337 8.72592 11.9783 8.83171 11.79 9.02002C11.6017 9.20832 11.4959 9.46371 11.4959 9.73002C11.4959 9.99632 11.6017 10.2517 11.79 10.44L13.09 11.73H9.5C9.23479 11.73 8.98043 11.8354 8.7929 12.0229C8.60536 12.2104 8.5 12.4648 8.5 12.73C8.5 12.9952 8.60536 13.2496 8.7929 13.4371C8.98043 13.6247 9.23479 13.73 9.5 13.73H13.09L11.79 15.02C11.6963 15.113 11.6219 15.2236 11.5711 15.3454C11.5203 15.4673 11.4942 15.598 11.4942 15.73C11.4942 15.862 11.5203 15.9927 11.5711 16.1146C11.6219 16.2365 11.6963 16.3471 11.79 16.44C11.883 16.5337 11.9936 16.6081 12.1154 16.6589C12.2373 16.7097 12.368 16.7358 12.5 16.7358C12.632 16.7358 12.7627 16.7097 12.8846 16.6589C13.0064 16.6081 13.117 16.5337 13.21 16.44L16.21 13.44ZM22.5 12.73C22.5 10.7522 21.9135 8.8188 20.8147 7.17431C19.7159 5.52982 18.1541 4.2481 16.3268 3.49122C14.4996 2.73434 12.4889 2.53631 10.5491 2.92216C8.60929 3.30801 6.82746 4.26042 5.42894 5.65895C4.03041 7.05747 3.078 8.8393 2.69215 10.7791C2.3063 12.7189 2.50433 14.7296 3.26121 16.5569C4.01809 18.3841 5.29981 19.9459 6.9443 21.0447C8.58879 22.1435 10.5222 22.73 12.5 22.73C15.1522 22.73 17.6957 21.6764 19.5711 19.8011C21.4464 17.9257 22.5 15.3822 22.5 12.73ZM4.5 12.73C4.5 11.1478 4.9692 9.60105 5.84825 8.28545C6.7273 6.96986 7.97673 5.94448 9.43854 5.33898C10.9003 4.73348 12.5089 4.57505 14.0607 4.88373C15.6126 5.19242 17.038 5.95434 18.1569 7.07316C19.2757 8.19198 20.0376 9.61745 20.3463 11.1693C20.655 12.7211 20.4965 14.3297 19.891 15.7915C19.2855 17.2533 18.2602 18.5027 16.9446 19.3818C15.629 20.2608 14.0823 20.73 12.5 20.73C10.3783 20.73 8.34344 19.8872 6.84315 18.3869C5.34286 16.8866 4.5 14.8517 4.5 12.73Z" fill="white"/>
						  </svg>
					  </span>
					</span>
				  </button>
				  
			 </div>
		 </div>
	  </div>
 </div>

 <app-modal 
	[isOpen]="confirmDeleteModalVisible" 
	[icon]="modalIcon" 
	[heading]="modalHeading"
	[subHeading]="modalSubHeading"
	[socialMediaInfos]="postService.extractSocialMediaInfo(data)"
	[hideElements]="modalHideElements"
	[showLoadingDots]="modalShowLoadingDots"
	(closeModal)="confirmDeleteModalVisible=false" 
	(deleteItem)="confirmPostRemoval($event)"
></app-modal>
  
  
<app-loader [isLoading]="isLoading" />