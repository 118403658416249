import { Component } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { IResponse } from 'src/app/interfaces/IResponse';
import { Response } from 'src/app/interfaces/Response';


@Component({
  selector: 'app-socialmedias',
  templateUrl: './socialmedias.component.html',
  styleUrls: ['./socialmedias.component.scss']
})
export class SocialmediasComponent {
  isGoogleConnected:boolean = false;
  isLinkedinConnected:boolean = false;
  isFacebookConnected:boolean = false;
  isInstagramConnected:boolean = false;
	apiUrl:string = environment.apiUrl;

	socialMedias: IResponse = { data: [],
    navigation: {
      page: 0,
      totalPages: 0,
      totalRows: 0,
      itemsPerPage: 0
    },
		success: false, error: '', status: 0 };


  
}
