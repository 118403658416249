import { Component, HostListener } from '@angular/core';
import { CALENDERMODE } from 'src/app/interfaces/CalendarMode';
import { TranslationService } from 'src/app/services/utilities/translation.service';
import { UiStateService } from 'src/app/services/utilities/ui-state.service';
import { CalenderBaseComponent } from '../../base/calendar.component';
import { PostService } from 'src/app/services/repositories/post.service';
import { CalendarService } from 'src/app/services/utilities/calendar.service';
import Swal from 'sweetalert2';
import { Debug } from 'src/app/utils/debug';
import { ScreenSizeService } from 'src/app/services/utilities/screen-size.service';

@Component({
  selector: 'app-post-calender',
  templateUrl: './post-calender.component.html',
  styleUrls: ['./post-calender.component.scss'],
})
export class PostCalenderComponent extends CalenderBaseComponent {
  windowWidth: number = window.innerWidth;
  isLargeView: boolean = false;
  isMediumView: boolean = false;
  isSmallView: boolean = false;
  isVerySmallView: boolean = false;
  screenWidth: number = 0;
  screenHeight: number = 0;
  constructor(
    protected override calendarService: CalendarService,
    protected override translationService: TranslationService,
    protected override postService: PostService,
    protected override uiStateService: UiStateService,
    private screenSizeService: ScreenSizeService
  ) {
    super(translationService, calendarService, postService, uiStateService);
    this.checkWindowSize();
  }
  currentMonth: string = '';

  currentCalenderDays: number[] = [];
  currentCalenderData: { day: number; data: any }[] = [];

  override ngOnInit(): void {
    super.ngOnInit();
    this.onResize();
    this.screenSizeService.screenWidth$.subscribe(
      (width: number) => (this.screenWidth = width)
    );
    this.screenSizeService.screenHeight$.subscribe(
      (height: number) => (this.screenHeight = height)
    );
    console.log('this.screenWidth', this.screenWidth);
    console.log('this.screenHeight', this.screenHeight);
    
    // Debug.log(`${this.constructor.name} initialized`);
  }

  togglePublished() {
    this.isPublished = !this.isPublished;
    this.uiStateService.updatePostTypeFilter({
      isPlanned: this.isPlanned,
      isPublished: this.isPublished,
    });
  }

  togglePlanned() {
    this.isPlanned = !this.isPlanned;
    this.uiStateService.updatePostTypeFilter({
      isPlanned: this.isPlanned,
      isPublished: this.isPublished,
    });
  }

  onCalendarModeChanged(value: any) {
    this.mode = value;
    this.calendarService.setCurrentCalendarMode(this.mode);
  }

  switchToYearView(data: any) {
    this.mode = CALENDERMODE.YEAR;
    this.calendarService.setCurrentCalendarMode(this.mode);
    Debug.log('switchToYearView', data);
  }

  switchToMonthView(data: any) {
    this.mode = CALENDERMODE.MONTH;
    this.calendarService.setCurrentCalendarMode(this.mode);
    Debug.log('switchToMonthView', data);
  }

  switchToDayView(data: any) {
    this.mode = CALENDERMODE.DAY;
    this.calendarService.setCurrentCalendarMode(this.mode);
    Debug.log('switchToDayView', data);
  }
  private checkWindowSize() {
    this.windowWidth = window.innerWidth;
  }

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.windowWidth = window.innerWidth;

    if (1232 < this.windowWidth && this.windowWidth < 1375) {
      // Medium view
      this.isLargeView = false;
      this.isMediumView = true;
      this.isSmallView = false;
      this.isVerySmallView = false;
    } else if (1375 <= this.windowWidth && this.windowWidth < 1515) {
      // Large view
      this.isLargeView = true;
      this.isMediumView = false;
      this.isSmallView = false;
      this.isVerySmallView = false;
    } else if (this.windowWidth >= 1515) {
      // Extra large view
      this.isLargeView = false;
      this.isMediumView = false;
      this.isSmallView = false;
      this.isVerySmallView = false;
    } else if (this.windowWidth >= 1100 && this.windowWidth < 1232) {
      // Small view (windowWidth <= 1232)
      this.isLargeView = false;
      this.isMediumView = false;
      this.isSmallView = true;
      this.isVerySmallView = false;
    } else {
      this.isLargeView = false;
      this.isMediumView = false;
      this.isSmallView = false;
      this.isVerySmallView = true;
    }
  }
}
