<div>
	<div class="fixed inset-0 flex z-40 md:hidden" role="dialog" aria-modal="true">
		<div class="flex-shrink-0 w-14" aria-hidden="true">
			<!-- Dummy element to force sidebar to shrink to fit close icon -->
		</div>
	</div>

	<!-- Static sidebar for desktop -->
	<div class="md:flex  md:flex-col md:fixed md:inset-y-0 border-r-4 border-slate-50" [style.width.px]="screenWidth * 18/100">
		<!-- Sidebar component, swap this element with another sidebar if you like -->
		<div class="flex flex-col flex-grow pt-14 bg-indigo-100 overflow-y-auto">
			<div class="flex items-center flex-shrink-0 px-4">
				<!-- <img class="h-12 w-auto m-auto" src="assets/img/logo.png" alt="logo"> -->
				<img class="{{logoSize}} m-auto cursor-pointer" [src]="getSideBarLogo()" alt="logo" (click)="homePageRedirect()" [ngClass]="{'h-auto w-64 mb-12':!currentRootGroup?.hasSagammLogo,' w-auto ':currentRootGroup?.hasSagammLogo}">
			</div>
			<div class="mt-10 flex-1 flex flex-col">
				<nav class="flex-1 px-2 pb-4 justify-between" [ngClass]="{'space-y-2': screenHeight<=810, 'space-y-5': screenHeight>810}">
					<div class="w-[70%] ml-[25%] absolute mt-[10%] " *ngIf="dropDown">
						<div class="text-[0.9vw] text-center font-bold"> {{ 'header.postAnalysis' | translate }}</div>
						<div class="w-full bg-[#7eced4] h-1 mb-[12px] mt-[8px]"></div>
						<!-- post analysis sub menu -->
						<div [routerLink]="auth?.UI['dashboard']?'/dashboard':'/home'"
							[class.btn-active]="router.url == '/dashboard'"
							class="text-gray-500 hover:text-cliking_blue hover:bg-white text-[0.9vw] mb-2 font-semibold py-2 cursor-pointer rounded-xl">
							<svg [ngClass]="{'w-[17.5px]': screenHeight<=810, 'w-[25px]': screenHeight>810}" class="absolute ml-1" viewBox="0 0 24 24" id="magicoon-Regular"
								xmlns="http://www.w3.org/2000/svg" fill="currentColor">
								<g id="SVGRepo_bgCarrier" stroke-width="0"></g>
								<g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
								<g id="SVGRepo_iconCarrier">
									<defs> </defs>
									<title>comments</title>
									<g id="comments-Regular">
										<path id="comments-Regular-2" data-name="comments-Regular" class="cls-1"
											d="M18,7.25h-.25V6A3.754,3.754,0,0,0,14,2.25H6A3.754,3.754,0,0,0,2.25,6V16a.75.75,0,0,0,.466.694A.741.741,0,0,0,3,16.75a.749.749,0,0,0,.536-.225L5.275,14.75H6.25V16A3.754,3.754,0,0,0,10,19.75h8.725l1.739,1.775A.749.749,0,0,0,21,21.75a.741.741,0,0,0,.284-.056A.75.75,0,0,0,21.75,21V11A3.754,3.754,0,0,0,18,7.25Zm-13.04,6a.75.75,0,0,0-.536.225l-.674.688V6A2.252,2.252,0,0,1,6,3.75h8A2.252,2.252,0,0,1,16.25,6v5A2.252,2.252,0,0,1,14,13.25Zm15.29,5.913-.674-.688a.75.75,0,0,0-.536-.225H10A2.252,2.252,0,0,1,7.75,16V14.75H14A3.754,3.754,0,0,0,17.75,11V8.75H18A2.252,2.252,0,0,1,20.25,11Z">
										</path>
									</g>
								</g>
							</svg>
							<span class="ml-8 ">{{'dashboard.allPosts' | translate}}</span>
						</div>
						<div [routerLink]="auth?.UI['dashboard'] ? '/dashboard/linkedin' : '/'"
							[class.btn-active]="router.url == '/dashboard/linkedin'"
							class="text-gray-500 hover:text-[#0073B0] hover:bg-white text-[0.9vw] mb-2 font-semibold py-2 cursor-pointer rounded-xl h-10"
							(mouseenter)="onHover('linkedin')" (mouseleave)="onHover(null)">

							<app-linkedin-fav-icon [showLabel]="true"
								[color]="hoveredIcon === 'linkedin' || router.url == '/dashboard/linkedin' ? '#0073B0' : '#6b7280'"
								[size]="screenHeight<=810 ? '17.5px':'25px'" class="absolute ml-1" />
						</div>

						<div [routerLink]="auth?.UI['dashboard'] ? '/dashboard/google' : '/'"
							[class.btn-active]="router.url == '/dashboard/google'"
							class="text-gray-500 hover:text-[#ea580c] hover:bg-white text-[0.9vw] mb-2 font-semibold py-2 cursor-pointer rounded-xl h-10"
							(mouseenter)="onHover('google')" (mouseleave)="onHover(null)">

							<app-google-fav-icon [showLabel]="true"
								[color]="hoveredIcon === 'google' || router.url == '/dashboard/google' ? '#ea580c' : '#6b7280'"
								[size]="screenHeight<=810 ? '17.5px':'25px'" class="absolute ml-1" />
						</div>

						<div [routerLink]="auth?.UI['dashboard'] ? '/dashboard/facebook' : '/'"
							[class.btn-active]="router.url == '/dashboard/facebook'"
							class="text-gray-500 hover:text-[#1877F2] hover:bg-white text-[0.9vw] mb-2 font-semibold py-2 cursor-pointer rounded-xl h-10"
							(mouseenter)="onHover('facebook')" (mouseleave)="onHover(null)">

							<app-facebook-fav-icon [showLabel]="true"
								[color]="hoveredIcon === 'facebook' || router.url == '/dashboard/facebook' ? '#1877F2' : '#6b7280'"
								[size]="screenHeight<=810 ? '17.5px':'25px'" class="absolute ml-1" />
						</div>

						<div [routerLink]="auth?.UI['dashboard'] ? '/dashboard/instagram' : '/'"
							[class.btn-active]="router.url == '/dashboard/instagram'"
							class="text-gray-500 hover:text-[#c13584] hover:bg-white text-[0.9vw] mb-2 font-semibold py-2 cursor-pointer rounded-xl h-10"
							(mouseenter)="onHover('instagram')" (mouseleave)="onHover(null)">

							<app-instagram-fav-icon [showLabel]="true"
								[color]="hoveredIcon === 'instagram' || router.url == '/dashboard/instagram' ? '#c13584' : '#6b7280'"
								[size]="screenHeight<=810 ? '17.5px':'25px'" class="absolute ml-1" />
						</div>
					</div>
					<a href="https://www.goodmeal.fr/login" target="_blank" [ngClass]="{'w-[20%]':dropDown}"
						class="text-gray-500 hover:text-cliking_blue group flex items-center px-3 py-2 space-y-1 text-[0.9vw] font-semibold rounded-md bg-white border-2 border-gray-500 hover:border-cliking_blue rounded-11 box-border shadow-blur">
						<!-- Heroicon name: outline/calendar -->
						<!-- <svg class="mr-3 flex-shrink-0 h-6 w-6" fill="currentColor" xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 512 512"><path d="M481 31C445.1-4.8 386.9-4.8 351 31l-15 15L322.9 33C294.8 4.9 249.2 4.9 221.1 33L135 119c-9.4 9.4-9.4 24.6 0 33.9s24.6 9.4 33.9 0L255 66.9c9.4-9.4 24.6-9.4 33.9 0L302.1 80 186.3 195.7 316.3 325.7 481 161c35.9-35.9 35.9-94.1 0-129.9zM293.7 348.3L163.7 218.3 99.5 282.5c-48 48-80.8 109.2-94.1 175.8l-5 25c-1.6 7.9 .9 16 6.6 21.7s13.8 8.1 21.7 6.6l25-5c66.6-13.3 127.8-46.1 175.8-94.1l64.2-64.2z"/></svg> -->
						<svg class="mr-3 flex-shrink-0 h-6 w-6" width="50px" height="50px" viewBox="0 0 24 24"
							fill="none" xmlns="http://www.w3.org/2000/svg">
							<g id="SVGRepo_bgCarrier" stroke-width="0"></g>
							<g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
							<g id="SVGRepo_iconCarrier">
								<path fill-rule="evenodd" clip-rule="evenodd"
									d="M10 2C10 1.57279 9.72862 1.19277 9.32454 1.05413C8.92046 0.915482 8.47293 1.04884 8.21065 1.38606L1.21065 10.3861C0.929784 10.7472 0.929784 11.2528 1.21065 11.6139L8.21065 20.6139C8.47293 20.9512 8.92046 21.0845 9.32454 20.9459C9.72862 20.8072 10 20.4272 10 20V16.0102C15.3788 16.1223 17.9631 17.1431 19.2613 18.2532C20.4954 19.3085 20.7201 20.5489 20.9557 21.8492L20.9558 21.8496C20.9759 21.9606 20.9961 22.0721 21.017 22.1838C21.1098 22.6798 21.5576 23.029 22.0613 22.9981C22.5649 22.9672 22.9666 22.5659 22.9981 22.0623C23.1695 19.3143 22.9121 15.3323 20.9709 12.0007C19.0871 8.7677 15.6945 6.28195 10 6.02241V2Z"
									fill="currentColor"></path>
							</g>
						</svg>
						<span *ngIf="!dropDown">{{ 'header.backToSatisfaction' | translate }}</span>
					</a>

					<!-- <a [ngClass]="{
						'w-[20%]':dropDown,
						'cursor-not-allowed text-gray-400 hover:text-gray-400':!auth?.UI['dashboard']
					}" [routerLink]="auth$?.UI?.canAccessDashboard?'/dashboard':'/home'" [class.btn-active]="router.url == '/dashboard'" class="text-gray-500 hover:text-cliking_blue hover:bg-white  flex items-center space-y-1 mt-4 px-3 py-2 text-[0.9vw] font-semibold rounded-xl shadow-blur-hover">
						<svg class="mr-3 flex-shrink-0 h-6 w-6" width="30px"  viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" transform="rotate(180)matrix(-1, 0, 0, 1, 0, 0)"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path d="M15.98 19.3789H17.02C18.61 19.3789 19.25 18.7789 19.25 17.2589V5.37891H13.75V17.2589C13.76 18.7789 14.4 19.3789 15.98 19.3789Z" fill="currentColor"></path> <path d="M7.48 14.3789H8.52C10.11 14.3789 10.75 13.7689 10.75 12.2589V5.37891H5.25V12.2589C5.26 13.7689 5.9 14.3789 7.48 14.3789Z" fill="currentColor"></path> <path d="M21.25 6.11891H2.75C2.34 6.11891 2 5.78891 2 5.37891C2 4.96891 2.34 4.62891 2.75 4.62891H21.25C21.66 4.62891 22 4.96891 22 5.37891C22 5.78891 21.66 6.11891 21.25 6.11891Z" fill="currentColor"></path> </g></svg>
						{{ 'header.postAnalysis' | translate }}
					</a> --> <!-- accessible dasboard -->
					<a (click)="openDropDown()" [routerLink]="auth?.UI['dashboard']?'/dashboard':'/home'"
						[class.btn-active]="router.url.startsWith('/dashboard')  || router.url.includes('/post-details')" [ngClass]="{
							'w-[20%]':dropDown,
							'cursor-not-allowed text-gray-400 hover:text-gray-400':!auth?.UI['dashboard']
						}" class="text-gray-500 hover:text-cliking_blue hover:bg-white flex items-center space-y-1 mt-4 px-3 py-2 text-[0.9vw] font-semibold rounded-xl shadow-blur-hover"
						[title]="'generic.comingSoon' | translate">
						<svg class="mr-3 flex-shrink-0 h-6 w-6" width="30px" viewBox="0 0 24 24"
							xmlns="http://www.w3.org/2000/svg" transform="rotate(180)matrix(-1, 0, 0, 1, 0, 0)">
							<g id="SVGRepo_bgCarrier" stroke-width="0"></g>
							<g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
							<g id="SVGRepo_iconCarrier">
								<path
									d="M15.98 19.3789H17.02C18.61 19.3789 19.25 18.7789 19.25 17.2589V5.37891H13.75V17.2589C13.76 18.7789 14.4 19.3789 15.98 19.3789Z"
									fill="currentColor"></path>
								<path
									d="M7.48 14.3789H8.52C10.11 14.3789 10.75 13.7689 10.75 12.2589V5.37891H5.25V12.2589C5.26 13.7689 5.9 14.3789 7.48 14.3789Z"
									fill="currentColor"></path>
								<path
									d="M21.25 6.11891H2.75C2.34 6.11891 2 5.78891 2 5.37891C2 4.96891 2.34 4.62891 2.75 4.62891H21.25C21.66 4.62891 22 4.96891 22 5.37891C22 5.78891 21.66 6.11891 21.25 6.11891Z"
									fill="currentColor"></path>
							</g>
						</svg>
						<span *ngIf="!dropDown">{{ 'header.postAnalysis' | translate }}</span>
					</a>
					<a [ngClass]="{
						'w-[20%]':dropDown,
						'cursor-not-allowed text-gray-400 hover:text-gray-400':!auth?.UI['post.creation']
					}" (click)="dropDown=false" [routerLink]="auth?.UI['post.creation']?'/posts':'/home'"
						[class.btn-active]="router.url == '/posts'"
						class="text-gray-500 hover:text-cliking_blue hover:bg-white  flex items-center space-y-1 mt-4 px-3 py-2 text-[0.9vw] font-semibold rounded-xl shadow-blur-hover">
						<!-- Heroicon name: outline/calendar -->
						<svg class="mr-3 flex-shrink-0 h-6 w-6 " fill="currentColor" xmlns="http://www.w3.org/2000/svg"
							height="16" width="16" viewBox="0 0 512 512">
							<path
								d="M481 31C445.1-4.8 386.9-4.8 351 31l-15 15L322.9 33C294.8 4.9 249.2 4.9 221.1 33L135 119c-9.4 9.4-9.4 24.6 0 33.9s24.6 9.4 33.9 0L255 66.9c9.4-9.4 24.6-9.4 33.9 0L302.1 80 186.3 195.7 316.3 325.7 481 161c35.9-35.9 35.9-94.1 0-129.9zM293.7 348.3L163.7 218.3 99.5 282.5c-48 48-80.8 109.2-94.1 175.8l-5 25c-1.6 7.9 .9 16 6.6 21.7s13.8 8.1 21.7 6.6l25-5c66.6-13.3 127.8-46.1 175.8-94.1l64.2-64.2z" />
						</svg>
						<span *ngIf="!dropDown">{{ 'header.posts' | translate }}</span>
					</a>

					<a [ngClass]="{
						'w-[20%]':dropDown,
						'cursor-not-allowed text-gray-400 hover:text-gray-400':!auth?.UI['post.library']
					}" (click)="dropDown=false" [routerLink]="auth?.UI['post.library']?'/library':'/home'"
						[class.btn-active]="router.url == '/library'"
						class="text-gray-500 hover:text-cliking_blue hover:bg-white  flex items-center space-y-1 mt-4 px-3 py-2 text-[0.9vw] font-semibold rounded-xl shadow-blur-hover">
						<!-- Heroicon name: outline/inbox -->
						<svg class="mr-3 flex-shrink-0 h-6 w-6 " fill="currentColor" xmlns="http://www.w3.org/2000/svg"
							height="16" width="18" viewBox="0 0 576 512">
							<path
								d="M290.8 48.6l78.4 29.7L288 109.5 206.8 78.3l78.4-29.7c1.8-.7 3.8-.7 5.7 0zM136 92.5V204.7c-1.3 .4-2.6 .8-3.9 1.3l-96 36.4C14.4 250.6 0 271.5 0 294.7V413.9c0 22.2 13.1 42.3 33.5 51.3l96 42.2c14.4 6.3 30.7 6.3 45.1 0L288 457.5l113.5 49.9c14.4 6.3 30.7 6.3 45.1 0l96-42.2c20.3-8.9 33.5-29.1 33.5-51.3V294.7c0-23.3-14.4-44.1-36.1-52.4l-96-36.4c-1.3-.5-2.6-.9-3.9-1.3V92.5c0-23.3-14.4-44.1-36.1-52.4l-96-36.4c-12.8-4.8-26.9-4.8-39.7 0l-96 36.4C150.4 48.4 136 69.3 136 92.5zM392 210.6l-82.4 31.2V152.6L392 121v89.6zM154.8 250.9l78.4 29.7L152 311.7 70.8 280.6l78.4-29.7c1.8-.7 3.8-.7 5.7 0zm18.8 204.4V354.8L256 323.2v95.9l-82.4 36.2zM421.2 250.9c1.8-.7 3.8-.7 5.7 0l78.4 29.7L424 311.7l-81.2-31.1 78.4-29.7zM523.2 421.2l-77.6 34.1V354.8L528 323.2v90.7c0 3.2-1.9 6-4.8 7.3z" />
						</svg>
						<span *ngIf="!dropDown">{{ 'header.library' | translate }}</span>
					</a>

					<a [ngClass]="{
						'w-[20%]':dropDown,
						'cursor-not-allowed text-gray-400 hover:text-gray-400':!auth?.UI['calendar']
					}" (click)="dropDown=false" [routerLink]="auth?.UI['calendar']?'/calendar':'/home'"
						[class.btn-active]="router.url == '/calendar'"
						class="text-gray-500 hover:text-cliking_blue hover:bg-white  flex items-center space-y-1 mt-4 px-3 py-2 text-[0.9vw] font-semibold rounded-xl shadow-blur-hover">
						<!-- Heroicon name: outline/calendar -->
						<svg class="mr-3 flex-shrink-0 h-6 w-6 " fill="currentColor" xmlns="http://www.w3.org/2000/svg"
							height="16" width="14" viewBox="0 0 448 512">
							<path
								d="M96 32V64H48C21.5 64 0 85.5 0 112v48H448V112c0-26.5-21.5-48-48-48H352V32c0-17.7-14.3-32-32-32s-32 14.3-32 32V64H160V32c0-17.7-14.3-32-32-32S96 14.3 96 32zM448 192H0V464c0 26.5 21.5 48 48 48H400c26.5 0 48-21.5 48-48V192z" />
						</svg>
						<span *ngIf="!dropDown">{{ 'header.postsPlanner' | translate }} </span>
					</a>
					<a [ngClass]="{
						'w-[20%]':dropDown,
						'cursor-not-allowed text-gray-400 hover:text-gray-400':!auth?.UI['google.presence.managment']
					}" (click)="dropDown=false" [routerLink]="auth?.UI['google.presence.managment']?'/presence-management':'/home'"
						[class.btn-active]="router.url.startsWith('/presence-management')"
						class="text-gray-500 hover:text-cliking_blue hover:bg-white  flex items-center space-y-1 mt-4 px-3 py-2 text-[0.9vw] font-semibold rounded-xl shadow-blur-hover">
						<svg class="mr-3 flex-shrink-0 h-6 w-6" fill="currentColor" width="800px" height="16" width="14"
							viewBox="0 0 512.00 512.00" xmlns="http://www.w3.org/2000/svg" stroke="#000000"
							transform="rotate(0)">
							<g id="SVGRepo_bgCarrier" stroke-width="0" />
							<g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"
								stroke="#CCCCCC" stroke-width="2.048" />
							<g id="SVGRepo_iconCarrier">
								<path
									d="M473.16,221.48l-2.26-9.59H262.46v88.22H387c-12.93,61.4-72.93,93.72-121.94,93.72-35.66,0-73.25-15-98.13-39.11a140.08,140.08,0,0,1-41.8-98.88c0-37.16,16.7-74.33,41-98.78s61-38.13,97.49-38.13c41.79,0,71.74,22.19,82.94,32.31l62.69-62.36C390.86,72.72,340.34,32,261.6,32h0c-60.75,0-119,23.27-161.58,65.71C58,139.5,36.25,199.93,36.25,256S56.83,369.48,97.55,411.6C141.06,456.52,202.68,480,266.13,480c57.73,0,112.45-22.62,151.45-63.66,38.34-40.4,58.17-96.3,58.17-154.9C475.75,236.77,473.27,222.12,473.16,221.48Z" />
							</g>
						</svg>
						<span *ngIf="!dropDown">{{ 'header.presenceManagement' | translate }}</span>
					</a>

					<a *ngIf="currentRootGroup?.hasSagammLogo" 
						[ngClass]="{
							'w-[20%]':dropDown,
							'cursor-not-allowed text-gray-400 hover:text-gray-400':!auth?.UI['sociabble']
						}" (click)="dropDown=false" [routerLink]="auth?.UI['sociabble']?'/sociabble-page':'/home'"
						[class.btn-active]="router.url == '/sociabble-page'" (mouseenter)="hoveringOverLink = true"
						(mouseleave)="hoveringOverLink = false"
						class="text-gray-500 hover:text-cliking_blue hover:bg-white  flex items-center space-y-1 mt-4 px-3 py-2 text-[0.9vw] font-semibold rounded-xl shadow-blur-hover">
						<img class="mr-2 flex-shrink-0 h-7 w-7 "
							[src]="(router.url == '/sociabble-page' || hoveringOverLink&& auth?.isAdmin)?'assets/img/sociabble-active.png':'assets/img/sociabble.png'"
							alt="sociabble">
						<span *ngIf="!dropDown"> Sociabble </span>
					</a>


				</nav>
				<nav *ngIf="env == 'development' || env == 'staging' ">
					<span href="#"
						class="text-gray-500 group flex items-center mb-5 ml-5 cursor-text  text-[0.9vw] font-semibold rounded-md">
						<span class="mr-2 text-3xl text-cliking_green">• </span>{{getUserRole()}}
						<span class="badge gap-2 text-[10px] ml-1 text-white" 
							[ngClass]="{
								'badge-info': env =='development',
								'badge-warning': env =='staging'
							}"
						>
							{{env}}
						</span>
					</span>
				</nav>
			</div>
		</div>
	</div>

	<div class="flex-grow">
		<div class="" [style.margin-left.px]="screenWidth * 18/100">
			<ng-content></ng-content>
		</div>
	</div>

</div>