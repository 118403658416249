import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { CALENDERMODE } from 'src/app/interfaces/CalendarMode';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CalendarService {
  lsData:any
  constructor() {
    this.lsData = localStorage.getItem(environment.sessionData)
    this.lsData = JSON.parse(this.lsData)
   }
  currentDate:Date  = new Date();
  currentCalendarView:CALENDERMODE = CALENDERMODE.MONTH;
  toDay:Date = new Date()
  currentSelectedHour:string = ''
  private currentDateSubject = new BehaviorSubject<Date>(this.currentDate);
  private currentSelectedHourSubject= new BehaviorSubject<string>(this.currentSelectedHour);
  private currentModeSubject = new BehaviorSubject<CALENDERMODE>(this.currentCalendarView);

  getcurrentSelectedHour():Observable<string> {
    return this.currentSelectedHourSubject.asObservable();
  }
  
  setcurrentSelectedHour(value:string) {
    this.currentSelectedHour = value;
    this.currentSelectedHourSubject.next(value);
  }

  getCurrentDate(): Observable<Date> {
    return this.currentDateSubject.asObservable();
  }

  getCurrentCalendarMode(): Observable<CALENDERMODE> {
     return this.currentModeSubject.asObservable();
  }
  setCurrentCalendarMode(mode:CALENDERMODE){
    this.currentCalendarView = mode;
    this.currentModeSubject.next(mode);
  }
  addDay(amount:number):void {
    const newDate = new Date(this.currentDate);
    newDate.setDate(this.currentDate.getDate() + amount);
    this.updateCurrentDate(newDate);
  }
  addWeek(amount:number){
    const newDate = new Date(this.currentDate);
    newDate.setDate(this.currentDate.getDate() + 7 * amount);
    this.updateCurrentDate(newDate);
  }
  addMonth(amount:number){
    const newDate = new Date(this.currentDate);
    newDate.setMonth(this.currentDate.getMonth() + amount);
    this.updateCurrentDate(newDate);
  }
  addYear(amount:number){
    const newDate = new Date(this.currentDate);
    newDate.setFullYear(this.currentDate.getFullYear() + amount);
    this.updateCurrentDate(newDate);
  }

   // Method to update the current date and emit the change
   private updateCurrentDate(newDate: Date) {
    this.currentDate = newDate;
    this.currentDateSubject.next(this.currentDate);
  }
  getCurrentWeek(): Date[] {
    // Create a new Date object to avoid modifying the original currentDate
    let start = new Date(this.currentDate);

  
    // Calculate the previous Sunday as the start of the week
    //start.setDate(start.getDate() - start.getDay());
  
    // for a week starting on Monday:
    start.setDate(start.getDate() - ((start.getDay() + 6) % 7));
  
    // Array to hold the days of the week
    const week: Date[] = [];
  
    // Loop to add each day of the week to the array
    for (let i = 0; i < 7; i++) {
      // Create a new Date for each day of the week
      let day = new Date(start);
      day.setDate(day.getDate() + i);

      week.push(day);
    }
  
    return week;
  }
  setCurrentDate(date: Date): void {
    if(this.isValidDate(date)){
      this.updateCurrentDate(date)
    }
  }
  setCurrentMonth(month:number): void {
    if (month >= 0 && month <= 11) { // Ensure the month is valid
      const newDate = new Date(this.currentDate);
      const currentDay = newDate.getDate();
  
      newDate.setMonth(month);
      newDate.setDate(Math.min(currentDay, this.daysInMonth(newDate.getFullYear(), month)));
  
      this.updateCurrentDate(newDate);
    }
  }
  setCurrentYear(year: number): void {
    const newDate = new Date(this.currentDate);
    newDate.setFullYear(year);
    this.updateCurrentDate(newDate);
  }

  private daysInMonth(year: number, month: number): number {
    return new Date(year, month + 1, 0).getDate();
  }
  private isValidDate(d:any) {
    return d instanceof Date && !isNaN(d.getTime());
  }

}
