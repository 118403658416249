import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { LoginComponent } from './components/user/login/login.component';
import { NewPasswordComponent } from './components/user/new-password/new-password.component';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { AuthGuard } from './guards/auth.guard';
import { AuthRedirectGuard } from './guards/auth-redirect.guard';
import { GlobalSettingsComponent } from './components/dynamic/globalSettings/globalSettings.component';
import { ChoosePageComponent } from './components/socialmedia/socialmedias/choose-page/choose-page.component';
import { NotfoundComponent } from './components/error/notfound/notfound.component';
import { SocialmediasComponent } from './components/socialmedia/socialmedias/socialmedias.component';
import { PostsComponent } from './components/post/posts/posts.component';
import { MainLayoutComponent } from './components/layouts/main-layout/main-layout.component';
import { AuthLayoutComponent } from './components/layouts/auth-layout/auth-layout.component';
import { PostCalenderComponent } from './components/post/post-calender/post-calender.component';
import { PostsLibraryComponent } from './components/post/posts-library/posts-library.component';
import { SociabblePageComponent } from './components/sociabble-page/sociabble-page.component';
import { PresenceManagementComponent } from './components/presence-management/presence-management.component';
import { ManagePageComponent } from './components/presence-management/manage-page/manage-page.component';
import { ApiErrorsComponent } from './components/presence-management/api-errors/api-errors.component';
import { PermissionGuard } from './guards/permission-redirect.guard';
import { InstagramStatsComponent } from './components/dashboard/stats/instagram-stats/instagram-stats.component';
import { FacebookStatsComponent } from './components/dashboard/stats/facebook-stats/facebook-stats.component';
import { GoogleStatsComponent } from './components/dashboard/stats/google-stats/google-stats.component';
import { LinkedinStatsComponent } from './components/dashboard/stats/linkedin-stats/linkedin-stats.component';
import { PostDetailsComponent } from './components/post/post-preview/post-details/post-details.component';
import { ErrorPageComponent } from './components/error/error-page/error-page.component';
import { HomePageComponent } from './components/home/home-page/home-page.component';
import { NotAllowedComponent } from './components/dynamic/not-allowed/not-allowed.component';
import { BackOfficeComponent } from './components/user/back-office/back-office.component';


const routes: Routes = [
  {
    path: '',
    component: AuthLayoutComponent,
    children: [
      {path: 'login', component: LoginComponent, canActivate: [AuthRedirectGuard],},
      {path: 'new-password/:token', component: NewPasswordComponent, canActivate: [AuthRedirectGuard],},
      {path: 'back-office', component: BackOfficeComponent,canActivate: [AuthGuard, PermissionGuard],data: { mapName: 'global.backoffice' }},
      {path: '', redirectTo: 'login', pathMatch: 'full'}
    ],
  },
  {
    path: '',
    component: MainLayoutComponent,
    canActivate: [AuthGuard],
    children: [
      
      { path: 'dashboard', component: DashboardComponent,canActivate: [AuthGuard, PermissionGuard],data: { mapName: 'dashboard' }},
			{ path: 'dashboard/instagram', component: InstagramStatsComponent,canActivate: [AuthGuard, PermissionGuard],data: { mapName: 'dashboard' }},
			{ path: 'dashboard/facebook', component: FacebookStatsComponent,canActivate: [AuthGuard, PermissionGuard],data: { mapName: 'dashboard' }},
			{ path: 'dashboard/google', component: GoogleStatsComponent,canActivate: [AuthGuard, PermissionGuard],data: { mapName: 'dashboard' }},
			{ path: 'dashboard/linkedin', component: LinkedinStatsComponent,canActivate: [AuthGuard, PermissionGuard],data: { mapName: 'dashboard' }},
      { path: 'social-medias', component: SocialmediasComponent },
      { path: 'posts', component: PostsComponent,canActivate: [AuthGuard, PermissionGuard],data: { mapName: 'post.creation' } },
      { path: 'settings', component: GlobalSettingsComponent,canActivate: [AuthGuard, PermissionGuard],data: { mapName: 'global.settings' }  },
      { path: 'social-medias/:id/choose-page', component: ChoosePageComponent },
      { path: 'calendar', component: PostCalenderComponent ,canActivate: [AuthGuard, PermissionGuard],data: { mapName: 'calendar' } },
      { path: 'library', component: PostsLibraryComponent , canActivate: [AuthGuard, PermissionGuard],data: { mapName: 'post.library' } },
      { path: 'library/post-details/:id', component: PostDetailsComponent , canActivate: [AuthGuard, PermissionGuard],data: { mapName: 'post.library' } },
      { path: 'sociabble-page', component: SociabblePageComponent,canActivate: [AuthGuard, PermissionGuard],data: { mapName: 'sociabble' }  },
      { path: 'presence-management', component: PresenceManagementComponent ,canActivate: [AuthGuard, PermissionGuard],data: { mapName: 'google.presence.managment' } },
      { path: 'presence-management/create-page', component: ManagePageComponent,canActivate: [AuthGuard, PermissionGuard],data: { mapName: 'google.presence.managment' }  },
      { path: 'presence-management/edit-page', component: ManagePageComponent,canActivate: [AuthGuard, PermissionGuard],data: { mapName: 'google.presence.managment' }  },
      { path: 'presence-management/api-errors', component: ApiErrorsComponent,canActivate: [AuthGuard, PermissionGuard],data: { mapName: 'google.presence.managment' }  },
      { path: 'home', component: HomePageComponent,canActivate: [AuthGuard, PermissionGuard], data: { mapName: 'home' }},
      { path: '', component: HomePageComponent,canActivate: [AuthGuard, PermissionGuard], data: { mapName: 'home' }},
      { path: 'error', component: ErrorPageComponent,},
      { path: 'not-allowed', component: NotAllowedComponent,},
      { path: '**', component: NotfoundComponent },
    ],
  },
];

@NgModule({
  imports: [
		RouterModule.forRoot(routes),
		BrowserModule,
		HttpClientModule
	],
  exports: [RouterModule]
})
export class AppRoutingModule {}
