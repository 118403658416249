import { Component, Input } from '@angular/core';
import { Subscription } from 'rxjs';
import { UiStateService } from 'src/app/services/utilities/ui-state.service';

@Component({
  selector: 'app-post-filters',
  templateUrl: './post-filters.component.html',
  styleUrls: ['./post-filters.component.scss']
})
export class PostFiltersComponent {
constructor(private uiStateService: UiStateService){}

  currentDateRange:any
  platforms={
    isGoogleON:false,
    isLinkedinON:false,
    isFacebookON:false,
    isInstagramON:false
  }
  postTypes:any={
    isPlanned:false,
    isPosted:false,
    isDraft:false
  }
  @Input() dashboardSource: string = ''
  @Input() dashboard: boolean = false;
  @Input() isRouteOnDashboard: boolean = false;
  subscriptions: Subscription[] = [];
  ngOnInit(){


   this.subscriptions.push( this.uiStateService.dateRangeUpdate$.subscribe((data) => {
      this.currentDateRange = data;  
    }));

    this.subscriptions.push(this.uiStateService.platformsFilterUpdated$.subscribe((data) => {
      this.platforms = {...data}
    }))
    
    this.subscriptions.push(this.uiStateService.postTypesUpdated$.subscribe((data) =>{
      this.postTypes = data
    }))

    if(this.isRouteOnDashboard){
      switch(this.dashboardSource){
        case 'linkedin':this.platforms={isLinkedinON:true,isGoogleON:false,isFacebookON:false,isInstagramON:false}; break;
        case 'facebook':this.platforms={isLinkedinON:false,isGoogleON:false,isFacebookON:true,isInstagramON:false}; break;
        case 'instagram':this.platforms={isLinkedinON:false,isGoogleON:false,isFacebookON:false,isInstagramON:true}; break;
        case 'google':this.platforms={isLinkedinON:false,isGoogleON:true,isFacebookON:false,isInstagramON:false}; break;
      }

    }
    else{

      this.updatePlatforms(this.platforms)
    }
  }

  getSelectedDateRange() {
    const startDate = this.currentDateRange?.startDate ? new Date(this.currentDateRange?.startDate) : null;
    const endDate = this.currentDateRange?.endDate ? new Date(this.currentDateRange?.endDate) : null;
  
    if (startDate && endDate) {
      return `${startDate.getDate().toString().padStart(2, "0")}/${(startDate.getMonth() + 1).toString().padStart(2, "0")}/${startDate.getFullYear()} - ${endDate.getDate().toString().padStart(2, "0")}/${(endDate.getMonth() + 1).toString().padStart(2, "0")}/${endDate.getFullYear()}`;
    }
  
    return 'Invalid date range';  // Optional: handle the case where the date is invalid or missing
  }
 
  updatePlatforms(value:any){
    switch(value.toString().toLocaleLowerCase()){
      case "linkedin":
        this.platforms.isLinkedinON = !this.platforms.isLinkedinON
        break;
        case "google":
          this.platforms.isGoogleON = !this.platforms.isGoogleON
          break;
        case "facebook":
          this.platforms.isFacebookON = !this.platforms.isFacebookON
          break;
        case "instagram":
          this.platforms.isInstagramON = !this.platforms.isInstagramON
          break;
    }
    if(this.isRouteOnDashboard ){
      // console.log(this.curre)
      switch(this.dashboardSource){
        case 'linkedin':this.platforms={isLinkedinON:true,isGoogleON:false,isFacebookON:false,isInstagramON:false}; break;
        case 'facebook':this.platforms={isLinkedinON:false,isGoogleON:false,isFacebookON:true,isInstagramON:false}; break;
        case 'instagram':this.platforms={isLinkedinON:false,isGoogleON:false,isFacebookON:false,isInstagramON:true}; break;
        case 'google':this.platforms={isLinkedinON:false,isGoogleON:true,isFacebookON:false,isInstagramON:false}; break;
      }
    }
    // console.log(this.platforms)
    this.uiStateService.updatePlatformsFilter(this.platforms);
  }

  updatePostType(value:any){
    
    if(this.isRouteOnDashboard) {
      this.postTypes={isPosted:this.postTypes.isPosted,isPlanned:this.postTypes.isPlanned}
    }
    switch(value.toString().split('.')[1]){
      case "planned":
        this.postTypes.isPlanned = !this.postTypes.isPlanned
        break;
      case "posted":
          this.postTypes.isPosted = !this.postTypes.isPosted
        break;
      case "draft":
          this.postTypes.isDraft  = !this.postTypes.isDraft
        break;
        
    }
    console.log("[post filters] postTypes after update",this.postTypes)
    this.uiStateService.updatePostTypeFilter(this.postTypes);
  }
  resetFilters(){
    if(this.isRouteOnDashboard) {
      this.postTypes={isPosted:true,isPlanned:true}
    }
    else{
      this.postTypes={isPosted:true,isPlanned:true,isDraft:true}
      this.platforms={isLinkedinON:true,isGoogleON:true,isFacebookON:true,isInstagramON:true}; 
    }

    const currentYear = new Date().getFullYear();
    const startDate = new Date(currentYear - 2, 0, 1); // January 1st, two years ago
    const endDate = new Date(currentYear, 11, 31); // December 31st of the current year
    this.currentDateRange={
      startDate:startDate,
      endDate:endDate
    }

    this.uiStateService.updatePostTypeFilter(this.postTypes);
    this.uiStateService.updatePlatformsFilter(this.platforms)
    this.uiStateService.updateDateRange(this.currentDateRange) 
  }

  ngOnDestroy() {
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }
}
