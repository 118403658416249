<div class="h-[82vh] bg-white rounded-xl">
	<app-calendar-filter [screenHeight]="screenHeight" [day]="currentDate.getDate().toString().padStart(2, '0')" [month]="getCurrentMonthStr()" [year]="currentYear" (previewDate)="prevDay()" (nextDate)="nextDay()"/>
	<div class="join h-[79vh] w-full">
		<div class="w-[55%]  pt-3"  *ngIf="!isLoading && currentDayPosts.length>0">
			<span class="text-[1vw] rounded-lg ml-8 p-1 uppercase sticky" [ngClass]="{'bg-cliking_blue text-white': toDay()}">
				{{getCurrentDayStr()}}
				<span class="font-semibold">
					{{currentDate.getDate().toString().padStart(2, '0')}}
				</span > 
			</span>
			<div *ngIf="currentDayPosts.length>0" class="overflow-y-auto h-[85%] ml-4 my-2">
				<div class="ml-4 mr-2 w-[95%]" *ngFor="let post of currentDayPosts ; index as i">
					<div *ngIf="post.isVisible">
						<span class="ml-2 text-base" *ngIf="post.postHourRange">
							{{post.postHourRange}}
						</span>
						<div class="w-full"
							[ngClass]="{
							'border-[2px] m-[2px] rounded-2xl': clikedPost == i,
							'p-[2px]': clikedPost != i,
							'border-[#1dc9b7] postedShadow': clikedPost == i && post.isPosted,
							'border-[#ffb822] plannedShodow': clikedPost == i && post.isPlanned
							}"
						>
							<app-post-day-label (click)="clickPost(i)" class="cursor-pointer"
								[post]="post"
								[currentGroups]="groupScopes"
								[isSelected]="clikedPost == i"
							/>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="w-[55%] pt-5 relative"  *ngIf="!isLoading && currentDayPosts.length==0">
			<span class="text-[1vw] rounded-lg ml-8 p-1 uppercase sticky" [ngClass]="{'bg-cliking_blue text-white': toDay()}">
				{{getCurrentDayStr()}}
				<span class="font-semibold">
					{{currentDate.getDate().toString().padStart(2, '0')}}
				</span > 
			</span>
			<div class="flex flex-col justify-center items-center h-3/4 gap-1">
				<span class="text-[#6b7280] font-bold text-base">
					<span>{{("posts.noPost" |translate)}}</span>  
					<span *ngIf="getDateTens() == 'passed' || getDateTens() == 'today'" class="lowercase"> {{("generic.posted" |translate)}} </span>
					<span *ngIf="getDateTens() == 'today'" class="lowercase"> {{("generic.or" |translate)}} </span>
					<span *ngIf="getDateTens() == 'futur' || getDateTens() == 'today'" class="lowercase"> {{("generic.planned" |translate)}} </span>
					<span class="lowercase"> {{("generic.this" |translate)}} </span>
					<span class="lowercase"> {{"dates.timeUnits.day" |translate}}</span>				
				</span>
				<span *ngIf="getDateTens() == 'futur' || getDateTens() == 'today'" class="text-[#a4a8b7] text-sm">
					{{'generic.calendarDayNoPostSubMsg'| translate}}
				</span>
				<button *ngIf="getDateTens() != 'passed'" class="bg-cliking_blue p-2 ml-6 mt-4 rounded-lg join" (click)="externLinks('/posts')">
					<span href="/posts" class="text-white text-[1vw] mr-2">
						{{'generic.CreateAPost' | translate}}
					</span>
					<span class="my-auto">
						<svg width="18" height="18" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path d="M12.6001 2.1001C7.0759 2.1001 2.6001 6.5759 2.6001 12.1001C2.6001 17.6243 7.0759 22.1001 12.6001 22.1001C18.1243 22.1001 22.6001 17.6243 22.6001 12.1001C22.6001 6.5759 18.1243 2.1001 12.6001 2.1001ZM18.4065 13.2291C18.4065 13.4953 18.1888 13.713 17.9227 13.713H14.213V17.4227C14.213 17.6888 13.9953 17.9065 13.7291 17.9065H11.4711C11.2049 17.9065 10.9872 17.6888 10.9872 17.4227V13.713H7.27752C7.01139 13.713 6.79365 13.4953 6.79365 13.2291V10.9711C6.79365 10.7049 7.01139 10.4872 7.27752 10.4872H10.9872V6.77752C10.9872 6.51139 11.2049 6.29365 11.4711 6.29365H13.7291C13.9953 6.29365 14.213 6.51139 14.213 6.77752V10.4872H17.9227C18.1888 10.4872 18.4065 10.7049 18.4065 10.9711V13.2291Z" fill="white"/>
						</svg>
					</span>
				</button>
			</div>
		</div>
		<div class="w-[44%]"  *ngIf="!isLoading && currentDayPosts.length>0">
			<app-show-post 
				class="w-full max-h-[70vh] block"	
				[screenHeight]="screenHeight" [screenWidth]="screenWidth" [posts]="selectedPost"
			/>
		</div>
		<div class="w-[44%]"  *ngIf="!isLoading && currentDayPosts.length==0">
			<div class="flex items-center justify-center ml-5">
				<img class="w-[100%]" src="/assets/img/noPostDayView.png" alt="">
			</div>
		</div>
	</div>
</div>
<app-loader [isLoading]="isLoading"/>