import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CalendarService } from 'src/app/services/utilities/calendar.service';
import { CalenderBaseComponent } from 'src/app/components/base/calendar.component';
import { PostService } from 'src/app/services/repositories/post.service';
import { TranslationService } from 'src/app/services/utilities/translation.service';
import { UiStateService } from 'src/app/services/utilities/ui-state.service';
import { CALENDERMODE } from 'src/app/interfaces/CalendarMode';

@Component({
  selector: 'app-calendar-filter',
  templateUrl: './calendar-filter.component.html',
  styleUrls: ['./calendar-filter.component.scss']
})
export class CalendarFilterComponent extends CalenderBaseComponent {

  constructor(
    protected override uiStateService: UiStateService,
    protected override postService: PostService,
    protected override translationService: TranslationService,
    protected override calendarService: CalendarService, 
  ) {
    super(translationService, calendarService, postService, uiStateService);
  }
  @Input() year : any = null;
  @Input() month : any = null;
  @Input() week : any = null;
  @Input() weekStart : any = '';
  @Input() weekEnd : any = '';
  @Input() startMonthStr : any = '';
  @Input() endMonthStr : any = '';
  @Input() weekYear : any = '';
  @Input() screenHeight: number = 0;

  @Input() day : any = null;
  @Output() calendarViewSelected = new EventEmitter<any>();
  @Output() previewDate = new EventEmitter<any>();
  @Output() nextDate = new EventEmitter<any>();
  searchQuery: string = '';
  showInput: boolean = false;

  selected: any;
  override ngOnInit(): void {
    this.calendarService
      .getCurrentCalendarMode()
      .subscribe((mode: CALENDERMODE) => {
        this.selected = mode;
    });
    this.uiStateService.platformsFilterUpdated$.subscribe((data) => {
      this.currentPlaTformsFilters =  data;
    });

  }

  switchToMonthView(month: number): void {
    // Debug.log("switching to month",month)
    this.calendarService.setCurrentMonth(month);
    this.calendarService.setCurrentCalendarMode(CALENDERMODE.MONTH);
  }

  switchToYearView(year: number): void {
    // Debug.log("switching to year",year)
    this.calendarService.setCurrentYear(year);
    this.calendarService.setCurrentCalendarMode(CALENDERMODE.YEAR);
  }
  
  updatePlatforms(data: string) {
    if(data.toLowerCase()==='facebook')
      this.currentPlaTformsFilters.isFacebookON = !this.currentPlaTformsFilters.isFacebookON
    if(data.toLowerCase()==='google')
      this.currentPlaTformsFilters.isGoogleON = !this.currentPlaTformsFilters.isGoogleON
    if(data.toLowerCase()==='linkedin')
      this.currentPlaTformsFilters.isLinkedinON = !this.currentPlaTformsFilters.isLinkedinON
    if(data.toLowerCase()==='instagram')
      this.currentPlaTformsFilters.isInstagramON = !this.currentPlaTformsFilters.isInstagramON

    this.uiStateService.updatePlatformsFilter(this.currentPlaTformsFilters);
    // this.setupYearPostIndications();
  }

  prevDate(){
    this.previewDate.emit();
  }

  nexDate(){
    this.nextDate.emit();
  }

  onCalendarModeChanged(data: any){
    this.mode = data 
    this.calendarService.setCurrentCalendarMode(this.mode);
  }

  onItemSelect(item: any, event: MouseEvent) {
    event.stopPropagation();
    if (this.selected !== item) {
      this.calendarViewSelected.emit(item);
    }
  }

  getMode(){
    return  this.calendarService.currentCalendarView;
  }

  externLinks(link: string){
    window.location.href = link
  }

  focusInput(inputElement: HTMLInputElement) {
    inputElement.focus();
    this.showInput = true; // Trigger the input expansion
  }
}
