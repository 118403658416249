<div class="mx-auto max-w-8xl text-5xl">
  <form [formGroup]="newGroupForm" >
    <fieldset class="m-5 border-[3px] border-cliking_blue rounded-3xl relative">
      <legend class="text-sm px-5 py-1 rounded-3xl bg-cliking_blue text-white">
        Enter New Group Details
      </legend>
      <div class="join m-5 flex flex-wrap gap-2">
        <!-- Group Name -->
        <input
          class="input input-bordered input-sm"
          type="text"
          placeholder="Group name"
          formControlName="name"
          [ngClass]="{
            'input-error': newGroupForm.get('name')?.invalid && newGroupForm.get('name')?.touched,
            'input-accent': newGroupForm.get('name')?.valid
          }"
        />
        <!-- Group Url -->
        <label
          for=""
          class="text-sm mb-2 bg-cliking_blue_nuance text-white font-bold rounded-md px-2 pt-1 cursor-pointer"
          (click)="isGroupImageUrlOpen=true"
        >
          <i class="fa fa-paperclip" aria-hidden="true"></i>
        </label>
        <span
          *ngIf="selectedFile"
          class="px-2 text-xs border-cliking_red rounded-full text-cliking_red w-6 h-6 text-center cursor-pointer"
          (click)="selectedFile=null"
        >✕</span>
    
        <div
          *ngIf="isGroupImageUrlOpen"
          class="absolute w-[60%] m-auto top-[25%] z-40 bg-white border-2 border-cliking_blue_nuance rounded-3xl overflow-auto h-[70vh]"
        >
          <app-image-uploader
            [croppedImage]="selectedFile?.croppedImage"
            [imageChangedEvent]="selectedFile?.imageChangedEvent"
            [isVisible]="isGroupImageUrlOpen"
            (onPanelClosed)="isGroupImageUrlOpen = false"
            (onImageConfirmed)="setGroupURLImage($event)"
          />
        </div>
    
        <!-- client satisfactionID -->
        <input
          class="input input-bordered input-sm"
          type="text"
          placeholder="client satisfaction Id"
          formControlName="clientSatisfactionId"
          [ngClass]="{
            'input-error': newGroupForm.get('clientSatisfactionId')?.invalid && newGroupForm.get('clientSatisfactionId')?.touched,
            'input-accent': newGroupForm.get('clientSatisfactionId')?.valid
          }"
        />
    
        <button
          class="btn text-white bg-dark_blue btn-sm hover:bg-cliking_blue"
          [disabled]="isParentSkipped"
          onclick="my_modal_2.showModal()"
        >
          choose Parent
          <div
            *ngIf="selectedGroup?.name"
            class="badge badge-secondary"
            [ngClass]="{
              'bg-gray-400 border-none text-gray-300': isParentSkipped
            }"
          >
            {{ selectedGroup?.name }}
          </div>
        </button>
        <dialog id="my_modal_2" class="modal">
          <div class="modal-box">
            <app-tree-view
              (itemSelected)="updateSelectedParentGroup($event)"
              [data]="rootGroups?.data"
            />
          </div>
          <form method="dialog" class="modal-backdrop">
            <button>close</button>
          </form>
        </dialog>
    
        <div class="form-control">
          <label class="cursor-pointer label">
            <span class="label-text inline-block w-max-[100%] overflow-ellipsis text-nowrap">is Top Level?</span>
            <input
              type="checkbox"
              class="checkbox checkbox-accent ml-3"
              [checked]="isParentSkipped"
              (change)="updateCheckbox()"
            />
          </label>
        </div>
    
        <div class="form-control">
          <label class="cursor-pointer label">
            <span class="label-text inline-block w-max-[100%] overflow-ellipsis text-nowrap">has Sagamm Logo ?</span>
            <input
              type="checkbox"
              class="checkbox checkbox-accent ml-3"
              [checked]="hasSagammLogo"
              (change)="updatePartnerCheckBox()"
            />
          </label>
        </div>
    
        <div class="form-control">
          <label class="cursor-pointer label">
            <span class="label-text inline-block w-max-[100%] overflow-ellipsis text-nowrap">is fake ?</span>
            <input
              type="checkbox"
              class="checkbox checkbox-accent ml-3"
              [checked]="isGroupFake"
              (change)="updateGroupFakeCheckBox()"
            />
          </label>
        </div>
    
        <button
          class="btn btn-circle btn-accent btn-sm text-white"
          (click)="createNewGroup()"
          [disabled]="!newGroupForm.valid || !isGroupParentValid()"
        >
          +
        </button>
      </div>
    </fieldset>
  </form>
  <p class="text-xs" > {{selectedFile|json}}</p>
  <app-table
    [res]="rootGroups"
    [isBrowsable]="true"
    [isWriteEnabled]="true"
    [selectedImage]="selectedFile"
    [skipColumns]="[
      'parent',
      'socialMedia',
      'ancestry',
      'expanded',
      'children',
      'depth',
    ]"
    [readOnlyKeys]="['children', 'Subgroups']"
    (UpdateGroups)="onUpdateGroups()"
    (editItem)="updateGroup($event)"
    (deleteItem)="deleteGroup($event)"
    (firstClicked)="getGroupsFirstPage()"
    (previoustClicked)="getGroupsPreviousPage()"
    (nextClicked)="getGroupsNextPage()"
    (lastClicked)="getGroupsLastPage()"
    [entity]="'Group'"
    [isAdmin]="true"
    (openfetchPostsModal)="showFetchPostsModal($event)"
    (onCroppedImageConfirmed)="setGroupURLImage($event)"
    (onCroppedImageCleared)="selectedFile=null"
  >
  </app-table>

  <app-modal
    [isOpen]="isFetchModalOpen"
    (closeModal)="closeFetchPostsModal()"
    [heading]="'fetch posts modal'"
    [showCloseBtn]="true"
  >
    
<span class="text-sm">
  <div>
    Fetching posts of group
  </div>
  <div class="join-vertical">
    <span class="font-bold">{{ currentGroup?.name }}  ({{ currentGroup?.socialMedia?.length }} total pages)</span>  
  </div>
  

</span>


    <div class="join gap-3">
      <div class="join-vertical">
        <div class="join">
          <button class="btn bg-linkedin rounded-full hover:bg-accent" (click)="fetchPosts('linkedin')" [ngClass]="{
            'cursor-not-allowed':isFetchingForLinkedinPosts
          }" [disabled]="isFetchingForLinkedinPosts ||countPages('linkedin')==0" >
            <!-- facebook svg -->
            <app-linkedin-fav-icon />
            <!-- connected pages count   -->
            <div class="badge badge-warning">
              <div class="">{{ countPages("linkedin") }}</div>
            </div>
          </button>
        </div>
          <span class="text-sm"  [ngClass]="{
            'loading loading-spinner loading-sm':linkedinPostsCount==-1 || isFetchingForLinkedinPosts
          }"> {{linkedinPostsCount>=0?linkedinPostsCount:""}} </span>
        <ul class="join-vertical" *ngIf="linkedinFetchData.show">
           <!-- <li class="text-sm">fetched: {{linkedinFetchData.fetched}}</li>  -->
          <li class="text-sm text-cliking_green">Added :{{linkedinFetchData.added}}</li>
          <li class="text-sm text-cliking_blue">Updated: {{linkedinFetchData.updated}}</li>
        </ul>

      </div>

      <div class="join-vertical">
          <div class="join">

            <button class="btn bg-orange-600 rounded-full hover:bg-accent" (click)="fetchPosts('google')" [ngClass]="{
              'cursor-not-allowed':isFetchingForGooglePosts
            }" [disabled]="isFetchingForGooglePosts ||countPages('google')==0">
            <!-- google svg -->
            <app-google-fav-icon />
            <!-- connected pages count   -->
            <div class="badge badge-warning">
              <div class="">{{ countPages("google") }}</div>
            </div>
          </button>
        </div>
          <span class="text-sm"  [ngClass]="{
            'loading loading-spinner loading-sm':googlePostsCount==-1 || isFetchingForGooglePosts
          }"> {{googlePostsCount>=0?googlePostsCount:""}} </span>
        <ul class="join-vertical" *ngIf="googleFetchData.show">
          <!-- <li class="text-sm">F: {{linkedinFetchData.fetched}}</li>  -->
          <li class="text-sm text-cliking_green">Added :{{googleFetchData.added}}</li>
          <li class="text-sm text-cliking_blue">Updated: {{googleFetchData.updated}}</li>
        </ul> 
      </div>

      <div class="join-vertical">
        <div class="join">

          <button class="btn bg-facebook rounded-full hover:bg-accent" (click)="fetchPosts('facebook')" [ngClass]="{
            'cursor-not-allowed':isFetchingForFacebookPosts
          }" [disabled]="isFetchingForFacebookPosts ||countPages('facebook')==0">
            <!-- facebook svg -->
            <app-facebook-fav-icon />
            <!-- connected pages count   -->
            <div class="badge badge-warning">
              <div class="">{{ countPages("facebook") }}</div>
            </div>
          </button>
        </div>
          <span class="text-sm" [ngClass]="{
            'loading loading-spinner loading-sm':facebookPostsCount==-1 || isFetchingForFacebookPosts
          }"> {{facebookPostsCount>=0?facebookPostsCount:""}} </span>
        <ul class="join-vertical" *ngIf="facebookFetchData.show">
           <!-- <li class="text-sm">fetched: {{linkedinFetchData.fetched}}</li>  -->
          <li class="text-sm text-cliking_green">Added :{{facebookFetchData.added}}</li>
          <li class="text-sm text-cliking_blue">Updated: {{facebookFetchData.updated}}</li>
        </ul>
      </div>

      <div class="join-vertical">
          <button class="btn bg-pink-600 rounded-full hover:bg-accent" (click)="fetchPosts('instagram')" [ngClass]="{
            'cursor-not-allowed':isFetchingForInstagramPosts
          }" [disabled]="isFetchingForInstagramPosts ||countPages('instagram')==0">
            <!-- instagram svg -->
            <app-instagram-fav-icon />
            <!-- connected pages count   -->
            <div class="badge badge-warning">
              <div class="">{{ countPages("instagram") }}</div>
            </div>
          </button>
          <span class="text-sm"  [ngClass]="{
            'loading loading-spinner loading-sm':instagramPostsCount==-1 || isFetchingForInstagramPosts
          }"> {{instagramPostsCount>=0?instagramPostsCount:""}} </span>
        <!-- <ul class="join-vertical" *ngIf="instagramFetchData.show">
          <li class="text-sm">fetched: {{linkedinFetchData.fetched}}</li> 
          <li class="text-sm text-cliking_green">Added :{{instagramFetchData.added}}</li>
          <li class="text-sm text-cliking_blue">Updated: {{instagramFetchData.updated}}</li>
        </ul> -->
      </div>

    </div>
  </app-modal>
  <app-modal 
		[isOpen]="isModalOpen"
		[heading]="'generic.successCreateGroup'"
		[icon]="modalIcon"
  ></app-modal>
</div>