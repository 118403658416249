<div #tooltip 
     class="border-cliking_red border-2 bg-[#fff5f8] rounded-xl relative flex items-center pl-4" 
     [style.width.px]="screenWidth * 20 / 100" 
     [style.visibility]="visibilityFlag ? 'visible' : 'hidden'"
     [ngClass]="{'z-20':visibilityFlag, 'z-0':!visibilityFlag }">
    <span *ngIf="!down" class="-ml-[30px]">
        <svg width="20px" height="20px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M13.9783 5.31877L10.7683 8.52877L8.79828 10.4888C7.96828 11.3188 7.96828 12.6688 8.79828 13.4988L13.9783 18.6788C14.6583 19.3588 15.8183 18.8688 15.8183 17.9188V12.3088V6.07877C15.8183 5.11877 14.6583 4.63877 13.9783 5.31877Z" fill="#fd4481"></path>
        </svg>
    </span>
    <span *ngIf="down" class="-ml-[0px] absolute right-1/2 bottom-0 translate-y-full translate-x-1/2">
        <svg width="13px" height="10px" viewBox="0 0 15 15" version="1.1" id="triangle" xmlns="http://www.w3.org/2000/svg" fill="#fd4481" transform="rotate(180)" stroke="#fd4481">
            <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
            <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
            <g id="SVGRepo_iconCarrier"> 
                <path id="path21090-9" d="M7.5385,2 C7.2437,2,7.0502,2.1772,6.9231,2.3846l-5.8462,9.5385C1,12,1,12.1538,1,12.3077C1,12.8462,1.3846,13,1.6923,13h11.6154 C13.6923,13,14,12.8462,14,12.3077c0-0.1538,0-0.2308-0.0769-0.3846L8.1538,2.3846C8.028,2.1765,7.7882,2,7.5385,2z"></path> 
            </g>
        </svg>
    </span>
    <span>
        <svg width="30px" height="30px" viewBox="0 0 24 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M7.493 0.015C7.442 0.021,7.268 0.039,7.107 0.055C5.234 0.242,3.347 1.208,2.071 2.634C0.660 4.211,-0.057 6.168,0.009 8.253C0.124 11.854,2.599 14.903,6.110 15.771C8.169 16.280,10.433 15.917,12.227 14.791C14.017 13.666,15.270 11.933,15.771 9.887C15.943 9.186,15.983 8.829,15.983 8.000C15.983 7.171,15.943 6.814,15.771 6.113C14.979 2.878,12.315 0.498,9.000 0.064C8.716 0.027,7.683 -0.006,7.493 0.015M8.853 1.563C9.967 1.707,11.010 2.136,11.944 2.834C12.273 3.080,12.920 3.727,13.166 4.056C13.727 4.807,14.142 5.690,14.330 6.535C14.544 7.500,14.544 8.500,14.330 9.465C13.916 11.326,12.605 12.978,10.867 13.828C10.239 14.135,9.591 14.336,8.880 14.444C8.456 14.509,7.544 14.509,7.120 14.444C5.172 14.148,3.528 13.085,2.493 11.451C2.279 11.114,1.999 10.526,1.859 10.119C1.618 9.422,1.514 8.781,1.514 8.000C1.514 6.961,1.715 6.075,2.160 5.160C2.500 4.462,2.846 3.980,3.413 3.413C3.980 2.846,4.462 2.500,5.160 2.160C6.313 1.599,7.567 1.397,8.853 1.563M7.706 4.290C7.482 4.363,7.355 4.491,7.293 4.705C7.257 4.827,7.253 5.106,7.259 6.816C7.267 8.786,7.267 8.787,7.325 8.896C7.398 9.033,7.538 9.157,7.671 9.204C7.803 9.250,8.197 9.250,8.329 9.204C8.462 9.157,8.602 9.033,8.675 8.896C8.733 8.787,8.733 8.786,8.741 6.816C8.749 4.664,8.749 4.662,8.596 4.481C8.472 4.333,8.339 4.284,8.040 4.276C7.893 4.272,7.743 4.278,7.706 4.290M7.786 10.530C7.597 10.592,7.410 10.753,7.319 10.932C7.249 11.072,7.237 11.325,7.294 11.495C7.388 11.780,7.697 12.000,8.000 12.000C8.303 12.000,8.612 11.780,8.706 11.495C8.763 11.325,8.751 11.072,8.681 10.932C8.616 10.804,8.460 10.646,8.333 10.580C8.217 10.520,7.904 10.491,7.786 10.530" fill="#fd4481"></path>
        </svg>
    </span>
    <span [ngStyle]="{'font-size': screenHeight / 65 + 'px'}">{{msg | translate}}</span>
</div>
