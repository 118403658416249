import {
  Component,
  HostListener,
  QueryList,
  ElementRef,
  ViewChildren,
  ViewChild,
  Input,
} from '@angular/core';

import { PostService } from 'src/app/services/repositories/post.service';
import { UiStateService } from 'src/app/services/utilities/ui-state.service';
import { TranslationService } from 'src/app/services/utilities/translation.service';
import { CalenderBaseComponent } from 'src/app/components/base/calendar.component';
import { CalendarService } from 'src/app/services/utilities/calendar.service';
import { CALENDERMODE } from 'src/app/interfaces/CalendarMode';
import { Debug } from 'src/app/utils/debug';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-calendar-week-view',
  templateUrl: './calendar-week-view.component.html',
  styleUrls: ['./calendar-week-view.component.scss'],
})
export class CalendarWeekViewComponent extends CalenderBaseComponent {
  constructor(
    protected override uiStateService: UiStateService,
    protected override calendarService: CalendarService,
    protected override postService: PostService,
    protected override translationService: TranslationService
  ) {
    super(translationService, calendarService, postService, uiStateService);
  }
  currentWeekStart: Date = new Date();
  currentWeekEnd: Date = new Date();
  startMonthStr: string = '';
  endMonthStr: string = '';
  showPostId: any = {};
  isLoading: boolean = false;
  subscriptions: Subscription[] = [];
  weekPostsEmpty: boolean = false;
  @Input() screenWidth: number = 0;
  @Input() screenHeight: number = 0;
  @Input() homePage: boolean = false;

  override ngOnInit() {
    super.ngOnInit();
    
    this.subscriptions.push(this.groupSubscription = this.postService.currentGroups.subscribe(
      (groups) => {
        this.groupScopes = groups;
        Debug.log('selected groups updated', this.groupScopes);
        if (this.groupScopes.length > 0) {
        }
        this.getWeekPosts();
      }
    ));
    this.subscriptions.push(this.uiStateService.platformsFilterUpdated$.subscribe((data) => {
      this.currentPlaTformsFilters =  data;
      this.allOff();
    }));

    // if(this.homePage)
      // this.updatePlatforms()

    this.updateDates();
  }

  updateDates() {
    let dates = this.getStartAndEndOfWeek(this.currentDate);
    this.currentWeekStart = dates.startOfWeek;
    this.currentWeekEnd = dates.endOfWeek;
    if(!this.homePage){
      this.startMonthStr = this.getTranslation(
        `dates.months.short.${this.currentWeekStart.getMonth() + 1}`
      );
      this.endMonthStr = this.getTranslation(
        `dates.months.short.${this.currentWeekEnd.getMonth() + 1}`
      );
    }else{
      this.startMonthStr = `dates.months.full.${this.currentWeekStart.getMonth() + 1}`;
      this.endMonthStr = `dates.months.full.${this.currentWeekEnd.getMonth() + 1}`;
    }
  }

  prevWeek() {
    this.navigateWeek(-7);
  }

  nextWeek() {
    this.navigateWeek(7);
  }

  navigateWeek(value: number) {
    this.currentDate.setDate(this.currentDate.getDate() + value);
    this.updateDates();
    this.getWeekPosts();
  }

  getWeekPosts() {
    this.isLoading = true;
    const lsData = this.postService.getGroupScopesFromLocalStorage();
    this.groupScopes = lsData.length > 0 ? lsData : this.groupScopes;
    const { startOfWeek, endOfWeek } = this.getStartAndEndOfWeek(
      this.currentDate
    );
    if (this.groupScopes.length > 0) {
      let m = this.calendarService.currentDate.getMonth() + 1;
      let dateStr: string = `${this.calendarService.currentDate.getFullYear()}-${m
      .toString()
        .padStart(2, '0')}-${this.calendarService.currentDate.getDate()}`;
      Debug.log('⚡⚡', 'Start', startOfWeek, 'End', endOfWeek);
      
      this.subscriptions.push(this.postService
      .getByTimeFrame(this.groupScopes, dateStr, 'week')
      .subscribe((res) => {
        this.weekPosts = this.getObjectsInRange(
          res.data,
          startOfWeek,
          endOfWeek
        );
        this.weekPostsEmpty = res.data.length === 0;
        console.log('this.weekPosts', this.weekPosts);
        this.prepareData();
        this.allOff();
        this.isLoading = false;
      }));
        
    }
  }

  getWeekDay(index: number): string {
    if (!this.weekPosts) {
      return ''; // Return a default or empty string if weekPosts is not ready
    }
    return Object.keys(this.weekPosts)[index]
      ? Object.keys(this.weekPosts)[index].toString()
      : '';
  }

  private getObjectsInRange(
    objects: Array<{
      publishingDatetime?: Date;
      expectedPublishingDatetime?: Date;
    }>,
    startDate: Date,
    endDate: Date
  ): { [key: string]: any[] } { // Résultat simplifié, uniquement une liste d'objets pour chaque date
    const addDays = (date: Date, days: number): Date => {
      const result = new Date(date);
      result.setDate(result.getDate() + days);
      return result;
    };
  
    const formatDate = (date: Date): string => {
      const year = date.getFullYear();
      const month = date.getMonth() + 1; // getMonth() is zero-indexed
      const day = date.getDate();
      return `${year}-${month.toString().padStart(2, '0')}-${day
        .toString()
        .padStart(2, '0')}`;
    };
  
    let result: { [key: string]: any[] } = {}; // Résultat regroupé par date uniquement
  
    let currentDate = new Date(startDate.toDateString()); // Réinitialise l'heure pour éviter les problèmes de DST
    while (currentDate <= endDate) {
      const formattedDate = formatDate(currentDate);
      result[formattedDate] = []; // Crée une entrée vide pour chaque date
      currentDate = addDays(currentDate, 1);
    }
  
    objects.forEach((obj) => {
      const objDate = obj.publishingDatetime || obj.expectedPublishingDatetime;
      if (objDate) {
        const date = new Date(objDate);
        const formattedObjDate = formatDate(date);
  
        // Si la date existe dans le résultat, on ajoute l'objet à la liste correspondante
        if (result[formattedObjDate]) {
          result[formattedObjDate].push(obj);
        }
      }
    });
  
    return result;
  }

  getStartAndEndOfWeek(date: Date) {
    // Create a new Date object to avoid modifying the original date
    let start = new Date(date);
    let end = new Date(date);

    // Adjusting the start date to the Monday of the current week
    // If today is Sunday (0), set to the previous Monday (-6). Otherwise, set to the previous Monday (1 - current day).
    start.setDate(
      start.getDate() - (start.getDay() === 0 ? 6 : start.getDay() - 1)
    );

    // Adjusting the end date to the Sunday of the current week
    // If today is Sunday (0), no need to add days. Otherwise, add days to reach the next Sunday (7 - current day).
    end.setDate(end.getDate() + (end.getDay() === 0 ? 0 : 7 - end.getDay()));

    // Reset the time part to the start and end of the day
    start.setHours(0, 0, 0, 0);
    end.setHours(23, 59, 59, 999);

    return { startOfWeek: start, endOfWeek: end };
  }

  switchToMonthView(month: number): void {
    // Debug.log("switching to month",month)
    this.calendarService.setCurrentMonth(month);
    this.calendarService.setCurrentCalendarMode(CALENDERMODE.MONTH);
  }

  switchToYearView(year: number): void {
    // Debug.log("switching to year",year)
    this.calendarService.setCurrentYear(year);
    this.calendarService.setCurrentCalendarMode(CALENDERMODE.YEAR);
  }

  switchToDay(day: number) {
    const newDate = new Date(this.currentWeekStart);
    newDate.setDate(this.currentWeekStart.getDate() + day);
    this.calendarService.setCurrentDate(newDate);
    this.calendarService.setCurrentCalendarMode(CALENDERMODE.DAY);
  }

  currentDay(day: number) {
    const dayDate = new Date(this.currentWeekStart);
    dayDate.setDate(this.currentWeekStart.getDate() + day);
    const toDay = new Date();
    if(dayDate.setHours(0, 0, 0, 0) === toDay.setHours(0, 0, 0, 0))
      return true
    return false
  }

  prepareData(){
    for (let key in this.weekPosts) {
      let postHourRangeUnique: any = {};
      if (this.weekPosts.hasOwnProperty(key)) {
        if (this.weekPosts[key].length > 0) {
          if (!postHourRangeUnique[key]) {
            postHourRangeUnique[key] = [];  
          }
          this.weekPosts[key].forEach((post: any) => {
            post.groups = [];
            post.platforms = [];
            post.linkedin = false;
            post.google = false;
            post.facebook = false;
            post.instagram = false;
            post.googleMedias = []
            post.linkedinMedias = []
            post.facebookMedias = []
            post.instagramMedias = []
            post.postImagesCount = 0
            post.SocialPlatforms.forEach((item: { source: string; medias: any, group: any })=>{
              if (!post.groups.some((group: any) => group.id === item.group.id) && this.groupScopes.includes(item.group.id)){
                post.groups.push({id: item.group.id, name: item.group.name, logo: item.group.logoUrl})
              }
              if(item.medias.length>1){
                post.postImagesCount = item.medias.length
              }
              if(!post.platforms.includes(item.source)){
                post.platforms.push(item.source)
              }
              if(item.source == "linkedin"){
                post.linkedin = true;
                if(item.medias.length>0)
                  post.googleMedias = item.medias;
              }
              if(item.source == "google"){
                post.google = true;
                if(item.medias.length>0)
                  post.linkedinMedias = item.medias;
              }
              if(item.source == "facebook"){
                post.facebook = true;
                if(item.medias.length>0)
                  post.facebookMedias = item.medias;
              }
              if(item.source == "instagram"){
                post.instagram = true;
                if(item.medias.length>0)
                  post.instagramMedias = item.medias;
              }
            })
            this.postVisiblity(key, post, postHourRangeUnique)
          });
        }
      }
    }
  }

  dayDate(i: number): string{
    const currentDate = this.currentWeekStart.getDate();
    const currentMonth = this.currentWeekStart.getMonth();
    const daysInMonth = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];  
    const isLeapYear = (year: number) => (year % 4 === 0 && year % 100 !== 0) || (year % 400 === 0);
    
    if (isLeapYear(this.currentWeekStart.getFullYear())) {
      daysInMonth[1] = 29; 
    }
    
    const newDate = currentDate + i;
    if (newDate <= daysInMonth[currentMonth]) {
      return newDate.toString().padStart(2, '0');
    } else {
      const nextMonthDate = newDate - daysInMonth[currentMonth];
      return nextMonthDate.toString().padStart(2, '0');
    }
    
  }

  allOff(){
    for (let key in this.weekPosts) {
      if (this.weekPosts.hasOwnProperty(key)) {
        if (this.weekPosts[key].length > 0) {
          let postHourRangeUnique: any = {};
          if (!postHourRangeUnique[key]) {
            postHourRangeUnique[key] = [];  
          }
          this.weekPosts[key].forEach((post: any) => {
            if(this.homePage)
              post.isVisible = true
            else
              post.isVisible = post.platforms.some((platform:string) => ((platform == 'facebook' && this.currentPlaTformsFilters.isFacebookON) || (platform == 'google' && this.currentPlaTformsFilters.isGoogleON) || (platform == 'linkedin' && this.currentPlaTformsFilters.isLinkedinON) || (platform == 'instagram' && this.currentPlaTformsFilters.isInstagramON)));
            this.postVisiblity(key, post, postHourRangeUnique)
          })
        }
      }
    }
  }

  postVisiblity(key: any ,post:any, postHourRangeUnique:any){
    if(post.isVisible){
      const date = new Date(post.publishingDatetime ? post.publishingDatetime : post.expectedPublishingDatetime);
      const hours = String(date.getUTCHours()).padStart(2, '0');
      const minutes = '00';
      let timeKey = `${hours}:${minutes}`;
      if (!postHourRangeUnique[key].includes(timeKey)) {
        post.postHourRange = timeKey;
        postHourRangeUnique[key].push(timeKey);
      }else
        post.postHourRange = null;
    }else
      post.postHourRange = null;
  }

  override ngOnDestroy() {
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }

  updatePlatforms() {
    this.currentPlaTformsFilters.isFacebookON = true;
    this.currentPlaTformsFilters.isGoogleON = true;
    this.currentPlaTformsFilters.isLinkedinON = true;
    this.currentPlaTformsFilters.isInstagramON = true;

    this.uiStateService.updatePlatformsFilter(this.currentPlaTformsFilters);
    // this.setupYearPostIndications();
  }

  externLinks(link: string){
    window.location.href = link
  }
}
