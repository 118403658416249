    <!-- <div *ngIf="post.postHourRange" class="flex items-center text-[0.9vw] font-semibold pt-3 ml-2 ">
        <span class="mr-1">{{post.postHourRange}}</span>
        <span>
            <svg *ngIf="posted" width="16" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M2.81697 1.51118C3.80367 0.851894 4.96371 0.5 6.15039 0.5C6.93832 0.5 7.71854 0.655195 8.44649 0.956723C9.17445 1.25825 9.83588 1.70021 10.393 2.25736C10.9502 2.81451 11.3921 3.47595 11.6937 4.2039C11.9952 4.93185 12.1504 5.71207 12.1504 6.5C12.1504 7.68669 11.7985 8.84673 11.1392 9.83342C10.4799 10.8201 9.54285 11.5892 8.44649 12.0433C7.35014 12.4974 6.14374 12.6162 4.97985 12.3847C3.81596 12.1532 2.74687 11.5818 1.90775 10.7426C1.06864 9.90352 0.497193 8.83443 0.265682 7.67054C0.0341703 6.50665 0.15299 5.30026 0.607116 4.2039C1.06124 3.10754 1.83028 2.17047 2.81697 1.51118Z" fill="#1DC9B7"/>
                <path d="M5.19612 7.25447L7.77012 4.67448C7.88253 4.56272 8.0346 4.5 8.19312 4.5C8.35163 4.5 8.5037 4.56272 8.61612 4.67448C8.67235 4.73025 8.71699 4.79661 8.74745 4.86973C8.77791 4.94284 8.7936 5.02127 8.7936 5.10048C8.7936 5.17968 8.77791 5.25811 8.74745 5.33122C8.71699 5.40434 8.67235 5.4707 8.61612 5.52648L5.61612 8.52647C5.50436 8.63732 5.35352 8.69981 5.19612 8.70047C5.11715 8.70093 5.03887 8.6858 4.96577 8.65594C4.89267 8.62608 4.82618 8.58208 4.77012 8.52647L3.36012 7.11048C3.29731 7.05669 3.2463 6.9905 3.21028 6.91606C3.17427 6.84162 3.15403 6.76054 3.15084 6.67791C3.14765 6.59528 3.16157 6.51288 3.19173 6.43588C3.2219 6.35889 3.26765 6.28896 3.32613 6.23048C3.3846 6.17201 3.45453 6.12626 3.53152 6.09609C3.60852 6.06593 3.69092 6.052 3.77355 6.0552C3.85618 6.05839 3.93726 6.07863 4.0117 6.11464C4.08614 6.15065 4.15233 6.20167 4.20612 6.26448L5.19612 7.25447Z" fill="white"/>
            </svg>
            <svg *ngIf="planned" width="16" viewBox="0 0 12 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M6.00781 0.5C4.82113 0.5 3.66109 0.851894 2.67439 1.51118C1.6877 2.17047 0.918664 3.10754 0.464538 4.2039C0.0104122 5.30026 -0.108408 6.50665 0.123104 7.67054C0.354615 8.83443 0.926059 9.90352 1.76517 10.7426C2.60429 11.5818 3.67339 12.1532 4.83727 12.3847C6.00116 12.6162 7.20756 12.4974 8.30392 12.0433C9.40027 11.5892 10.3373 10.8201 10.9966 9.83342C11.6559 8.84673 12.0078 7.68669 12.0078 6.5C12.0078 5.71207 11.8526 4.93185 11.5511 4.2039C11.2496 3.47595 10.8076 2.81451 10.2505 2.25736C9.6933 1.70021 9.03187 1.25825 8.30392 0.956723C7.57596 0.655195 6.79575 0.5 6.00781 0.5Z" fill="#FFB822"/>
                <path d="M6.20781 5.5H7.40781C7.56694 5.5 7.71955 5.56321 7.83208 5.67574C7.9446 5.78826 8.00781 5.94087 8.00781 6.1C8.00781 6.25913 7.9446 6.41174 7.83208 6.52426C7.71955 6.63679 7.56694 6.7 7.40781 6.7H5.60781C5.44868 6.7 5.29607 6.63679 5.18355 6.52426C5.07103 6.41174 5.00781 6.25913 5.00781 6.1V3.1C5.00781 2.94087 5.07103 2.78826 5.18355 2.67574C5.29607 2.56321 5.44868 2.5 5.60781 2.5C5.76694 2.5 5.91955 2.56321 6.03208 2.67574C6.1446 2.78826 6.20781 2.94087 6.20781 3.1V5.5Z" fill="white"/>
            </svg>
        </span>
    </div> -->
<div class="overflow-hidden pb-2 mx-2 rounded-b-lg mb-2" [ngClass]="{'bg-[#e9faf8]':posted, 'bg-[#fff8e9]':planned, 'rounded-t-lg': mediaPreview==''}">
    <div class="relative w-full h-[140px] pb-[6px]" *ngIf="mediaPreview!='' && post.postImagesCount ==0">
        <img referrerpolicy="no-referrer" src="{{mediaPreview}}" alt="Nouvelle Collaboration" class="w-full h-[140px] object-cover">
    </div>
    <div *ngIf="post.postImagesCount > 1" class="h-[140px]">
        <div class="relative w-full h-[134px] ml-[6px] mt-[6px] p-[6px]"> 
            <!-- Base layer with gradient -->  <!-- Second layer with gradient -->
            <div class="absolute -top-[6px] -left-[6px] bg-gradient-to-b from-blue-900 to-blue-700 w-full h-[134px]"></div>
            <!-- Third layer with gradient -->
            <div class="absolute -top-[3px] -left-[3px] bg-gradient-to-b from-teal-700 to-teal-600 w-full h-[134px]"></div>
            <!-- Top layer with gradient -->
            <div class="absolute -top-[0px] -left-[0px]  w-full h-[134px]">
                <div>
                    <img referrerpolicy="no-referrer" *ngIf="mediaIsVideo(mediaPreview)" controls src="assets/img/video-icon.png">
                    <img referrerpolicy="no-referrer" *ngIf="!mediaIsVideo(mediaPreview)" [src]="mediaPreview" class="object-cover w-[100%]" style="height:134px;"/>
                    <div *ngIf="post.postImagesCount>1" class="absolute top-0 right-1 rounded-full border-[2px] font-semibold border-cliking_blue bg-white text-[0.9vw] text-cliking_blue w-7 h-7 text-center p-1" style="transform: translate(0%, -35%);" >+{{post.postImagesCount-1}}</div>
                </div>
            </div>
        </div>
    </div>
    <div class="flex">
        <span class="w-[95%] text-[0.9vw] hover:whitespace-normal truncate text-center mx-auto" [ngClass]="{'pt-2': post.postImagesCount ==0}">
            <span *ngFor="let group of post.groups; let i = index">{{group.name}}<span *ngIf="post.groups.length>1 && i<post.groups.length -1">, </span></span>
        </span>
    </div>
    <!-- Section inférieure -->
        <!-- Texte -->
    <div id="2" class="py-1 w-[95%]">
        <h3 class="text-gray-800 font-semibold text-[0.9vw] mx-1 w-full hover:line-clamp-none line-clamp-2">{{post.title}}</h3>
    </div>
    <div class="flex py-2 w-[95%]" *ngIf="!mediaPreview">
        <span class="w-full mx-1 text-[0.9vw] line-clamp-3 hover:line-clamp-none text-gray-500" [ngClass]="{ 'whitespace-normal line-clamp-2': mediaPreview==''}">
            {{post.text}}
        </span>
    </div>
    <div class="grid grid-cols-8 w-[95%] mx-auto">
        <app-social-media-round-icon *ngFor="let platform of post.platforms" class="col-span-1" [calendar]="true" [smallScreen]="screenHeight<810 || screenWidth<1201" [source]="platform" [greyedOut]="!(platform == 'facebook' && (homePage ? true : currentPlaTformsFilters.isFacebookON) || platform == 'google' && (homePage ? true : currentPlaTformsFilters.isGoogleON) || platform == 'linkedin' && (homePage ? true : currentPlaTformsFilters.isLinkedinON) || platform == 'instagram' && (homePage ? true : currentPlaTformsFilters.isInstagramON))"  ></app-social-media-round-icon>
        <span *ngFor="let emptySpan of numberOfEmptyArray(6-post.platforms.length)" class="col-span-1 text-[0.9vw]"></span>
        <span class="col-span-2 text-[0.9vw] text-right">
            {{postHour}}
        </span>
    </div>
</div>