
    <span class=" flex justify-center font-semibold	 text-base mb-1  cursor-pointer" [ngStyle]="{'font-size': screenHeight/55 + 'px'}" (click)="goToMonth(month.monthIndex)">{{toTitleCase(getMonthStr())}}</span>
    <div class="grid grid-cols-7 grid-rows-1 gap-0 items-center justify-center" [style.width.px]="screenWidth*16.43/100">
        <span class="flex items-center justify-center col-span-1 text-xs text-[#a09c9c]" *ngFor="let day of weekDays">
            {{day | translate}}
        </span> 
    </div>
    <div class="grid grid-cols-7 grid-rows-1 gap-0 items-center justify-center" *ngFor="let week of month.content; let j=index" [style.width.px]="screenWidth*16.43/100"  [style.height.px]="screenHeight<=810 ? screenHeight*2.9/100 : screenHeight*3/100">
        <div *ngFor="let d of week; let i=index"
        >
            <div class="flex items-center justify-center col-span-1 text-xs text-[#544e61]"  [ngStyle]="{'font-size': screenHeight/60 + 'px'}"
            (click)="showDay(d,j)"
            [ngClass]="{
                'rounded-tl-md':i==0 && j==0 ,
                'rounded-tr-md':i==6 && j==0 ,
                'rounded-bl-md':i==0 && j==5 ,
                'rounded-br-md':i==6 && j==5 ,
                'text-[#c4c8cf]': d.day > 20 && i < 7 && j<2 || d.day < 15 &&  (j ==5||j==4),
                'text-white': isCurrentDay(d.day, month.month,j,i),
                'day-preview':canShowIndication(d,i,j),

                
                
            }"
            style="text-decoration-thickness: 4px;" >
                <div class="relative flex items-center justify-center px-1 pt-[2px]" 
                     [ngClass]="{
                        'bg-cliking_blue rounded-full text-white cursor-pointer hover:text-black day-preview': isCurrentDay(d.day, month.month,j,i),
                        'cursor-pointer hover:bg-[#e6e6e6] hover:rounded-xl ':canShowIndication(d,i,j),
                        'day-preview':canShowIndication(d,i,j),
                        'pb-1': screenHeight<=810 && canShowIndication(d,i,j),
                        'pb-2': screenHeight>810 && canShowIndication(d,i,j),
                        'pb-[2px]': isCurrentDay(d.day, month.month,j,i)&& !canShowIndication(d,i,j),
                        }">
                    <span class="day-preview"> 
                        {{ d.day.toString().padStart(2, '0')  }} 
                    </span>
                    <span *ngIf="canShowIndication(d,i,j) && !(d.data.hasPublished && d.data.hasPlanned && !(d.day > 20 && i < 7 && j<2 || d.day < 15 &&  (j ==5||j==4)))"
                     class="absolute top-[calc(100%-12.5px)] text-[20px]" [ngClass]="{
                        'text-[#FFB822]': d.data.hasPlanned && !d.data.hasPublished && !(d.day > 20 && i < 7 && j<2 || d.day < 15 &&  (j ==5||j==4)),
                        'text-[#1DC9B7]': d.data.hasPublished && !d.data.hasPlanned && !(d.day > 20 && i < 7 && j<2 || d.day < 15 &&  (j ==5||j==4)),
                        'day-preview':canShowIndication(d,i,j),
                    }"> 
                        •
                    </span>
                    <span  class="absolute top-[calc(100%-12.5px)] text-[20px] day-preview"
                        *ngIf="canShowIndication(d,i,j) && d.data.hasPublished && d.data.hasPlanned && !(d.day > 20 && i < 7 && j<2 || d.day < 15 &&  (j ==5||j==4))"
                        [ngClass]="{
                        'day-preview':canShowIndication(d,i,j),
                        }">
                        <span class="text-[#1DC9B7] day-preview">•</span>
                        <span class="text-[#FFB822] day-preview">•</span>
                    </span>
                </div>
            </div>
        </div>
    </div>
    
    <!-- </div> -->
    
    <!-- 'underlinePlanned': d.data.hasPlanned && !d.data.hasPublished && !(d.day > 20 && i < 7 && j<2 || d.day < 15 &&  (j ==5||j==4)),
                'underlinePublished': d.data.hasPublished && !d.data.hasPlanned && !(d.day > 20 && i < 7 && j<2 || d.day < 15 &&  (j ==5||j==4)),
                'underlinePublishedAndExpected': d.data.hasPublished && d.data.hasPlanned && !(d.day > 20 && i < 7 && j<2 || d.day < 15 &&  (j ==5||j==4)), -->