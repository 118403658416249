import {
  Component,
  OnInit,
  OnDestroy,
  ViewChild,
  ElementRef,
  ChangeDetectorRef,
  Renderer2,
  AfterViewInit,
  HostListener,
} from '@angular/core';
import flatpickr from 'flatpickr';
import monthSelectPlugin from 'flatpickr/dist/plugins/monthSelect';
import { French } from 'flatpickr/dist/l10n/fr.js';
import { DateService } from '../../../services/utilities/date.service';
import { TranslateService } from '@ngx-translate/core';
import { UiStateService } from 'src/app/services/utilities/ui-state.service';
import { environment } from 'src/environments/environment';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-date-filter',
  templateUrl: './date-filter.component.html',
  styleUrls: ['./date-filter.component.scss'],
})
export class DateFilterComponent implements AfterViewInit, OnInit, OnDestroy {
  @ViewChild('flatpickrInput') flatpickrInput!: ElementRef;
  @ViewChild('flatpickrYearSelect') flatpickrYearSelect!: ElementRef;
  @ViewChild('dateDisplay') dateDisplay!: ElementRef;
  @ViewChild('dateFilter') dateFilter!: ElementRef;
  private langChangeSubscription: any;
  private fp: any;
  private previousDates: Date[] = [];
  private startDate?: Date | null; // used for custom Year Flatpickr
  private endDate?: Date | null; // used for custom Year Flatpickr
  private locale!: string;
  private flatpickrOptions: any;
  private timeframe: string = 'month';
  private isUserInteraction: boolean = false;
  private switching: boolean = false;
  subscriptions: Subscription[]=[]
  yearsArray: number[] = this.setYearArray();
  isDropdownOpen: boolean = false;
  activeButton: string = 'month';
  lsData:any
  constructor(
    private dateService: DateService,
    private cdr: ChangeDetectorRef,
    private renderer: Renderer2,
    private translate: TranslateService,
    private uiStateService: UiStateService
  ) {
    this.locale = this.translate.currentLang;
        //load localstorage data
        this.lsData = localStorage.getItem(environment.sessionData)
        this.lsData = JSON.parse(this.lsData)
        this.lsData.filterData.dateRange.startDate =  new Date(this.lsData.filterData.dateRange.startDate)
        this.lsData.filterData.dateRange.endDate =  new Date(this.lsData.filterData.dateRange.endDate)
        this.startDate = this.lsData.filterData.dateRange.startDate
        this.endDate = this.lsData.filterData.dateRange.endDate
        this.timeframe = 'custom'
        this.activeButton = 'custom';
        // this.fp.selectedDates = [this.startDate,this.endDate]
        this.uiStateService.updateDateRange(this.lsData.filterData.dateRange)
  }
 
  ngOnInit() {
    // listens to any language change to update the date picker accordingly
    this.subscriptions.push( this.translate.onLangChange.subscribe(
      (event: any) => {
        // Update Flatpickr with the new language
        this.locale = this.translate.currentLang;
        // Debug.log("subscribtion update: this.translate.currentLang", this.translate.currentLang)
        //this.initializeFlatpickr({});
        this.setAllData()
        this.updateDateDisplay();
      }
    ));
  }

  ngOnDestroy() {
    this.subscriptions.forEach(sub => sub.unsubscribe());
    // unsubscibe to prevent memleaks
    if (this.langChangeSubscription) {
      this.langChangeSubscription.unsubscribe();
    }

    if (this.fp) {
      this.fp.destroy();
    }
  }

  ngAfterViewInit() {
    // Run automatically after the view is initialized
    // the DOM nees to be completely initialized before we can add the date picker
    if (this.flatpickrInput && this.flatpickrInput.nativeElement) {
      this.initializeFlatpickr({timeframe:this.timeframe});
      this.updateDateDisplay();
    } else {
      console.error('Flatpickr element not found!');
    }
  }

  toggleDropdown(): void {
    this.isDropdownOpen = !this.isDropdownOpen;
  }

  // listens to clicks on the page: if a click occurs anywhere outside the dropdown, closes the dropdown
  @HostListener('document:click', ['$event'])
  onDocumentClick(event: MouseEvent) {
    if (
      !this.switching &&
      this.isDropdownOpen == true &&
      !this.dateFilter.nativeElement.contains(event.target)
    ) {
      this.isDropdownOpen = false;
    }
    this.switching = false; // the switcher closes the dropdown, so we have to check if we're switching before closing the modal, then reset this var
  }

  initializeFlatpickr(args: any) {
    const calendar = this.flatpickrInput.nativeElement;
    const controller = this;

    this.flatpickrOptions = {
      mode: 'range',
      inline: true,
      monthSelectorType: 'static',
      onChange: (selectedDates: Date[], dateStr: string, instance: any) => {
        if (selectedDates.length === 2 && this.timeframe == 'year') {
          const [startDate, endDate] = selectedDates;
          const lastDayOfMonth = new Date(
            endDate.getFullYear(),
            endDate.getMonth() + 1,
            0
          );
          instance.setDate([startDate, lastDayOfMonth]);
        }
        if (args.monthSelect && args.monthSelect == true) {
          // Selectionne le dernier jour du mois au lieu du premier
          if (selectedDates.length == 2) {
            const endDate = selectedDates[1];
            const lastDayOfMonth = new Date(
              endDate.getFullYear(),
              endDate.getMonth() + 1,
              0
            );
            instance.setDate([selectedDates[0], lastDayOfMonth]);
            if (
              selectedDates[0].getMonth() == selectedDates[1].getMonth() &&
              selectedDates[0].getFullYear() == selectedDates[1].getFullYear()
            ) {
              const selected =
                instance.calendarContainer.querySelector('.selected');
              selected.classList.add('startRange');
              selected.classList.add('endRange');
            }
            if (
              selectedDates[0].getFullYear() != selectedDates[1].getFullYear()
            ) {
              const selected =
                instance.calendarContainer.querySelector('.selected');
              if (!selected.classList.contains('inRange')) {
                selected.classList.add('outRange');
              }

              if (
                selectedDates[0].getFullYear() != instance.currentYear &&
                controller.previousDates[0].getFullYear() ==
                  instance.currentYear
              ) {
                setTimeout(function () {
                  controller.fp.jumpToDate(selectedDates[0]);

                  const month = selectedDates[0].getMonth();
                  const monthDivs =
                    controller.fp.calendarContainer.querySelectorAll(
                      '.flatpickr-monthSelect-month'
                    );
                  for (let i = 0; i < monthDivs.length; i++) {
                    controller.removeFpStyles(monthDivs[i]);

                    if (i > month) {
                      monthDivs[i].classList.add('inRange');
                    } else if (i == month) {
                      monthDivs[i].classList.add('startRange');
                    }
                    if (i == monthDivs.length - 1) {
                      monthDivs[i].classList.add('inEndRange');
                    }
                  }
                  const endRange =
                    controller.fp.calendarContainer.querySelector('.endRange');
                  endRange?.classList.remove('endRange');
                }, 0);
              }
            }
          }

          // remove previous selection classes (it's not automatic apparently ?)
          if (
            selectedDates.length == 1 &&
            selectedDates[0].toDateString() !=
              controller.previousDates[0].toDateString() &&
            selectedDates[0].toDateString() !=
              controller.previousDates[1].toDateString()
          ) {
            setTimeout(function () {
              const inRangeDivs = document.querySelectorAll('.inRange');
              for (let i = 0; i < inRangeDivs.length; i++) {
                inRangeDivs[i].classList.remove('inRange');
              }
              const startRangeDiv = document.querySelector('.startRange');
              if (startRangeDiv) {
                startRangeDiv.classList.remove('startRange');
              }

              const endRangeDiv = document.querySelector('.endRange');
              if (endRangeDiv) {
                endRangeDiv.classList.remove('endRange');
              }
            }, 0);
          }
        }
        this.handleDayClick(event, args);

        controller.previousDates = [...this.fp.selectedDates];
        this.updateActiveButton();
        this.updateStartEndButtons(
          selectedDates,
          args.monthSelect && args.monthSelect == true
        );
      },
      onYearChange: function (
        selectedDates: Date[],
        dateStr: string,
        instance: any
      ) {
        // if (!controller.isUserInteraction) return; // Ignore programmatic changes
        controller.isUserInteraction = false;

        if (args.monthSelect && args.monthSelect == true) {
          if (controller.timeframe == 'year') {
            const startOfYear = new Date(instance.currentYear, 0, 1);
            const endOfYear = new Date(instance.currentYear, 11, 31);
            instance.setDate([startOfYear, endOfYear]);
          } else if (controller.timeframe == 'month') {
            const startOfYear = new Date(
              instance.currentYear,
              selectedDates[0].getMonth(),
              selectedDates[0].getDate()
            );
            const endOfYear = new Date(
              instance.currentYear,
              selectedDates[1].getMonth(),
              selectedDates[1].getDate()
            );
            instance.setDate([startOfYear, endOfYear]);
          }
          if (
            instance.selectedDates[1]?.getFullYear() == instance.currentYear
          ) {
            controller.addStylesToLastInRangeDiv({});
          }
        }
      },
      onMonthChange: function (
        selectedDates: Date[],
        dateStr: string,
        instance: any
      ) {
        if (!controller.isUserInteraction) return; // this is to ignore programmatic changes
        controller.isUserInteraction = false; // reset the var

        if (
          !args.monthSelect ||
          args.monthSelect == false ||
          controller.timeframe != 'custom'
        ) {
          switch (controller.timeframe) {
            case 'day':
              let newDate = new Date(
                instance.currentYear,
                instance.currentMonth,
                selectedDates[0].getDate()
              );
              newDate.setDate(
                Math.min(
                  newDate.getDate(),
                  new Date(
                    instance.currentYear,
                    instance.currentMonth + 1,
                    0
                  ).getDate()
                )
              );
              // if new Month does not have the same day (ex: 31st of the month, next month is 30 day long)
              if (newDate.getMonth() != instance.currentMonth) {
                newDate = new Date(
                  instance.currentYear,
                  instance.currentMonth + 1,
                  0
                );
              }
              instance.setDate([newDate, newDate], true);
              break;
            // no "week" case; does not wokrs as expected. todo.
            case 'month':
              const newStartMonth = new Date(
                instance.currentYear,
                instance.currentMonth,
                1
              );
              const newEndMonth = new Date(
                instance.currentYear,
                instance.currentMonth + 1,
                0
              );
              instance.setDate([newStartMonth, newEndMonth], true);
              break;
          }
        }
      },
      onReady: function (
        _selectedDates: Date[],
        _dateStr: string,
        instance: any
      ) {
        // attaches event listener to the prev / next arrows.
        // Used to tell if the month change is triggered by the user, or by another ft.
        instance.prevMonthNav.addEventListener('click', () => {
          controller.isUserInteraction = true;
        });
        instance.nextMonthNav.addEventListener('click', () => {
          controller.isUserInteraction = true;
        });
      },
    };

 
    if (args.datesRange) {
      this.flatpickrOptions.defaultDate = args.datesRange;
      this.updateActiveButton(args.datesRange);
    } else {
      this.activeButton = 'all-data';
      const currentYear = new Date().getFullYear();
      const startDate = null //new Date(currentYear - 2, 0, 1); // January 1st, two years ago
      const endDate = null// new Date(currentYear, 11, 31); // December 31st of the current year

      this.flatpickrOptions.defaultDate = [startDate, endDate];
      
    }

    if (this.locale === 'fr') {
      this.flatpickrOptions.locale = French;
    }

    if (args.monthSelect && args.monthSelect == true) {
      this.flatpickrOptions.plugins = [
        monthSelectPlugin({
          shorthand: true, // defaults to false
          dateFormat: 'Y-m-d', // defaults to "F Y"
          altFormat: 'F j, Y', // defaults to "F Y"
          theme: 'light', // defaults to "light"
        }),
      ];
    }

    this.fp = flatpickr(
      this.flatpickrInput.nativeElement,
      this.flatpickrOptions
    );
    // Debug.log("flatpickr instance", this.fp)
    // if (args.datesRange && args.datesRange[0] == null && args.datesRange[1] == null) {this.clearDate()}
    this.previousDates = [...this.fp.selectedDates];
    this.initializeSwitcher();

    if (
      this.timeframe == 'year' ||
      (args.monthSelect &&
        args.monthSelect == true &&
        this.fp.selectedDates[0] &&
        this.fp.selectedDates[1] &&
        this.fp.selectedDates[1]?.toDateString() ==
          this.dateService
            .getEndOfYear(this.fp.selectedDates[0])
            .toDateString())
    ) {
      this.addStylesToLastInRangeDiv({
        startDate: this.fp.selectedDates[0],
        endDate: this.fp.selectedDates[1],
      });
    }
  }

  handleDayClick(event: any, args: any | null = null) {
    const clickedDate = new Date(event.target.dateObj); // the clicked date
    const selectedDates = this.previousDates; // the current selected range

    const currentMonth = this.fp.currentMonth; // used to redraw the fp later
    const currentYear = this.fp.currentYear; // used to redraw the fp later

    if (selectedDates.length === 2) {
      //if the clicked date is either dates of the current selection, we re-select the other one
      if (clickedDate.getTime() === selectedDates[0].getTime()) {
        this.setDateAndResetView(selectedDates[1], currentMonth, currentYear);
      } else if (clickedDate.getTime() === selectedDates[1].getTime()) {
        this.setDateAndResetView(selectedDates[0], currentMonth, currentYear);
      }
    }
    if (args && args.monthSelect && args.monthSelect == true) {
      this.addStylesToLastInRangeDiv({});
    }
  }

  updateDateDisplay() {
    let selectedDates = this.fp.selectedDates;
    if(this.startDate && this.endDate){
      selectedDates=[this.startDate ,this.endDate]
    }
    const locale = this.locale == 'fr' ? 'fr-FR' : 'en-US'; // norm is BCP 47

    if (!selectedDates.length) {
      // Case 4: No range is given
      // this.dateDisplay.nativeElement.textContent = 'Cumul';
    } else if (selectedDates.length == 1 || this.isDay(selectedDates)) {
      // Case 1: Single date
      // this.dateDisplay.nativeElement.textContent = this.formatDate(
      //   selectedDates[0]
      // );
    } 
    // else if (selectedDates.length == 2) {
    //   if (this.isMonth(selectedDates)) {
    //     // Case 2: Whole month
    //     // const options = { month: 'long', year: 'numeric' };
    //     // this.dateDisplay.nativeElement.textContent =
    //     //   selectedDates[0].toLocaleDateString(locale, options);
    //   } else if (this.isYear(selectedDates)) {
    //     // Case 3: Whole year
    //     // this.dateDisplay.nativeElement.textContent = selectedDates[0]
    //     //   .getFullYear()
    //     //   .toString();
    //   } else {
    //     // Case 5: Custom range
    //     // this.dateDisplay.nativeElement.textContent = `${this.formatDate(
    //     //   selectedDates[0]
    //     // )} - ${this.formatDate(selectedDates[1])}`;
    //   }
    // }
    
    this.uiStateService.updateDateRange({
      startDate: selectedDates[0],
      endDate: selectedDates[1],
    });
  }

  formatDate(date: Date) {
    const options = { day: '2-digit', month: '2-digit', year: 'numeric' };
    return date.toLocaleDateString();
  }

  // separte ft of handleDayClick
  setDateAndResetView(date: any, month: any, year: any) {
    this.fp.setDate(date);
    this.fp.changeMonth(month - this.fp.currentMonth);
    this.fp.currentYear = year;
    this.fp.redraw();
  }

  // After setting or clearing dates, call this method to update button states
  updateActiveButton(dates: Date[] | null = null) {
    // this.clearActiveButtons();
    const selectedDates = dates == null ? this.fp.selectedDates : dates;
    if (selectedDates[0] == null && selectedDates[1] == null) {
      this.activeButton = 'custom';
      this.timeframe = 'custom';
    } else if (this.isDay(selectedDates)) {
      this.activeButton = 'day';
      this.timeframe = 'day';
    } else if (this.isWeek(selectedDates)) {
      this.activeButton = 'week';
      this.timeframe = 'week';
    } else if (this.isMonth(selectedDates)) {
      this.activeButton = 'month';
      this.timeframe = 'month';
    } else if (this.isYear(selectedDates)) {
      this.activeButton = 'year';
      this.timeframe = 'year';
    } else {
      this.activeButton = 'custom';
      this.timeframe = 'custom';
    }
  }

  updateStartEndButtons(dates: Date[] | null, month: boolean) {
    const beginEndBtns = document.querySelectorAll('.calendar-begin-end-btn');
    if (dates && dates[0] && dates[1]) {
      if (month) {
        if (dates[0]?.getFullYear() != dates[1]?.getFullYear()) {
          for (let i = 0; i < beginEndBtns.length; i++) {
            beginEndBtns[i].classList.add('active');
          }
        } else {
          for (let i = 0; i < beginEndBtns.length; i++) {
            beginEndBtns[i].classList.remove('active');
          }
        }
      } else {
        if (dates[0]?.getMonth() != dates[1]?.getMonth()) {
          for (let i = 0; i < beginEndBtns.length; i++) {
            beginEndBtns[i].classList.add('active');
          }
        } else {
          for (let i = 0; i < beginEndBtns.length; i++) {
            beginEndBtns[i].classList.remove('active');
          }
        }
      }
    } else {
      for (let i = 0; i < beginEndBtns.length; i++) {
        beginEndBtns[i].classList.remove('active');
      }
    }
  }

  clearDate(event: any | null = null) {
    if (event) event.preventDefault();
    if (this.fp.calendarContainer.classList.contains('inline')) {
      this.resetFlatpickr({
        timeframe: 'custom',
        datesRange: [null, null],
        monthSelectView: this.fp.loadedPlugins.includes('monthSelect'),
        activeBtn: 'custom',
      });
    } else {
      this.resetFlatpickrYear();
      this.activeButton = 'custom';
    }
    this.updateStartEndButtons(null, false);
  }

  resetFlatpickr(args: any) {
    this.fp.destroy();
    this.flatpickrYearSelect.nativeElement.classList.remove('inline');

    this.initializeFlatpickr({
      monthSelect: args.monthSelectView,
      datesRange: args.datesRange,
      timeframe: args.timeframe,
    });
    this.updateStartEndButtons(args.datesRange, args.monthSelectView);
  }

  navigateToStartDate(event: any) {
    event.preventDefault();
    const selectMonthLoadedPresent =
      this.fp.loadedPlugins.includes('monthSelect');

    // Fetch the current selected date range from Flatpickr
    const selectedDates = this.fp.selectedDates;

    // If a start date is selected, navigate to it
    if (selectedDates && selectedDates.length > 0) {
      const startDate = selectedDates[0];
      const month = selectedDates[0].getMonth();
      this.fp.jumpToDate(startDate);

      if (
        selectMonthLoadedPresent &&
        selectedDates[0].getFullYear() != selectedDates[1].getFullYear()
      ) {
        const monthDivs = this.fp.calendarContainer.querySelectorAll(
          '.flatpickr-monthSelect-month'
        );
        for (let i = 0; i < monthDivs.length; i++) {
          this.removeFpStyles(monthDivs[i]);

          if (i > month) {
            monthDivs[i].classList.add('inRange');
          } else if (i == month) {
            monthDivs[i].classList.add('startRange');
          }
        }
      }
    }
  }

  navigateToEndDate(event: any) {
    event.preventDefault();
    const selectMonthLoadedPresent =
      this.fp.loadedPlugins.includes('monthSelect');

    // Fetch the current selected date range from Flatpickr
    const selectedDates = this.fp.selectedDates;

    // If an end date is selected, navigate to it
    if (selectedDates && selectedDates.length > 1) {
      const endDate = selectedDates[1];
      const month = selectedDates[1].getMonth();
      this.fp.jumpToDate(endDate);

      if (
        selectMonthLoadedPresent &&
        selectedDates[0].getFullYear() != selectedDates[1].getFullYear()
      ) {
        const monthDivs = this.fp.calendarContainer.querySelectorAll(
          '.flatpickr-monthSelect-month'
        );
        for (let i = 0; i < monthDivs.length; i++) {
          this.removeFpStyles(monthDivs[i]);

          if (i < month) {
            monthDivs[i].classList.add('inRange');
          } else if (i == month) {
            monthDivs[i].classList.add('endRange');
          }
        }
      }
    }
  }

  setTimeframe(
    timeframe: string,
    start: Date,
    end: Date,
    monthSelectView: boolean
  ) {
    this.resetFlatpickrYear();
    this.previousDates = [...this.fp.selectedDates];
    this.activeButton = timeframe;
    this.timeframe = timeframe;
    const datesRange = [start, end];
    this.resetFlatpickr({
      datesRange: datesRange,
      monthSelectView: monthSelectView,
      activeBtn: timeframe,
    });
    this.updateStartEndButtons(datesRange, monthSelectView === true);
    this.cdr.detectChanges();
  }

  setToday() {
    const today = new Date();
    this.setTimeframe('day', today, today, false);
  }

  setThisWeek() {
    const today = new Date();
    const datesRange = [
      this.dateService.getStartOfWeek(today, this.locale),
      this.dateService.getEndOfWeek(today, this.locale),
    ];
    this.setTimeframe('week', datesRange[0], datesRange[1], false);
  }

  setThisMonth() {
    const today = new Date();
    const datesRange = [
      this.dateService.getStartOfMonth(today),
      this.dateService.getEndOfMonth(today),
    ];
    this.setTimeframe('month', datesRange[0], datesRange[1], false);
  }

  setThisYear() {
    const today = new Date();
    const datesRange = [
      this.dateService.getStartOfYear(today),
      this.dateService.getEndOfYear(today),
    ];
    this.setTimeframe('year', datesRange[0], datesRange[1], true);
  }

  setAllData() {
    this.previousDates = [...this.fp.selectedDates];
    this.clearDate();
    this.resetFlatpickrYear();
    this.fp.calendarContainer.classList.remove('inline');
    this.activeButton = 'all-data';
    // const today = new Date();
    this.timeframe = 'all-data';
    this.flatpickrYearSelect.nativeElement.classList.add('inline');
    this.cdr.detectChanges();

    const yearDivs = this.flatpickrYearSelect.nativeElement.querySelectorAll(
      '.flatpickr-monthSelect-month'
    );
    this.fp.setDate([this.startDate, this.endDate]);
    for (let i = 0; i < yearDivs.length; i++) {
      if (i == 0 || i == yearDivs.length - 1) {
        if (i == 0) {
          yearDivs[i].classList.add('startRange');
        }
        if (i == yearDivs.length - 1) {
          yearDivs[i].classList.add('endRange');
        }
      } else {
        yearDivs[i].classList.add('inRange');
      }
    }
    this.updateStartEndButtons(null, false);
  }

  isDay(dates: Date[]) {
    return (
      dates.length === 2 && dates[0].toDateString() === dates[1].toDateString()
    );
  }

  isWeek(dates: Date[]) {
    const firstDayOfWeek = this.fp.l10n.firstDayOfWeek;
    const startOfWeek = new Date(dates[0]);
    const daysSinceFirstDay = (dates[0].getDay() - firstDayOfWeek + 7) % 7;
    startOfWeek.setDate(dates[0].getDate() - daysSinceFirstDay);
    const endOfWeek = new Date(startOfWeek);
    endOfWeek.setDate(startOfWeek.getDate() + 6);

    return (
      dates.length === 2 &&
      dates[0].toDateString() === startOfWeek.toDateString() &&
      dates[1].toDateString() === endOfWeek.toDateString()
    );
  }

  isMonth(dates: Date[]) {
    const startOfMonth = new Date(
      dates[0].getFullYear(),
      dates[0].getMonth(),
      1
    );
    const endOfMonth = new Date(
      dates[0].getFullYear(),
      dates[0].getMonth() + 1,
      0
    );

    return (
      dates.length === 2 &&
      dates[0].toDateString() === startOfMonth.toDateString() &&
      dates[1].toDateString() === endOfMonth.toDateString()
    );
  }

  isYear(dates: Date[]) {
    const startOfYear = new Date(dates[0].getFullYear(), 0, 1);
    const endOfYear = new Date(dates[0].getFullYear(), 11, 31);
    return (
      dates.length === 2 &&
      dates[0].toDateString() === startOfYear.toDateString() &&
      dates[1].toDateString() === endOfYear.toDateString()
    );
  }

  addStylesToLastInRangeDiv(args: any) {
    // if (!args.startDate || !args.endDate || this.isWholeYear([args.startDate, args.endDate])) {
    setTimeout(function () {
      const inRangeDivs = document.querySelectorAll(
        '.flatpickr-monthSelect-month.inRange'
      );
      const endRangeDiv = document.querySelector(
        '.flatpickr-monthSelect-month.endRange'
      );
      const startRangeDiv = document.querySelector(
        '.flatpickr-monthSelect-month.startRange'
      );

      const lastInRangeDiv = inRangeDivs[inRangeDivs.length - 1];
      if (!endRangeDiv) {
        if (lastInRangeDiv) {
          lastInRangeDiv.classList.add('endRange');
          lastInRangeDiv.classList.remove('inRange');
        } else if (startRangeDiv) {
          startRangeDiv.classList.add('endRange');
          startRangeDiv.classList.remove('inRange');
        }
      }
    }, 0);
    // }
  }

  clearRangeStyle() {
    const controller = this;
    setTimeout(function () {
      const inRangeDivs = document.querySelectorAll(
        '.flatpickr-monthSelect-month'
      );
      for (let i = 0; i < inRangeDivs.length; i++) {
        controller.removeFpStyles(inRangeDivs[i]);
      }
    }, 0);
  }

  setYearArray() {
    // TODO with accreditations:
    // get the earliest year of creation of all the groups of the users / selected groups.

    const dates = [];
    const today = new Date();
    const currentYear = today.getFullYear();

    for (let year = currentYear; year > 2020; year--) {
      dates.push(year);
    }

    return dates.reverse();
  }

  removeFpStyles(element: Element) {
    element.classList.remove('startRange');
    element.classList.remove('endRange');
    element.classList.remove('inRange');
    element.classList.remove('outRange');
    element.classList.remove('inEndRange');
    element.classList.remove('selected');
  }

  initializeSwitcher() {
    const switcher = this.fp.calendarContainer.querySelector(
      '.flatpickr-current-month'
    );
    const switcherYear = this.flatpickrYearSelect.nativeElement.querySelector(
      '.flatpickr-current-month'
    );
    const selectMonthLoadedPresent =
      this.fp.loadedPlugins.includes('monthSelect');
    let timeframe = 'cumul';
    const currentYear = new Date().getFullYear();
    let datesRange = [new Date(currentYear - 2, 0, 1),new Date(currentYear, 11, 31)];
    let activeBtn = 'custom';
    if (switcher) {
      this.renderer.listen(switcher, 'click', (event: MouseEvent) => {
        this.switching = true; // the switcher closes the dropdown, so we have indicate the switch is occuring to prevent the close
        event.preventDefault();
        this.startDate = null;
        this.endDate = null;
        if (selectMonthLoadedPresent === false) {
          this.resetFlatpickrYear();
          timeframe = 'year';
          activeBtn = 'this-year';
        }
        this.resetFlatpickr({
          timeframe: timeframe,
          datesRange: datesRange,
          monthSelectView: !selectMonthLoadedPresent,
          activeBtn: activeBtn,
        });
      });
      // // Below is the code for the second switcher on the custom Year Flastpickr, to cycle between the 3 views
      // 	this.renderer.listen(switcherYear, 'click', (event: MouseEvent) => {

      // 		this.resetFlatpickr({
      // 			timeframe: timeframe,
      // 			datesRange: datesRange,
      // 			monthSelectView: false,
      // 			activeBtn: activeBtn
      // 		})
      // 	})
    }
  }

  resetFlatpickrYear() {
    this.startDate = null;
    this.endDate = null;
    const inRangeDivs = this.flatpickrYearSelect.nativeElement.querySelectorAll(
      '.flatpickr-monthSelect-month'
    );
    for (let i = 0; i < inRangeDivs.length; i++) {
      inRangeDivs[i].classList.remove('inRange');
      inRangeDivs[i].classList.remove('startRange');
      inRangeDivs[i].classList.remove('endRange');
      inRangeDivs[i].classList.remove('rangeHover');
    }
  }

  updateInRangeClasses() {
    const parentDiv = this.flatpickrYearSelect.nativeElement.querySelector(
      '.flatpickr-monthSelect-months'
    );
    if (parentDiv) {
      const spans = parentDiv.querySelectorAll('.flatpickr-monthSelect-month');
      let startFound = false;

      spans.forEach((span: any, index: number) => {
        // Check if current span has 'startRange' class
        if (span.classList.contains('startRange')) {
          startFound = true;
          return; // Continue to next iteration
        }

        // Check if current span has 'endRange' class
        if (span.classList.contains('endRange')) {
          startFound = false;
        }

        // If between 'startRange' and 'endRange', add 'inRange' class
        if (startFound) {
          span.classList.add('inRange');
        } else {
          // Remove 'inRange' class if it's not between 'startRange' and 'endRange'
          span.classList.remove('inRange');
        }
      });
    }
  }

  setDateCustomYearFlatpickr(event: any, year: number) {
    if (!this.startDate && !this.endDate) {
      this.startDate = new Date(year, 0, 1);
      event.target.classList.add('startRange');
    } else if (this.startDate && !this.endDate) {
      this.endDate = new Date(year, 11, 31);
      event.target.classList.remove('rangeHover');
      if (year == this.startDate.getFullYear()) {
        event.target.classList.add('endRange');
      }
    } else if (this.startDate && this.endDate) {
      if (
        this.startDate.getFullYear() != year &&
        this.endDate.getFullYear() != year
      ) {
        const inRangeDivs =
          this.flatpickrYearSelect.nativeElement.querySelectorAll(
            '.flatpickr-monthSelect-month'
          );
        for (let i = 0; i < inRangeDivs.length; i++) {
          inRangeDivs[i].classList.remove('inRange');
          inRangeDivs[i].classList.remove('startRange');
          inRangeDivs[i].classList.remove('endRange');
          inRangeDivs[i].classList.remove('rangeHover');
        }
        this.startDate = new Date(year, 0, 1);
        this.endDate = null;
        event.target.classList.add('startRange');
      }
    }
    this.fp.setDate([this.startDate, this.endDate]);
    this.updateActiveButton(this.fp.selectedDates);
  }

  hoverDateCustomYearFlatpickr(event: any, year: number) {
    if (this.startDate && !this.endDate) {
      const inRangeDivs =
        this.flatpickrYearSelect.nativeElement.querySelectorAll(
          '.flatpickr-monthSelect-month'
        );
      for (let i = 0; i < inRangeDivs.length; i++) {
        inRangeDivs[i].classList.remove('inRange');
        if (inRangeDivs[i].classList.contains('rangeHover')) {
          inRangeDivs[i].classList.remove('startRange');
          inRangeDivs[i].classList.remove('endRange');
        }
      }
      if (this.startDate.getFullYear() == year) {
        event.target.classList.remove('endRange');
        event.target.classList.add('startRange');
      } else {
        let tempEndDate = new Date(year, 11, 31);
        if (tempEndDate < this.startDate) {
          const oldStartRange =
            this.flatpickrYearSelect.nativeElement.querySelector('.startRange');
          oldStartRange?.classList.add('endRange');
          oldStartRange?.classList.remove('startRange');
          event.target.classList.add('startRange');
          event.target.classList.add('rangeHover');
        } else {
          const oldEndRange =
            this.flatpickrYearSelect.nativeElement.querySelector('.endRange');
          oldEndRange?.classList.remove('endRange');
          oldEndRange?.classList.add('startRange');
          event.target.classList.add('endRange');
          event.target.classList.add('rangeHover');
        }
        this.updateInRangeClasses();
      }
    }
  }

  onSubmit() {
    this.updateDateDisplay();
    this.isDropdownOpen = !this.isDropdownOpen;
  }

	capitalize(str: string): string {
    if (!str) return str;
    return str.charAt(0).toUpperCase() + str.slice(1);
  }
}
