import { Component, Input } from '@angular/core';
import { Debug } from 'src/app/utils/debug';

@Component({
  selector: 'app-grid-images-fb',
  templateUrl: './grid-images-fb.component.html',
  styleUrls: ['./grid-images-fb.component.scss']
})
export class GridImagesFbComponent {
  @Input() facebookMedias: any;
  @Input() gridFbImageHeight: number=0;
  @Input() screenHeight: number=0;
  orientation: string ='';
  
  async ngOnInit(): Promise<void> {
    const orientationPromises = this.facebookMedias.map((item: any) => this.setOrientation(item));
    try {
      await Promise.all(orientationPromises);
      // console.log(this.facebookMedias);
      this.orientation = this.type();
      // console.log('this.orientation', this.orientation);
    } catch (error) {
      console.error('Error while setting orientations:', error);
    }
  }

  checkImageOrientation(imageUrl: string): Promise<string> {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.src = imageUrl;

      img.onload = () => {
        if (img.width > img.height) {
          resolve('landscape');
        } else if (img.width < img.height) {
          resolve('portrait');
        } else {
          resolve('square');
        }
      };

      img.onerror = (error) => {
        reject(new Error('Error loading image'));
      };
    });
  }

  async setOrientation(image: any): Promise<void> {
    try {
      image.orientation = await this.checkImageOrientation(image.url);
    } catch (error) {
      console.error('Error while loading the image:', error);
    }
  }

  type(){
    if(this.facebookMedias.length==2){
      if(this.facebookMedias[0].orientation == 'portrait' && this.facebookMedias[1].orientation == 'portrait')
        return 'portrait'
      if((this.facebookMedias[0].orientation == 'landscape' || this.facebookMedias[1].orientation == 'landscape') && (this.facebookMedias[0].orientation != 'square' && this.facebookMedias[1].orientation != 'square'))
        return 'landscape'
      if(this.facebookMedias[0].orientation == 'square' || this.facebookMedias[1].orientation == 'square')
        return 'square'
    }
    if(this.facebookMedias.length==3){
      if(this.facebookMedias[0].orientation == 'portrait' && this.facebookMedias[1].orientation == 'portrait' && this.facebookMedias[2].orientation == 'portrait')
        return 'portrait'
      if(this.facebookMedias[0].orientation == 'landscape' && this.facebookMedias[1].orientation == 'landscape' && this.facebookMedias[2].orientation == 'landscape')
        return 'landscape'
      if(this.facebookMedias[0].orientation == 'square' && this.facebookMedias[1].orientation == 'square' && this.facebookMedias[2].orientation == 'square')
        return 'square'
      if(this.facebookMedias[0].orientation == 'square' && this.facebookMedias[1].orientation == 'portrait' && this.facebookMedias[2].orientation == 'portrait')
        return 'FirstsquareRestportrait'
      if(this.facebookMedias[0].orientation == 'portrait')
        return 'FirstImgportrait'
      if(this.facebookMedias[0].orientation == 'landscape')
        return 'FirstImglandscape'
      if(this.facebookMedias[0].orientation == 'square')
        return 'FirstImgsquare'
    }

    if(this.facebookMedias.length==4){
      if(this.facebookMedias[0].orientation == 'portrait')
        return 'portrait'
      if(this.facebookMedias[0].orientation == 'landscape')
        return 'landscape'
      if(this.facebookMedias[0].orientation == 'square')
        return 'square'
    }
    
    if(this.facebookMedias.length>4){
      const counts = { square: 0, landscape: 0, portrait: 0 };
      for (const media of this.facebookMedias) {
        if(media.orientation == 'square'){
          counts['square']++;
        }
        else if(media.orientation == 'landscape'){
          counts['landscape']++;
        }else{
          counts['portrait']++;
        }
      }
      // console.log(this.facebookMedias.length);
      
      const maxCount = Math.max(counts.square, counts.landscape, counts.portrait);
      if (counts.landscape === maxCount) {
        return 'landscape';
      }
      if (counts.square === maxCount) {
        return 'square';
      }
      if (counts.portrait === maxCount) {
        return 'portrait';
      }
    }
    return ''
  }

  imgContainerHeight(factor: number): string{
    if(this.screenHeight !=0){
      return (this.screenHeight<900 ? (this.screenHeight*32/100)/factor: (this.screenHeight*40/100)/factor).toString() + 'px'
    }else
      return (360/factor).toString()+'px'
  }

}
