<!-- <fieldset class="mt-3 mx-auto border border-black border-1 rounded-3xl relative " [style.height.px]="screenHeight < 850 ? screenHeight * 40/100 : screenHeight * 33/100" [style.width.px]="screenWidth*41.59/100"> -->
<fieldset 
	class="mt-3 mx-auto border  rounded-3xl relative " 
	[style.width.px]="screenWidth*41.59/100"
	[ngClass]="{
		'cursor-not-allowed': isUpdate,
		'border-black border-1': !isUpdate,
	}"
>
	<legend 
		class="absolute top-0 left-1/2 transform -translate-x-1/2 -translate-y-1/2 mx-auto rounded-full p-2 px-4 text-white z-20" 
		[style.width.px]="legendWidth()" 
		[ngStyle]="{'font-size': (legendWidth()/15.5) + 'px'}"
		[ngClass]="{
			'bg-[#F0F0F0] text-[#BDBDBD]': isUpdate,
			'c-bg-blue': !isUpdate,
		}"
	>
		{{'posts.planPost'|translate}}
	</legend>
	<div class="m-auto">
		<div *ngIf="isUpdate" class="absolute top-0 bottom-0 right-0 left-0 rounded-3xl bg-white flex justify-center items-center flex-col">
			<svg width="78" height="81" viewBox="0 0 78 81" fill="none" xmlns="http://www.w3.org/2000/svg">
				<!-- First SVG -->
				<path d="M13.5404 4.34961V8.34961H6.99492C3.38128 8.34961 0.449463 11.0371 0.449463 14.3496V20.3496H61.5404V14.3496C61.5404 11.0371 58.6086 8.34961 54.9949 8.34961H48.4495V4.34961C48.4495 2.13711 46.4995 0.349609 44.0858 0.349609C41.6722 0.349609 39.7222 2.13711 39.7222 4.34961V8.34961H22.2676V4.34961C22.2676 2.13711 20.3176 0.349609 17.904 0.349609C15.4904 0.349609 13.5404 2.13711 13.5404 4.34961ZM61.5404 24.3496H0.449463V58.3496C0.449463 61.6621 3.38128 64.3496 6.99492 64.3496H54.9949C58.6086 64.3496 61.5404 61.6621 61.5404 58.3496V24.3496Z" fill="#D7D7D7"/>
				
				<!-- Second SVG (Image Positioned Over the First One) -->
				<rect x="46" y="49" width="32" height="32" fill="url(#pattern0_7968_324843)"/>
				
				<defs>
					<pattern id="pattern0_7968_324843" patternContentUnits="objectBoundingBox" width="1" height="1">
						<use xlink:href="#image0_7968_324843" transform="scale(0.03125)"/>
					</pattern>
					<image id="image0_7968_324843" width="32" height="32" xlink:href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAYAAABzenr0AAAACXBIWXMAABYlAAAWJQFJUiTwAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAMRSURBVHgBvVc9bxNBEJ3ZOLaTynQQCXEuKKDBFxQJIRES6EJjBH0kRB83SChC4EgpUjo/AER6kFKlQsGhgSoxoqDkqFLiAiWO49th5mxf/HG7vjg2T7Ks3Zub93Z3bmYWISZooZDxa+k8gLqPSDkAdHg603pcZQsPCCs+6L3JdG0by6VqHL84yIDuvHQaCAUFarmDMIZieF8HvTb1bcODYQTIivXJ1Bs2KcCFQCWVOl4z7UikAFk1IX4mQAdGAvLqRItRu6F6J+pzq7nRkgvQSbLP+tyLXN+TzsHoV96L/p0IBciZ08nUwfjIz0RwTLjtmAiPQAJu/OQCdJrB3RoFmnjrNapfxneePQCcyQB9+QkgPxvcLOAjF2if3e0cGM3qpLNyFAkZ+IhF4/e4NAvq+cOm2qXboNc/mB0vuaBePQ1t6fIloHe7kaYJzi38V1C0UMwg4LKBHnA22zUOCJjIRh5ixpy3JLFJ3Cm/VsuDBbSz3/9yr4gocoH9uDKnten8xOur9wq8Azmj2WEViH84f7NrWsZ0+Afg+pVIclr/CPTpB9jAx15NIOItztt28C5okJU/6ZqOXHWbPGLn+gQg5BRXMAfiQESw40GIS96Cw3mA4le4ASLOSS7IKDg3yPJk0Fn2gwVgrMYhgCna285Mn6gZVQXIncwIyIcU4SnS9H2gmYFczjwqJmKLIKjIEZStRvM3zN+5BJwhMIN33KzVtQ+wpybSyW1bHPQmoC7yNgwipCjZMJk+2lZYLlY10JbRSrKdjdwmYt9cYKVplZ4gqIYJ0iUuxyuRdm93g3QsRUmIyeZURPw9BhWkaU7htnLMHbP8h1X49O5qSRGswH+ABr05+XUj6LbDRJRIJqUn8GD88BKpWrE9CAVILCDpxTGL4KaUOTruCF2pGLlFajTg8ZhEeD777r0bWC4mittzcGBE5LLyWBeTQBUbYirpaoRNuCAk4FTqyDXdEWNdTptNq7lvjHDLucXfahCUhr6c9gkJrufTeS5eC4gqx4nk2lkvwZkU4TdpqPBceYIzXNzr+T+03m85gGxeJQAAAABJRU5ErkJggg=="/>
				</defs>
			</svg>
			
			
			<span class="w-1/2 text-base text-[#544E61] text-center">Impossible de programmer une publication lorsque le post est déjà publié</span>
		</div>
		<div class="col-span-8 flex justify-between mt-8 w-3/4 mx-auto">
			<button type="button" class=" cursor-pointer col-span-2 text-4xl" (click)="isUpdate ? null : prevMonth()">←</button>
			<span class="row-span-1 col-span-6" >{{currentMonth | titlecase}}</span>
			<button type="button" class="mr-1 cursor-pointer col-span-2 text-4xl left-[4px]" (click)="isUpdate ? null : nextMonth()">→</button>
		</div>
		<!-- <div class="col-span-8 grid grid-cols-8 gap-2 w-3/4 m-auto datetime-btn my-auto">
			<button type="button" 
			(click)="pickDate(day)" 
			class="flex justify-center items-center rounded-md text-xl bg-white w-12 h-10" 
			[disabled]="isPastDate(day.value)" 
			[class.bg-cliking_blue]="isSelectedDate(day.value)"
			[class.selectedDate]="isSelectedDate(day.value)"
			[style.width.px]="screenWidth*3.19/100"
			[style.height.px]="screenHeight*4/100"
			*ngFor="let day of daysArray" [ngStyle]="{'font-size': screenHeight/45 + 'px'}">{{day.label}}</button>

		</div> -->
		<!-- Calendar Container -->
		<div class="ol-span-7 grid grid-cols-7 gap-2 w-3/4 m-auto datetime-btn my-auto">
			<!-- Day Names Row -->
			<div
				class="col-span-1 text-center font-bold"
				*ngFor="let day of dayNames"
				[ngStyle]="{ 'font-size': screenHeight / 45 + 'px' }"
			>
				{{ day }}
			</div>

			<!-- Days Grid -->
			<div
				class="col-span-1 text-center"
				*ngFor="let day of daysArray"
			>
				<button
					*ngIf="day.value"
					type="button"
					(click)="isUpdate ? null : pickDate(day)"
					class="flex justify-center items-center rounded-md text-xl bg-white w-12 h-10"
					[disabled]="isPastDate(day.value)"
					[class.bg-cliking_blue]="isSelectedDate(day.value)"
					[class.selectedDate]="isSelectedDate(day.value)"
					[style.width.px]="screenWidth * 3.19 / 100"
					[style.height.px]="screenHeight * 4 / 100"
					[ngStyle]="{ 'font-size': screenHeight / 45 + 'px' }"
				>
					{{ day.label }}
				</button>
			</div>
		</div>
		<div class="flex items-center rounded-md col-span-8 justify-center" [style.margin-bottom.px]="screenHeight<850 ? screenHeight*1.6/100: screenHeight*0.8/100" [style.margin-top.px]="screenHeight<850 ? screenHeight*1.6/100: screenHeight*0.8/100" >
			<span class="flex items-center" [ngStyle]="{'font-size': screenWidth/70 + 'px'}">
				{{'generic.at'|translate}} 
				<input 
					*ngIf="!isUpdate"
					[value]="selectedTime"
					(change)="isUpdate ? null : pickTime($event)" 
					class="m-2 appearance-none block p-1 cursor-pointer border rounded-lg text-white bg-cliking_blue leading-tight focus:outline-none focus:ring-1 focus:ring-cliking_blue focus:border-cliking_blue" type="time" id="time"   [style.height.px]="screenHeight*4/100" [ngStyle]="{'font-size': screenWidth/70 + 'px'}" [style.width.px]="screenWidth*7/100" name="time"
				>
			</span>
		</div>
	</div>
	<!-- if is update, no calendar -->

</fieldset>

