import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { TranslationService } from 'src/app/services/utilities/translation.service';
import { BaseComponent } from '../../base/base.component';


import * as moment from 'moment';

@Component({
  selector: 'app-post-create-preview',
  templateUrl: './post-create-preview.component.html',
  styleUrls: ['./post-create-preview.component.scss']
})
export class PostCreatePreviewComponent extends BaseComponent {
  @Input() selectedPlateform: string = "";
  @Input() data: any;
  @Input() googleAdditionalData: any = null;
  @Input() screenHeight: number = 0;
  @Input() screenWidth: number = 0;
  @Input() copyPost: boolean = true;
  @Input() postIsCreated: boolean = false;

  showedDate: string = "";
  linkedinData: any = null;
  linkedinMedias: any[] = [];
  googleData: any = null;
  googleMedias: any[] = [];
  facebookData: any = null;
  facebookMedias: any[] = [];
  instagramData: any = null;
  instagramMedias: any[] = [];
  mediaAllpreview: any = null;

  showFullIgText: boolean = false;
  showFullGoogleText: boolean = false;
	formattedText: SafeHtml | null = null;
	
  localPostOfferOpen: boolean = false;
	offerTermsConditionsIsExpanded: boolean = false;

  constructor(
    translationService: TranslationService,
		private sanitizer: DomSanitizer) {
			super(translationService);
		}

	ngOnInit(){
		this.initData();
		this.showedDate = this.timeSince(this.data?.date ? this.data?.date : this.data?.createdAt);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['data'] && this.data) {
      this.initData();
    }
  }

	initData(){
		// Ensure data is processed when available
		this.data.socialMedia?.forEach((item: { medias: string[]; source: string; }) => {
			if (item.source === "linkedin") {
				this.linkedinData = item;
			}
			if (item.source === "google") {
				this.googleData = item;
			}
			if (item.source === "facebook") {
				this.facebookData = item;
			}
			if (item.source === "instagram") {
				this.instagramData = item;
			}
		});
		

		// Assign media lists
		this.linkedinMedias = this.data.mediaList || [];
		this.facebookMedias = this.data.mediaList || [];
		this.instagramMedias = this.data.mediaList || [];
		this.googleMedias = this.data?.mediaGoogle ? [{ url: this.data.mediaGoogle }] : [];

		if(this.noMediaExtracted()){

			this.linkedinMedias = this.alreadyPublishedMedias("linkedin")
			this.facebookMedias = this.alreadyPublishedMedias("facebook")
			this.instagramMedias = this.alreadyPublishedMedias("instagram")
			this.googleMedias = this.alreadyPublishedMedias("google")
		}

		this.formattedText = this.parseLinks(this.data?.text);

		// additional data
		if (!this.googleAdditionalData) {
			this.googleAdditionalData = this.data.socialMedia.find((sm: any) => sm.source === "google")?.additionalData;
		}
		//
		this.choiceAllMediaPreview()
	}

	choiceAllMediaPreview(){
		if(this.googleMedias.length > 0){
			this.mediaAllpreview = this.googleMedias[0] ;
			return
		}
		
		if(this.linkedinMedias.length > 0){
			this.mediaAllpreview = this.linkedinMedias[0] ;
			return
		}
		
		if(this.facebookMedias.length > 0){
			this.mediaAllpreview = this.facebookMedias[0] ;
			return
		}
		
		if(this.instagramMedias.length > 0){
			this.mediaAllpreview = this.instagramMedias[0] ;
			return
		}
	}
	noMediaExtracted(){
		//if we have media on the post but mediaList is empty
		return this.data.mediaList.length==0 && (this.alreadyPublishedMedias("facebook").length !== 0 || this.alreadyPublishedMedias("linkedin").length !== 0 || this.alreadyPublishedMedias("google").length !== 0 || this.alreadyPublishedMedias("instagram").length !== 0)
	}
	alreadyPublishedMedias(source: string){
		if(this.data?.socialMedia?.length > 0){
			const targetPage = this.data?.socialMedia?.find((sm: any) => sm.source === source)
			if(targetPage?.externalId && targetPage?.medias.length > 0){
				// console.log(source,"[returning]",targetPage.medias[0])
				return targetPage.medias
			}
		}
		return []
	}

  formatDateWithMoment(dateString: string): string {
    if (dateString != null) {
      return moment(dateString).format('D MMMM YYYY');
    } else {
      return '';
    }
  }

  mediaIsVideo(medias: any[]): boolean {
    return (medias.length === 1 && medias[0] != null && medias[0]?.type?.startsWith("video/"));
  }

  displayFullIgText(): void {
    this.showFullIgText = !this.showFullIgText;
  }

  // Instagram-specific functionality
  igActiveIndex: number = 0;

  prev(): void {
    this.igActiveIndex =
      (this.igActiveIndex - 1 + this.instagramMedias.length) %
      this.instagramMedias.length;
  }

  next(): void {
    this.igActiveIndex =
      (this.igActiveIndex + 1) % this.instagramMedias.length;
  }

  // Google-specific functionality
  displayFullGoogleText(): void {
    this.showFullGoogleText = !this.showFullGoogleText;
  }

  capitalize(str: string): string {
    if (!str) return str;
    return str.charAt(0).toUpperCase() + str.slice(1);
  }

	// FB-specific functionality

	timeSince(dateString: string): string {
    
		const date = new Date(dateString);
		const now = new Date();
		const diffInMs = now.getTime() - date.getTime();
		const diffInSecondes = Math.floor(diffInMs / 1000); 
		const diffInMinutes = Math.floor(diffInSecondes / 60); // Convert milliseconds to minutes
		const diffInHours = Math.floor(diffInMinutes / 60);
		
		if (diffInSecondes < 60) {
			return `${diffInSecondes} s`;
		} else if (diffInMinutes < 60) {
			return `${diffInMinutes} min`;
		} else if (diffInHours < 24) {
			return `${diffInHours} h`;
		} else {
			// Format date in "5 novembre 2024, 11h41"
			const options: Intl.DateTimeFormatOptions = {
				day: 'numeric',
				month: 'long',
				year: 'numeric',
				hour: '2-digit',
				minute: '2-digit',
				hour12: false,
			};
			return date.toLocaleDateString(this.translationService.getLanguage(), options);
		}
	}

	parseLinks(text: string): SafeHtml {
    if (!text) return text;

    // Regex to match URLs
    const urlRegex = /\b((https?:\/\/|www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*))\b/g;

    // Replace URLs with styled spans
    const processedText = text.replace(urlRegex, (match) => {
      const url = match.startsWith('http') ? match : `https://${match}`;
      return `<a href="${url}" target="_blank" style="color: #0064d1; text-decoration: none;">${match}</a>`;
    });

    // Sanitize the HTML to prevent XSS
    return this.sanitizer.bypassSecurityTrustHtml(processedText);
  }

	googleDateFormat() {
		// Step 1: Find the first social media with source "google"
	
		if (this.googleAdditionalData && this.googleAdditionalData?.localPostEvent?.schedule) {
			const schedule = this.googleAdditionalData.localPostEvent.schedule;
	
			// Extract start and end dates & times
			const { startDate, endDate, startTime, endTime } = schedule;
	
			// Step 2: Get today's date
			const today = new Date();
			const todayObj = { day: today.getDate(), month: today.getMonth() + 1, year: today.getFullYear() };
	
			// Step 3: Determine if this is an "offer" post
			const isOffer = this.data.typePost.toLowerCase() === "offer";
	
			// Step 4: Check language
			const isFrench = this.getTranslation("locale") === "fr";
	
			// Step 5: Get Translations
			const validPrefix = isOffer ? (isFrench ? "Valable " : "Valid ") : ""; // Prefix only for offers
			const todayWord = isFrench ? "Aujourd'hui" : "Today";
			const onThe = isFrench ? "le" : ""; // Used for "Valable le 11 févr."
			const from = isFrench ? "du" : ""; // Used for "Valable du 11 févr."
			const to = isFrench ? "au" : "-"; // Used for "au 12 févr." in French, "-" in English
	
			// Step 6: Function to format month name
			const formatMonth = (date: { month: number }) => {
				return this.getTranslation(`dates.months.short.${date.month}`);
			};
	
			// Step 7: Function to format time (HH:MM)
			const formatTime = (time: { hours: number; minutes: number }) => {
				return `${time.hours.toString().padStart(2, "0")}:${time.minutes.toString().padStart(2, "0")}`;
			};
	
			// Step 8: Function to format a full date
			const formatFullDate = (date: { day: number; month: number; year: number }) => {
				return isFrench
					? `${onThe} ${date.day} ${formatMonth(date)}` // "le 11 févr."
					: `${formatMonth(date)} ${date.day}`; // "Feb 11"
			};
	
			// Step 9: Check if the event/offer lasts only one day
			const isSingleDayEvent =
				startDate.day === endDate.day &&
				startDate.month === endDate.month &&
				startDate.year === endDate.year;
	
			if (isSingleDayEvent) {
				// Step 10: If the event/offer is today
				if (
					startDate.day === todayObj.day &&
					startDate.month === todayObj.month &&
					startDate.year === todayObj.year
				) {
					return isFrench
						? `${validPrefix}${todayWord} de ${formatTime(startTime)} à ${formatTime(endTime)}` // "Valable Aujourd'hui de 01:00 à 02:00"
						: `${validPrefix}${todayWord}, ${formatTime(startTime)} - ${formatTime(endTime)}`; // "Valid Today, 01:00 - 02:00"
				}
	
				// Step 11: If it's a single-day event/offer but not today
				return isFrench
					? `${validPrefix}${formatFullDate(startDate)} de ${formatTime(startTime)} à ${formatTime(endTime)}` // "Valable le 11 févr. de 01:00 à 02:00"
					: `${validPrefix}${formatFullDate(startDate)}, ${formatTime(startTime)} - ${formatTime(endTime)}`; // "Valid Feb 11, 01:00 - 02:00"
			}
	
			// Step 12: Multi-day event/offer formatting
			return isFrench
				? `${validPrefix}${from} ${formatFullDate(startDate)} ${to} ${formatFullDate(endDate)}` // "Valable du 11 févr. au 12 févr."
				: `${validPrefix}${formatFullDate(startDate)} ${to} ${formatFullDate(endDate)}`; // "Valid 11 févr. - 12 févr."
		}
	
		// console.log("No Google social media data available.");
		return "";
	}

	anyLocalPostOffer() {
		if (this.googleAdditionalData?.localPostOffer) {
			const offer = this.googleAdditionalData.localPostOffer
			return offer.couponCode || offer.redeemOnlineUrl || offer.termsConditions
		}
	}
	
}