<div class="relative w-full" [class]="css">
    <!-- Champ de recherche -->
    <input
      type="text"
      [(ngModel)]="searchTerm"
      [placeholder]="placeholder"
      class="input input-bordered w-full"
      (click)="toggleDropdown()"
      (input)="filterItems()"
    />
  
    <!-- Liste déroulante personnalisée -->
    <ul
      *ngIf="isOpen && filteredItems.length > 0"
      class="absolute z-10 w-full bg-white border border-gray-300 rounded-b-lg shadow-lg max-h-60 overflow-y-auto"
    >
      <li
        *ngFor="let item of filteredItems"
        (click)="selectItem(item)"
        class="px-4 py-2 hover:bg-gray-100 cursor-pointer"
      >
        {{ displayLabel(item) }}
      </li>
    </ul>
  
    <!-- Message si aucun résultat -->
    <div
      *ngIf="isOpen && filteredItems.length === 0"
      class="absolute z-10 w-full bg-white border border-gray-300 rounded-b-lg shadow-lg p-2 text-gray-500"
    >
      Aucun résultat trouvé
    </div>
  </div>