<div role="tablist" class="tabs tabs-lifted">
    <input
        type="radio"
        name="my_tabs_2"
        role="tab"
        class="tab [--tab-border-color:#5d78ff] border-cliking_blue"
        aria-label="Profiles"
        checked
    />
    <div
        role="tabpanel"
        class="tab-content bg-base-100 border-cliking_blue rounded-box p-6"
        >
        <form class="mx-5 mt-5" action="" method="post">
            <label class="input input-bordered flex items-center gap-2">
            <input
                (keyup)="checkifprofileExists($event)"
                type="text"
                class="grow"
                name="name"
                placeholder="Enter profile and press '+' icon"
                [(ngModel)]="tmpProfile"
            />
            <span *ngIf="alreadyExists" class="text-red-600"
                >already exist</span
            >
            <span *ngIf="tmpProfile.trim().length < 3" class="text-orange-400"
                >Profile length need to 3 or more
            </span>
            <svg
                *ngIf="!alreadyExists && tmpProfile.trim().length >= 3"
                (click)="CreateProfile()"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 448 512"
                fill="currentColor"
                class="cursor-pointer w-4 h-4 opacity-70"
            >
                <path
                d="M256 80c0-17.7-14.3-32-32-32s-32 14.3-32 32V224H48c-17.7 0-32 14.3-32 32s14.3 32 32 32H192V432c0 17.7 14.3 32 32 32s32-14.3 32-32V288H400c17.7 0 32-14.3 32-32s-14.3-32-32-32H256V80z"
                />
            </svg>
            </label>
        </form>

        <app-table
            *ngIf="Profiles.data?.length > 0"
            [skipColumns]="['permissions']"
            [entity]="'Profile'"
            [res]="Profiles"
            [isWriteEnabled]="true"
            (editItem)="editProfile($event)"
            (deleteItem)="deleteProfile($event)"
        >
        </app-table>
    </div>
        <!-- ProfilePermissions -->
        <input
        type="radio"
        name="my_tabs_2"
        role="tab"
        class="tab [--tab-border-color:#5d78ff]"
        aria-label="Permissions"
    />
    <div
        role="tabpanel"
        class="tab-content bg-base-100 border-cliking_blue rounded-box p-6"
        >
        <div class="join">
            <app-back-office-dropdown [items]="Profiles.data"
            labelField="name"
            placeholder="Select a profile"
            [css]="'w-full max-w-xs px-2 text-sm'"
            (itemSelected)="updateSelectedProfile($event)" />
            <button
            [disabled]="!isPermissionsFormDirty"
            class="mx-5 btn btn-accent text-white"
            (click)="savePermissions()"
            >
            Save
            </button>
        </div>
        <div *ngIf="selectedProfile?.name">
            <h3 class="text-xs mt-5 ml-2 text-left">
            {{ selectedProfile?.name }} Actions :
            <span class="text-cliking_green"
                >{{ selectedProfile.permissions.length }} Granted</span
            >
            /
            <span class="text-cliking_red_nuance"
                >{{
                AppPermissions.length - selectedProfile.permissions.length
                }}
                Denied</span
            >
            of {{ AppPermissions.length }} Total
            </h3>
            <app-permissions-editor
            [Profile]="selectedProfile"
            [AppPermissions]="AppPermissions"
            (onToggleSwitched)="UpdatePermissions($event)"
            />
        </div>
    </div>

    <!-- permissionsMapper -->
    <input
        type="radio"
        name="my_tabs_2"
        role="tab"
        class="tab [--tab-border-color:#5d78ff]"
        aria-label="PermissionsMapper"
    />

    <div
        role="tabpanel"
        class="tab-content bg-base-100 border-cliking_blue rounded-box p-6"
    >
        <div class="join">
        <app-ui-permissions-mapper
            [appProfiles]="Profiles.data"
            [systemPermissions]="AppPermissions"
        />
        </div>
    </div>

    <!-- accreditations -->
    <input
        type="radio"
        name="my_tabs_2"
        role="tab"
        class="tab [--tab-border-color:#5d78ff]"
        aria-label="Accreditations"
    />
    <div
        role="tabpanel"
        class="tab-content bg-base-100 border-cliking_blue rounded-box p-6"
    >
        <div class="join">
        <button
            class="mx-5 btn bg-cliking_blue hover:bg-cliking_green text-white"
            (click)="isAccreditationInputsVisible = true"
        >
            New
        </button>
        <button class="btn" (click)="showAccreditations()">
            Saved Accreditations
            <div class="badge badge-secondary">
            {{ AppAccreditations.length }}
            </div>
        </button>
        </div>
        <div *ngIf="isAccreditationInputsVisible" class="join">
        <app-back-office-dropdown [items]="users.data"
            labelField="firstName+++lastName"
            placeholder="Select a user"
            [css]="'w-full max-w-xs px-2 text-sm'"
            (itemSelected)="updateSelectedUser($event)" />
        <app-back-office-dropdown [items]="Profiles.data"
            labelField="name"
            placeholder="Select a profile"
            [css]="'w-full max-w-xs px-2 text-sm'"
            (itemSelected)="updateSelectedProfile($event)" />
        <app-back-office-dropdown [items]="groups.data"
            labelField="name"
            placeholder="Select a group"
            [css]="'w-full max-w-xs px-2 text-sm'"
            (itemSelected)="updateSelectedGroup($event)" />
        <button
            [disabled]="!selectedUser || !selectedProfile || !selectedGroup"
            class="mx-5 btn btn-accent text-white"
            (click)="saveAccreditations()"
        >
            Save
        </button>
        </div>

        <div
        *ngIf="
            selectedUser &&
            selectedProfile &&
            selectedGroup &&
            overrideUserPermissions
        "
        ></div>
        <app-table
        [skipEdit]="true"
        [res]="accreditationsDisplay"
        [isWriteEnabled]="true"
        (deleteItem)="deleteAccreditation($event.item)"
        />
    </div>
</div>