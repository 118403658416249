<div [formGroup]="pageForm">
	<input 
	[formGroup]="pageForm"
	#autocomplete 
	type="text" 
	name="address"
	id="address"
	formControlName="address"
	placeholder="Enter your address" 
	class="px-5 py-[3px] mt-3 mb-5 mx-auto block w-11/12 text-base border border-1 border-black rounded-3xl shadow-sm focus:outline-none focus:ring-cliking_blue focus:border-cliking_blue" 
	/>
</div>
