import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CalenderBaseComponent } from 'src/app/components/base/calendar.component';
import { PostService } from 'src/app/services/repositories/post.service';
import { TranslationService } from 'src/app/services/utilities/translation.service';
import { UiStateService } from 'src/app/services/utilities/ui-state.service';
import { CalendarService } from 'src/app/services/utilities/calendar.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-post-label-preview',
  templateUrl: './post-label-preview.component.html',
  styleUrls: ['./post-label-preview.component.scss'],
})
export class PostLabelPreviewComponent extends CalenderBaseComponent {
  @Input() post: any;
  @Input() screenWidth: number = 0;
  @Input() screenHeight: number = 0;
  @Input() postHour: string = '';
  @Input() homePage: boolean = false;
  @Output() labelClicked = new EventEmitter();
  previewIndex: number = 0;
  apiUrl = environment.apiUrl;
  mediaPreview: string = '';
  posted: Boolean = false; 
  planned: Boolean = false;   
  @Output() hidedPost = new EventEmitter<any>();
  @Output() showedPost = new EventEmitter<any>();


  constructor(
    protected override uiStateService: UiStateService,
    protected override postService: PostService,
    protected override translationService: TranslationService,
    protected override calendarService: CalendarService
  ) {
    super(translationService, calendarService, postService, uiStateService);
  }

  override ngOnInit(): void {
    super.ngOnInit();
    this. preparePost();
  }

  preparePost(){
    this.getPostHour();
    this.choiceMediaPreview();
    this.postType();
  }

  numberOfEmptyArray(nb: number): any[] {
    return new Array(nb);
  }

  getPostHour(){
    const date = new Date(this.post.publishingDatetime ? this.post.publishingDatetime : this.post.expectedPublishingDatetime);
    const hours = String(date.getUTCHours()).padStart(2, '0');
    const minutes = String(date.getUTCMinutes()).padStart(2, '0');

    this.postHour = `${hours}:${minutes}`;
  }

  choiceMediaPreview(){
    if(this.post.googleMedias.length > 0){
      this.mediaPreview = this.post.googleMedias[0].url;
      return
    }
    
    if(this.post.linkedinMedias.length > 0){
      this.mediaPreview = this.post.linkedinMedias[0].url;
      return
    }
    
    if(this.post.facebookMedias.length > 0){
      this.mediaPreview = this.post.facebookMedias[0].url;
      return
    }
    
    if(this.post.instagramMedias.length > 0){
      this.mediaPreview = this.post.instagramMedias[0].url;
      return
    }
  }

  postType(){
    if(this.post.expectedPublishingDatetime && !this.post.publishingDatetime){
      this.planned = true;
    }else {
      this.posted = true;
    }
  }

  mediaIsVideo(media: any): boolean {
    return media?.mimeType?.startsWith('video') ?? false;
  }

}
