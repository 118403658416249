import { Component } from '@angular/core';

@Component({
  selector: 'app-back-office-connexion-state',
  templateUrl: './back-office-connexion-state.component.html',
  styleUrls: ['./back-office-connexion-state.component.scss']
})
export class BackOfficeConnexionStateComponent {

}
