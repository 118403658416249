import { Component } from '@angular/core';
import { Subscription } from 'rxjs';
import { IResponse } from 'src/app/interfaces/IResponse';
import { UserService } from 'src/app/services/repositories/user.service';
import { ICON } from 'src/app/interfaces/ModalIcon';
import { GroupService } from 'src/app/services/repositories/group.service';

@Component({
  selector: 'app-back-office-profile',
  templateUrl: './back-office-profile.component.html',
  styleUrls: ['./back-office-profile.component.scss']
})
export class BackOfficeProfileComponent {
  constructor(
    private userService: UserService,
    private groupService: GroupService,
  ) {}
  tmpProfile: string = '';
  alreadyExists: boolean = false;
  subscriptions: Subscription[] = [];
  isModalOpen: boolean = false;
  head: string = '';
  subHead: string = '';
  modalIcon: ICON = ICON.success;
  AppAccreditations: any[] = [];
  AppPermissions: { id: number; action: string }[] = [];
  groups: IResponse = {
    data: [],
    navigation: {
      page: 0,
      totalPages: 0,
      totalRows: 0,
      itemsPerPage: 0,
    },
    success: false,
    error: '',
    status: 0,
  };

  users: IResponse = {
    data: [],
    navigation: {
      page: 0,
      totalPages: 0,
      totalRows: 0,
      itemsPerPage: 0,
    },
    success: false,
    error: '',
    status: 0,
  };
  Profiles: IResponse = {
    data: [],
    success: true,
    error: '',
    status: 0,
    navigation: {
      page: 0,
      totalPages: 0,
      totalRows: 0,
      itemsPerPage: 0,
    },
  };
  filteredUsers: any[] = [];
  searchTerm: string = '';
  isOpen: boolean = false;
  selectedUser: any = null;

  ngOnInit(){
    this.subscriptions.push(  this.groupService.getGroups().subscribe((res) => {
      this.groups = res;
    }));
    //get all profiles
    this.subscriptions.push(this.userService.getAllProfiles().subscribe((res) => {
      this.Profiles = res;
      this.Profiles.data = this.sortByIdDesc(this.Profiles.data);
    }));

    //get all users
    this.subscriptions.push(this.userService.getAllUsers().subscribe((res) => {
      this.users = res;
    }));

    //get all permissions
    this.subscriptions.push(this.userService.getAllPermissions().subscribe((res) => {
      this.AppPermissions = res.data;
    }));

    //get All Accreditations
    this.subscriptions.push(this.userService.getAllAccreditations().subscribe((res) => {
      this.AppAccreditations = res.data;
      this.accreditationsDisplay =
        this.prepareAccreditationsForTableDisplay(res);
    }));
  }

  CreateProfile() {
    const tmpProfile = this.tmpProfile.toLocaleLowerCase().trim();
    console.log('createing profile');
    if (!this.alreadyExists && tmpProfile.length >= 3) {
      this.subscriptions.push( this.userService.newProfile(tmpProfile).subscribe((res) => {
        this.Profiles = res;
        this.Profiles.data = this.sortByIdDesc(this.Profiles.data);
        this.tmpProfile = '';
      }));
    }
    (this.head = 'generic.successCreateProfile'), (this.subHead = '');
    this.isModalOpen = true;
    setTimeout(() => {
      this.isModalOpen = false;
    }, 1500);
  }

  checkifprofileExists(data: any) {
    const currentValue = data.target.value.toLocaleLowerCase().trim();
    this.alreadyExists = this.Profiles.data.some((profile: any) => {
      return profile.name === currentValue;
    });
    // console.log(this.alreadyExists,currentValue,this.Profiles.data.some((profile:any)=>{profile.name==currentValue}))
  }

  editProfile(data: any) {
    console.log('editingProfile', data);
    this.subscriptions.push(this.userService.updateProfile(data.item.id, data.item).subscribe());
    //TODO fetch the new profiles list
  }

  deleteProfile(data: any) {
    console.log('deleting profile', data);
    this.subscriptions.push(this.userService.deleteProfile(data.item.id).subscribe((res) => {
      console.log("deleteProfile res", res)
    }))
    // this.Profiles.data = this.Profiles.data.filter(
    //   (p: any) => p.id != data.item.id
    // );
    this.Profiles.data = this.sortByIdDesc(this.Profiles.data);
    //TODO fetch the new profiles list
  }

  sortByIdDesc(data:any) {
		return data.sort((a:any, b:any) => b.id - a.id);
	}

  //------------------------------------------------------------permissions methods
  isPermissionsFormDirty: boolean = false;
  UpdatePermissions(data: any) {
    console.log('permissions changes', data);
    //checking if dirty will be based on the fact that this.selectedProfile.permissions and data has same value or not
    this.isPermissionsFormDirty = true; //true if not same values false if the same
    //this.selectedProfile.permissions = data
  }
  savePermissions() {
    const postData: { profileId: string; permissions: any[] } = {
      profileId: this.selectedProfile.id,
      permissions: [],
    };

    postData.profileId = this.selectedProfile.id;
    // console.log("savePermissions",this.selectedProfile.permissions,this.AppPermissions)

    console.log(this.selectedProfile);
    const permissionsIds = this.selectedProfile.permissions.map(
      (permission: any) => permission.id
    );

    console.log(permissionsIds);

    this.subscriptions.push(this.userService
      .saveProfilePermissions({
        profileId: this.selectedProfile.id,
        permissionIds: permissionsIds,
      })
      .subscribe((res: IResponse) => {
        console.log(res);
      }));

    this.isPermissionsFormDirty = false;
  }

  //------------------------------------------------------------accreditations methods

  accreditationsDisplay: any = {};
  private prepareAccreditationsForTableDisplay(input: any): any {
    // console.log('perparing ', input);
    let accs = input.data.map((acc: any) => {
      return {
        id: acc.id,
        user: acc.user.firstName + ' ' + acc.user.lastName,
        email: acc.user.email,
        group: acc.group.name,
        profile: acc.profile.name,
      };
    });
    accs = this.sortByIdDesc(accs);
    return {
      data: accs,
      navigation: {
        page: 0,
        totalPages: 0,
        totalRows: 0,
        itemsPerPage: 0,
      },
    };

  }
  systemPermissions = [];
  overrideUserPermissions: boolean = false;

  toggleOverridePermissions() {
    this.overrideUserPermissions = !this.overrideUserPermissions;
  }

  isAccreditationAlreadyExists(): boolean {
    return false;
  }
  showAccreditations() {}
  isAccreditationInputsVisible: boolean = false;
  saveAccreditations() {
    if (!this.selectedUser || !this.selectedGroup || !this.selectedProfile) {
      return;
    }

    const postAccreditationData = {
      userId: this.selectedUser.id,
      groupId: this.selectedGroup.id,
      profileId: this.selectedProfile.id,
      applyToSubGroups: true,
    };

    this.subscriptions.push( this.userService.saveAccreditations(postAccreditationData).subscribe({
      next: (saveRes: IResponse) => {
        this.AppAccreditations = saveRes.data;
        /* Swal.fire({
            icon: 'success',
            title: this.translationService.t('Accreditation created Successfully'),
            showConfirmButton: false,
            timer: 1500,
          }); */
        (this.head = 'generic.successCreateAccreditation'), (this.subHead = '');
        this.modalIcon = ICON.success;
        this.isModalOpen = true;
        setTimeout(() => {
          this.close();
        }, 2000);

        // Fetch all accreditations after saving
        this.subscriptions.push(this.userService.getAllAccreditations().subscribe({
          next: (getAllRes: IResponse) => {
            console.log('res.data', getAllRes.data);
            this.AppAccreditations = getAllRes.data;

            this.accreditationsDisplay =
              this.prepareAccreditationsForTableDisplay(getAllRes);
          },
          error: (err) => {
            console.error('Error fetching all accreditations:', err);
          },
        }));
      },
      error: (err) => {
        console.error('Error saving accreditation:', err);
        /* Swal.fire({
            icon: 'error',
            title: this.translationService.t('Error saving accreditation'),
            showConfirmButton: true,
          }); */
        (this.head = 'generic.creationFailure'),
          (this.subHead = 'generic.creationAccreditationFailureMsg');
        this.modalIcon = ICON.fail;
        this.isModalOpen = true;
      },
    }));
  }

  deleteAccreditation(item: any) {
    console.log(this.AppAccreditations.length);

    console.log(item.id);
    this.subscriptions.push( this.userService.removeAccreditation(item.id).subscribe((res: any) => {
      console.log(res);

      this.subscriptions.push(this.userService.getAllAccreditations().subscribe((res: IResponse) => {
        console.log('res.data', res.data);
        this.AppAccreditations = res.data;
        this.accreditationsDisplay =
          this.prepareAccreditationsForTableDisplay(res);
      }));

      console.log(this.AppAccreditations.length);
    }));
  }

  selectedProfile: any;
  updateSelectedProfile(data: any) {
    this.selectedProfile = this.Profiles.data.filter(
      (profile: any) => profile.id == data.id
    )[0];
   }

  updateSelectedUser(data: any) {
    this.selectedUser =data;
  }
  
  selectedGroup: any;
  updateSelectedGroup(data: any) {
    this.selectedGroup = data;
  }

  close() {
    this.isModalOpen = false;
  }

  ngOnDestroy() {
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }

  filterUsers() {
    if (!this.searchTerm) {
      this.filteredUsers = [...this.users.data];
    } else {
      this.filteredUsers = this.users.data.filter((user:any) =>
        `${user.firstName} ${user.lastName}`
          .toLowerCase()
          .includes(this.searchTerm.toLowerCase())
      );
    }
  }

  // Sélectionner un utilisateur et fermer la liste
  selectUser(user: any) {
    this.selectedUser = user;
    this.searchTerm = `${user.firstName} ${user.lastName}`; // Met à jour le champ avec la sélection
    this.isOpen = false;
    this.updateSelectedUser(user); // Appelle ta méthode existante
  }

  // Optionnel : fermer la liste si on clique en dehors
  closeDropdown(event: MouseEvent) {
    const target = event.target as HTMLElement;
    if (!target.closest('.relative')) {
      this.isOpen = false;
    }
  }
}
