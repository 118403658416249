<div class="p-4">
  <div (click)="addMoreHour()" class=" text-cliking_blue px-4 py-2 rounded-lg mb-4 cursor-pointer">
    + {{"pages.hours.addHourType" | translate}}
  </div>

	<!-- NEW more hours instances -->
	<div *ngFor="let moreHour of newMoreHoursPeriods; let i = index" class="mb-4 border p-4 rounded-lg bg-zinc-50 text-sm">
		
		<div class="flex justify-end mt-4 flex-col">
			<div class="flex items-start space-x-4">
				<div class="w-full text-sm">
					<label class="block text-gray-700">{{"pages.hours.hourType" | translate}}</label>
					<select [(ngModel)]="moreHour.hoursTypeId" class="border rounded-lg w-full p-2">
						<!-- Filter moreHoursTypes to exclude those already in groupedMoreHours -->
						<option *ngFor="let type of moreHoursTypes | filterTypes:groupedMoreHours" [value]="type.hoursTypeId">
							{{ translate.currentLang == 'fr' ? type.localizedDisplayName : type.displayName }}
						</option>
					</select>
				</div>
			</div>	
			<!-- //// -->
			<div class="flex justify-end mt-4 flex-col">
				<div *ngFor="let day of moreHour.days; let j = index" class="day-hours flex items-start">
					<div class="w-1/6 day-name text-sm mt-[11px] overflow-hidden whitespace-nowrap text-ellipsis">{{ getTranslatedDay(day.day) }}</div>
					<div class="w-1/6 flex items-center mr-1 mt-[11px]">
						<label class="flex items-center">
							<input type="checkbox" [(ngModel)]="day.closed" (change)="toggleClosed(day, i, 'new')" class="sr-only">
							<div class="toggle-switch relative inline-flex items-center h-6 rounded-full w-11">
								<span [ngClass]="{'bg-cliking_blue': !day.closed, 'bg-gray-400': day.closed}" class="absolute left-0 top-0 bottom-0 right-0 rounded-full transition-colors cursor-pointer"></span>
								<span [ngClass]="{'translate-x-6': !day.closed, 'translate-x-1': day.closed}" class="inline-block w-4 h-4 transform bg-white rounded-full transition-transform cursor-pointer"></span>
							</div>
							<span class="ml-2 text-sm">{{ day.closed ? ('pages.hours.closed' | translate) : ('pages.hours.open' | translate) }}</span>
						</label>
					</div>
					<div class="w-1/6 mt-[11px] items-center">
						<div *ngIf="!day.closed" class="flex justify-center">
							<label class="flex items-center">
								<input type="checkbox" [(ngModel)]="day.allDay" (change)="toggleAllDay(day, i, 'new')" class="form-checkbox h-4 w-4 text-blue-600 transition duration-150 ease-in-out">
								<span class="ml-2 text-sm">24h</span>
							</label>
						</div>
					</div>
					<div class="w-3/6">
						<div *ngIf="!day.closed && !day.allDay" class="periods">
							<div *ngFor="let period of day.periods; let j = index" class="time-period flex items-center space-x-2 mt-2 justify-end">
								<span>{{('pages.hours.from' | translate)}}</span>
								<app-custom-time-input class="time-input"
									[(ngModel)]="period.openTime" 
									[context]="{ dayIndex: j, periodIndex: j, type: 'open' }"
									(timeChange)="onTimeChange($event)"
								></app-custom-time-input>
								<span>{{('pages.hours.to' | translate)}}</span>
								<app-custom-time-input class="time-input"
									[(ngModel)]="period.closeTime" 
									[context]="{ dayIndex: j, periodIndex: j, type: 'close' }"
									(timeChange)="onTimeChange($event)"
								></app-custom-time-input>
								<div *ngIf="j == 0" (click)="addTimePeriod(day)" class=" text-black flex items-center cursor-pointer">
									<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="h-6 w-6">
										<path stroke-linecap="round" stroke-linejoin="round" d="M12 9v6m3-3H9m12 0a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
									</svg>
								</div>
								<div *ngIf="j != 0" (click)="removeTimePeriod(day, j)" class="ml-2 text-red-500 flex items-center justify-center w-6 h-6 rounded-full bg-gray-200 cursor-pointer">
									<svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
										<path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
									</svg>
								</div>
							</div>
							
						</div>
					</div>
				</div>
			</div>
			<!-- //// -->
			<div class="flex mt-4 justify-center gap-2">
				<div (click)="cancelMoreHourCreation(i)" class="text-neutral-400 bg-gray-200 px-4 py-1 flex items-center rounded-xl cursor-pointer">
				<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-6 m-1">
						<path stroke-linecap="round" stroke-linejoin="round" d="M6 18 18 6M6 6l12 12" />
					</svg>
					{{"generic.cancel" | translate}}
				</div>
				<div (click)="createNewMoreHour(i)" class="bg-cliking_blue text-white px-4 py-1 flex items-center rounded-xl cursor-pointer">
					<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-6 m-1">
						<path stroke-linecap="round" stroke-linejoin="round" d="m4.5 12.75 6 6 9-13.5" />
					</svg>
					{{"generic.confirm" | translate}}
				</div>
			</div>
		</div>
	</div>


	<!-- EXISTING more hours instances -->
	<div *ngFor="let moreHour of groupedMoreHours; let i = index" class="mb-5 border p-4 rounded-lg bg-zinc-50 text-sm">
		<!-- hour type and Chevron at the top -->
		<div class="flex justify-between items-center cursor-pointer" (click)="toggleForm(i)">
			<div class="flex justify-between items-center"><span class="text-lg font-bold">{{ 'google.hoursType.' + moreHour.hoursTypeId | translate }}</span> </div>
			<svg xmlns="http://www.w3.org/2000/svg" [ngClass]="{'rotate-180': expandedIndex === i}" class="h-6 w-6 transition-transform duration-300" fill="none" viewBox="0 0 24 24" stroke="currentColor">
				<path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7" />
			</svg>
		</div>
	
		<!-- The collapsible form -->
		<div *ngIf="expandedIndex === i" class="flex justify-end mt-4 flex-col">

			<!-- //// -->
			<div *ngFor="let day of moreHour.days; let j = index" class="day-hours flex items-start">
				<div class="w-1/6 day-name text-sm mt-[11px] overflow-hidden whitespace-nowrap text-ellipsis">{{ getTranslatedDay(day.day) }}</div>
				<div class="w-1/6 flex items-center mr-1 mt-[11px]">
					<label class="flex items-center">
						<input type="checkbox" [(ngModel)]="day.closed" (change)="toggleClosed(day, i)" class="sr-only">
						<div class="toggle-switch relative inline-flex items-center h-6 rounded-full w-11">
							<span [ngClass]="{'bg-cliking_blue': !day.closed, 'bg-gray-400': day.closed}" class="absolute left-0 top-0 bottom-0 right-0 rounded-full transition-colors cursor-pointer"></span>
							<span [ngClass]="{'translate-x-6': !day.closed, 'translate-x-1': day.closed}" class="inline-block w-4 h-4 transform bg-white rounded-full transition-transform cursor-pointer"></span>
						</div>
						<span class="ml-2 text-sm">{{ day.closed ? ('pages.hours.closed' | translate) : ('pages.hours.open' | translate) }}</span>
					</label>
				</div>
				<div class="w-1/6 mt-[11px] items-center">
					<div *ngIf="!day.closed" class="flex justify-center">
						<label class="flex items-center">
							<input type="checkbox" [(ngModel)]="day.allDay" (change)="toggleAllDay(day, i)" class="form-checkbox h-4 w-4 text-blue-600 transition duration-150 ease-in-out">
							<span class="ml-2 text-sm">24h</span>
						</label>
					</div>
				</div>
				<div class="w-3/6">
					<div *ngIf="!day.closed && !day.allDay" class="periods">
						<div *ngFor="let period of day.periods; let j = index" class="time-period flex items-center space-x-2 mt-2 justify-end">
							<span>{{('pages.hours.from' | translate)}}</span>
							<app-custom-time-input class="time-input"
								[(ngModel)]="period.openTime" 
								[context]="{ dayIndex: j, periodIndex: j, type: 'open' }"
								(timeChange)="onTimeChange($event)"
							></app-custom-time-input>
							<span>{{('pages.hours.to' | translate)}}</span>
							<app-custom-time-input class="time-input"
								[(ngModel)]="period.closeTime" 
								[context]="{ dayIndex: j, periodIndex: j, type: 'close' }"
								(timeChange)="onTimeChange($event)"
							></app-custom-time-input>
							<div *ngIf="j == 0" (click)="addTimePeriod(day)" class=" text-black flex items-center cursor-pointer">
								<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="h-6 w-6">
									<path stroke-linecap="round" stroke-linejoin="round" d="M12 9v6m3-3H9m12 0a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
								</svg>
							</div>
							<div *ngIf="j != 0" (click)="removeTimePeriod(day, j)" class="ml-2 text-red-500 flex items-center justify-center w-6 h-6 rounded-full bg-gray-200 cursor-pointer">
								<svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
									<path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
								</svg>
							</div>
						</div>
						
					</div>
				</div>

			</div>

			<div class="flex mt-4 justify-center gap-2">
				<div (click)="removeMoreHour(i)" class="erase-btn bg-gray-200 px-4 py-1 flex items-center rounded-xl cursor-pointer">
					<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-6 m-1">
						<path stroke-linecap="round" stroke-linejoin="round" d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0" />
					</svg>
					{{"generic.delete" | translate}}
				</div>
				<!-- <div (click)="emitChanges()" class="bg-cliking_blue text-white px-4 py-1 flex items-center rounded-xl cursor-pointer">
					<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-6 m-1">
						<path stroke-linecap="round" stroke-linejoin="round" d="m4.5 12.75 6 6 9-13.5" />
					</svg>
					Valider
				</div> -->
			</div>

			<!-- //// -->
			
		</div>


	</div>
</div>


<app-modal 
  [isOpen]="isModalOpen"
  [heading]="heading"
  [subHeading]='subHeading' 
  (closeModal)="closeModal()" 
  [icon]="modalIcon"
	(deleteItem)="deleteSpecialHour()"
></app-modal>