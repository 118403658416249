import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-grid-images-linkedin',
  templateUrl: './grid-images-linkedin.component.html',
  styleUrls: ['./grid-images-linkedin.component.scss']
})
export class GridImagesLinkedinComponent {
  @Input() linkedinMedias: any;
  @Input() screenHeight: number = 0;
  orientation: string ='';
  // linkedinMedias: any[] = [
  //   {
  //       "url": "https://placehold.co/1000x600/000000/FFFFFF",
  //   },
  //   {
  //       "url": "https://placehold.co/600x600/orange/white",
  //   },
  // ];
  
  async ngOnInit(): Promise<void> {
		// console.log("this.linkedinMedias", this.linkedinMedias);

    const orientationPromises = this.linkedinMedias.map((item: any) => this.setOrientation(item));
    try {
      await Promise.all(orientationPromises);
      this.orientation = this.type();
    } catch (error) {
      console.error('Error while setting orientations:', error);
    }
  }

  checkImageOrientation(imageUrl: string): Promise<[string, number]> {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.src = imageUrl;
      img.onload = () => {
        const isLandscape = img.width > img.height;
        if (isLandscape) {
          resolve(["landscape",img.width]);
        } else {
          resolve(["portrait",img.width]);
        }
      };
      img.onerror = (error) => {
        reject("Error loading image");
      };
    });
  }

  async setOrientation(image: any): Promise<void> {
    try {
      let result  = await this.checkImageOrientation(image.url);
      image.orientation = result[0]
      image.width = result[1]
    } catch (error) {
      console.error('Error while loading the image:', error);
    }
  }

  type(){
    if(this.linkedinMedias.length==2){
      this.linkedinMedias[0].width = this.linkedinMedias[0].width * 100 / (this.linkedinMedias[1].width +  this.linkedinMedias[0].width);
      this.linkedinMedias[0].width = this.linkedinMedias[0].width < (200/3) ? this.linkedinMedias[0].width   : (200/3) ;
    }
    if(this.linkedinMedias.length>2){
      if(this.linkedinMedias[0].orientation == 'portrait'  )
        return 'portrait'
      if(this.linkedinMedias[0].orientation == 'landscape'  )
        return 'landscape'
      if(this.linkedinMedias[0].orientation == 'square'  )
        return 'square'
    }
    return ''
  }

  imgContainerHeight(): string{
    if(this.screenHeight !=0){
      return (this.screenHeight<900 ? this.screenHeight*30 /100: this.screenHeight*37.5/100).toString() + 'px'
    }else
      return '320px'
  }

}
