import { Component, Input, Renderer2, ElementRef, ViewChild, SimpleChanges } from '@angular/core';
import { Subscription } from 'rxjs';
import { ScreenSizeService } from 'src/app/services/utilities/screen-size.service';

@Component({
  selector: 'app-tooltip-up',
  templateUrl: './tooltip-up.component.html',
  styleUrls: ['./tooltip-up.component.scss']
})
export class TooltipUpComponent {
  @Input() validationsList:string[] = [];
  @Input() visibilityFlag:boolean = false;
  @Input() msg: string = '';
  tooltipWidth: number = 0;
  screenWidth: number = 0;
  screenHeight: number = 0;

  tooltipLeft: number = 0;
  @ViewChild('tooltip') tooltipElement!: ElementRef;
  constructor(private renderer: Renderer2, private screenSizeService: ScreenSizeService) {}
 subscriptions: Subscription[] =[]
  ngOnInit(){
    this.subscriptions.push(this.screenSizeService.screenWidth$.subscribe((width: number) => this.screenWidth = width));
    this.subscriptions.push(this.screenSizeService.screenHeight$.subscribe((height: number) => this.screenHeight = height));
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['visibilityFlag']) {
      setTimeout(() => {
        // Only try to manipulate the element if it exists
        if (this.tooltipElement) {
          this.applyFadeOutEffect();
        }
      }, 4000);
    }
  }

  applyFadeOutEffect() {
    // Safely apply class if the element is defined
    if (this.tooltipElement && this.tooltipElement.nativeElement) {
      this.renderer.addClass(this.tooltipElement.nativeElement, 'animate-fadeOut');
      setTimeout(() => {
        this.visibilityFlag = false;
      }, 800);
    }
  }
  ngOnDestroy() {
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }
}
