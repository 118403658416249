import { Component, Input } from '@angular/core';
import { Subscription } from 'rxjs';
import { ScreenSizeService } from 'src/app/services/utilities/screen-size.service';

@Component({
  selector: 'app-stats-tooltip',
  templateUrl: './stats-tooltip.component.html',
  styleUrls: ['./stats-tooltip.component.scss']
})
export class StatsTooltipComponent {

  constructor(
    private screenSizeService: ScreenSizeService
  ){}
  @Input() tooltipText: string = ''
  @Input() right: boolean = false
  @Input() left: boolean = false
  @Input() downLeft: boolean = false
  @Input() downRight: boolean = false
  screenWidth: number = 0;
  screenHeight: number = 0;
  subscriptions:Subscription[]=[]
  ngOnInit(){
    this.subscriptions.push(this.screenSizeService.screenWidth$.subscribe((width: number) => this.screenWidth = width));
    this.subscriptions.push(this.screenSizeService.screenHeight$.subscribe((height: number) => this.screenHeight = height));
  }
  ngOnDestroy(){
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }
}
