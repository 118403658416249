<div class="regular-hours space-y-4 m-4 text-sm">
  <div *ngFor="let day of days; let i = index" class="day-hours flex items-start">
    <div class="w-1/6 day-name text-sm mt-[11px] overflow-hidden whitespace-nowrap text-ellipsis">{{ getTranslatedDay(day.day) }}</div>
		<div class="w-1/6 flex items-center mr-1 mt-[11px]">
			<label class="flex items-center">
				<input type="checkbox" [(ngModel)]="day.closed" (change)="toggleClosed(day, i)" class="sr-only">
				<div class="toggle-switch relative inline-flex items-center h-6 rounded-full w-11">
					<span [ngClass]="{'bg-cliking_blue': !day.closed, 'bg-gray-400': day.closed}" class="absolute left-0 top-0 bottom-0 right-0 rounded-full transition-colors cursor-pointer"></span>
					<span [ngClass]="{'translate-x-6': !day.closed, 'translate-x-1': day.closed}" class="inline-block w-4 h-4 transform bg-white rounded-full transition-transform cursor-pointer"></span>
				</div>
				<span class="ml-2 text-sm">{{ day.closed ? ('pages.hours.closed' | translate) : ('pages.hours.open' | translate) }}</span>
			</label>
		</div>
		<div class="w-1/6 mt-[11px] items-center">
			<div *ngIf="!day.closed" class="flex justify-center">
				<label class="flex items-center">
					<input type="checkbox" [(ngModel)]="day.allDay" (change)="toggleAllDay(day, i)" class="form-checkbox h-4 w-4 text-blue-600 transition duration-150 ease-in-out">
					<span class="ml-2 text-sm">24h</span>
				</label>
			</div>
		</div>
    <div class="w-3/6">
      <div *ngIf="!day.closed && !day.allDay" class="periods">
        <div *ngFor="let period of day.periods; let j = index" class="time-period flex items-center space-x-2 mt-2 justify-end">
					<span>{{('pages.hours.from' | translate)}}</span>
          <app-custom-time-input class="time-input"
						[(ngModel)]="period.openTime" 
						[context]="{ dayIndex: days.indexOf(day), periodIndex: j, type: 'open' }"
						(timeChange)="onTimeChange($event)"
					></app-custom-time-input>
          <span>{{('pages.hours.to' | translate)}}</span>
          <app-custom-time-input class="time-input"
						[(ngModel)]="period.closeTime" 
						[context]="{ dayIndex: days.indexOf(day), periodIndex: j, type: 'close' }"
						(timeChange)="onTimeChange($event)"
					></app-custom-time-input>
					<div *ngIf="j == 0" (click)="addTimePeriod(day)" class=" text-black flex items-center cursor-pointer">
						<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="h-6 w-6">
							<path stroke-linecap="round" stroke-linejoin="round" d="M12 9v6m3-3H9m12 0a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
						</svg>
					</div>
          <div *ngIf="j != 0" (click)="removeTimePeriod(day, j)" class="ml-2 text-red-500 flex items-center justify-center w-6 h-6 rounded-full bg-gray-200 cursor-pointer">
            <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
            </svg>
          </div>
        </div>
        
      </div>
    </div>
  </div>
</div>
