import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'extractPostTimeStamp'
})
export class ExtractPostTimeStampPipe implements PipeTransform {

  transform(post: any, typeString: string): string {
    // Check if the post object is valid
    if (!post) {
      return 'Invalid Post';
    }
    // Handle different types of timestamps
    switch (typeString) {
      case 'post':
       return post.publishingDatetime 
      case 'planned':
       return post.expectedPublishingDatetime 
      case 'draft':
       return post.createdAt 
    }
    console.log("⚠️",typeString, post)
    return 'Invalid Timestamp';
  }

}
