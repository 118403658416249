import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-back-office-home',
  templateUrl: './back-office-home.component.html',
  styleUrls: ['./back-office-home.component.scss']
})
export class BackOfficeHomeComponent {
  @Input() user: any;
  @Input() groups: any;
  @Output() selectedGroup: EventEmitter<number> = new EventEmitter()
  favoris: boolean = false;
  general: boolean = false;
  demo: boolean = false;
  all: boolean = false;
  selectedItemId:any;
  displayProperty: string = 'name';
  childrenProperty: string = 'children';

  selectGroup(group: any){
    this.selectedGroup.emit(group)
  }

  allViewState(): boolean{
    return this.favoris || this.general || this.demo || this.all
  }

  disabledViews(){
    this.favoris = false;
    this.general = false;
    this.demo = false;
    this.all = false;
  }

  groupSocilMedia(group: any){
    let groupPlateforms: any = []; 
    if(group.length >0){
      group.forEach((element: any) => {
        if(!groupPlateforms.includes(element.source))
          groupPlateforms.push(element.source)
      });
    }
    return groupPlateforms
  }

  updateSelectedParentGroup(data: any) {
    this.selectedGroup = data;
  }

  toggle(item: any, depth: number): void {
    item.expanded = !item.expanded;
    if (item.expanded && this.hasChildren(item)) {
      item[this.childrenProperty].forEach((child:any) => {
        child.depth = depth + 1;
      });
    }
  }
  
  hasChildren(item: any): boolean {
    return item[this.childrenProperty] && item[this.childrenProperty].length > 0;
  }

  subGroupsLenght(groups: any): number{
    let numberOfsubGroups = 0
    groups.forEach((group: any)=>{
      numberOfsubGroups += group.children.length
      if(group.children.length>0)
        numberOfsubGroups += this.subGroupsLenght(group.children)
    })
    return numberOfsubGroups
  }
}
