<div class="max-h-[70vh]">
  <div
    *ngIf="posts.length > 0"
    class="bg-white rounded-r-3xl flex justify-center h-full"
  >
    <div class="w-[90%] text-sm my-4 pb-4">
      <fieldset class="rounded-t-3xl h-full pb-5" >
				<ng-container>
					<app-social-media-tabs
						class="w-full max-h-[70vh]"	
						[origin]="'show-post'"
						[data]="preparePostDataForPreview()"
						[selectedPagesSources]="selectedPagesSources"
						[socialMediaPagesList]="socialMediaPagesList"
						[googleMediaBrowserContext]="googleMediaBrowserContext"
						[previewFilesList]="previewFilesList"
						[socialMediaPagesList]="socialMediaPagesList"
						[googleBtnValue]="googleBtnValue"
            [calendarView]="true"
						[googleAdditionalData]="null"
					></app-social-media-tabs>
				</ng-container>
      </fieldset>
    </div>
  </div>
  <div
    *ngIf="posts.length == 0"
    class="w-full h-[70vh] bg-white rounded-3xl flex justify-center items-center"
  >
    <span class="text-center text-base text-gray-300">
      {{ "posts.no-data-for-date" | translate }} {{ day }}
    </span>
  </div>
</div>
