import { Component, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, NgForm, Validators } from '@angular/forms';
import { IResponse } from 'src/app/interfaces/IResponse';
import { UserService } from 'src/app/services/repositories/user.service';
import { TranslationService } from 'src/app/services/utilities/translation.service';
import { AuthService } from 'src/app/services/utilities/auth.service';
import Swal from 'sweetalert2';
import { ICON } from 'src/app/interfaces/ModalIcon';
import { BaseComponent } from 'src/app/components/base/base.component';
import { Debug } from 'src/app/utils/debug';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-users-manager',
  templateUrl: './users-manager.component.html',
  styleUrls: ['./users-manager.component.scss'],
})
export class UsersManagerComponent extends BaseComponent {
  user!: any;
  isPasswordModalOpen: boolean = false;
  selectedUserId: number | null = null;
  selectedUserEmail: string | null = null;
  users: IResponse = {
    data: [],
    navigation: {
      page: 0,
      totalPages: 0,
      totalRows: 0,
      itemsPerPage: 0,
    },
    success: false,
    error: '',
    status: 0,
  };
  isModalOpen = false;
  subHeadingMsg: string = '';
  headingMsg: string = '';
  modalIcon: ICON = ICON.success;
  subscriptions: Subscription[] = [];

  constructor(
    translationService: TranslationService,
    private userService: UserService,
    private formBuilder: FormBuilder,
    protected authService: AuthService
  ) {
    super(translationService);
  }
  newUserForm!: FormGroup;

  ngOnInit() {
    this.subscriptions.push(this.authService.loadCurrentUser().subscribe((user: any) => {
      this.user = user.data;
      // Debug.log("This.user:", this.user)
    }));
    this.newUserForm = this.formBuilder.group(
      {
        firstName: ['', [Validators.required, Validators.minLength(3)]],
        lastName: ['', [Validators.required, Validators.minLength(3)]],
        email: ['', [Validators.required, Validators.email]],
        password: ['', [Validators.required, Validators.minLength(6)]],
        confirmPassword: ['', Validators.required],
        isAdmin: [false, Validators.required],
        lang: ['fr', Validators.required]
      },
      {
        validators: this.passwordMatchValidator,
      }
    );
    //get all users
    // Debug.log("loading the users...")
    this.subscriptions.push(this.userService.getUsers().subscribe((res) => {
      this.users = res;
    }));
  }
  passwordMatchValidator(
    formGroup: FormGroup
  ): { [key: string]: boolean } | null {
    const password = formGroup.get('password');
    const confirmPassword = formGroup.get('confirmPassword');
    if (
      password &&
      confirmPassword &&
      password.value !== confirmPassword.value
    ) {
      // Directly setting errors on confirmPassword field
      confirmPassword.setErrors({ passwordMismatch: true });
      return { passwordMismatch: true };
    } else {
      confirmPassword?.setErrors(null); // Important to clear errors if passwords now match
    }
    return null; // Return null if there is no error
  }

  createNewUser() {
    // Debug.log(newUserForm)
    const newUser = { ...this.newUserForm.value };
    Debug.log(newUser);
    this.subscriptions.push(this.userService.createUser(newUser).subscribe({
      next: (res) => {
        Debug.log('User created successfully', res);
        // Handle response
        /*  Swal.fire({
          icon: 'success',
          title:this.translationService.t('user created Successfully'),
          showConfirmButton: false,
          timer: 1500,
        }); */
        this.subscriptions.push(this.userService.getUsers().subscribe());
        this.headingMsg = this.getTranslation(
          'generic.userCreatedSuccessfully'
        );
        this.subHeadingMsg =
          newUser.firstName +
          ' ' +
          newUser.lastName +
          ' ' +
          this.getTranslation('generic.userCreatedSuccessfullyMsg');
        if (newUser.isAdmin) {
          this.subHeadingMsg +=
            ' ' + this.getTranslation('generic.isSuperAdmin');
        }
        this.modalIcon = ICON.success;
        this.isModalOpen = true;
        setTimeout(() => {
          this.close();
        }, 2000);
      },
      error: (err) => {
        console.error('Error creating user', err);
        this.modalIcon = ICON.fail;
        this.headingMsg = this.getTranslation('generic.creationFailure');
        this.subHeadingMsg = this.getTranslation(
          'generic.creationUserFailureMsg'
        );
        this.isModalOpen = true;

        /* Swal.fire({
          icon: 'error',
          title: err,
          showConfirmButton: true,
        }); */
      },
    }));

    //
    this.newUserForm.reset();
  }

  onUpdateUsers() {
   this.subscriptions.push( this.userService
      .getDataByPage(
        this.users.navigation.page,
        this.users.navigation.itemsPerPage
      )
      .subscribe((res) => {
        Debug.log('request the updated changes', res);
        this.users = res;
      }));
  }

  editUser(data: any) {
    Debug.log('editing user', data);
    this.subscriptions.push(this.userService.updateUser(data.item.id, data.item).subscribe());
  }

  deleteUser(data: any) {
    this.subscriptions.push(this.userService.deleteUser(data.item.id).subscribe());
    this.users.data = this.users.data.filter((u: any) => u.id != data.item.id);
    //todo update the accreditations
  }

  //pagination logic
  getUsersFirstPage() {
		if (this.users.navigation.page > 1) {
			this.users.navigation.page = 1; // Go to the first page
			this.onUpdateUsers();
		}
	}
	
	getUsersPreviousPage() {
		if (this.users.navigation.page > 1) {
			this.users.navigation.page = Number(this.users.navigation.page) - 1; // Go to the previous page
			this.onUpdateUsers();
		}
	}
	
	getUsersNextPage() {
		if (this.users.navigation.page < this.users.navigation.totalPages) {
			this.users.navigation.page = Number(this.users.navigation.page) + 1; // Go to the next page
			this.onUpdateUsers();
		}
	}
	
	getUsersLastPage() {
		if (this.users.navigation.page < this.users.navigation.totalPages) {
			this.users.navigation.page = this.users.navigation.totalPages; // Go to the last page
			this.onUpdateUsers();
		}
	}

  openChangePasswordModal(user: any) {
    Debug.log('openChangePasswordModal from users-manager');
    this.selectedUserId = user.id;
    this.selectedUserEmail = user.email;
    this.isPasswordModalOpen = true;
  }

  closeChangePasswordModal() {
    this.selectedUserId = null;
    this.selectedUserEmail = null;
    this.isPasswordModalOpen = false;
  }

  close() {
    this.isModalOpen = false;
  }

  openModal() {
    this.isModalOpen = true;
    this.createNewUser();
  }

  override ngOnDestroy(): void {
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }
}
