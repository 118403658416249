<div class="ml-3 grid grid-rows-1 grid-cols-3 h-16 items-center z-50" [ngClass]="{'h-16': screenHeight>810 && screenHeight !=0, 'h-14' : screenHeight<810 && screenHeight !=0}">
    <span *ngIf="year && !month && !day" class="text-center text-[1.25vw] col-span-1 grid grid-cols-12 row-span-1 text-gray-600 font-semibold"> 
        <span class="col-span-1 cursor-pointer" (click)="prevDate()">{{'<'}}</span>
        <span class="col-span-2 text-center text-[1.25vw]">
            {{year}}
        </span> 
        <span class="col-span-1 cursor-pointer" (click)="nexDate()">{{'>'}}</span>
    </span>
    <span *ngIf="week" class="text-center text-[1.25vw] col-span-1 grid grid-cols-12 row-span-1 text-gray-600 font-semibold"> 
        <span class="col-span-1 cursor-pointer" (click)="prevDate()">{{'<'}}</span>
        <span class="col-span-10 mx-auto" [ngClass]="{'col-span-7':startMonthStr==endMonthStr, 'col-span-8':startMonthStr!=endMonthStr && weekYear==weekStart.getFullYear()}">
            <span>{{weekStart.getDate().toString().padStart(2, '0')}} </span>
            <span *ngIf="startMonthStr!=endMonthStr" (click)="switchToMonthView(weekStart.getMonth())" class="cursor-pointer font-semibold hover:bg-[#e6e6e6] hover:rounded-xl p-1">{{startMonthStr}}.</span>
            <span *ngIf="weekYear!=weekStart.getFullYear()" (click)="switchToYearView(weekStart.getFullYear())"class="cursor-pointer font-semibold hover:bg-[#e6e6e6] hover:rounded-xl p-1">{{' '+weekStart.getFullYear()}}</span>
            -
            <span>{{weekEnd.getDate().toString().padStart(2, '0')}} </span>
            <span (click)="switchToMonthView(weekEnd.getMonth())" class="cursor-pointer font-semibold hover:bg-[#e6e6e6] hover:rounded-xl p-1">{{endMonthStr}}. </span>
            <span (click)="switchToYearView(weekEnd.getFullYear())"class="cursor-pointer font-semibold hover:bg-[#e6e6e6] hover:rounded-xl p-1">{{' '+weekYear}}</span>
        </span> 
        <span class="col-span-1 cursor-pointer" (click)="nexDate()">{{'>'}}</span>
    </span>
    <span *ngIf="month && !day" class="text-center text-[1.25vw] col-span-1 grid grid-cols-12 row-span-1 text-gray-600 font-semibold"> 
        <span class="col-span-1 cursor-pointer" (click)="prevDate()">{{'<'}}</span>
        <span class="col-span-6 mx-auto" >
            <span>{{month}} </span>
            <span class="cursor-pointer hover:bg-[#e6e6e6] hover:rounded-xl p-1" (click)="switchToYearView(year)">{{year}}</span>
        </span>
        <span class="col-span-1 cursor-pointer" (click)="nexDate()">{{'>'}}</span>
    </span>
    <span *ngIf="day && month && year" class="text-center text-[1.25vw] col-span-1 grid grid-cols-12 row-span-1 text-gray-600 font-semibold"> 
        <span class="col-span-1 cursor-pointer" (click)="prevDate()">{{'<'}}</span>
        <span class="col-span-5 mx-auto">
            <span>{{day}} </span>
            <span class="cursor-pointer hover:bg-[#e6e6e6] hover:rounded-xl p-1" (click)="switchToMonthView(month)">{{month}}. </span>
            <span class="cursor-pointer hover:bg-[#e6e6e6] hover:rounded-xl p-1" (click)="switchToYearView(year)">{{year}}</span>
            
        </span>
        <span class="col-span-1 cursor-pointer" (click)="nexDate()">{{'>'}}</span>
    </span>
    <span class="col-span-1 items-center justify-center">
        <button *ngFor="let item of ['dates.timeUnits.year','dates.timeUnits.month','dates.timeUnits.week','dates.timeUnits.day']" class="first-letter:capitalize text-[0.9vw] mx-2 hover:bg-[#f7f8ff] p-2 rounded-xl hover:text-cliking_blue" (click)="onCalendarModeChanged(item)" [ngClass]="{'text-cliking_blue font-semibold bg-[#f7f8ff]':getMode()==item}"> {{item | translate}} </button>
    </span>
    <span class="col-span-1">
        <span class="flex space-x-4 ml-3 relative items-center justify-end mr-3">
            <app-drop-down-list-items 
            [label]="'generic.platform'" 
            [tailWindClasses]="'relative border-2 p-2  text-[0.9vw] z-50 font-semibold rounded-lg'" 
            [items]="['Linkedin','Google','Facebook','Instagram']" (itemToggled)="updatePlatforms($event)"
            [checkedItemsStatus]="currentPlaTformsFilters"
            [calendar]="true"
            />
            <!-- <div class="relative">
                <input
                  [(ngModel)]="searchQuery"
                  type="text" 
                  placeholder="Rechercher..."
                  class="px-5 pl-4 pr-5 w-0 h-9 bg-[#f5f5f5] text-sm border rounded-full focus:outline-none focus:ring-2 focus:ring-cliking_blue transition-all duration-300 ease-in-out"
                  [ngClass]="{'w-36': showInput}"
                  (focus)="showInput = true"
                  (blur)="showInput = false"
                  #inputField
                />
                <svg *ngIf="!showInput" (click)="focusInput(inputField)"
                    class="absolute top-1/2 transform -translate-y-1/2 translate-x-1/2 cursor-pointer text-gray-500"
                     width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M14.4837 12.8608L11.8876 10.2647C11.7705 10.1476 11.6116 10.0825 11.445 10.0825H11.0205C11.7392 9.16328 12.1663 8.00714 12.1663 6.74945C12.1663 3.75756 9.74202 1.33331 6.75012 1.33331C3.75823 1.33331 1.33398 3.75756 1.33398 6.74945C1.33398 9.74135 3.75823 12.1656 6.75012 12.1656C8.00781 12.1656 9.16395 11.7385 10.0831 11.0199V11.4443C10.0831 11.611 10.1482 11.7698 10.2654 11.887L12.8615 14.4831C13.1063 14.7278 13.5021 14.7278 13.7442 14.4831L14.4811 13.7462C14.7259 13.5014 14.7259 13.1056 14.4837 12.8608ZM6.75012 10.0825C4.90916 10.0825 3.41711 8.59302 3.41711 6.74945C3.41711 4.90848 4.90655 3.41644 6.75012 3.41644C8.59109 3.41644 10.0831 4.90588 10.0831 6.74945C10.0831 8.59042 8.59369 10.0825 6.75012 10.0825Z" fill="#6A778B"/>
                </svg>
            </div> -->
            <button class="bg-cliking_blue p-2 ml-6 rounded-lg join" (click)="externLinks('/posts')">
                <span *ngIf="!showInput" href="/posts" class="text-white text-[0.9vw] mr-2 duration-300 ease-in-out">
                    {{'generic.CreateAPost' | translate}}
                </span>
                <span class="my-auto">
                    <svg width="18" height="18" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M12.6001 2.1001C7.0759 2.1001 2.6001 6.5759 2.6001 12.1001C2.6001 17.6243 7.0759 22.1001 12.6001 22.1001C18.1243 22.1001 22.6001 17.6243 22.6001 12.1001C22.6001 6.5759 18.1243 2.1001 12.6001 2.1001ZM18.4065 13.2291C18.4065 13.4953 18.1888 13.713 17.9227 13.713H14.213V17.4227C14.213 17.6888 13.9953 17.9065 13.7291 17.9065H11.4711C11.2049 17.9065 10.9872 17.6888 10.9872 17.4227V13.713H7.27752C7.01139 13.713 6.79365 13.4953 6.79365 13.2291V10.9711C6.79365 10.7049 7.01139 10.4872 7.27752 10.4872H10.9872V6.77752C10.9872 6.51139 11.2049 6.29365 11.4711 6.29365H13.7291C13.9953 6.29365 14.213 6.51139 14.213 6.77752V10.4872H17.9227C18.1888 10.4872 18.4065 10.7049 18.4065 10.9711V13.2291Z" fill="white"/>
                    </svg>
                </span>
            </button>
        </span>
    </span>
</div>