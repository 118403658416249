import {
  Component,
  EventEmitter,
  HostListener,
  Input,
  Output,
  QueryList,
  ViewChildren,
} from '@angular/core';
import { CalenderBaseComponent } from 'src/app/components/base/calendar.component';
import { CALENDERMODE } from 'src/app/interfaces/CalendarMode';
import { PostService } from 'src/app/services/repositories/post.service';
import { CalendarService } from 'src/app/services/utilities/calendar.service';
import { TranslationService } from 'src/app/services/utilities/translation.service';
import { UiStateService } from 'src/app/services/utilities/ui-state.service';
import { PostFullHourPreviewComponent } from '../calendar-week-view/post-full-hour-preview/post-full-hour-preview.component';
import { Debug } from 'src/app/utils/debug';
import { ICON } from 'src/app/interfaces/ModalIcon';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
@Component({
  selector: 'app-calendar-day-view',
  templateUrl: './calendar-day-view.component.html',
  styleUrls: ['./calendar-day-view.component.scss'],
})
export class CalendarDayViewComponent extends CalenderBaseComponent {
  @Input() screenWidth: number = 0;
  @Input() screenHeight: number = 0;
  
  user: any ;
  clikedPost: number = 0;
  selectedPost: any[] = [];
  isLoading: boolean = false;
  subscriptions: Subscription[] = [];

  postFullHourPreviewComponents!: QueryList<PostFullHourPreviewComponent>;
  isClickHandled: boolean = false;
 
  currentDayPosts: any[] = [];
  posts: any[] = [];

  constructor(
    protected override postService: PostService,
    protected override uiStateService: UiStateService,
    protected override translationService: TranslationService,
    protected override calendarService: CalendarService,
    private router: Router,
  ) {
    super(translationService, calendarService, postService, uiStateService);
  }
  override ngOnInit(): void {
    super.ngOnInit();
    this.subscriptions.push(this.groupSubscription = this.postService.currentGroups.subscribe(
      (groups) => {
        this.groupScopes = groups;
        Debug.log('selected groups updated', this.groupScopes);
        if (this.groupScopes.length > 0) {
          this.getDayPosts();
        }
      }
    ));
    this.subscriptions.push(this.uiStateService.platformsFilterUpdated$.subscribe((data) => {
      this.currentPlaTformsFilters =  data;
      this.allOff();
    }));
    
  }

  prevDay() {
    this.navigateBy(-1);
  }

  nextDay() {
    this.navigateBy(1);
  }

  navigateBy(ammount: number) {
    this.calendarService.addDay(ammount);
    this.getDayPosts();
  }

  getDayPosts() {
    this.isLoading = true;
    const lsData = this.postService.getGroupScopesFromLocalStorage();
    this.groupScopes = lsData.length > 0 ? lsData : this.groupScopes;
    if (this.groupScopes.length > 0) {
      let dateStr: string =
        this.currentDate.getFullYear() +
        '-' +
        (this.currentDate.getMonth() + 1) +
        '-' +
        this.currentDate.getDate();
      Debug.log('loading posts day for ' + dateStr + this.groupScopes);
      this.subscriptions.push(this.postService
        .getByTimeFrame(this.groupScopes, dateStr, 'day')
        .subscribe((res) => {
          this.currentDayPosts = res.data;
          this.posts = JSON.parse(JSON.stringify(res.data));
          this.prepareData();
          this.isLoading = false;
        }));
    }
  }

  togglePostFullHourPannelVisibility(posts: any) {
    this.isFullHourPannelVisible = true;
    this.fullHourPannelData = this.sortPostsByTime(posts);
  }

  getDayPostsByHour(hour: number) {
    let hourStr = hour.toString().padStart(2, '0');
    let filtredposts = this.currentDayPosts.filter((post) => {
      let postDate =
        post.expectedPublishingDatetime == null
          ? post.publishingDatetime
          : post.expectedPublishingDatetime;
      let localTime = this.utcToCurrentTimeZone(new Date(postDate));
      let localHour = localTime.split(' ')[1].split(':')[0];
      // Debug.log("postDate",postDate,"localTime",localTime,"UTCH:",hourStr ,"LTZH:",localHour,"same hour",localHour === hourStr)
      if (localHour === hourStr) {
        //Debug.log("👌 local post Time",localTime,"post UTC",postDate,"UTCH:",hourStr ,"LTZH:",localHour,"same hour",localHour === hourStr);
      }
      return localHour === hourStr;
    });
    //  Debug.log(filtredposts,"formatted hour",hourStr,"row hour",hour)
    return filtredposts;
  }

  createUniqueKey(post: any, excludeKeys: string[]): string {
    return Object.entries(post)
      .filter(([key]) => !excludeKeys.includes(key))
      .map(([key, value]) => `${key}:${value}`)
      .join('|');
  }

  getCurrentDayStr() {
    return this.getTranslation('dates.days.fullNumber.' + (this.currentDate.getDay()==0?7:this.currentDate.getDay()));
  }

  getCurrentMonthStr() {
    return this.getTranslation(
      'dates.months.short.' + (this.currentDate.getMonth() + 1)
    );
  }

  generateRange(n: number): number[] {
    return Array.from({ length: n }, (v, k) => k);
  }

  switchToMonthView(month: number): void {
    // Debug.log("switching to month",month)
    this.calendarService.setCurrentMonth(month);
    this.calendarService.setCurrentCalendarMode(CALENDERMODE.MONTH);
  }

  switchToYearView(year: number): void {
    // Debug.log("switching to year",year)
    this.calendarService.setCurrentYear(year);
    this.calendarService.setCurrentCalendarMode(CALENDERMODE.YEAR);
  }

  switchToWeekView() {
    this.calendarService.setCurrentCalendarMode(CALENDERMODE.WEEK);
  }

  setActivePlatforms(platformList:any) {
    const result:any = [];
    for (let platform in platformList) {
      if (platformList[platform]) {
        result.push(
          platform.substring(2, platform.length - 2).toLowerCase()
        );
      }
    }
    return result
  }

  prepareData(){
    let postHourRangeUnique: any[] = [];
    this.selectedPost = [];
    for (let post of this.currentDayPosts) {
      post.groups = [];
      post.platforms = [];
      post.linkedin = false;
      post.google = false;
      post.facebook = false;
      post.instagram = false;
      post.googleMedias = []
      post.linkedinMedias = []
      post.facebookMedias = []
      post.instagramMedias = []
      post.SocialPlatforms.forEach((item: { source: string; medias: any, group: any })=>{
        if (!post.groups.some((group: any) => group.id === item.group.id) && this.groupScopes.includes(item.group.id)){
          post.groups.push({id: item.group.id, name: item.group.name, logo: item.group.logoUrl})
        }
        if(!post.platforms.includes(item.source)){
          post.platforms.push(item.source)
        }
        if(item.source == "linkedin"){
          post.linkedin = true;
          if(item.medias.length>0)
            post.googleMedias = item.medias;
        }
        if(item.source == "google"){
          post.google = true;
          if(item.medias.length>0)
            post.linkedinMedias = item.medias;
        }
       if(item.source == "facebook"){
        post.facebook = true;
        if(item.medias.length>0)
          post.facebookMedias = item.medias;
        }
       if(item.source == "instagram"){
        post.instagram = true;
        if(item.medias.length>0)
          post.instagramMedias = item.medias;
        }
      })
      this.postVisiblity(post, postHourRangeUnique);
      if(post.publishingDatetime){
        post.isPosted = true;
        post.postIsCreated = true;
      }
      if(post.expectedPublishingDatetime && !post.publishingDatetime){
        post.isPlanned = true;
        post.postIsCreated = false;
      }
    }
  }

  clickPost(index: number){
    this.clikedPost = index
    this.selectedPost[0] = this.currentDayPosts[index];
  }

  toDay(): boolean{
    let dateStr =
        this.calendarService.currentDate.getFullYear() +
        '-' +
        (this.calendarService.currentDate.getMonth() + 1) +
        '-' +
        this.calendarService.currentDate.getDate();
    let today = new Date();
    let todayStr = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();
    return dateStr === todayStr;
  }

  override ngOnDestroy() {
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }

  externLinks(link: string){
    window.location.href = link
  }

  getDateTens(): string{
    const today = new Date();
    const currentDay = new Date(this.currentDate);
    today.setHours(0, 0, 0, 0);
    currentDay.setHours(0, 0, 0, 0);

    if (currentDay < today) {
      return 'passed';
    } else if (currentDay > today) {
      return 'futur';
    } else {
      return 'toDay';
    }
  }

  allOff(){
    let postHourRangeUnique: any[] = [];
    this.selectedPost = [];
    this.currentDayPosts.forEach((post:any) => {
      this.postVisiblity(post, postHourRangeUnique);
    })
  }

  postVisiblity(post:any, postHourRangeUnique:any){
    post.isVisible = post.platforms.some((platform:string) => ((platform == 'facebook' && this.currentPlaTformsFilters.isFacebookON) || (platform == 'google' && this.currentPlaTformsFilters.isGoogleON) || (platform == 'linkedin' && this.currentPlaTformsFilters.isLinkedinON) || (platform == 'instagram' && this.currentPlaTformsFilters.isInstagramON)));
    if(post.isVisible){
      const date = new Date(post.publishingDatetime ? post.publishingDatetime : post.expectedPublishingDatetime);
      const hours = String(date.getUTCHours()).padStart(2, '0');
      const minutes = '00';
      if(!postHourRangeUnique.includes(`${hours}:${minutes}`)){
        post.postHourRange = `${hours}:${minutes}`;
        postHourRangeUnique.push(`${hours}:${minutes}`)
      }else
        post.postHourRange = null;
      if(this.selectedPost.length == 0){
        this.selectedPost[0] = post;
      }
    }else
      post.postHourRange = null;
  }
}
