import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-drop-down-list-items',
  templateUrl: './drop-down-list-items.component.html',
  styleUrls: ['./drop-down-list-items.component.scss']
})
export class DropDownListItemsComponent {

  @Input() label:string = '';
  @Input() tailWindClasses:string = '';
  @Input() items:any[] = [];
  @Input() checkedItemsStatus: any;
  @Input() calendar: boolean = false;
  @Output() itemToggled:EventEmitter<any> = new EventEmitter();
  activePlats: number = 0
  ngOnInit():void {
    this.activePlats = Object.values(this.checkedItemsStatus).filter(value => value === true).length;
  }
  toggleItem(item:string){
    console.log(item)
    this.itemToggled.emit(item);
    this.isItemChecked(item)
   
  }
  isItemChecked(item:string){
    //case item is translatable (will be generic.[the actual item name]) postType case
    if(item.includes('.')){
      item=item.split('.')[1]
    }
    //convert item to title case
    item = item.charAt(0).toUpperCase() + item.slice(1).toLowerCase();
    //add string 'is' as prefix
    if(['Google','Facebook','Linkedin','Instagram'].includes(item)) {item=item+"ON"}
    item='is'+item
    //console.log("item",item,'status',this.checkedItemsStatus[item],'object',this.checkedItemsStatus)
    this.activePlats = Object.values(this.checkedItemsStatus).filter(value => value === true).length;
    
    return this.checkedItemsStatus[item]
   
  }
}
