import {
  Component,
  Input,
  OnInit,
  Output,
  EventEmitter,
  ChangeDetectorRef,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Debug } from 'src/app/utils/debug';

interface TimePeriod {
  openTime: string;
  closeTime: string;
}

interface DayHours {
  day: string;
  closed: boolean;
  allDay: boolean;
  periods: TimePeriod[];
}

@Component({
  selector: 'app-regular-hours',
  templateUrl: './regular-hours.component.html',
  styleUrls: ['./regular-hours.component.scss'],
})
export class RegularHoursComponent implements OnInit {
  @Input() regularHours: any;
  @Output() regularHoursChange = new EventEmitter<any>();

  days: DayHours[] = [
    { day: 'MONDAY', closed: true, allDay: false, periods: [] },
    { day: 'TUESDAY', closed: true, allDay: false, periods: [] },
    { day: 'WEDNESDAY', closed: true, allDay: false, periods: [] },
    { day: 'THURSDAY', closed: true, allDay: false, periods: [] },
    { day: 'FRIDAY', closed: true, allDay: false, periods: [] },
    { day: 'SATURDAY', closed: true, allDay: false, periods: [] },
    { day: 'SUNDAY', closed: true, allDay: false, periods: [] },
  ];

  constructor(
    private translate: TranslateService,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnInit() {
    Debug.log(
      'live from app-regular-hours -- Regular Hours: ',
      this.regularHours
    );
    if (this.regularHours) {
      this.initializeHours();
    }
    Debug.log('this.days', this.days);
  }

  initializeHours() {
    this.regularHours.periods.forEach((period: any) => {
      const day = this.days.find((d) => d.day === period.openDay);
      if (day) {
        day.closed = false;
        Debug.log(
          'Object.values period.openTime | period.closeTime',
          Object.values(period.openTime),
          Object.values(period.closeTime)
        );
        if (
          Object.values(period.openTime).length != 0 &&
          Object.values(period.closeTime).length != 0
        ) {
          // if null -> open 24h
          const openTime = this.formatTime(period.openTime);
          const closeTime = this.formatTime(period.closeTime);
          if (openTime == '00:00' && closeTime == '00:00') {
            day.allDay = true;
          } else {
            day.periods.push({
              openTime: openTime,
              closeTime: closeTime,
            });
          }
        } else {
          day.allDay = true;
        }
      }
    });

    Debug.log(this.days);
  }

  formatTime(time: any): string {
    const hours = time.hours ? time.hours.toString().padStart(2, '0') : '00';
    const minutes = time.minutes
      ? time.minutes.toString().padStart(2, '0')
      : '00';
    return `${hours}:${minutes}`;
  }

  addTimePeriod(day: DayHours) {
    day.periods.push({ openTime: '', closeTime: '' });
    this.emitChanges();
  }

  removeTimePeriod(day: DayHours, index: number) {
    day.periods.splice(index, 1);
    this.emitChanges();
  }

  toggleClosed(day: DayHours, i: number) {
    day.closed = !day.closed;
    this.days[i].closed = !this.days[i].closed;

    if (!day.closed && day.periods.length === 0) {
      this.addTimePeriod(day);
    }

    this.cdr.detectChanges();
    this.emitChanges();
  }

  toggleAllDay(day: DayHours, i: number) {
    day.allDay = !day.allDay;
    this.days[i].allDay = !this.days[i].allDay;

    if (!day.allDay && day.periods.length === 0) {
      this.addTimePeriod(day);
    }

    this.cdr.detectChanges();
    this.emitChanges();

    // day.allDay = !day.allDay;
    // if (day.allDay) {
    //   day.periods = [];
    // }
    // this.emitChanges();
  }

  getTranslatedDay(day: string): string {
    return this.translate.instant(`dates.days.full.${day}`);
  }

  onTimeChange(event: any) {
    const { value, context } = event;
    const { dayIndex, periodIndex, type } = context;

    if (type === 'open') {
      this.days[dayIndex].periods[periodIndex].openTime = value;
    } else if (type === 'close') {
      this.days[dayIndex].periods[periodIndex].closeTime = value;
    }

    this.emitChanges();
  }

  emitChanges() {
    // const updatedRegularHours = {
    //   periods: this.days.filter(day => !day.closed).map(day => ({
    //     openDay: day.day,
    //     periods: day.periods
    //   }))
    // };
    // this.regularHoursChange.emit(updatedRegularHours);
    const updatedRegularHours: any = { periods: [] };

    this.days
      .filter((day) => !day.closed)
      .forEach((day: any) => {
        day.periods.forEach((period: any) => {
          // if ((!period.openTime && !period.closeTime) || (period.openTime == "00:00" && period.closeTime == "00:00")) {
          // 	day.allDay = true
          // }
          const openTime = day.allDay
            ? {}
            : this.parseTimeString(period.openTime);
          const closeTime = day.allDay
            ? { hours: 24 }
            : this.parseTimeString(period.closeTime);

          updatedRegularHours.periods.push({
            openDay: day.day,
            openTime: openTime,
            closeDay: day.day,
            closeTime: closeTime,
          });
        });
      });

    this.regularHoursChange.emit(updatedRegularHours);
    // return output;
  }

  parseTimeString(timeString: string): { hours: number; minutes?: number } {
    const [hoursStr, minutesStr] = timeString.split(':');
    const hours = parseInt(hoursStr, 10);
    const minutes = minutesStr ? parseInt(minutesStr, 10) : 0;
    return minutes > 0 ? { hours, minutes } : { hours };
  }
}
