<div class=" pt-1 px-3 bg-white rounded-3xl h-[81.5vh] overflow-y-auto" [style.padding-bottom.px]="paddingPercentage()">
    <app-calendar-filter [screenHeight]="screenHeight" [year]="getCurrentYear()" (previewDate)="prevYear()" (nextDate)="nextYear()"/>
    <div >
        <div class="calender-days grid grid-cols-4 gap-x-8 mx-2 mt-2 h-[68vh]" [ngClass]="{'gap-y-1' : screenHeight<=810, 'gap-y-2' : screenHeight>810}">
            <div class="col-span-1" *ngFor="let m of data">
                <div>
                    <app-month-preview
                    [screenHeight]="screenHeight" [screenWidth]="screenWidth"
                    [month]="m"
                    (switchToMonthView)="switchToMonthView($event)"
                    (daySelected)="showDayPosts($event)"
                    />
                </div>
            </div>
            <div *ngIf="isDayPostsPannelVisible">
                <app-day-posts-preview
                [previewHeight]="calculatedHeight"
                [selectedDate]="selectedDate"
                [xpos]="pannelPositions.x"
                [ypos]="pannelPositions.y"
                [isVisible]="true"
                (visibilityChange)="handleVisibilityChange($event)"
                [user]="user"
                [currentPlaTformsFilters]="currentPlaTformsFilters"
                [groupScopes]="groupScopes"
                />
            </div>
        </div>
    </div>
</div>
<app-loader [isLoading]="isLoading"/>
