import { Component, HostListener, Input } from '@angular/core';
import { CalenderBaseComponent } from 'src/app/components/base/calendar.component';
import { CALENDERMODE } from 'src/app/interfaces/CalendarMode';
import { PostService } from 'src/app/services/repositories/post.service';
import { CalendarService } from 'src/app/services/utilities/calendar.service';
import { TranslationService } from 'src/app/services/utilities/translation.service';
import { UiStateService } from 'src/app/services/utilities/ui-state.service';
import { Debug } from 'src/app/utils/debug';
import { AuthService } from 'src/app/services/utilities/auth.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-calendar-year-view',
  templateUrl: './calendar-year-view.component.html',
  styleUrls: ['./calendar-year-view.component.scss'],
})
export class CalendarYearViewComponent extends CalenderBaseComponent {
  constructor(
    protected override uiStateService: UiStateService,
    protected override postService: PostService,
    protected override translationService: TranslationService,
    protected override calendarService: CalendarService, 
    protected authService: AuthService
  ) {
    super(translationService, calendarService, postService, uiStateService);
  }

  @Input() screenWidth: number = 0;
  @Input() screenHeight: number = 0;
  activePlatforms: any[] = [];
  user: any ;
  isLoading: boolean = false;
  nbOfPostsPerDay: number = 0;
  subscriptions: Subscription[] = [];
  

  calculatedHeight!: number;
  @HostListener('document:keydown.escape', ['$event'])
  onKeydownHandler() {
    this.isDayPostsPannelVisible = false;
  }
  getTody(): Date {
    return this.calendarService.toDay;
  }

  getCurrentYear(): number {
    return this.calendarService.currentDate.getFullYear();
  }

  @HostListener('click', ['$event'])
  onDocumentClick(event: MouseEvent) {
    console.log('event.target', event);
    
    if ((event.target as HTMLElement).classList.contains('day-preview')) {
      let width: number = document.documentElement.clientWidth;
      let height: number = document.documentElement.clientHeight;
      const spaceFromTop = 100;  
      let offsetX: number = width - event.clientX > 550 ? 0 : 500;
      let offsetY: number = height - event.clientY > this.calculatedHeight ? 0 : this.calculatedHeight + 20;
      
      this.pannelPositions.x = event.clientX - offsetX;
      this.pannelPositions.y = event.clientY - offsetY < 0 ? event.clientY - Math.abs(offsetY) / 10 : event.clientY - offsetY;
      
      if (this.pannelPositions.y + this.calculatedHeight > height - spaceFromTop && this.pannelPositions.y !== event.clientY - offsetY) {
        this.pannelPositions.y = height - this.calculatedHeight - spaceFromTop - 10; 
      }
      
      if (this.pannelPositions.y < spaceFromTop) {
        this.pannelPositions.y = spaceFromTop; 
      }
    }
  }

  switchToMonthView(data: any) {
    this.calendarService.setCurrentMonth(data.monthIndex);
    data.year = this.calendarService.currentDate.getFullYear();
    this.calendarService.setCurrentCalendarMode(CALENDERMODE.MONTH);
    Debug.log('switchToMonthView', data, this.mode);
  }

  override ngOnInit(): void {
    this.user = this.authService.loadUserfromLs();

    super.ngOnInit();
    this.subscriptions.push(this.groupSubscription = this.postService.currentGroups.subscribe(
      (groups) => {
        Debug.log('group scopes updated year view', groups);

        this.setYearDates();
        this.setupYearPostIndications();
      }
    ));
    this.subscriptions.push(this.uiStateService.platformsFilterUpdated$.subscribe((data) => {
      this.currentPlaTformsFilters =  data;
      this.setupYearPostIndications();
     }));
  }

  setupYearPostIndications() {
    this.isLoading =  true
    const lsData = this.postService.getGroupScopesFromLocalStorage();
    this.groupScopes = lsData.length > 0 ? lsData : this.groupScopes;
    this.activePlatforms = [...this.setActivePlatforms({...this.currentPlaTformsFilters})]; 
    if (this.groupScopes.length > 0 && this.activePlatforms.length > 0) {
      this.subscriptions.push(this.postService
        .getYearPosts(
          this.calendarService.currentDate.getFullYear(),
          this.groupScopes,
          this.activePlatforms
        )
        .subscribe((data) => {
          this.getPostsToDates(data);
          this.isLoading =  false
        }));
    }else{
      this.isLoading =  false
    }
  }

  override handleVisibilityChange(isVisible: boolean) {
    this.isDayPostsPannelVisible = isVisible;
  }

  selectedDayPosts: any = [];
  selectedDate: any;
  pannelPositions: any = {};
  isDayPostsPannelVisible: boolean = false;
  showDayPosts(res: any) {
    this.isDayPostsPannelVisible = true;
    this.selectedDate = res.date;
    this.nbOfPostsPerDay = res.postNumber;
    if(this.nbOfPostsPerDay == 1)
      this.calculatedHeight = 240
    if(this.nbOfPostsPerDay == 2)
      this.calculatedHeight = 400
    if(this.nbOfPostsPerDay > 2 )
      this.calculatedHeight = 512
    
  }

  prevYear() {
    this.navigateWith(-1);
  }

  nextYear() {
    this.navigateWith(1);
  }

  navigateWith(ammount: number) {
    this.currentYear += ammount;
    this.calendarService.setCurrentYear(this.currentYear);
    this.setYearDates();
    this.setupYearPostIndications();
  }

  setYearDates(): void {
    for (let i = 0; i < 12; i++) {
      this.data[i] = this.getDaysOfMonth(i, this.currentYear);
    }
  }

  getDaysOfMonth(month: number, year: number) {
    function getDaysInMonth(year: number, month: number) {
      return new Date(year, month + 1, 0).getDate();
    }

    const startOfMonth = new Date(year, month, 1);
    const endOfMonth = new Date(year, month, getDaysInMonth(year, month));

    // Obtenir le jour de la semaine du premier jour du mois (0 = dimanche, 1 = lundi, etc.)
    let dayOfWeek = startOfMonth.getDay();

    // Calculer le nombre de jours à soustraire pour revenir au lundi précédent
    let daysToSubtract = (dayOfWeek === 0) ? 6 : dayOfWeek - 1;

    // Créer une nouvelle date pour le début de la semaine (lundi)
    let startOfWeek = new Date(startOfMonth);
    startOfWeek.setDate(startOfMonth.getDate() - daysToSubtract);

    const monthIndex = startOfMonth.getMonth();
    startOfWeek.setDate(startOfWeek.getDate() - startOfWeek.getDay()+1);
    
    // Adjust endOfWeek to the following Saturday
    let endOfWeek = new Date(endOfMonth);
    endOfWeek.setDate(endOfWeek.getDate() + (6 - endOfWeek.getDay()));

    const weeks = [];
    let currentDate = new Date(startOfWeek);

    // Add extra days at the beginning if needed
    if (startOfWeek.getMonth() !== month) {
      for (let i = 0; i < 3; i++) {
        weeks.push({
          day: currentDate.getDate(),
          month: currentDate.getMonth(),
          data: {},
        });
        currentDate.setDate(currentDate.getDate() + 1);
      }
    }

    // Add days of the month and extra days at the end if needed
    while (currentDate <= endOfWeek || weeks.length < 42) {
      weeks.push({
        day: currentDate.getDate(),
        month: currentDate.getMonth(),
        data: {},
      });
      currentDate.setDate(currentDate.getDate() + 1);
    }

    const monthName = new Date(2000, month).toLocaleString('fr-FR', {
      month: 'long',
    });
    return { month: monthName, monthIndex: monthIndex, content: weeks };
  }
  override ngOnDestroy() {
    super.ngOnDestroy();
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }

  paddingPercentage(){
    return this.screenHeight > 700 ? 0 : 15
  }

  onCalendarModeChanged(data: any){
    this.mode = data 
    this.calendarService.setCurrentCalendarMode(this.mode);
  }
     
  updatePlatforms(data: string) {
    if(data.toLowerCase()==='facebook')
      this.currentPlaTformsFilters.isFacebookON = !this.currentPlaTformsFilters.isFacebookON
    if(data.toLowerCase()==='google')
      this.currentPlaTformsFilters.isGoogleON = !this.currentPlaTformsFilters.isGoogleON
    if(data.toLowerCase()==='linkedin')
      this.currentPlaTformsFilters.isLinkedinON = !this.currentPlaTformsFilters.isLinkedinON
    if(data.toLowerCase()==='instagram')
      this.currentPlaTformsFilters.isInstagramON = !this.currentPlaTformsFilters.isInstagramON

    this.uiStateService.updatePlatformsFilter(this.currentPlaTformsFilters);
    this.setupYearPostIndications();
  }

  setActivePlatforms(platformList:any) {
    const result:any = [];
    for (let platform in platformList) {
      if (platformList[platform]) {
        // console.log(platform.substring(2,platform.length-2).toLowerCase());
        result.push(
          platform.substring(2, platform.length - 2).toLowerCase()
        );
      }
    }
    return result
  }

}
