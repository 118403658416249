import {
  Component,
  EventEmitter,
  Input,
  Output,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { CalenderBaseComponent } from '../../base/calendar.component';
import { UiStateService } from 'src/app/services/utilities/ui-state.service';
import { PostService } from 'src/app/services/repositories/post.service';
import { TranslationService } from 'src/app/services/utilities/translation.service';
import { CalendarService } from 'src/app/services/utilities/calendar.service';
import { Debug } from 'src/app/utils/debug';
import { AuthService } from 'src/app/services/utilities/auth.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-show-post',
  templateUrl: './show-post.component.html',
  styleUrls: ['./show-post.component.scss'],
})
export class ShowPostComponent extends CalenderBaseComponent {
  @Input() posts: any;
  @Input() hour: number = 0;
  @Input() day: string = '';
  @Input() screenWidth: number = 0;
  @Input() screenHeight: number = 0;
  currentIndex: number = 0;
  user!: any;
  subscriptions: Subscription[]=[]
  constructor(
    protected override uiStateService: UiStateService,
    protected override postService: PostService,
    protected override translationService: TranslationService,
    protected override calendarService: CalendarService,
    protected authService: AuthService
  ) {
    super(translationService, calendarService, postService, uiStateService);
  }

  override ngOnInit() {
    console.log('this.posts', this.posts);
    
    
   this.subscriptions.push( this.authService.loadCurrentUser().subscribe((user: any) => {
      this.user = user.data;
    }));
  }

  post() {
    return this.posts[this.currentIndex];
  }

  SendPostForUpdate() {
    Debug.log('updating post', this.posts[this.currentIndex]);

    const postJsonStr = JSON.stringify(this.posts[this.currentIndex]);

    const mediaQuery = `/posts?postTemplate=${encodeURIComponent(postJsonStr)}`;

    Debug.log(mediaQuery);
    window.location.href = mediaQuery;
  }

  copyPost() {
    Debug.log('updating post', this.posts[this.currentIndex]);

    const postJsonStr = JSON.stringify(this.posts[this.currentIndex]);

    const mediaQuery = `/posts?useAs=post&postTemplate=${encodeURIComponent(postJsonStr)}`;

    Debug.log(mediaQuery);
    window.location.href = mediaQuery;
  }

	postData:any;
	selectedPagesSources:any;
	socialMediaPagesList:any;
	googleEventOfferStartDate:any;
	googleEventOfferEndDate:any;
	googleMediaBrowserContext:any;
	previewFilesList:any;
	googleBtnValue:any;
	preparePostDataForPreview() {
    
    const post = this.post()
		this.postData = post;
		this.selectedPagesSources = post?.SocialPlatforms;
		this.socialMediaPagesList = post?.SocialPlatforms;
		this.googleEventOfferStartDate = null;
		this.googleEventOfferEndDate = null;
		this.googleMediaBrowserContext = null;
		this.previewFilesList = post?.SocialPlatforms?.[0]?.medias?.filter(
      (media:any) =>
				media?.mimeType?.startsWith('image') || media?.mimeType?.startsWith('photo')
		) || []; 
		this.googleBtnValue = null;
		return post
	}

  override ngOnDestroy(): void {
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }
  
}
