import { Component } from '@angular/core';
import { GroupService } from 'src/app/services/repositories/group.service';
import { UserService } from 'src/app/services/repositories/user.service';
import { PostService } from 'src/app/services/repositories/post.service';
import { AuthService } from '../../../services/utilities/auth.service';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-back-office',
  templateUrl: './back-office.component.html',
  styleUrls: ['./back-office.component.scss']
})
export class BackOfficeComponent {
constructor(
    private groupService: GroupService,
    private userService: UserService,
    private authService: AuthService,
    private postService: PostService,
    private router: Router,
  ) {}
  env = environment.env;
  rootGroups: any = {};
  subscriptions: any = [];
  user: any;
  home: boolean = true;
  groups: boolean = false;
  users: boolean = false;
  profiles: boolean = false;
  mails: boolean = false;
  connectionState: boolean = false;
  devSpace: boolean = false;

  ngOnInit(){
    this.user = this.authService.loadUserfromLs();
    
    this.subscriptions.push(this.groupService.getStructuredGroups().subscribe((res) => {
      console.log(this.authService.loadUserfromLs());
      this.rootGroups = res;
      // Debug.log("group tree",this.rootGroups)
      this.rootGroups.data = this.rootGroups.data.map((g: any) => {
        g.Subgroups = g.children?.length;
        return g;
      });
    }));
  }

  useSelectedGroup(group: any){
    let groupScopes: any = []
    this.postService.saveGroupScopesToLocalStorage([]);
    this.postService.saveAdminGroupToLocalStorage([]);
    // Recursive function to collect all IDs
    const collectGroupIds = (group: any) => {
      groupScopes.push(group.id); // Add the current group ID
      if (group.children && group.children.length > 0) {
        group.children.forEach((child: any) => collectGroupIds(child)); // Recursively add children
      }
    };
    collectGroupIds(group)
    
    this.postService.saveAdminGroupToLocalStorage([...new Set([...group.ancestryIds, ...groupScopes])]);
    this.postService.saveGroupScopesToLocalStorage(groupScopes);
    window.location.href = '/home';
  }

  disabledViews(){
    this.home = false;
    this.groups = false;
    this.users = false;
    this.profiles = false;
    this.mails = false;
    this.connectionState = false;
    this.devSpace = false;
  }

  logout() {
    const token = localStorage.getItem('token') 
    this.subscriptions.push(this.authService.deleteCurrentConnectedUser(token).subscribe())
    if (token) localStorage.removeItem('token');
    localStorage.removeItem(environment.sessionData);
    this.router.navigate(['/login']);
  }

  updateGroups(groups: any){
    this.rootGroups = groups;
  }

}
