      <!-- bulljs -->
      <div>
        <!-- load fetch background jobs as accordian -->

        <div class="join join-vertical overflow-auto w-full">
          <div
            class="collapse collapse-arrow join-item border-base-300 border text-left text-sm"
            *ngFor="let job of jobs; let index = index"
          >
            <input type="checkbox" name="my-accordion-4" checked />
            <div class="collapse-title text-xl font-medium">
              {{ job.label }}
              <span class="badge-primary text-xs px-2 rounded-lg"
                >[{{ job.name }}]
                <span class="text-yellow-400"
                  >last 15 on all categories (and all failed jobs)</span
                >
              </span>
            </div>
            <div class="collapse-content">
              <div
                role="tablist"
                class="tabs tabs-bordered w-full overflow-auto"
              >
                <!-- ACTIVE Jobs -->
                <input
                  type="radio"
                  [name]="'job_categories_' + index"
                  role="tab"
                  class="tab"
                  [attr.aria-label]="'ACTIVE (' + job['ACTIVE'].count + ')'"
                  checked="checked"
                />
                <div
                  role="tabpanel"
                  class="tab-content p-10 w-max-content overflow-x-auto"
                >
                  <button
                    *ngIf="job['ACTIVE'].count > 0"
                    class="btn btn-error mt-2 mb-2"
                    (click)="clearJobs(job.name, 'ACTIVE')"
                  >
                    Clear Active Jobs
                  </button>
                  <ngx-json-viewer
                    class="break-words overflow-x-auto whitespace-pre-wrap"
                    *ngIf="job['ACTIVE'].count > 0"
                    [json]="job['ACTIVE'].jobs"
                  ></ngx-json-viewer>
                </div>

                <!-- WAITING Jobs -->
                <input
                  type="radio"
                  [name]="'job_categories_' + index"
                  role="tab"
                  class="tab"
                  [attr.aria-label]="'WAITING (' + job['WAITING'].count + ')'"
                />
                <div
                  role="tabpanel"
                  class="tab-content p-10 w-max-content overflow-x-auto"
                >
                  <button
                    *ngIf="job['WAITING'].count > 0"
                    class="btn btn-error mt-2 mb-2"
                    (click)="clearJobs(job.name, 'WAITING')"
                  >
                    Clear Waiting Jobs
                  </button>
                  <ngx-json-viewer
                    class="break-words overflow-x-auto whitespace-pre-wrap"
                    *ngIf="job['WAITING'].count > 0"
                    [json]="job['WAITING']?.jobs"
                  ></ngx-json-viewer>
                </div>

                <!-- COMPLETED Jobs -->
                <input
                  type="radio"
                  [name]="'job_categories_' + index"
                  role="tab"
                  class="tab"
                  [attr.aria-label]="
                    'COMPLETED (' + job['COMPLETED'].count + ')'
                  "
                />
                <div
                  role="tabpanel"
                  class="tab-content p-10 w-max-content overflow-x-auto"
                >
                  <button
                    *ngIf="job['COMPLETED'].count > 0"
                    class="btn btn-error mt-2 mb-2"
                    (click)="clearJobs(job.name, 'COMPLETED')"
                  >
                    Clear Completed Jobs
                  </button>
                  <ngx-json-viewer
                    class="break-words overflow-x-auto whitespace-pre-wrap"
                    *ngIf="job['COMPLETED'].count > 0"
                    [json]="job['COMPLETED']?.jobs"
                  ></ngx-json-viewer>
                </div>

                <!-- FAILED Jobs -->
                <input
                  type="radio"
                  [name]="'job_categories_' + index"
                  role="tab"
                  class="tab"
                  [attr.aria-label]="'FAILED (' + job['FAILED'].count + ')'"
                />
                <div
                  role="tabpanel"
                  class="tab-content p-10 w-max-content overflow-x-auto"
                >
                  <button
                    *ngIf="job['FAILED'].count > 0"
                    class="btn btn-error mt-2 mb-2"
                    (click)="clearJobs(job.name, 'FAILED')"
                  >
                    Clear Failed Jobs
                  </button>
                  <ngx-json-viewer
                    class="break-words overflow-x-auto whitespace-pre-wrap"
                    *ngIf="job['FAILED'].count > 0"
                    [json]="job['FAILED']?.jobs"
                  ></ngx-json-viewer>
                </div>
                <!-- DELAYED Jobs -->
                <input
                  type="radio"
                  [name]="'job_categories_' + index"
                  role="tab"
                  class="tab"
                  [attr.aria-label]="'DELAYED (' + job['DELAYED'].count + ')'"
                />
                <div
                  role="tabpanel"
                  class="tab-content p-10 w-max-content overflow-x-auto"
                >
                  <button
                    *ngIf="job['DELAYED'].count > 0"
                    class="btn btn-error mt-2 mb-2"
                    (click)="clearJobs(job.name, 'DELAYED')"
                  >
                    Clear Delayed Jobs
                  </button>
                  <ngx-json-viewer
                    class="break-words overflow-x-auto whitespace-pre-wrap"
                    *ngIf="job['DELAYED'].count > 0"
                    [json]="job['DELAYED']?.jobs"
                  ></ngx-json-viewer>
                </div>

                <!-- PAUSED Jobs -->
                <!-- <input
                  type="radio"
                  [name]="'job_categories_' + index"
                  role="tab"
                  class="tab"
                  [attr.aria-label]="'PAUSED (' + job['PAUSED']?.count + ')'"
                />
                <div role="tabpanel" class="tab-content p-10 w-max-content  overflow-x-auto">
                  <button *ngIf="job['PAUSED'].count > 0" class="btn btn-error mt-2 mb-2" (click)="clearJobs(job.name, 'PAUSED')">
                    Clear Paused Jobs
                  </button>
                  <ngx-json-viewer class="break-words overflow-x-auto whitespace-pre-wrap" *ngIf="job['PAUSED'].count > 0" [json]="job['PAUSED']?.jobs"></ngx-json-viewer>
                </div> -->
              </div>
            </div>
          </div>
        </div>
      </div>