import { Component, EventEmitter, HostListener, Input, Output } from '@angular/core';

@Component({
  selector: 'app-back-office-dropdown',
  templateUrl: './back-office-dropdown.component.html',
  styleUrls: ['./back-office-dropdown.component.scss']
})
export class BackOfficeDropdownComponent {
  @Input() items: any[] = [];           // Liste des éléments à afficher
  @Input() labelField: string | ((item: any) => string) = 'name';
  @Input() valueField: string = '';   // Champ utilisé pour la valeur
  @Input() placeholder: string = 'Rechercher...'; // Placeholder personnalisable
  @Input() css: string = ''; // Placeholder personnalisable

  @Output() itemSelected = new EventEmitter<any>(); // Événement émis lors de la sélection

  filteredItems: any[] = [];
  searchTerm: string = '';
  isOpen: boolean = false;
  selectedItem: any = null;

  ngOnInit() {
    this.filteredItems = [...this.items]; // Initialisation avec tous les éléments
  }

  ngOnChanges() {
    this.filteredItems = [...this.items]; // Met à jour si la liste d'items change
  }

  // Afficher le label en fonction du champ spécifié ou d'une fonction personnalisée
  displayLabel(item: any): string {
    if (typeof this.labelField === 'function') {
      return this.labelField(item);
    }
    if(this.labelField.includes('+++')){
      let labels = this.labelField.split('+++');
      return item[labels[0]] + ' ' + item[labels[1]];
      
    }
    return item[this.labelField] || '';
  }

  // Filtrer les éléments en fonction du terme de recherche
  filterItems() {
    if (!this.searchTerm) {
      this.filteredItems = [...this.items];
    } else {
      this.filteredItems = this.items.filter(item =>
        this.displayLabel(item)
          .toLowerCase()
          .includes(this.searchTerm.toLowerCase())
      );
    }
  }

  // Sélectionner un élément et émettre l'événement
  selectItem(item: any) {
    this.selectedItem = item;
    this.searchTerm = this.displayLabel(item); // Met à jour le champ avec la sélection
    this.isOpen = false;
    
    if(this.valueField !== ''){
      this.itemSelected.emit(item[this.valueField]);
    }else{  
      this.itemSelected.emit(item);
    }
  }

  // Ouvrir/fermer la liste
  toggleDropdown() {
    this.isOpen = !this.isOpen;
    if (this.isOpen) {
      this.filterItems();
    }
  }

  // Fermer la liste si clic en dehors
  @HostListener('document:click', ['$event'])
  onDocumentClick(event: MouseEvent) {
    const target = event.target as HTMLElement;
    if (!target.closest('.relative')) {
      this.isOpen = false;
    }
  }
}
