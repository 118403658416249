
<div class="dropdown z-50">
  <div tabindex="0" role="button" [class]="tailWindClasses">{{label|translate}} 
    <span *ngIf="calendar">({{activePlats}})</span>
    <i class="fa fa-caret-down" [ngClass]="{'ml-2 mr-1': calendar, 'ml-5': !calendar}"></i>
  </div>
  <ul tabindex="0" class="dropdown-content border border-slate-300 menu bg-base-100 rounded-xl z-[1] min-w-full shadow mt-[1px] text-xs" >
    <li *ngFor="let item of items">
      <span class="px-0 flex items-center justify-start gap-1" (click)="toggleItem(item)" [ngClass]="{'text-cliking_blue':isItemChecked(item)}">
        <span *ngIf="isItemChecked(item) && item!=='Linkedin' && item!=='Google' && item!=='Facebook' && item!=='Instagram'">
          <svg width="15" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-check">
            <path d="M20 6 9 17l-5-5"/>
          </svg>
        </span>
        
        <input type="checkbox" *ngIf="item=='Linkedin' || item=='Google' || item=='Facebook' || item=='Instagram'" [checked]="isItemChecked(item)">
        
        <img *ngIf="item=='Linkedin'" class="rounded-full w-4 inline" src="assets/img/simple-logo-linkedin.png" alt="linkedin"/>
        <svg *ngIf="item=='Google'" class="p-1 bg-orange-600 rounded-full" width="17" height="17" viewBox="0 0 48 48" fill="#ffffff">
          <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
          <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
          <g id="SVGRepo_iconCarrier">
            <g id="Layer_2" data-name="Layer 2">
              <g id="invisible_box" data-name="invisible box">
                <rect width="48" height="48" fill="none"></rect>
                <rect width="48" height="48" fill="none"></rect>
              </g>
              <g id="icons_Q2" data-name="icons Q2">
                <path d="M24.7,20.5v7.6H35.6a10.9,10.9,0,0,1-10.9,8,12.1,12.1,0,1,1,7.9-21.3l5.6-5.6A20,20,0,1,0,24.7,44c16.8,0,20.5-15.7,18.9-23.5Z"></path>
              </g>
            </g>
          </g>
        </svg>
        
        <img *ngIf="item=='Facebook'" class="rounded-full w-4 inline" src="assets/img/simple-logo-facebook.png" alt="facebook"/>
        
        <img *ngIf="item=='Instagram'" class="rounded-full w-4 inline" src="assets/img/instagram.svg" alt="instagram"/>
        
        <svg *ngIf="item=='generic.planned' && !isItemChecked(item)" width="18" height="18" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-clock-3">
          <circle cx="12" cy="12" r="10"/>
          <polyline points="12 6 12 12 16.5 12"/>
        </svg>
        
        <svg *ngIf="item=='generic.draft' && !isItemChecked(item)" width="18" height="18" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-circle-dashed">
          <path d="M10.1 2.182a10 10 0 0 1 3.8 0"/>
          <path d="M13.9 21.818a10 10 0 0 1-3.8 0"/>
          <path d="M17.609 3.721a10 10 0 0 1 2.69 2.7"/>
          <path d="M2.182 13.9a10 10 0 0 1 0-3.8"/>
          <path d="M20.279 17.609a10 10 0 0 1-2.7 2.69"/>
          <path d="M21.818 10.1a10 10 0 0 1 0 3.8"/>
          <path d="M3.721 6.391a10 10 0 0 1 2.7-2.69"/>
          <path d="M6.391 20.279a10 10 0 0 1-2.69-2.7"/>
        </svg>
        
        <svg *ngIf="item=='generic.posted' && !isItemChecked(item)" width="18" height="18" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-circle-check">
          <circle cx="12" cy="12" r="10"/>
          <path d="m9 12 2 2 4-4"/>
        </svg>
      
        <span class="text-xs">{{item | translate}}</span>
      </span>
      
    </li>
  </ul>
</div>