import { Component } from '@angular/core';
import { Subscription } from 'rxjs';
import { AuthService } from 'src/app/services/utilities/auth.service';

@Component({
  selector: 'app-connected-users-manager',
  templateUrl: './connected-users-manager.component.html',
  styleUrls: ['./connected-users-manager.component.scss']
})
export class ConnectedUsersManagerComponent {
  connectedUsers: any ;
  subscriptions: Subscription[] = [];
  constructor(
    private authService: AuthService,
  ) {}
  
  ngOnInit(){
    this.subscriptions.push(this.authService.allConnectedUsers().subscribe((res: any)=>{
      this.connectedUsers =  res
      console.log('connectedUsers', this.connectedUsers);
    }))
  }
 
  ngOnDestroy(){
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }
  disconnectUser(data: any) {
  }
}
