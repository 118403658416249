import { Component, ElementRef, EventEmitter, HostListener, Input, Output, Renderer2} from '@angular/core';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { CalendarService } from 'src/app/services/utilities/calendar.service';
import { CalenderBaseComponent } from 'src/app/components/base/calendar.component';
import { PostService } from 'src/app/services/repositories/post.service';
import { TranslationService } from 'src/app/services/utilities/translation.service';
import { UiStateService } from 'src/app/services/utilities/ui-state.service';
import { CALENDERMODE } from 'src/app/interfaces/CalendarMode';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-day-posts-preview',
  templateUrl: './day-posts-preview.component.html',
  styleUrls: ['./day-posts-preview.component.scss'],
  animations: [
		trigger('fadeAnimation', [
      state('true', style({ opacity: 1, display: 'flex' })), // Fully visible
      state('false', style({ opacity: 0, display: 'none' })), // Hidden
      transition('true => false', [animate('1s ease-out')]), // Fades out over 1 second
    ]),
  ],
})
export class DayPostsPreviewComponent extends CalenderBaseComponent{

  @Input() override data: any = [];
  @Input() selectedDayDate: string = '';
  @Input() selectedDate: string = '';
  @Input() posts: any = null;
  date: any;
  @Input() override groupScopes: any;
  @Input() user: any;
  @Input() xpos: number = 0;
  @Input() ypos: number = 0;
  @Input() isVisible: boolean = false;
  private ignoreFirstClick = true;
  @Output() visibilityChange = new EventEmitter<boolean>();
  @Input() previewHeight!: number; // New input for 
  @Input()  override currentPlaTformsFilters: any = {
    isFacebookON: true,
    isLinkedinON: true,
    isGoogleON: true,
    isInstagramON: true,
  };
  insights: boolean = true;
  loadPost: boolean =  false;
	showLoadPostDiv: boolean = false;
  subscriptions: Subscription[] = [];


 constructor(
     protected override uiStateService: UiStateService,
     protected override postService: PostService,
     protected override translationService: TranslationService,
     protected override calendarService: CalendarService,
     private elementRef: ElementRef,
     private renderer: Renderer2
   ) {
     super(translationService, calendarService, postService, uiStateService);
   }

   override ngOnInit(){
    setTimeout(() => {
      this.ignoreFirstClick = false; 
    });
    if(this.selectedDate != ''){
      this.loadPost =  true
      this.date = new Date(this.selectedDate)
  
    this.subscriptions.push(this.postService
          .getByTimeFrame(this.groupScopes, this.selectedDate, 'day')
          .subscribe((res) => {
            if (res.data.length > 0) {
              this.data = res.data
              this.loadPost =  false
            }
          }));
    }else{
      this.date = new Date(this.selectedDayDate)
    }
  }

  switchToDayView(day: Date): void {
    // Debug.log("switching to month",month)
    this.calendarService.setCurrentDate(day);
    this.calendarService.setCurrentCalendarMode(CALENDERMODE.DAY);
  }
 
  switchToMonthView(month: number): void {
    // Debug.log("switching to month",month)
    this.calendarService.setCurrentMonth(month);
    this.calendarService.setCurrentCalendarMode(CALENDERMODE.MONTH);
  }

  switchToYearView(year: number): void {
    // Debug.log("switching to year",year)
    this.calendarService.setCurrentYear(year);
    this.calendarService.setCurrentCalendarMode(CALENDERMODE.YEAR);
  }
  
  close(){
    this.isVisible = false
    this.visibilityChange.emit(this.isVisible)
  }

  getUniquePosts(data: any[]): any[] {
    const uniqueCombinations = new Set();
    const uniquePosts: any[] = [];
  
    for (const item of data) {
      item.title=item.title==""||item.title==undefined?"N/A":item.title
      const combo = `${item.title}-${item.text}-${item.typePost}`;
      if (!uniqueCombinations.has(combo)) {
        uniqueCombinations.add(combo);
        uniquePosts.push(item);
      }
    }
    return uniquePosts;
  }

  getTime(item: any) {
    const date: Date = item.expectedPublishingDatetime == null ? new Date(item.publishingDatetime) : new Date(item.expectedPublishingDatetime);
    return date.getHours().toString().padStart(2, '0') + ':' + date.getMinutes().toString().padStart(2, '0');
  }

  getDate(item: any) {
    const date: Date = item.expectedPublishingDatetime == null ? new Date(item.publishingDatetime) : new Date(item.expectedPublishingDatetime);
    return date.getDate().toString().padStart(2, '0') + ' / ' + (date.getMonth() + 1).toString().padStart(2, '0') + " / " + date.getFullYear();
  }
  override getTranslation(key: string): string {
    return this.translationService.t(key);
  }

  allOff(post: any): boolean{
    let platforms: any[] = [];
    post.SocialPlatforms.forEach((platform: any)=>{
      if(!platforms.includes(platform.source)){
        platforms.push(platform.source)
      }
    })
    return platforms.some((platform:string) => ((platform == 'facebook' && this.currentPlaTformsFilters.isFacebookON) || (platform == 'google' && this.currentPlaTformsFilters.isGoogleON) || (platform == 'linkedin' && this.currentPlaTformsFilters.isLinkedinON) || (platform == 'instagram' && this.currentPlaTformsFilters.isInstagramON)));
  }

  onAnimationDone(event: any): void {
    if (!this.loadPost) {
      this.showLoadPostDiv = false; // Remove the div after fade-out
    }
  }

  @HostListener('document:click', ['$event'])
  onClickOutside(event: MouseEvent): void {
    if (this.ignoreFirstClick) return; 

    if (!this.elementRef.nativeElement.contains(event.target)) {
      this.isVisible = false
      this.visibilityChange.emit(this.isVisible); 
    }
  }

  override ngOnDestroy() {
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }

  displayAs(post: any): string {
		if (post) {
    if (new Date(post.expectedPublishingDatetime) > new Date()) {
				return 'planned';
			} else {
				return 'post';
			}
		} else {
			return ''
		}

	}
}
