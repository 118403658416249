import { Component,
  ElementRef,
  HostListener,
  ViewChild,
  ViewChildren,
  QueryList,
  ChangeDetectorRef,
	Renderer2,
	OnDestroy
} from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { PostService } from '../../../services/repositories/post.service';
import { environment } from 'src/environments/environment';
import { TranslationService } from 'src/app/services/utilities/translation.service';
import Compressor from 'compressorjs';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { firstValueFrom } from 'rxjs';

import { MiniMediaBrowserComponent } from '../mini-media-browser/mini-media-browser.component';
import { SOCIAL_MEDIA_PLATFORMS } from 'src/app/utils/social-media.constant';
import { SocialMediaPlatform } from 'src/app/interfaces/SocialMediaPlatform';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { googlePlatformValidator } from 'src/app/validators/google-postType.validator';
import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import Swal from 'sweetalert2';
import { Router, ActivatedRoute } from '@angular/router';
import { BaseComponent } from '../../base/base.component';
import { DropdownComponent } from '../../dynamic/dropdown/dropdown.component'; // Import the dropdown component
import { Subscription } from 'rxjs';
import { SocialMedia } from 'src/app/interfaces/SocialMedia';
import { GroupService } from 'src/app/services/repositories/group.service';
import { IResponse } from 'src/app/interfaces/IResponse';
import { ICON } from 'src/app/interfaces/ModalIcon';
import { Debug } from 'src/app/utils/debug';
import { sanitizeString } from 'src/app/utils/utils';
import { ScreenSizeService } from 'src/app/services/utilities/screen-size.service';

// interface PostData {
//   typePost: string;
//   text: string;
//   publishingDatetime: Date;
//   category: string;
//   distributor: string;
//   author: string;
//   public: boolean ;
//   title: string;
// }

@Component({
  selector: 'app-create-post',
  templateUrl: './create-post.component.html',
  styleUrls: ['./create-post.component.scss'],
  animations: [
    trigger('fadeInOut', [
      state('in', style({ opacity: 1 })),
      transition(':enter', [
        // ':enter' is alias to 'void => *'
        style({ opacity: 0 }),
        animate(300),
      ]),
      transition(':leave', [
        // ':leave' is alias to '* => void'
        animate(300, style({ opacity: 0 })),
      ]),
    ]),
		trigger('fadeAnimation', [
      state('true', style({ opacity: 1, display: 'flex' })), // Fully visible
      state('false', style({ opacity: 0, display: 'none' })), // Hidden
      transition('true => false', [animate('1s ease-out')]), // Fades out over 1 second
    ]),
  ],
	
})

export class CreatePostComponent extends BaseComponent {
  postForm!: FormGroup;
  private subs = new Subscription();
  screenWidth: number = 0;
  screenHeight: number = 0;
  ratio: string = '';
  aspectRatios = [
    { label: '5:4', value: 5 / 4 },
    { label: '4:3', value: 4 / 3 },
    { label: '3:2', value: 3 / 2 },
    { label: '8:5', value: 8 / 5 },
    { label: '16:9', value: 16 / 9 },
  ];
  tooltipPositions: any;
	@ViewChild(MiniMediaBrowserComponent) miniMediaBrowser!: MiniMediaBrowserComponent;

  SOCIAL_MEDIA_PLATFORMS = SOCIAL_MEDIA_PLATFORMS;

  constructor(
    translationService: TranslationService,
		private sanitizer: DomSanitizer,
    protected postService: PostService,
    private groupService: GroupService,
    private formBuilder: FormBuilder,
    private cdRef: ChangeDetectorRef,
    private router: Router,
		private route: ActivatedRoute,
    private screenSizeService: ScreenSizeService,
		private renderer: Renderer2,
		private elementRef: ElementRef,
  ) {
    super(translationService);
  }
  data: any = [];
  apiUrl: string = environment.apiUrl;
  selectedGroup: Number = 0;
  selectedSocialMedia: Number = 0;
  socialMediaOptions: any[] = [];
  socialMediaPagesList: any[] = [];

  SocialMediaPlatform = SocialMediaPlatform; // Expose the enum to the template
  selectedPlatform: SocialMediaPlatform[] = [];

  googleExists: boolean = false;
  linkedinExists: boolean = false;
  facebookExists: boolean = false;
  instagramExists: boolean = false;
  socialMediaId: number = 0;
  currentSocialMediaPages: any[] = [];
  selectedSocialMediaPage: any;

  isOfferSelected: boolean = false;
  isUpdateSelected: boolean = false;
  isEventSelected: boolean = false;
  typePost: string = 'N/A';

  uploadedImagePath!: string;
  selectedFiles: File[] = [];

  creationStep: number = 0;
  currentSocialMedia: any;
  selectedDate: Date | null = null;
  selectedPagesSources: any = [];

  //google post event or offer settings
  // LocalPostEvent
  googleEventOfferStartDate!: Date;
  googleEventOfferEndDate!: Date;

  selectedLabel: string = 'generic.link'; // Default value
  dynamicAdditionalData: string = '';

  postUrl: any;
  postId: any;
	postData: any;
  postUrlAction: string = '';
	loadPost: boolean = false;
	postAction: string = '';
	showLoadPostDiv: boolean = false;
  urlPostToggledItems: any[] = [];
  toolTipMsgTitle: boolean = false;
  fieldsetColorTitle: boolean = false;
  toolTipMsgPages: boolean = false;
  fieldsetColorPages: boolean = false;
  toolTipMsgPlateforms: boolean = false;
  fieldsetColorPlateforms: boolean = false;
  toolTipMsgGoogle: boolean = false;
  fieldsetColorGoogle: boolean = false;
  toolTipMsg: boolean = false;
  MediaForInstagram: boolean = false;
  fieldsetColorstagram: boolean = false;
  toolTipMsgGoogleMediaInvalid: boolean = false;
  fieldsetColorGoogleMediaInvalid: boolean = false;
  toolTipMsgValidLink: boolean = false;
  msgValidLink: string = '';
  fieldsetColorValidLink: boolean = false;
  toolTipMsgGoogleEventOfferDateValid: boolean = false;
  fieldsetColorGoogleEventOfferDateValid: boolean = false;
  AdditionalDataValidation: string = '';
  fieldsetColorDataValidation: boolean = false;
  facebookLinkedinMessageVideo: boolean = false;
  fieldsetColorMessageVideo: boolean = false;
  toolTipMsgcuponCode: boolean = false;
  addTextOrImageMsg: boolean = false;
  fieldsetColorcuponCode: boolean = false;
  isModalOpen: boolean = false;
  updateModaleOpen: boolean = false;
  modalIcon: ICON = ICON.fail;
  modalIconEdit: ICON = ICON.edit;
  headinsSuccessMsg: string = 'generic.creationFailure';
  subHeadinsSuccessMsg: string = 'generic.creationPostFailureMsg';
  canPressNext: boolean = true;
  elementsPositions: any[] = [];
  toolTiHeights: any[] = [];
  isDraftValidFlag: boolean = false;
	draftSaved: boolean = false;
	draftIsSaving: boolean = false;
	postIsPosting: boolean = false;
	existingMediasList: any[] = [];
	hoveredPlatforms = new Set<string>();

  @ViewChildren('myElement') myElements!: QueryList<ElementRef>;
  @ViewChildren('tooltipElement') tooltipElementts!: QueryList<ElementRef>;
	@ViewChild(DropdownComponent) dropdownComponent!: DropdownComponent;

  groupScopes:number[]=[]
  ngOnInit(): void {
    this.groupScopes = this.postService.getGroupScopesFromLocalStorage()
		// Initialize the post form
		this.initializePostForm();
	
		// Initialize default dates for Google Event Offer
		this.initializeGoogleEventDates();
	
		// Parse URL parameters to determine the context (new, edit, draft, etc.)
		const urlParams = this.getUrlParams();
	
		// Handle asynchronous fetching of post data
		(async () => {
			this.screenSizeService.screenWidth$.subscribe(
				(width: number) => (this.screenWidth = width)
			);
			this.screenSizeService.screenHeight$.subscribe(
				(height: number) => (this.screenHeight = height)
			);
	
			if (this.screenWidth !== 0) {
				this.getClosestAspectRatio();
			}

			if (urlParams.postId) {
				await this.fetchPostData(urlParams.postId, urlParams.useAs);
			} else {
				this.initializeNewPost();
			}
	
			// Execute dependent logic after data is loaded
			this.postService.updateSelectedSources([]);
			this.subscribeToServices();
	
			this.renderer.listen(this.elementRef.nativeElement, 'click', () => {
				this.draftSaved = false;
			});
			this.renderer.listen(this.elementRef.nativeElement, 'keyup', () => {
				this.draftSaved = false;
			});
	
			this.ngAfterViewInit();
		})();
	}
	
	
	private initializePostForm(): void {
		this.postForm = this.formBuilder.group(
			{
				typePost: [(this.typePost || 'STANDARD'), [Validators.required]],
				text: [''],
				title: ['', [Validators.required, Validators.minLength(1)]],
				publishingDatetime: [''],
				expectedPublishingDatetime: [''],
				g_localPostOffer_couponCode: [''],
				g_localPostOffer_redeemOnlineUrl: [''],
				g_localPostOffer_termsConditions: [''],
				g_callToAction_url: [''],
			},
			{ validators: googlePlatformValidator }
		);
	}
	
	private initializeGoogleEventDates(): void {
		this.googleEventOfferStartDate = new Date();
		this.googleEventOfferEndDate = new Date();
		this.googleEventOfferStartDate.setHours(0, 0, 0, 0);
		this.googleEventOfferEndDate.setHours(23, 59, 0, 0);
	}
	
	private getUrlParams(): { postId?: string; useAs?: string } {
		const params = new URLSearchParams(window.location.search);
		this.postAction = params.get('useAs') || ''
		if (this.postAction == "update" || this.postAction == "draft" || this.postAction == "planned")  {
			this.loadPost = true;
		}
		return {
			postId: params.get('postId') || undefined,
			useAs: params.get('useAs') || undefined,
		};
	}
	
	private async fetchPostData(postId: string, useAs?: string): Promise<void> {
		if (true) {
			try {
        console.log("✅✅✅✅",this.groupScopes)
				const res = await firstValueFrom(this.postService.getPostById(postId, false,this.groupScopes));
				if (res) {
					this.postData = res.data;
					this.postUrl = res.data;
		
					if (useAs === 'draft') {
						this.postUrlAction = 'newPostFromDraft';
						this.draftIsSaving = false;
						this.draftSaved = true;
					} else if (useAs === 'planned') {
						this.postUrlAction = 'planned';
					}
		
					this.postUrlAction = this.postUrlAction === '' ? 'update' : this.postUrlAction;
		
					// Set up the form and other properties based on the fetched data
					this.initializePostFormFromData();
					this.loadPost = false;
					setTimeout(() => {
						this.updateModaleOpen = true;
					}, 1000);
					// if (this.isUpdate() && this.hasInstagram()) {
					// }
					// console.log("here: ", this.loadPost)
				}
			} catch (error) {
				console.error('Failed to fetch post data:', error);
			}
		}
	}
	
	onAnimationDone(event: any): void {
    if (!this.loadPost) {
      this.showLoadPostDiv = false; // Remove the div after fade-out
    }
  }
	
	private initializePostFormFromData(): void {
		if (!this.postData) return;
	
		// Update post form with data
		// google specifics
		this.postAdditionalData = this.postService.getFirstAdditionalData(this.postData);
		if (this.postAdditionalData){
			this.onSelectChange(this.postAdditionalData?.callToAction?.actionType)
			if (this.postAdditionalData?.localPostEvent?.schedule) {
				// Extract start and end date-time
				const startDateTime = this.createDateTime(
					this.postAdditionalData?.localPostEvent?.schedule?.startDate,
					this.postAdditionalData?.localPostEvent?.schedule?.startTime
				);

				const endDateTime = this.createDateTime(
					this.postAdditionalData?.localPostEvent?.schedule?.endDate,
					this.postAdditionalData?.localPostEvent?.schedule?.endTime
				);

				// Call update methods
				this.updateGoogleEventOfferDate(startDateTime, 'start');
				this.updateGoogleEventOfferDate(endDateTime, 'end');

				// Extract and call time update separately
				this.updateGoogleEventOfferTime(
					this.formatTime(this.postAdditionalData?.localPostEvent?.schedule?.startTime),
					'start'
				);
				this.updateGoogleEventOfferTime(
					this.formatTime(this.postAdditionalData?.localPostEvent?.schedule?.endTime),
					'end'
				);
			}
		}
		
		// this.postForm.patchValue({
		// 	typePost: this.postData.typePost,
		// 	text: this.postData.text,
		// 	title: this.postData.title,
		// 	publishingDatetime:
		// 		this.postUrlAction === 'update' ? this.postData.publishingDatetime : '',
		// 	expectedPublishingDatetime:
		// 		this.postUrlAction === 'update'
		// 			? this.postData.expectedPublishingDatetime
		// 			: '',
		// 	g_localPostOffer_couponCode: this.postData.g_localPostOffer_couponCode || '',
		// 	g_localPostOffer_redeemOnlineUrl: this.postData.g_localPostOffer_redeemOnlineUrl || '',
		// 	g_localPostOffer_termsConditions: this.postData.g_localPostOffer_termsConditions || '',
		// 	g_callToAction_url: this.postAdditionalData.callToAction.url || '',
		// });

		console.log('Form Values After Patch:', this.postForm.value);
		this.cdRef.detectChanges();
		// Handle media, platforms, and additional data
		this.handleMediaAndPlatforms();
	}

	createDateTime(dateObj: any, timeObj: any): Date {
		if (!dateObj || !timeObj) return new Date(); // Fallback to prevent errors
	
		return new Date(
			dateObj.year,
			dateObj.month - 1, // JavaScript months are 0-based
			dateObj.day,
			timeObj.hours,
			timeObj.minutes,
			timeObj.seconds
		);
	}

	formatTime(timeObj: any): string {
		if (!timeObj) return '00:00'; // Fallback
	
		// Format as HH:mm (e.g., "14:30")
		return `${String(timeObj.hours).padStart(2, '0')}:${String(timeObj.minutes).padStart(2, '0')}`;
	}
	
	postAdditionalData: any;
	private handleMediaAndPlatforms(): void {
		const urlPostImages = this.getUrlPostMedias();
		this.previewFilesList = urlPostImages;
		this.existingMediasList = this.transformMediaData(
			this.postData.SocialPlatforms
		);
	
		this.calculateMediaDimensions();
		// this.updatePlatformsFromPost();

	}
	
	// private getFirstAdditionalData(): any {
	// 	const platformWithAdditionalData = this.postData.SocialPlatforms.find(
	// 		(platform: any) => platform.additionalData
	// 	); // Find the first platform with additionalData
	
	// 	return platformWithAdditionalData ? platformWithAdditionalData.additionalData : null;
	// }

	private initializeNewPost(): void {
		this.postUrlAction = 'newPost';
		this.selectedPagesSources = [];
		this.selectedPlatform = [];
		this.isOfferSelected = false;
		this.isUpdateSelected = false;
		this.isEventSelected = false;
	}
	
	private subscribeToServices(): void {
		this.subs.add(
      this.postService.currentGroups.subscribe((g: number[]) => {
        if (g.length == 0)
          g = this.postService.getGroupScopesFromLocalStorage();
        if (g.length > 0) {
          this.groupService.getGroupByIds(g).subscribe((res: IResponse) => {
            this.socialMediaPagesList = [];
            const GroupSocialMediaTree = this.getRecursiveSocialMediaPages(
              res.data
            );
            // Debug.log('GroupSocialMediaTree', GroupSocialMediaTree);
            //get all social media from groups and subgroups recursively
            if (GroupSocialMediaTree.length > 0) {
              // Initialize all flags to false
              let googleFound = false;
              let linkedinFound = false;
              let facebookFound = false;
              let instagramFound = false;

              // Loop through all social media entries once and update flags
              for (let sm of GroupSocialMediaTree) {
                const source = sm.source.toLowerCase();
                if (source === 'google' && !googleFound) {
                  googleFound = true;
                }
                if (source == 'linkedin' && !linkedinFound) {
                  linkedinFound = true;
                }
                if (source == 'facebook' && !facebookFound) {
                  facebookFound = true;
                }
                if (source == 'instagram' && !instagramFound) {
                  instagramFound = true;
                }
              }

              // Update BehaviorSubjects based on the found flags
              this.postService.googleExists.next(googleFound);
              this.postService.linkedinExists.next(linkedinFound);
              this.postService.facebookExists.next(facebookFound);
              this.postService.instagramExists.next(instagramFound);
            } else {
              // No social media found, set all to false
              this.postService.googleExists.next(false);
              this.postService.linkedinExists.next(false);
              this.postService.facebookExists.next(false);
              this.postService.instagramExists.next(false);
            }
          });
        }
        this.selectedPagesSources = [];

        this.selectedPlatform = [];
        // this.socialMediaPagesList = [];
        // this.currentSocialMediaPages=[]
        this.isOfferSelected =
          this.isEventSelected =
          this.isUpdateSelected =
            false;

				// console.log("this.postUrl check:", this.postUrl)
        if (this.postUrl) {
          this.isOfferSelected = this.postUrl.typePost.toLowerCase() == 'offer';
          this.isUpdateSelected = (this.postUrl.typePost.toLowerCase() == 'update' || this.postUrl.typePost.toLowerCase() == 'standard');
          this.isEventSelected = this.postUrl.typePost.toLowerCase() == 'event';
          this.typePost = this.postUrl.typePost;

					// console.log("this.isUpdateSelected", this.isUpdateSelected)
					// console.log("this.isOfferSelected", this.isOfferSelected)
					// console.log("this.isEventSelected", this.isEventSelected)
          this.postForm.setValue({
            typePost: this.typePost,
            text: this.postUrl.text,
            title: this.postUrl.title,
            publishingDatetime:
              this.postUrlAction == 'update'
                ? this.postUrl.publishingDatetime
                : '',
            expectedPublishingDatetime:
              this.postUrlAction == 'update'
                ? this.postUrl.expectedPublishingDatetime
                : '',
							g_localPostOffer_couponCode: this.postAdditionalData?.localPostOffer?.couponCode || '',
							g_localPostOffer_redeemOnlineUrl: this.postAdditionalData?.localPostOffer?.redeemOnlineUrl || '',
							g_localPostOffer_termsConditions: this.postAdditionalData?.localPostOffer?.termsConditions || '',
							g_callToAction_url: this.postAdditionalData?.callToAction?.url || '',
          });

					// console.log("this.postForm JSON", JSON.stringify(this.postForm.value, null, 2));

					this.detectLinks(this.postUrl.text);
          //update the toggled platforms
          let urlPostPagesPlatforms: string[] = this.getUrlPostSocilaMediPLatforms();
						
						// console.log("this.postForm JSON before selectPlatform", JSON.stringify(this.postForm.value, null, 2));
          for (let url of urlPostPagesPlatforms) {
						this.selectPlatform(url);
          }
					// console.log("this.postForm JSON after selectPlatform", JSON.stringify(this.postForm.value, null, 2));

          //update each selected page fron the page dropdown

          this.urlPostToggledItems = this.postUrl.SocialPlatforms.reduce(
            (accumulator: any, socialPlatform: any) => {
              accumulator[socialPlatform.id] = true;
              return accumulator;
            },
            {}
          );

					this.selectedPagesSources = this.postUrl.SocialPlatforms
					// console.log("this.urlPostToggledItems", this.urlPostToggledItems)
					
          this.postService.updateSelectedSources(this.urlPostToggledItems);
					// console.log("this.selectedPagesSources", this.selectedPagesSources)
					// console.log("this.postUrl.SocialPlatforms", this.postUrl.SocialPlatforms)

        }
      })
    );

	
		this.subs.add(
			this.postService.facebookExists.subscribe((value) => {
				this.facebookExists = value
			})
		);

		this.subs.add(
			this.postService.linkedinExists.subscribe((value) => {
				this.linkedinExists = value
			})
		);

		this.subs.add(
			this.postService.instagramExists.subscribe((value) => {
				this.instagramExists = value
			})
		);

		this.subs.add(
			this.postService.googleExists.subscribe((value) => {
				this.googleExists = value
			})
		);

		this.subs.add(
			this.postService.selectedSocialMedia.subscribe(
				(value) => (this.selectedSocialMedia = value)
			)
		);
		this.subs.add(
			this.postService.currentPickedDate.subscribe(
				(date) => (this.selectedDate = date)
			)
		);

	}
	

  getClosestAspectRatio() {
    const currentRatio = this.screenWidth / this.screenHeight;
    let closestRatio = this.aspectRatios[0];

    this.aspectRatios.forEach((aspectRatio) => {
      const difference = Math.abs(currentRatio - aspectRatio.value);
      if (difference < Math.abs(currentRatio - closestRatio.value)) {
        closestRatio = aspectRatio;
      }
    });
    this.ratio = closestRatio.label;
  }

  getUrlPostSocilaMediPLatforms(): string[] {
    let urlPostPagesPlatforms: string[] = [];
    if (this.postUrl) {

      this.postUrl.SocialPlatforms.forEach((sm: any) => {
        urlPostPagesPlatforms.push(sm.source);

        // if (this.postService.currentGroupSource.getValue().id != sm.group.id) {
        //   sm.group.socialMedia = this.postUrl.SocialPlatforms.map(
        //     ({ group, ...rest }: SocialMedia) => {
        //       return { ...rest };
        //     }
        //   );
        // }
      });
    }

    return Array.from(new Set(urlPostPagesPlatforms));
  }

  getRecursiveSocialMediaPages(groups: any[]): any[] {
    groups = !Array.isArray(groups) ? [groups] : groups;
    let socialMediaPages: any[] = [];
    groups.forEach((group: any) => {
      // Add the group's social media pages if any
      if (group.socialMedia && group.socialMedia.length > 0) {
        socialMediaPages = [...socialMediaPages, ...group.socialMedia];
      }

      // Recursively add social media pages from children
      if (group.children && group.children.length > 0) {
        for (let child of group.children) {
          socialMediaPages = [
            ...socialMediaPages,
            ...this.getRecursiveSocialMediaPages(child),
          ];
        }
      }
    });
    //removing any duplicates
    const pagesArrayString: string[] = socialMediaPages.map((sm: any) =>
      JSON.stringify(sm)
    );
    const uniquePagesArrayString = [...new Set(pagesArrayString)];
    this.socialMediaOptions = uniquePagesArrayString.map((sm: any) =>
      JSON.parse(sm)
    );
    return uniquePagesArrayString.map((sm: any) => JSON.parse(sm));
  }

  getUrlPostMedias() {
    const medias: any[] = [];
    if (this.postUrl) {
      for (let sm of this.postUrl.SocialPlatforms) {
        for (let m of sm.medias) {
          if (medias.filter((ms) => ms.id == m.id).length == 0) {
            medias.push(m);
          }
        }
      }
    }
    return medias;
  }

  override ngOnDestroy() {
    this.groupScopes = this.postService.getGroupScopesFromLocalStorage()
    this.subs.unsubscribe();
  }

  selectPlatform(platform: string, click: boolean = false) {

		// console.log("selectPlatform", platform)
    switch (platform) {
      case 'instagram':
        if (!this.selectedPlatform.includes(SocialMediaPlatform.INSTAGRAM)) {
          this.selectedPlatform.push(SocialMediaPlatform.INSTAGRAM);
        } else {
          this.selectedPlatform = this.selectedPlatform.filter(
            (p: SocialMediaPlatform) => {
              return p !== SocialMediaPlatform.INSTAGRAM;
            }
          );
        }
        break;
      case 'google':
        if (!this.selectedPlatform.includes(SocialMediaPlatform.GOOGLE)) {
          this.selectedPlatform.push(SocialMediaPlatform.GOOGLE);
        } else {
          this.selectedPlatform = this.selectedPlatform.filter(
            (p: SocialMediaPlatform) => {
              return p !== SocialMediaPlatform.GOOGLE;
            }
          );
        }
        break;
      case 'facebook':
        if (!this.selectedPlatform.includes(SocialMediaPlatform.FACEBOOK)) {
          this.selectedPlatform.push(SocialMediaPlatform.FACEBOOK);
        } else {
          this.selectedPlatform = this.selectedPlatform.filter(
            (p: SocialMediaPlatform) => {
              return p !== SocialMediaPlatform.FACEBOOK;
            }
          );
        }
        break;
      case 'linkedin':
        if (!this.selectedPlatform.includes(SocialMediaPlatform.LINKEDIN)) {
          this.selectedPlatform.push(SocialMediaPlatform.LINKEDIN);
        } else {
          this.selectedPlatform = this.selectedPlatform.filter(
            (p: SocialMediaPlatform) => {
              return p !== SocialMediaPlatform.LINKEDIN;
            }
          );
        }
        break;
    }
    // if (!this.selectedPlatform.includes(SocialMediaPlatform.GOOGLE)) {
    //   this.typePost = 'N/A';
    //   this.postForm.patchValue({
    //     typePost: this.typePost,
    //   });
    //   this.isOfferSelected =
    //     this.isEventSelected =
    //     this.isUpdateSelected =
    //       false;
			
		// 	console.log("isUpdateSelected select plateform", this.isUpdateSelected)
    // }

    //update social media pages list
    const groupIds = this.postService.currentGroupsSource.getValue();
    this.groupService.getGroupByIds(groupIds).subscribe((res: any) => {
			
			const recursiveSocialMediaPages = this.getRecursiveSocialMediaPages(res.data).filter((sm: any) => {
        if (this.selectedPlatform.includes(sm.source.toLowerCase())) {
          return sm;
        }
      });

			// Sort by source in the desired order
			const order = ['linkedin', 'google', 'facebook', 'instagram'];
			recursiveSocialMediaPages.sort((a: any, b: any) => {
				return order.indexOf(a.source.toLowerCase()) - order.indexOf(b.source.toLowerCase());
			});

			// if the user clicked on the plateform, we activate select all the platform's pages
			if (click) { 
				const diff = recursiveSocialMediaPages.filter((x: any) => !this.socialMediaPagesList.some((y: any) => y.id === x.id));
				this.selectAllPagesInDropdown(diff)
			}
      this.socialMediaPagesList = recursiveSocialMediaPages
			// console.log("this.socialMediaPagesList", this.socialMediaPagesList)
    });

		
		// console.log("socialMediaPagesList 3", this.socialMediaPagesList)
    //remove any toggledItem
    // this.postService.SyncSelectedPagesWithCurrentSelectedPlatforms(
			//   this.socialMediaPagesList
			// );
			// reset posttype to N/A if google is not selected
			
    this.handlePlatformExclusion(SocialMediaPlatform.GOOGLE);
    this.handlePlatformExclusion(SocialMediaPlatform.FACEBOOK);
    this.handlePlatformExclusion(SocialMediaPlatform.INSTAGRAM);
    this.handlePlatformExclusion(SocialMediaPlatform.LINKEDIN);
		
    this.selectPlatformValidation();
  }

  getPlatformProperty(
    source: string,
    property: keyof (typeof this.SOCIAL_MEDIA_PLATFORMS)[0]
  ): any {
    const platform = this.SOCIAL_MEDIA_PLATFORMS.find(
      (p) => p.source === source
    );
    return platform ? platform[property] : null;
  }

  handlePlatformExclusion(platform: SocialMediaPlatform) {
    let toggledItems = this.postService.toggledItemsSource.getValue();
    if (!this.selectedPlatform.includes(platform)) {
      if (platform == SocialMediaPlatform.GOOGLE) {
				if (!this.postUrl) {
					this.typePost = 'N/A';
					this.postForm.patchValue({ typePost: 'N/A' });
				}
        //remove any toggled item where the id a google page id
        //get google pages ids
        const googlePagesIds = this.selectedPagesSources
          .filter((p: any) => p.source === platform.toLowerCase())
          .map((p: any) => p.id);

        // Loop through each property in toggledItems and delete if the key is in googlePagesIds
        for (const prop in toggledItems) {
          if (googlePagesIds.includes(Number(prop))) {
            delete toggledItems[prop];
          }
        }
      }

      this.selectedPagesSources = this.selectedPagesSources.filter(
        (p: any) => p.source !== platform.toLowerCase()
      );

      let selectedPages = this.postService.selectedSourcesSubject
        .getValue()
        .filter((p: any) => p.source !== platform.toLowerCase());

      const newToggledItems = selectedPages.reduce(
        (acc: { [key: number]: boolean }, s: any) => {
          if (toggledItems[s.id] !== undefined) {
            acc[s.id] = toggledItems[s.id];
          }
          return acc;
        },
        {}
      );

      this.postService.updateToggledItems(newToggledItems);
    }

  }

  switchPostType(button: string) {
    if (this.isGooglePageSelected()) {
      this.isOfferSelected =
        this.isEventSelected =
        this.isUpdateSelected =
          false;
			// console.log("isUpdateSelected switchPostType", this.isUpdateSelected)
      this.typePost = button;
      this.postForm.patchValue({ typePost: button });

      switch (button) {
        case 'offer':
          this.isOfferSelected = true;
          break;
        case 'update':
          this.isUpdateSelected = true;
          break;
        case 'event':
          this.isEventSelected = true;
          break;
      }
    } else {
      this.isOfferSelected =
        this.isEventSelected =
        this.isUpdateSelected =
          false;
    }
    this.postTypeValidation();
  }

  async onFilesSelected(event: any) {
    this.calculateMediaDimensions();
    if (event.target.files) {
      // //   Debug.log("input files", event.target.files);
      // const fileProcessPromises = Array.from(event.target.files).map(
      //   (file: any) => {

			// 		const fileName = sanitizeString(file.name)
			// 		console.log("fileName", fileName)
			// 		console.log("file", file)
      //     return new Promise((resolve, reject) => {
      //       if (file.type.startsWith('image/')) {
      //         // It's an image, apply compression
      //         new Compressor(file, {
      //           quality: 1, // Adjust quality as needed
      //           success: (compressedResult) => {
      //             // Convert the Blob back into a File
      //             const compressedFile = new File(
      //               [compressedResult],
      //               fileName,
      //               {
      //                 type: compressedResult.type,
      //                 lastModified: Date.now(),
      //               }
      //             );
      //             resolve(compressedFile);
      //           },
      //           error: (err) => {
      //             console.error('Compression error:', err);
      //             reject(err);
      //           },
      //         });
      //       } else {
      //         // It's not an image, add the file directly
      //         resolve(file);
      //       }
      //     });
      //   }
      // );

      // Wait for all files to be processed
      // const newFiles = (await Promise.all(fileProcessPromises)) as File[];
      const newFiles = event.target.files as File[];
      this.selectedFiles = [...this.selectedFiles, ...newFiles];
      // Debug.log('selectedFiles', this.selectedFiles);
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.positionTooltip();
    this.ngAfterViewInit();
  }
  @ViewChild('tooltip') tooltipRef!: ElementRef;
  @ViewChild('button') buttonRef!: ElementRef;
  tooltipLeft: number = 0;
  positionTooltip() {
    // Ensure the elements are loaded
    if (this.tooltipRef && this.buttonRef) {
      const buttonEl = this.buttonRef.nativeElement;
      const tooltipEl = this.tooltipRef.nativeElement;

      // Calculate the button's position
      const buttonRect = buttonEl.getBoundingClientRect();

      // Adjust the tooltip's left position to be the same as the button's
      this.tooltipLeft =
        buttonRect.left + buttonRect.width / 2 - tooltipEl.offsetWidth / 2;

    }
  }

  onSubmit(isDraft: boolean = false) {

    // Check if there are files selected for upload

    if (this.selectedFiles && this.selectedFiles.length > 0) {
      const formData = new FormData();

      //exclude any images if post created from a draft or other existing post

      this.selectedFiles.forEach((file, index) => {
        formData.append(`files`, file);
      });

      // If there are files, upload them
      this.postService
        .uploadFile(formData)
        .toPromise()
        .then((response) => {

          this.createPost([this.processUploadResponse(response)], isDraft);
        });
    } else {
      // If there are no files, directly submit the post
      this.createPost([], isDraft);
    }
    //rest UI filters
  }

  previewFilesList: any[] = [];

  previewOn: boolean = false;
  hidePreview() {
    this.previewOn = false;
  }

  isImage(file: any | null): boolean {
		const fileType = file?.type || file?.mimeType
    return fileType?.split('/')[0] === 'image' || fileType?.split('/')[0] === 'photo';
  }

  isVideo(file: any | null): boolean {
		const fileType = file?.type || file?.mimeType
    return fileType?.split('/')[0] === 'video';
  }
  isEmojiPickerOpen: boolean = false;
  justToggledEmojiPicker = false;
  toggleEmojiPicker() {
    this.isEmojiPickerOpen = !this.isEmojiPickerOpen;
    this.justToggledEmojiPicker = true;

    // Reset the flag after a short delay
    setTimeout(() => {
      this.justToggledEmojiPicker = false;
    }, 100);

    // Debug.log('toggleEmojiPicker...');
  }
  @ViewChild('postTextArea') postTextArea!: ElementRef;
  addHashTag() {
    const curreText = this.postForm.get('text')?.value.trim() + ' #';
    this.postForm.patchValue({ text: curreText });
    this.postTextArea.nativeElement.focus();
  }
  insertEmoji(emoji: String) {
    const newTxt = this.postForm.get('text')?.value + emoji;
    this.postForm.patchValue({ text: newTxt });
    this.postTextArea.nativeElement.focus();
    this.isEmojiPickerOpen = false;
  }

  @HostListener('document:click', ['$event'])
  onClick(event: MouseEvent) {
    if (this.justToggledEmojiPicker) {
      // Do nothing if the emoji picker was just toggled
      return;
    }

    const targetElement = event.target as HTMLElement;

    // Check if the click is outside the emoji picker
    if (
      targetElement &&
      !targetElement.closest('app-emoji-picker') &&
      this.isEmojiPickerOpen
    ) {
      // Debug.log("emoji picker is open now");
      this.isEmojiPickerOpen = false;
      // Debug.log("emoji picker is closed now");
    }
  }

  getSlideDimentions(media: any) {
    const url = media.url;

    if (media.type.includes('image/')) {
      const img = new Image();
      img.src = url;
      return img.width + ' x ' + img.height;
    } else if (media.type.includes('video/')) {
      return this.getVideoDuration(media.url).then((duration) => {
        return duration;
      });
    }
    return '';
  }

  calculateMediaDimensions() {
    this.previewFilesList.forEach((slide:any) => {
			const slideType = slide?.type || slide?.mimeType

      if (slideType?.includes('image/')) {
        const img = new Image();
        img.onload = () => {
          slide.dimensions = img.width + ' x ' + img.height;
        };
        img.src = slide.url as string;
      } else if (slideType?.includes('video/')) {
        this.getVideoDuration(slide.url as string).then((duration) => {
          slide.dimensions = this.formatDuration(Math.floor(duration));
        });
      }
    });
  }

	fileThumbnail(slide:any): string{
		const slideType = slide?.type || slide?.mimeType

		return slideType?.includes('image') || slideType?.includes('photo')
		? slide?.url
		: 'assets/img/label-icons/video.png'
	}

  formatDuration(seconds: number): string {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const remainingSeconds = seconds % 60;

    const paddedHours = hours.toString().padStart(2, '0');
    const paddedMinutes = minutes.toString().padStart(2, '0');
    const paddedSeconds = remainingSeconds.toString().padStart(2, '0');

    return `${paddedHours}:${paddedMinutes}:${paddedSeconds}`;
  }

  getVideoDuration(videoUrl: string): Promise<number> {
    return new Promise((resolve, reject) => {
      const video = document.createElement('video');
      video.preload = 'metadata'; // Preload the metadata of the video
      // Set up event listeners
      let isCleanedUp = false; // Flag to indicate if cleanup has been performed
      // Success handler
      const onLoadMetadata = () => {
        if (!isCleanedUp) {
          resolve(video.duration);
          cleanup(); // Clean up after loading metadata
        }
      };

      video.addEventListener('loadedmetadata', onLoadMetadata, false);
      video.src = videoUrl; // Set the video URL to start loading the metadata

      // Clean up function to remove event listeners and the video element
      const cleanup = () => {
        video.remove();
      };
    });
  }

  removeSlide(slide: any) {
		if (slide.id){ // removes a previoulsy loaded media. Remove from existingMediasList
			this.previewFilesList = this.previewFilesList.filter(
				(file) => file.id != slide.id
			);
			const ids = this.previewFilesList.map((file: any) => file.id);
			this.existingMediasList = this.existingMediasList.filter((file: any) =>
				ids.includes(file.id)
			);
		} else { // removes a new media. Remove from selectedFiles
			this.previewFilesList = this.previewFilesList.filter(
				(file) => file.url != slide.url
			);
			const names = this.previewFilesList.map((file: any) => file.name);
			this.selectedFiles = this.selectedFiles.filter((file: any) =>
				names.includes(file.name)
			);
		}
		this.uplodedMediaValidation();
  }

  previewIndex: number = 0;
  previewSlide(media: any) {
    this.previewOn = true;
    this.previewIndex = this.previewFilesList.findIndex(
      (file) => file.url == media.url
    );
  }

  previewFiles(event: Event, mediaType: string = 'all', selectFile: boolean = false): void {

		const element = event.target as HTMLInputElement;
    let files: FileList | null = element.files;
		// console.log("event", event, files)
		// console.log("files", files )
    let validFileTypes: any[] = [];
    if (mediaType == 'images') {
      validFileTypes = ['image/png', 'image/jpg', 'image/jpeg'];
    } else if (mediaType == 'video') {
      validFileTypes = ['video/mp4'];
    } else {
      validFileTypes = ['image/png', 'image/jpg', 'image/jpeg', 'video/mp4'];
    }

    if (files) {
			let i = 0;
      Array.from(files).forEach((file) => {
        // Check if the file type is valid
        const isValidType = validFileTypes.includes(file.type);
        const isValidSize =
          (file.type.startsWith('image') && file.size <= 4 * 1024 * 1024) || // 4MB for images
          (file.type.startsWith('video') &&
            file.size <= 1 * 1024 * 1024 * 1024); // 1GB for videos

				if (isValidType && isValidSize) {
					const url = URL.createObjectURL(file);
					const newFile = {
						name: sanitizeString(file.name),
						originalname: file.name,
						url: url,
						type: file.type,
					}
					this.previewFilesList.push(newFile);

					// console.log("selectFile", selectFile, 'files', files)
					// console.log("currentMediaBrowserContext", this.currentMediaBrowserContext)
			
					if (selectFile && files?.length == 1 && i == 0) {
						// this.miniMediaBrowser.select(files[0]);
						// this.miniMediaBrowser.confirmFile();
						this.isMiniMediaBrowserOpen = true;
						this.currentMediaBrowserContext = this.googleMediaBrowserContext;
						this.miniMediaBrowserMediaType = "photos";
						this.miniMediaBrowserConfirmed({label: "google", data: {selected: newFile, isSkipped: false}})
						this.googleMediaBrowserContext = {
							...this.googleMediaBrowserContext,
							data: { selected: newFile, isSkipped: false },
						};

					}
				} else if (!isValidType) {
					console.warn(`File type ${file.type} is not allowed.`);
				} else if (!isValidSize) {
					console.warn(`File ${file.name} exceeds size limits.`);
				}
				i++;
			});
	
			// Trigger file selection event if valid files were added
			if (this.previewFilesList.length > 0) {
				this.onFilesSelected(event);
			}

			
		}
    this.uplodedMediaValidation();
  }

  getGoogleMediaWarningMessage() {
    let message: string = '';

    // cases:
    // 1 - video , no images -> "no video"
    // 2 - video + 1 image -> "no video"
    // 3 - video + 1+ images -> "only one image (and no video)"
    // 4 - 1+ images -> "only one image"
    // 5 - 1 image, but too small -> "image too small"
    // 6 - 1 image, valid -> no message

    const hasVideo =
      this.postForm.get('typePost')?.value != 'N/A' &&
      this.previewFilesListVideosCount() > 0;
    const imageCount = this.previewFilesListImagesCount();
    const hasImages = imageCount > 0;
    const isSingleImage = imageCount === 1;
    const firstImage = isSingleImage ? this.previewFilesList[0] : null;
    const isImageTooSmall = isSingleImage && this.fileTooSmall(firstImage);

    // Case 1 and 2: No video allowed
    if (hasVideo && !hasImages) {
      message = this.getTranslation('mediaWarnings.googleNoVideos'); // Case 1
    } else if (hasVideo && isSingleImage) {
      message = this.getTranslation('mediaWarnings.googleNoVideos'); // Case 2
    } else if (hasVideo && imageCount > 1) {
      message = this.getTranslation('mediaWarnings.googleOneImageNoVideo'); // Case 3
    }
    // Case 4: More than 1 valid image, no video
    else if (!hasVideo && imageCount > 1) {
      message = this.getTranslation('mediaWarnings.googleOneImage'); // Case 4
    }
    // Case 5: 1 image but too small
    else if (!hasVideo && isSingleImage && isImageTooSmall) {
      message = this.getTranslation('mediaWarnings.googleImageTooSmall'); // Case 5
    }
    //

    return message;
  }

  previewFilesListVideosCount() {
    let videosCount = this.previewFilesList.filter((m: any) => {
      return this.isVideo(m);
    }).length;
    // Debug.log('media',this.previewFilesList,"count",videosCount);
    return videosCount;
  }

  previewFilesListImagesCount() {
    return this.previewFilesList.filter((m: any) => {
      return this.isImage(m);
    }).length;
  }

  clearFiles(): void {
    // Revoke URLs to release memory
    this.previewFilesList.forEach((file) => {
      if (typeof file.url === 'string') {
        URL.revokeObjectURL(file.url);
      }
    });

    // Clear the array
    this.previewFilesList = [];
    this.selectedFiles = [];
  }

  private processUploadResponse(response: any) {
    let imageObjects = [];

    if (response && response.files) {
      imageObjects = response.files.filter((file: any) => file !== undefined);
    }

    // Submit the post form with the image paths
    return imageObjects;
  }

  isMiniMediaBrowserOpen: boolean = false;
  miniMediaBrowserMediaType: string = 'all';
  currentMediaBrowserContext: any = null;
  googleMediaBrowserContext: any = { label: 'google', data: null };
  facebookLinkedinMediaBrowserContext: any = {
    label: 'facebookLinkedin',
    data: null,
  };

  OpenMiniMediaBrowser(
    event: MouseEvent,
    mediaContext: any,
    mediaType: string = 'all'
  ): void {
    event.stopPropagation(); // Prevent click event from bubbling to the document
    this.currentMediaBrowserContext = mediaContext;
		// console.log("mediaContext", mediaContext)
    this.isMiniMediaBrowserOpen = true;
    this.miniMediaBrowserMediaType = mediaType;
  }

  miniMediaBrowserConfirmed(data: any) {
    //update the contex
    if (this.currentMediaBrowserContext.label == 'google') {
      this.googleMediaBrowserContext.data = data;
			this.googleMediaBrowserContext = {
				...this.googleMediaBrowserContext, data
			};
      // Debug.log(this.googleMediaBrowserContext);
    }
    if (this.currentMediaBrowserContext.label == 'facebookLinkedin') {
      this.facebookLinkedinMediaBrowserContext.data = data;
      if (this.facebookLinkedinMediaBrowserContext.data.isSkipped) {
        this.backToMainSelection();
        this.selectMediaType('none');
      }
      // Debug.log(this.facebookLinkedinMediaBrowserContext);
    }
    this.isMiniMediaBrowserOpen = false;
  }

  drop(event: CdkDragDrop<string[]>) {
    // Debug.log('drop', event.previousIndex, event.currentIndex);
    // Debug.log('previewFilesList', this.previewFilesList);
    moveItemInArray(
      this.previewFilesList,
      event.previousIndex,
      event.currentIndex
    );
  }

  isgoogleMediaValid(): boolean {
    return (
      this.previewFilesListVideosCount() == 0 &&
      this.previewFilesList.length == 1
    );
  }
	
  isfacebookLinkedinValid(): boolean {
    return (
      (this.previewFilesListVideosCount() == 0 &&
        this.previewFilesList.length > 0) ||
      (this.previewFilesListVideosCount() == 1 &&
        this.previewFilesList.length == 1)
    );
  }

  private async createPost(uploadedMedia: any, isDraft: boolean = false) {

		// console.log("selectedSources CREATE POST", JSON.stringify(this.selectedPagesSources.map((source:any) => {return {id: source.id, name: source.name}})))

    
		const googleTypePost = this.setTypePost()
		const googleAdditionalData = this.setGoogleAdditionalData(googleTypePost);
    
		// merge the two media arrays together
		const allMeadiasArray = [...(uploadedMedia[0] || []), ...this.existingMediasList]; 

		// sort the medias according to the list in PreviewFileList
		const sortedAllMeadiasArray = this.previewFilesList.map(item => {
			if (item.id) {
				return allMeadiasArray.find((media) => media.id == item.id)
			} else if (item.name){
				return allMeadiasArray.find((media) => (media.originalname == item.name) || (media.originalname == item.originalname))
			}
		})

		// console.log("sortedAllMeadiasArray", sortedAllMeadiasArray)
    if (sortedAllMeadiasArray && sortedAllMeadiasArray.length > 0) {
      for (let media of sortedAllMeadiasArray) {
        media.platforms = [];
        // let mediaName = decodeURIComponent(
        //   media.url.split('-')[media.url.split('-').length - 1]
        // );
        let mediaName = media.originalname;
        // Debug.log(this.selectedFiles)
        for (let socialMedia of this.socialMediaPagesList) {
          switch (socialMedia.source) {
            case 'google':
							const fileType = this.googleMediaBrowserContext.data?.selected?.type || this.googleMediaBrowserContext.data?.selected?.mimeType
                // if(this.googleMediaBrowserContext.data?.selected?.originalname === mediaName){
                //   console.log(this.googleMediaBrowserContext.data?.selected?.originalname,"=",mediaName,"? ",this.googleMediaBrowserContext.data?.selected?.originalname === mediaName)
                //   console.log("this.googleMediaBrowserContext.data.selected",this.googleMediaBrowserContext.data.selected)
                //   console.log("this.googleMediaBrowserContext.data.isSkipped",this.googleMediaBrowserContext.data.isSkipped)
                //   console.log("this.isgoogleMediaValid()",this.isgoogleMediaValid())
                // }
              
              
							if (
									!this.googleMediaBrowserContext.data ||
									!this.googleMediaBrowserContext.data.selected && 
									!this.googleMediaBrowserContext.data.isSkipped // no selected + notskipped means no media choices were made yet. push all medias 
								) {
									media.platforms.push({
                    id: socialMedia.id,
                    source: socialMedia.source,
                  });
							} else if (fileType?.includes('image') || this.isgoogleMediaValid()) {
                if (this.isgoogleMediaValid()) {
                  media.platforms.push({
                    id: socialMedia.id,
                    source: socialMedia.source,
                  });
                } else {
                  if (
                    !this.googleMediaBrowserContext.data.isSkipped &&
                    this.googleMediaBrowserContext.data?.selected?.originalname ==
                      mediaName
                  ) {
                    media.platforms.push({
                      id: socialMedia.id,
                      source: socialMedia.source,
                    });
                  }
                }
              }
              break;
            case 'linkedin':
            case 'facebook':
              if (this.isfacebookLinkedinValid()) {
                media.platforms.push({
                  id: socialMedia.id,
                  source: socialMedia.source,
                });
              } else {
                if (!this.facebookLinkedinMediaBrowserContext.data?.isSkipped) {
									const fileType = this.facebookLinkedinMediaBrowserContext.data?.selected?.type || this.facebookLinkedinMediaBrowserContext.data?.selected?.mimeType
                  if (fileType?.includes('image')) {
                    for (let selectedMedia of this
                      .facebookLinkedinMediaBrowserContext.data.selection) {
                      if (selectedMedia.name == mediaName) {
                        media.platforms.push({
                          id: socialMedia.id,
                          source: socialMedia.source,
                        });
                      }
                    }
                  } else {
                    if (this.facebookLinkedinMediaBrowserContext.data?.selected?.name == mediaName) {
                      media.platforms.push({
                        id: socialMedia.id,
                        source: socialMedia.source,
                      });
                    }
                  }
                }
              }
              break;
            case 'instagram':
              media.platforms.push({
                id: socialMedia.id,
                source: socialMedia.source,
              });
              break;
          }
        }
      }
    } else {
      uploadedMedia = [];
    }
    console.log("sortedAllMeadiasArray",sortedAllMeadiasArray)
    //remove dates if isDraft is true
    if (isDraft) {
      this.postForm.patchValue({ expectedPublishingDatetime: '' });
      this.postForm.patchValue({ publishingDatetime: '' });
    }
    
    
    
    /// alter uploadedMedia by adding 'platform' propety on the image/video opbect
    const postData = {
      ...this.postForm.value,
      typePost: googleTypePost,
      uploadedMedia: sortedAllMeadiasArray.length > 0 ? sortedAllMeadiasArray : [],
      socialMedia: this.selectedPagesSources,
      additionalData: googleAdditionalData,
      isDraft: isDraft,
			link: this.firstLink,
      updateId: this.postUrl ? this.postUrl.id : null, // to edit the post if it exists as draft or old post
    };
    if (!isDraft && this.postUrlAction !== 'update') {
      postData.expectedPublishingDatetime.setHours(
        this.selectedDate?.getHours(),
        this.selectedDate?.getMinutes(),
        this.selectedDate?.getSeconds()
      );
    }
    // this.canPressNext = false;
    
    
		console.log("this.postUrlAction", this.postUrlAction)
    console.log("postData",postData)
    
		
    this.postService.createPost(postData, this.postUrlAction).subscribe({
      next: (res) => {
        // if (!isDraft) { // if the user submited the post, delete the draft
        // 	this.deleteCurrentDraft(); // delete the currently used draft if there's one
        // }
        Debug.log('Post created successfully', res);
        // Handle response
        if (isDraft){
          this.draftIsSaving = false;
          this.draftSaved = true;
        } 

        // check if the post is now or planned
        const now = new Date(); // Get the current date and time
        if (isDraft){
          this.headinsSuccessMsg = this.translationService.t('generic.successCreateDraft');
          this.subHeadinsSuccessMsg = '';
        } else if (this.isUpdate()) { // Planned post
          this.headinsSuccessMsg = this.translationService.t('generic.successEditPost');
          this.subHeadinsSuccessMsg = this.translationService.t('generic.mayTakeMinutes');
        } else if (this.selectedDate && this.selectedDate > now) { // Planned post
          this.headinsSuccessMsg = this.translationService.t('generic.successPlannedPost') + " " + this.dateFormatingPlannedPost(this.selectedDate);
          this.subHeadinsSuccessMsg = '';
        } else { // Instant post
          this.headinsSuccessMsg = this.translationService.t('generic.successCreatePost');
          this.subHeadinsSuccessMsg = this.translationService.t('generic.mayTakeMinutes');
        }
        
        this.modalIcon = ICON.success;
        this.isModalOpen = true;
        setTimeout(() => {
          // if (isDraft) {
          // 	// stays on the page if it's a draft
          // 	this.isModalOpen = false;
          // 	this.close();
          // } else {
          // 	// redirect to library if it's a post
            this.router.navigate(['/library']); // comment to stay on the page after post creation
          // }
        }, 2000);

      },
      error: (err) => {
        console.error('Error creating post', err);
        this.headinsSuccessMsg = 'generic.creationFailure';
        this.subHeadinsSuccessMsg = 'generic.creationDraftFailureMsg';
        this.modalIcon = ICON.fail;
        this.isModalOpen = true;
        this.canPressNext = false;
      },
    });
		
  }

  IsDraftValid(): boolean {
    const selectedPages = Object.keys(
      this.postService.toggledItemsSource.getValue()
    );

    return (
      selectedPages.length > 0 &&
      !!this.postForm.get('title')?.value &&
      (!!this.postForm.get('text')?.value || this.selectedFiles.length > 0)
    );
  }

  saveDraft($event: Event) {
		// console.log("saveDraft call. draftIsSaving: ", this.draftIsSaving);

		if (!this.draftIsSaving) {
			$event.preventDefault();
			this.draftIsSaving = true;
			console.log('saving draft');
			this.postForm.get('expectedPublishingDatetime')?.setValue(null);
			this.postForm.get('publishingDatetime')?.setValue(null);
			this.onSubmit(true);
		}
  }

  isGooglePageSelected() {
		// if (this.postUrlAction == 'newPostFromDraft') {
    //   this.selectedPagesSources = this.postUrl.SocialPlatforms;
    // }
		
    this.postService.updateSelectedSources(this.selectedPagesSources);
    // const previousGooglePageIfAny =
    //   this.postUrl && this.getUrlPostSocilaMediPLatforms().includes('google');
    const currentGooglePagesIfAny =
      this.selectedPagesSources &&
      this.selectedPagesSources.length > 0 &&
      this.selectedPagesSources.filter((p: any) => p.source == 'google')
        .length > 0;
    const anyGooglePageSelected =
      currentGooglePagesIfAny //|| previousGooglePageIfAny;

    if (!anyGooglePageSelected) {
			
      this.typePost = 'N/A';
      this.postForm.patchValue({
        typePost: this.typePost,
      });
    }

    return anyGooglePageSelected;
  }

  isFacebookPageSelected() {
    return (
      this.selectedPagesSources.filter((p: any) => p.source == 'facebook')
        .length > 0 ||
      (this.postUrl &&
        this.getUrlPostSocilaMediPLatforms().includes('facebook'))
    );
  }
  isLinkedinPageSelected() {
    return (
      this.selectedPagesSources.filter((p: any) => p.source == 'linkedin')
        .length > 0 ||
      (this.postUrl &&
        this.getUrlPostSocilaMediPLatforms().includes('linkedin'))
    );
  }
  isInstagramSelected() {
    return (
      this.selectedPagesSources.filter((p: any) => p.source == 'instagram')
        .length > 0 ||
      (this.postUrl &&
        this.getUrlPostSocilaMediPLatforms().includes('instagram'))
    );
  }

  isThereAnyGoogleMediaIssue(): boolean {
		if (this.isGooglePageSelected()) {
			if (this.isUpdate()) return false;
      // valid if there is no media
      if (this.previewFilesList.length === 0) return false;
      // valid if there is one image in previewFilesList
      if (
        this.previewFilesList.length == 1 &&
        (this.previewFilesList[0].type?.includes('image') || this.previewFilesList[0].mimeType?.includes('image')) &&
        !this.fileTooSmall(this.previewFilesList[0])
      )
        return false;
      // if there is many items in previewFilesList on only one image
      if (
        this.previewFilesListImagesCount() == 1 &&
        this.previewFilesList.length > 1
      ) {
        this.googleMediaBrowserContext.data = {
          selected: this.previewFilesList.filter((p: any) =>
            p.type.includes('image')
          )[0],
        };
        return false;
      }
      // if there is multiple images and  google media is either selected or skipped
      if (
        this.previewFilesListImagesCount() > 1 &&
        this.previewFilesList.length > 1 &&
        this.googleMediaBrowserContext.data != null
      )
        return false;

      if (this.googleMediaBrowserContext.data != null) return false;

      return true;
    }
		
    return false;
  }

  isThereAnyFacebookLinkedinIssue(): boolean {
    if (this.isFacebookPageSelected() || this.isLinkedinPageSelected()) {
      if (this.previewFilesList.length === 0) return false;

      if (
        this.previewFilesListVideosCount() == 1 &&
        this.previewFilesList.length == 1
      ) {
        this.facebookLinkedinMediaBrowserContext.data = {
          selected: this.previewFilesList[0],
        };
        return false;
      }

      if (this.previewFilesListImagesCount() == this.previewFilesList.length) {
        return false;
      }

      if (this.facebookLinkedinMediaBrowserContext.data != null) return false;
      return true;
    }
		
    return false;
  }

  async nextStep($event: Event) { 

    const target = $event.target as HTMLElement; // Cast the target to HTMLElement
    this.divPostTextAreaIsExpanded = false;
    if (this.creationStep == 0) {
      if (!this.isStep1Valid()) {
        return;
      }
    }
    if (this.creationStep == 1) {
      if (!await this.isStep2Valid()) {
        return;
      }
    }
    if (this.creationStep == 2) this.canPressNext = false;
    if (
      target.innerHTML
        .toString()
        .includes(this.getTranslation('posts.nextStep'))
    ) {
      //if post type event or update or offer
      //if media is mixed (videos and and photos) and currentStep is not 1
      if (
        (this.typePost != 'N/A' ||
          (this.previewFilesList.length > 1 &&
            this.isPreviewListContainVideos())) &&
        this.creationStep != 1
      ) {
        this.creationStep = 1;
      } else {
        this.creationStep = 2;
      }
    } else if (!this.postIsPosting){
			this.postIsPosting = true;
      if (this.postService.publishNowSource.getValue()) {
        this.postForm.get('publishingDatetime')?.setValue(new Date());
        this.postForm.get('expectedPublishingDatetime')?.setValue(new Date());
      } else {
        this.canPressNext = false;

        this.postForm
          .get('expectedPublishingDatetime')
          ?.setValue(this.postService.currentPickedDateSource.getValue());
        this.postForm.get('publishingDatetime')?.setValue(null);
      }
      this.onSubmit();
    }
  }

  isPreviewListContainVideos() {
    for (let vp of this.previewFilesList) {
			const mediaType = vp.type || vp.mimeType
      if (mediaType?.includes('video')) {
        return true;
      }
    }
    return false;
  }

  prevStep() {
    if (
      (this.typePost != 'N/A' ||
        (this.previewFilesList.length > 1 &&
          this.isPreviewListContainVideos())) &&
      this.creationStep != 1
    ) {
      this.creationStep = 1;
    } else this.creationStep = 0;
  }

  getChoosedSocialMediaPages(): any[] {
    const selectedPagesIds = Object.keys(
      this.postService.getSelectedSocialMediaIds()
    );
    return this.socialMediaPagesList.filter((p) =>
      selectedPagesIds.includes(p.id.toString())
    );
  }

  currentPreviewPlatform: number = 0;
  nextSocial() {
    this.currentPreviewPlatform++;
    if (this.currentPreviewPlatform >= this.getChoosedSocialMediaPages().length)
      this.currentPreviewPlatform = 0;
  }

  prevSocial() {
    this.currentPreviewPlatform--;
    if (this.currentPreviewPlatform < 0)
      this.currentPreviewPlatform =
        this.getChoosedSocialMediaPages().length - 1;
  }

  validationToolTips: string[] = [];

  textValidation() {
    if (this.postForm.get('text')?.value === '') {
      this.toolTipMsg = true;
      // this.canPressNext = false;

      setTimeout(() => {
        this.canPressNext = true;
        this.toolTipMsg = false;
      }, 4800);
    } else if (this.postForm.get('text')?.value !== '') {
      this.toolTipMsg = false;
    }
  }

  addTextOrImageValidation() {
    if(this.postForm.get('text')?.value == '' && this.previewFilesList.length == 0){
      this.addTextOrImageMsg = true;
      setTimeout(() => {
        this.addTextOrImageMsg = false;
      }, 4800);
    } else{
      this.addTextOrImageMsg = false;
    }
  }

  titleValidation() {
    if (this.postForm.get('title')?.value === '') {
      this.toolTipMsgTitle = true;
      this.fieldsetColorTitle = true;
      setTimeout(() => {
        this.toolTipMsgTitle = false;
      }, 4800);
    } else if (this.postForm.get('title')?.value !== '') {
      this.fieldsetColorTitle = false;
      this.toolTipMsgTitle = false;
    }
  }

  postTypeValidation(): boolean {

    let postTypeValid: boolean = true;

    if (this.selectedPagesSources.length > 0) {
      const anyGooglePageSelected =
        this.selectedPagesSources.filter((p: any) => p.source == 'google')
          .length > 0;

      if (anyGooglePageSelected) {
        postTypeValid =
          this.postForm.get('typePost')?.value != 'N/A' ? true : false;
      } else {
        this.postForm.get('typePost')?.setValue('N/A');
        this.isOfferSelected =
          this.isEventSelected =
          this.isUpdateSelected =
            false;
      }
			// console.log("isUpdateSelected postTypeValidation", this.isUpdateSelected)

    }

    // if (this.postUrl) {
    //   Debug.log('url post type', this.postUrl);
    //   this.typePost = this.postUrl.typePost;
    //   this.postForm.patchValue({ typePost: this.typePost });
    // }


    if (postTypeValid) {
      this.toolTipMsgGoogle = false;
      this.fieldsetColorGoogle = false;
      return true;
    } else if (!postTypeValid) {
      this.fieldsetColorGoogle = true;
      this.toolTipMsgGoogle = true;
      // this.canPressNext = false;
      setTimeout(() => {
        this.canPressNext = true;
        this.toolTipMsgGoogle = false;
      }, 4800);
      return false;
    }
    return true;
  }

  uplodedMediaValidation(): boolean {
    let uplodedMediaValid: boolean = true;
    let anyInstagramPageSelected = false;
    if (this.selectedPagesSources.length > 0) {
      anyInstagramPageSelected =
        this.postService?.selectedSourcesSubject
          ?.getValue()
          .filter((p: any) => p.source == 'instagram').length > 0;

      uplodedMediaValid =
        anyInstagramPageSelected && this.previewFilesList.length == 0
          ? false
          : true;
    }
    if (uplodedMediaValid && anyInstagramPageSelected) {
      this.MediaForInstagram = false;
      this.fieldsetColorstagram = false;
      return true;
    } else if (!uplodedMediaValid && anyInstagramPageSelected) {
      this.fieldsetColorstagram = true;
      this.MediaForInstagram = true;
      // this.canPressNext = false;
      setTimeout(() => {
        this.canPressNext = true;
        this.MediaForInstagram = false;
      }, 4800);
      return false;
    }
    this.fieldsetColorstagram = false;
    this.MediaForInstagram = false;
    return true;
  }

  selectPagesSourcesValidation() {
    this.toolTipMsgPages = this.selectedPagesSources.length === 0;
    this.fieldsetColorPages = this.selectedPagesSources.length === 0;
    // this.canPressNext = !(this.selectedPagesSources.length === 0);
    if (this.toolTipMsgPages) {
      setTimeout(() => {
        this.canPressNext = true;
        this.toolTipMsgPages = false;
      }, 4800);
    }
  }

  selectPlatformValidation() {
    this.toolTipMsgPlateforms = this.selectedPlatform.length == 0;
    this.fieldsetColorPlateforms = this.selectedPlatform.length == 0;
    // this.canPressNext = !(this.selectedPlatform.length == 0);
    if (this.toolTipMsgPlateforms) {
      setTimeout(() => {
        this.canPressNext = true;
        this.toolTipMsgPlateforms = false;
      }, 4800);
    }
  }

  isStep1Valid(): boolean {
    this.textValidation();
    this.addTextOrImageValidation();
    this.titleValidation();
    this.selectPagesSourcesValidation();
    this.selectPlatformValidation();
    this.postTypeValidation();
    this.uplodedMediaValidation();
    const isValid =
      this.selectedPagesSources.length > 0 &&
      this.postTypeValidation() &&
      this.uplodedMediaValidation() &&
      this.postForm.get('title')?.value != '' &&
     (this.postForm.get('text')?.value != '' || this.previewFilesList.length > 0);
    return isValid;
  }
  showTooltipVisibility: boolean = false;

  showToolTip() {
    this.showTooltipVisibility = true;
  }

  hideToolTip() {
    this.showTooltipVisibility = false;
  }

  async isCurrentStepValid(): Promise<boolean> {
    // Debug.log('currentStep',this.creationStep,this.isStep2Valid());

    return (
      (this.creationStep == 0 && this.isStep1Valid()) ||
      (this.creationStep == 1 && await this.isStep2Valid()) ||
      (this.creationStep == 2 && this.isStep3Valid())
    );
  }

  updateValidationsToolTipContent(
    mediaContext: { data: any },
    message: string,
    isThereIssue: boolean
  ): boolean {
    let result: boolean = true;
    if (mediaContext.data == null && isThereIssue) {
      this.addValidationMsg(message);
      result = false;
      this.showToolTip();
    }
    return result;
  }

  googleMediaInvalid() {}

  async isStep2Valid(): Promise<boolean> {
    let isValid: boolean = false;
    const getGoogleMediaWarningMessage = this.getTranslation(
      'posts.step2Validation.googleMediaInvalid'
    );

    const isGoogleMediaOk = this.updateValidationsToolTipContent(
      this.googleMediaBrowserContext,
      getGoogleMediaWarningMessage,
      this.isThereAnyGoogleMediaIssue()
    );

    const facebookLinkedinMessage = this.getTranslation(
      'posts.step2Validation.facebookLinkedinInvalid'
    );

    const isFacbookLinkedinOk = this.updateValidationsToolTipContent(
      this.facebookLinkedinMediaBrowserContext,
      facebookLinkedinMessage,
      this.isThereAnyFacebookLinkedinIssue()
    );

    //check if postType is event or offer
    let isGoogleEventOfferDateValid: boolean = false;
    const postType = this.postForm.get('typePost')?.value;
    if (postType == 'event' || postType == 'offer') {
      // add validation tip message if not already added
      const startDateInvalidMsg = this.getTranslation(
        'posts.step2Validation.eventOfferStartDateInvalid'
      );
      const endDateInvalidMsg = this.getTranslation(
        'posts.step2Validation.eventOfferEndDateInvalid'
      );
      if (this.googleEventOfferStartDate == null) {
        this.addValidationMsg(startDateInvalidMsg);
        isGoogleEventOfferDateValid = false;
      } else {
        this.removeValidationMsg(startDateInvalidMsg);
        isGoogleEventOfferDateValid = true;
      }

      if (
        this.googleEventOfferEndDate == null ||
        (this.googleEventOfferEndDate &&
          this.googleEventOfferEndDate < this.googleEventOfferStartDate)
      ) {
        // this.addValidationMsg(endDateInvalidMsg);
        this.toolTipMsgGoogleEventOfferDateValid = true;
        this.fieldsetColorGoogleEventOfferDateValid = true;
        isGoogleEventOfferDateValid = false;
        this.canPressNext = false;
        setTimeout(() => {
          this.canPressNext = true;
          this.toolTipMsgGoogleEventOfferDateValid = false;
        }, 4800);
      } else {
        this.fieldsetColorGoogleEventOfferDateValid = false;
        this.toolTipMsgGoogleEventOfferDateValid = false;
        // this.removeValidationMsg(endDateInvalidMsg);
        isGoogleEventOfferDateValid = true;
      }
    } else {
      isGoogleEventOfferDateValid = true;
    }

    if (!isGoogleMediaOk) {
      this.toolTipMsgGoogleMediaInvalid = true;
      this.fieldsetColorGoogleMediaInvalid = true;
      // this.canPressNext = false;
      setTimeout(() => {
        this.canPressNext = true;
        this.toolTipMsgGoogleMediaInvalid = false;
      }, 4800);
    } else {
      this.toolTipMsgGoogleMediaInvalid = false;
      this.fieldsetColorGoogleMediaInvalid = false;
    }

    if (!this.cuponCodeValidation()) {
      return false;
    }

    // Validation du lien (asynchrone)
    const url = this.postForm.get('g_localPostOffer_redeemOnlineUrl')?.value?.trim() || '';
    console.log('url.', url);
    
    const isLinkInvalid = await this.linkValidation(url);
    if (isLinkInvalid && this.postForm.get('g_localPostOffer_redeemOnlineUrl')?.value !== '') {
        return false;
    }

    if (
      !await this.dynamicAdditionalDataValidation() 
    ) {
      return false;
    }

    if (!isFacbookLinkedinOk) {
      this.facebookLinkedinMessageVideo = true;
      this.canPressNext = false;
      setTimeout(() => {
        this.facebookLinkedinMessageVideo = false;
        this.canPressNext = true;
      }, 4800);
    } else {
      this.facebookLinkedinMessageVideo = false;
    }

    if (
      !isGoogleMediaOk ||
      !isFacbookLinkedinOk ||
      !isGoogleEventOfferDateValid
    ) {
      isValid = false;
      this.showToolTip();
    } else {
      isValid = true;
      this.validationToolTips = [];
      this.hideToolTip();
    }
    return isValid;
  }

  isStep3Valid(): boolean {
    return this.creationStep == 2 && this.postForm.valid;
  }

  isDatePicked(): boolean {
    return !this.postService.publishNow;
  }

  publishNow(): boolean {
    return this.postService.publishNowSource.getValue();
  }

  SetPublishNow() {
    if(this.postService.publishNowSource.getValue()){
      this.postService.publishNowSource.next(false);
      const now = new Date();
      this.postService.updatePickedDate(now);
    } else {
      this.postService.updatePickedDate(null);
    }
  }

  onSelectChange(value: string): void {
    // Update the selectedLabel based on the value
    if (value === 'CALL') {
      this.selectedLabel = 'generic.phone';
    } else if (value) {
      this.selectedLabel = 'generic.link';
    }
    this.dynamicAdditionalData = value;
    // this.dynamicAdditionalDataValidation();
  }

  toolTipShow() {
    setTimeout(() => {
      this.showTooltipVisibility = this.validationToolTips.length > 0;
      this.cdRef.detectChanges();
    }, 100);
  }
	
  toolTipHide() {
    setTimeout(() => {
      this.showTooltipVisibility = false;
      this.cdRef.detectChanges();
    }, 100);
  }

  private addValidationMsg(msg: string): void {
    if (!this.validationToolTips.includes(msg)) {
      this.validationToolTips.push(msg);
    }
  }

  private removeValidationMsg(msg: string): void {
    if (this.validationToolTips.includes(msg)) {
      this.validationToolTips = this.validationToolTips.filter((m) => m != msg);
    }
  }

  updateGoogleEventOfferDate(data: any, targetDate: string) {
    let date =
      targetDate == 'start'
        ? this.googleEventOfferStartDate
        : this.googleEventOfferEndDate;
    date = data;
    this.googleEventOfferStartDate =
      targetDate == 'start' ? data : this.googleEventOfferStartDate;
    this.googleEventOfferEndDate =
      targetDate == 'end' ? data : this.googleEventOfferEndDate;
    if (this.googleEventOfferEndDate < this.googleEventOfferStartDate) {
      this.addValidationMsg(
        this.getTranslation('posts.step2Validation.eventOfferEndDateInvalid')
      );
    } else if (this.googleEventOfferEndDate > this.googleEventOfferStartDate) {
      this.removeValidationMsg(
        this.getTranslation('posts.step2Validation.eventOfferEndDateInvalid')
      );
    }
  }

  updateGoogleEventOfferTime(data: any, targetDate: string) {

    let date =
      targetDate == 'start'
        ? this.googleEventOfferStartDate
        : this.googleEventOfferEndDate;
    let hours = Number(data.split(':')[0]);
    let minutes = Number(data.split(':')[1]);

    if (targetDate == 'start') {

      this.googleEventOfferStartDate.setHours(hours);
      this.googleEventOfferStartDate.setMinutes(minutes);
    } else if (targetDate == 'end') {
      this.googleEventOfferEndDate.setHours(hours);
      this.googleEventOfferEndDate.setMinutes(minutes);
    }

  }

  handleBoolean(data: boolean) {
    this.toolTipMsgPages = data;
  }
	
	selectedPagesSourcesChange(data: any){
		this.selectedPagesSources = data;
		// console.log("selectedSourcesSubject", this.)
		// console.log("selectedSources CHANGE EMITER", JSON.stringify(this.selectedPagesSources.map((source:any) => {return {id: source.id, name: source.name}})))
	}

  async linkValidation(url: string): Promise<boolean> {
    const linkregex = /^(https?:\/\/)?([a-z0-9-]+\.)+[a-z0-9]{2,6}(\:[0-9]+)?(\/[\w\-\.]*)*(\?[^\s]*)?(\#[^\s]*)?$/i;
    if(url != ''){
      if (!linkregex.test(url) && url != '') {
          this.msgValidLink = 'posts.step2Validation.linkNotValid';
          this.toolTipMsgValidLink = true;
          this.fieldsetColorValidLink = true;
          setTimeout(() => {
              this.canPressNext = true;
              this.toolTipMsgValidLink = false;
          }, 4800);
          return true;
      }
      const validLink = await this.existingLink(url);
      if (validLink) {
        this.msgValidLink = 'posts.step2Validation.nonExistentLink';
        this.toolTipMsgValidLink = true;
        this.fieldsetColorValidLink = true;
        setTimeout(() => {
            this.canPressNext = true;
            this.toolTipMsgValidLink = false;
        }, 4800);
        return true;
      }
    }
    return false;
  }

  async existingLink(url: string): Promise<boolean> {
    let urlToTest;

    if (url.match(/^http:\/\//i)) {
        urlToTest = `https://${url.slice(7)}`;
    } else if (url.match(/^https?:\/\//i)) {
        urlToTest = url;
    } else {
        urlToTest = `https://${url}`;
    }
    try {
      const response = await fetch(urlToTest, { method: 'HEAD', mode: 'no-cors' });

      this.fieldsetColorValidLink = false;
      this.toolTipMsgValidLink = false;
      console.log('url valid');
      
      return false; // Lien valide et accessible
    } catch (error) {
      this.msgValidLink = 'posts.step2Validation.nonExistentLink';
      this.toolTipMsgValidLink = true;
      this.fieldsetColorValidLink = true;
      setTimeout(() => {
          this.canPressNext = true;
          this.toolTipMsgValidLink = false;
      }, 4800);
      console.log('url invalid 2');
      return true; // Lien inaccessible
    }
  }

  async dynamicAdditionalDataValidation() {
    const phoneRegex = /^((\+33\s?|0)[1-9](?:[\s.-]?[0-9]{2}){4})$/;
    const linkregex = /^(https?:\/\/)?([a-z0-9-]+\.)+[a-z0-9]{2,6}(\:[0-9]+)?(\/[\w\-\.]*)*(\?[^\s]*)?(\#[^\s]*)?$/i;
    let g_up_eve_info = this.postForm.get('g_callToAction_url' )?.value;

    const isCall = this.dynamicAdditionalData === 'CALL';
    const isOther =
    this.dynamicAdditionalData && this.dynamicAdditionalData !== 'CALL';

    if (isCall) {
      if (!phoneRegex.test(g_up_eve_info)) {
        this.AdditionalDataValidation = 'CALL';
        this.fieldsetColorDataValidation = true;
        this.canPressNext = false;
        setTimeout(() => {
          this.canPressNext = true;
          this.AdditionalDataValidation = '';
        }, 4800);
        return false;
      }
    } else if (isOther) {
        const isValidLinkFormat = linkregex.test(g_up_eve_info);
        let hasValidationError = !isValidLinkFormat;

        if (isValidLinkFormat) {
          const notValidLink = await this.existingLink(g_up_eve_info);
          this.msgValidLink = 'posts.step2Validation.nonExistentLink';
          hasValidationError = notValidLink;
        }else{
          this.msgValidLink = 'posts.step2Validation.linkNotValid';
        }
        if (hasValidationError) {
          this.AdditionalDataValidation = 'other';
          this.fieldsetColorDataValidation = true;
          this.canPressNext = false;

          setTimeout(() => {
            this.canPressNext = true;
            this.AdditionalDataValidation = '';
          }, 4800);

          return false;
        }
    }
    this.fieldsetColorDataValidation = false;
    this.AdditionalDataValidation = '';

    return true;
  }

  cuponCodeValidation(): boolean {
    if (
      this.postForm.get('g_localPostOffer_redeemOnlineUrl')?.value != '' &&
      this.postForm.get('g_localPostOffer_couponCode')?.value == ''
    ) {
      this.toolTipMsgcuponCode = true;
      this.fieldsetColorcuponCode = true;
      // this.canPressNext = false;
      setTimeout(() => {
        this.canPressNext = true;
        this.toolTipMsgcuponCode = false;
      }, 4800);
      return false;
    } else {
      this.fieldsetColorcuponCode = false;
      this.toolTipMsgcuponCode = false;
      return true;
    }
  }

  close() {
    this.isModalOpen = false;
    this.canPressNext = true;
  }

  fileTooSmall(file: any): boolean {
    if (!this.isGooglePageSelected) return false;
    // Split the dimensions string to extract width and height
    const [width, height] = file.dimensions
      .split('x')
      .map((dim: any) => parseInt(dim.trim()));

    // Google's minimum resolution is 400x300
    const MIN_WIDTH = 400;
    const MIN_HEIGHT = 300;

    // Return true if either width or height is smaller than the minimum required
    return width < MIN_WIDTH || height < MIN_HEIGHT;
  }

  // FACEBOOK / LINKEDIN "Video OR Photo(s)" Fieldset fts
  mediaSelectionStep: string = 'initial'; // Initial state

  uploadedVideos: any[] = []; // Populate these dynamically from the existing media
  uploadedPhotos: any[] = [];
  selectedVideo: any = null;
  selectedPhotos: any[] = [];

  selectMediaType(mediaType: string) {
    this.mediaSelectionStep = mediaType;
    if (mediaType === 'images') {
      const selection = [
        ...this.previewFilesList.filter((file) => file.type.includes('image')),
      ]; // Default to all photos selected
      this.facebookLinkedinMediaBrowserContext.data = {
        isSkipped: false,
        selection: selection,
        selected: selection[0],
      };
    }
    if (mediaType === 'video') {
      const selection = [
        ...this.previewFilesList.filter((file) => file.type.includes('video')),
      ]; // Default to all photos selected
      if (selection.length == 1) {
        this.facebookLinkedinMediaBrowserContext.data = {
          isSkipped: false,
          selection: selection,
          selected: selection[0],
        };
      }
    }
    if (mediaType === 'none') {
      this.facebookLinkedinMediaBrowserContext.data = { isSkipped: true };
    }
  }

  chooseVideo() {
    if (this.uploadedVideos.length > 1) {
    } else if (this.uploadedVideos.length === 1) {
      this.selectedVideo = this.uploadedVideos[0];
    }
  }

  backToMainSelection() {
    this.mediaSelectionStep = 'initial';
    this.facebookLinkedinMediaBrowserContext.data = null;
  }

  // END  -- FACEBOOK / LINKEDIN "Video OR Photo(s)" Fieldset fts

  ngAfterViewInit(): void {
    // Loop over each element in the QueryList
    this.myElements?.forEach((element, index) => {
      const rect = element.nativeElement.getBoundingClientRect();

      const x = rect.left + window.scrollX;
      const y = rect.top + window.scrollY;
      const r = rect.right + window.scrollX + (this.screenWidth * 18) / 100;
      const h = rect.height;
      this.elementsPositions.push({ X: x, Y: y, R: r, H: h });
    });
  }

  updateHeight($event: any, index: number) {
    setTimeout(() => {
      this.toolTiHeights[index] = $event;
    }, 100);
  }

	divPostTextAreaIsExpanded:boolean = false;
	toggleExpandDivPostTextArea() {
		this.divPostTextAreaIsExpanded = !this.divPostTextAreaIsExpanded
		if (this.divPostTextAreaIsExpanded) {
			this.postTextArea.nativeElement.focus();
		}
	}

	selectAllPagesInDropdown(diff: any[]){
		if (this.dropdownComponent) {
      this.dropdownComponent.selectAllPagesFromPlatform(diff); // Call the dropdown method
    }
	}

	deleteCurrentPost() {
		if (this.postUrl) {
			this.postService.deletePost(this.postUrl).subscribe((res) => {
			});
		}
  }

	transformMediaData(data: any) {
		const mediaMap = new Map<number, any>();
	
		data.forEach((post:any) => {
			const platform = { id: post.id, source: post.source };
	
			post.medias.forEach((media:any) => {
				if (!mediaMap.has(media.id)) {
					// Create new media entry if it doesn't exist in the map
					mediaMap.set(media.id, {
						id: media.id,
						url: media.url,
						mimeType: media.mimeType,
						orignalname: media.name,
						platforms: [platform]
					});
				} else {
					// If media already exists, just push the platform into the platforms array
					mediaMap.get(media.id).platforms.push(platform);
				}
			});
		});
	
		// Convert the map values into an array
		return Array.from(mediaMap.values());
	}

  preparePostDataForPreview(): any {
    return {
      typePost: this.postForm.value.typePost,
      text: this.postForm.value.text,
      title: this.postForm.value.title,
      googleEventOfferStartDate: this.googleEventOfferStartDate,
      googleEventOfferEndDate: this.googleEventOfferEndDate,
      socialMedia: this.selectedPagesSources,
      mediaGoogle: this.googleMediaBrowserContext.data?.selected?.url
        ? this.googleMediaBrowserContext.data?.selected?.url
        : this.previewFilesList[0]?.url,
      mediaList: this.previewFilesList.filter(item =>
				item?.type?.startsWith("image") || item?.mimeType?.startsWith("image")
			)
    };
  }

	dateFormatingPlannedPost(date:Date) {
		// const date = new Date(dateString);
		const now = new Date();
		const today = now.getDate();
		const tomorrow = today + 1;
		// Extract parts of the date
		const day = date.getDate();
		const month = date.toLocaleString('en-US', { month: 'short' });
		const year = date.getFullYear().toString().slice(-2);
		const hours = date.getHours().toString().padStart(2, '0');
		const minutes = date.getMinutes().toString().padStart(2, '0');
		
		// Combine into the desired format
		if (day == today) {
			return this.translationService.t('dates.relative.today') + ' ' + this.translationService.t('pages.at') + ` ${hours}:${minutes}`;
		} else if (day == tomorrow) {
			return this.translationService.t('dates.relative.tomorrow') + ' ' + this.translationService.t('pages.at') + ` ${hours}:${minutes}`;
		} else {
			return this.translationService.t('dates.relative.onThe') + ` ${day} ${month} ${year}, ` + this.translationService.t('pages.at') + ` ${hours}:${minutes}`;
		}
	}

	firstLink: string | null = null;
	rawText: string | null = null;
  highlightedText: SafeHtml | null = null;
	detectLinks(value: string): void {
    // Regular expression to match URLs
		const urlRegex = /\b((https?:\/\/|www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*))\b/g;
    const links = value.match(urlRegex);

    // Update the first link only if not set
    if (links && links.length > 0) {
      this.firstLink = links[0];
    } else {
			this.firstLink = null;
		}
		
		// console.log("firstLink", this.firstLink)
    // Highlight links in the textarea
		const processedText = value.replace(urlRegex, (match) => {
      return `<span style="color: blue; text-decoration: underline;">${match}</span>`;
    });

		this.highlightedText = this.sanitizer.bypassSecurityTrustHtml(processedText);
  }

	onContentEditableInput(event: Event): void {
		const value = (event.target as HTMLDivElement).innerText;
	
		// Update the rawText (hidden textarea value)
		this.rawText = value;
	
		// Detect and highlight links
		this.detectLinks(value);
	}
	
	// getPost(postId: string){ 
	// 	this.postService.getPostById(postId).pipe(
	// 		catchError((error: any) => {
	// 			if (error.status === 403) {
	// 				window.location.href = "/not-allowed";
	// 			}
	// 			if (error.status === 404) {
	// 				window.location.href = "/not-exists";
	// 			}
	// 			if (error.status === 500) {
	// 				window.location.href = "/error";
	// 			}
	// 			// Return an empty observable to terminate further processing
	// 			return of(null);
	// 		})
	// 	).subscribe((res: any) => {
	// 		//this.data is the current 
	// 		this.postUrl = res.data
	// 		console.log('this.postUrl', this.postUrl);
			
	// 		// this.fetchInsights();
	// 		// this.saveData = JSON.stringify(this.data)
	// 		// this.prepareData();
	// 		// this.isLoading = false;

	// 	})
	// }

	isUpdate() {
		return this.postAction == 'update';
	}

	hasInstagram() {
		return this.socialMediaPagesList?.some((e: any) => e.source == "instagram");
	}

	getPostTextAreaHeight(): string {
		if (this.divPostTextAreaIsExpanded) {
			return 'calc(100% - (1.25rem * 2))';
		}
	
		const baseHeight = this.screenHeight * 17 / 100;
		const additionalHeight = this.screenHeight * 0.93 / 10;
		let finalHeight = this.isGooglePageSelected() ? baseHeight : baseHeight + additionalHeight;
	
		return `${finalHeight}px`;
	}

	setGoogleAdditionalData(googleTypePost: string) {
		// CallToAction
		let callToAction = null;
		if (googleTypePost != 'OFFER' && this.dynamicAdditionalData) {
			callToAction = {
				actionType: this.dynamicAdditionalData,
				url: this.postForm.get('g_callToAction_url' )?.value,
			};
		}

		let localPostEvent = null;
		if (googleTypePost != 'STANDARD') {
			localPostEvent = {
				title: this.postForm.get('title')?.value,
				schedule: {
					startDate: {
						// object (Date)
						year: this.googleEventOfferStartDate.getFullYear(),
						month: this.googleEventOfferStartDate.getMonth() + 1,
						day: this.googleEventOfferStartDate.getDate(),
					},
					startTime: {
						// object (TimeOfDay)
						hours: this.googleEventOfferStartDate.getHours(),
						minutes: this.googleEventOfferStartDate.getMinutes(),
						seconds: this.googleEventOfferStartDate.getSeconds(),
						nanos: 0,
					},
					endDate: {
						// object (Date)
						year: this.googleEventOfferEndDate.getFullYear(),
						month: this.googleEventOfferEndDate.getMonth() + 1,
						day: this.googleEventOfferEndDate.getDate(),
					},
					endTime: {
						// object (TimeOfDay)
						hours: this.googleEventOfferEndDate.getHours(),
						minutes: this.googleEventOfferEndDate.getMinutes(),
						seconds: this.googleEventOfferEndDate.getSeconds(),
						nanos: 0,
					},
				},
			};
		}

		// LocalPostOffer
		let localPostOffer = null;
		if (googleTypePost == 'OFFER') {
			localPostOffer = {
				couponCode: this.postForm.get('g_localPostOffer_couponCode')?.value,
				redeemOnlineUrl: this.postForm.get('g_localPostOffer_redeemOnlineUrl')?.value,
				termsConditions: this.postForm.get('g_localPostOffer_termsConditions')?.value,
			};
		}

		return {
			callToAction: callToAction,
			localPostEvent: localPostEvent,
			localPostOffer: localPostOffer,
		};

	}

	setTypePost() {
		let googleTypePost;
    switch (this.typePost.toLowerCase()) {
      case 'offer':
        googleTypePost = 'OFFER';
        break;
      case 'event':
        googleTypePost = 'EVENT';
        break;
      default:
        googleTypePost = 'STANDARD';
        break;
    }
		return googleTypePost
	}
	
}