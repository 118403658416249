<h1 class="ml-10 max-w-7xl text-blue-800 font-semibold text-3xl pb-10 ">{{ 'socialMedia.connectAPage' | translate}}</h1>

<div class="w-[45rem] ml-10 space-y-3">
	<!-- Logo and Name Div -->
	<div *ngIf = 'type == "linkedin"' class="flex items-center justify-center bg-linkedin text-white font-bold py-4 px-4  rounded-lg h-20 flex-shrink-0">
		<img src="assets/img/linkedin-logo.png" alt="Linkedin Logo" class="object-contain max-w-full max-h-full">
	</div>
	<div *ngIf = 'type == "google"' class="flex items-center justify-center bg-red-600 text-white font-bold py-4 px-4  rounded-lg h-20 flex-shrink-0">
		<img src="assets/img/google-logo.png" alt="Google Logo" class="object-contain max-w-full max-h-full">
	</div>
	<div *ngIf = 'type == "facebook"' class="flex items-center justify-center bg-facebook text-white font-bold py-4 px-4  rounded-lg h-20 flex-shrink-0">
		<img src="assets/img/facebook-logo.png" alt="Facebook Logo" class="object-contain max-w-full max-h-full">
	</div>
	<div *ngIf = 'type == "instagram"' class="flex items-center justify-center bg-gradient-to-r from-pink-500 to-yellow-500 text-white font-bold py-4 px-4  rounded-lg h-20 flex-shrink-0">
		<img src="assets/img/instagram-logo.png" alt="Instagramogo" class="object-contain max-w-full max-h-full">
	</div>
	<!-- Connection Button Div -->
	<form (ngSubmit)="onSubmit()" class="space-y-3">
		<!-- <div id="container" *ngIf="pages.length==1" class="bg-white w-[45rem] 0 mt-3 pb-[30px] mr-5 rounded-xl ">
			<legend class="ml-10 px-1 font-semibold p-6 text-xl text-center">
				 {{'generic.selectEstablishment'| translate}}
			</legend>
			<div class="dropdown w-full">
				<div class="mt-0 mx-5 relative">
					<button (click)="toggleDropdown($event)" type="button" class="flex h-8 rounded-full border border-gray-300 shadow-sm  w-full py-2 px-5  bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:border-blue-700 " aria-haspopup="listbox" aria-expanded="true" aria-labelledby="listbox-label">
					  <span class="flex items-center">
						<span class="items-center ml-auto block truncate text-gray-700" > {{currentGroup?.name || 'generic.noEstablishmentSelected' | translate}} </span>
					  </span>
					  <span class="ml-3 absolute inset-y-0 right-0 flex items-center pr-1 pointer-events-none text-sm font-sans font-black">
						<span class="mr-2">
						  <svg height="48" viewBox="0 0 48 48" width="24" xmlns="http://www.w3.org/2000/svg"><path d="M14.83 16.42l9.17 9.17 9.17-9.17 2.83 2.83-12 12-12-12z"/><path d="M0-.75h48v48h-48z" fill="none"/></svg>
						</span>
					  </span>
					</button>
						<div #dropdownContainer >
							<ul 
								class="absolute z-10 mt-1 w-full bg-white rounded-xl border-2 border-blue-300 max-h-52 overflow-auto" 
								[class.hidden]="!isDropdownOpen"
							>
								<li class=" cursor-pointer text-base select-none relative py-1 pl-3 pr-9 rounded-md hover:bg-gray-100"
									*ngFor="let group of groupsFlatList"
									(click)="selectGroup(group.group)"
									[id]="'listbox-option-' "
									role="option">
								<div class="flex items-center ">
									<span class="ml-2 text-gray-700 font-sans font-black block truncate rounded-md"> {{group.group.name}} </span>
								</div>
								</li>
							</ul>
						</div>
				  </div>
			</div>
		</div> -->
		<div #pageList class="bg-white rounded-2xl border border-blue-300 pb-2 pt-2 gap-4">
			<div *ngIf="this.infoMessage" class="text-sm text-center w-full">{{this.infoMessage | translate}}</div>
			<div class=" max-h-[40vh] overflow-y-auto">
				<div *ngFor="let page of pages; let i = index" class="flex items-center w-full">
					<label class="flex items-center gap-3 grid-cols-3 p-2 cursor-pointer justify-between w-full">
						<span class="col-span-1 w-1/3 px-2">
							<!-- <input type="checkbox" name="page" [id]="page.id" [value]="page.id" [(ngModel)]="selectedPages[i].selected" > -->
							<app-social-media-round-icon [source]="type" [greyedOut]="false"></app-social-media-round-icon>
							 
							<span class="text-sm ml-1">{{ page.name }}</span>
						</span>
						<select class="col-span-1 px-2 w-1/3 border border-gray-300 rounded-xl text-sm py-2" [name]="'group-' + page.id" [(ngModel)]="selectedPages[i].selectedGroup">
							<option Value="null" disabled selected label="{{'generic.noEstablishmentSelected' | translate}}"></option>
							<option [ngValue]="group.group.id" *ngFor="let group of groupsFlatList"  > {{group.group.name}} </option>
						</select>
						<span class="col-span-1 w-1/3">
							<a type="button" class="flex items-center mx-2 px-3 py-2 bg-cliking_blue text-white rounded hover:bg-blue-600" [href]="page.url" target="_blank">
								<svg class="w-4 h-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="#ffffff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
									<g id="SVGRepo_bgCarrier" stroke-width="0"></g>
									<g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
									<g id="SVGRepo_iconCarrier">
										<path d="M18 13v6a2 2 0 01-2 2H5a2 2 0 01-2-2V8a2 2 0 012-2h6"></path>
										<polyline points="15 3 21 3 21 9"></polyline>
										<line x1="10" y1="14" x2="21" y2="3"></line>
									</g>
								</svg>
								<span class="ml-2 text-sm">{{'generic.seeThisPage' | translate}}</span>
							</a>
						</span>	
					</label>
				</div>
			</div>
		</div>
		<div>
			<button type="submit" class=" px-4 py-2 bg-cliking_blue text-white hover:bg-blue-600 w-[45rem] rounded-xl" [ngClass]="{'cursor-not-allowed': disableSubmitButton}" [disabled]="disableSubmitButton">{{'generic.submit' | translate}}</button>
		</div>
	</form>

	<!-- Log Out Button -->
<div class="flex items-center justify-center mt-4">
		<button 
			class="px-4 py-2 text-sm rounded hover:underline text-gray-500" 
			(click)="logout()"
		>
			{{"generic.logout" | translate}}
		</button>
	</div>
</div>
	
<app-modal 
[isOpen]="isModalOpen"
[subHeading]="subHeading"
[heading]="heading"
(closeModal)="close()"
(continueModal)="continue()"
[icon]="modalIcon"
[connectPages]="connectAllPages"
>
</app-modal>


