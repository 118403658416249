import { EventEmitter, Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { UploadResponse } from 'src/app/interfaces/UploadResponse';
import { environment } from 'src/environments/environment';
import {
  BehaviorSubject,
  Subject,
  Observable,
  catchError,
  throwError,
} from 'rxjs';
import { Group } from 'src/app/interfaces/Group';
import { Debug } from 'src/app/utils/debug';

@Injectable({
  providedIn: 'root',
})
export class PostService {
  constructor(private http: HttpClient) {}
  private apiUrl = environment.apiUrl;
  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
  };

  public googleExists: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false
  );
  public facebookExists: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);
  public linkedinExists: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);
  public instagramExists: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);
  public socialMediaOptions: BehaviorSubject<any> = new BehaviorSubject<any>(
    []
  );
  public selectedSocialMedia: BehaviorSubject<any> = new BehaviorSubject<any>(
    []
  );
  disablePostTypeEvent = new EventEmitter();
  emitEvent(data: any) {
    this.groupsUpdeted.next(data);
  }

  private groupUpdeted = new Subject<Group>();
  groupUpdeted$ = this.groupUpdeted.asObservable();

  private groupsUpdeted = new Subject<number[]>();
  groupsUpdeted$ = this.groupsUpdeted.asObservable();
  mergePostsBySocialPlatform(posts: any[]): any[] {
    const mergedPosts: { [key: string]: any } = {};
    posts.forEach((post) => {
      // Create a unique key based on all properties except 'SocialPlatform'
      const key = this.createUniqueKey(post, ['SocialPlatform', 'id']);
      if (!mergedPosts[key]) {
        const { id, SocialPlatform, ...restOfPost } = post;
        mergedPosts[key] = { ...restOfPost, SocialPlatform: [] };
      }
      // Add the 'SocialPlatform' to the array in the merged post
      mergedPosts[key].SocialPlatform.push({
        name: post.SocialPlatform,
        postId: post.id,
      });
    });

    return Object.values(mergedPosts);
  }

  createUniqueKey(post: any, excludeKeys: string[]): string {
    return Object.entries(post)
      .filter(([key]) => !excludeKeys.includes(key))
      .map(([key, value]) => `${key}:${value}`)
      .join('|');
  }

  public currentGroupsSource: BehaviorSubject<number[]> = new BehaviorSubject<
    number[]
  >([]);

  public currentGroupSource: BehaviorSubject<Group> =
    new BehaviorSubject<Group>({
      id: 0,
      name: '',
      logoUrl: '',
      parent: null,
      ancestry: [],
      children: 0,
      socialMedia: [],
    });
  currentGroups = this.currentGroupsSource.asObservable();

  public toggledItemsSource = new BehaviorSubject<{ [key: number]: boolean }>(
    {}
  );
  public selectedSourcesSubject = new BehaviorSubject<any[]>([]);
  currentSelectedPages = this.selectedSourcesSubject.asObservable();
  currentToggledItems = this.toggledItemsSource.asObservable();

  public currentPickedDateSource: BehaviorSubject<Date | null> =
    new BehaviorSubject<Date | null>(null);
  currentPickedDate = this.currentPickedDateSource.asObservable();

  public publishNowSource: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);
  publishNow = this.publishNowSource.asObservable();

  SyncSelectedPagesWithCurrentSelectedPlatforms(socialMediaPagesList: any[]) {
    let selectedIds = Object.keys(this.toggledItemsSource.getValue())
      .filter((id: string) =>
        socialMediaPagesList.some((item: any) => item.id.toString() === id)
      )
      .reduce((acc: { [key: number]: boolean }, curr: string) => {
        const numKey = parseInt(curr, 10); // Convert string key back to number
        acc[numKey] = true;
        return acc;
      }, {});
  }
  //TODO: remove after implement multiple current selected groups
  updateGroup(group: any | null | undefined): void {
    if (group) {
      this.currentGroupSource.next(group);
      //this.emitEvent(group);
    } else {
      console.error('Attempted to update with undefined or null group');
      // Optionally handle or fallback to a default group state
    }
  }
  updateGroups(groups: number[]): void {
    this.currentGroupsSource.next(groups);
    this.emitEvent(groups);
  }

  updateToggledItems(toggledItems: { [key: number]: boolean }): void {
    this.toggledItemsSource.next(toggledItems);
    // Debug.log("post service toggled pages updated",toggledItems);
    // this.emitEvent(toggledItems)
  }

  updateSelectedSources(newSources: any[]): void {
    this.selectedSourcesSubject.next(newSources);
    // Debug.log("newSources",newSources);
    // this.emitEvent(newSources)
  }

  updatePickedDate(pickedDate: Date | null): void {
    this.currentPickedDateSource.next(pickedDate);
    if (pickedDate == null) {
      this.publishNowSource.next(true);
    } else {
      this.publishNowSource.next(false);
    }
  }

  updatePublishNow(value: boolean): void {
    this.publishNowSource.next(value);
  }

  getSelectedSocialMediaIds() {
    for (let key in this.toggledItemsSource.getValue()) {
      if (
        this.toggledItemsSource.getValue().hasOwnProperty(key) &&
        this.toggledItemsSource.getValue()[key] === false
      ) {
        delete this.toggledItemsSource.getValue()[key];
      }
    }
    return this.toggledItemsSource.getValue();
  }

  createPost(postData: any, postUrlAction: string | null): Observable<any> {
		console.log("postUrlAction", postUrlAction)
		if (postUrlAction == 'update') {
			return this.http.patch<any>(
				`${this.apiUrl}/publications/${postData.updateId}`,
				postData,
				this.httpOptions
			);

		} else {
			return this.http.post<any>(
				`${this.apiUrl}/publications`,
				postData,
				this.httpOptions
			);
		}
  }

  // Add other methods for different operations (get, update, delete, etc.)
  getPosts(groupId: any): Observable<any> {
    return this.http
      .get<any>(
        `${this.apiUrl}/publications/by-group/${groupId}`,
        this.httpOptions
      )
      .pipe(
        catchError((error) => {
          console.error('Error fetching posts:', error);
          return throwError(() => error);
        })
      );
  }

  getPostById(postId: any, includeComments: boolean,groupIds:any[]): Observable<any> {
    return this.http.get<any>(`${this.apiUrl}/publications/${postId}?includeComments=${includeComments}&groupIds=${groupIds}`, this.httpOptions)
  }

  getPostComments(commentFetchData: any): Observable<any> {
    return this.http.post<any>(`${this.apiUrl}/publications/api-post-comments`,commentFetchData, this.httpOptions)
  }
  
  deletePost(post: any, selectedSocialMediasIds: any[] | null = null): Observable<any> {
		const url = `${this.apiUrl}/publications/${post.id}`;
		const options = {
			body: { selectedSocialMediasIds },
		};
	
		return this.http.delete(url, options);
	}

  sendComment(params:any,page:any): Observable<any> {
    return this.http.post(`${this.apiUrl}/publications/api-comment/`, {params,page}, this.httpOptions)
  }
  sendReply(params:any,page:any): Observable<any> {
    return this.http.post(`${this.apiUrl}/publications/api-reply/`, {params,page}, this.httpOptions)
  }
  getYearPosts(year: number, groupIds: number[], activePlatforms: any[]=[]): Observable<any> {
    return this.http
      .get<any>(
        `${this.apiUrl}/publications/count-by-date/${year}/${groupIds}?platforms=${activePlatforms.join(',')}`,
        this.httpOptions
      )
      .pipe(
        catchError((error) => {
          console.error('Error fetching Year posts:', error);
          return throwError(() => error);
        })
      );
  }

  getByDateRange(
    startDate: Date,
    endDate: Date,
    groupIds: number[],
    activePlatforms: string[],
    activePostTypes: string[],
    limit: number = 20,
    skip: number = 0,
  ): Observable<any> {
		
    const startStr = startDate?.getTime()
      // startDate.getFullYear() +
      // '-' +
      // (startDate.getMonth() + 1) +
      // '-' +
      // startDate.getDate();
    const endStr = endDate.getTime()
      // endDate.getFullYear() +
      // '-' +
      // (endDate.getMonth() + 1) +
      // '-' +
      // endDate.getDate();
      

    return this.http
      .get<any>(
        `${this.apiUrl}/publications/group/${groupIds}/get-posts-by-date-range/${startStr}/${endStr}?platforms=${activePlatforms.join(',')}&types=${activePostTypes.join(',')}&limit=${limit}&offset=${skip}`,this.httpOptions
      )
      .pipe(
        catchError((error) => {
          console.error('Error fetching posts:', error);
          return throwError(() => error);
        })
      );
  }

  getPlannedByDateRange(
    startDate: Date,
    endDate: Date,
    groupIds: number[],
    limit: number = 20,
    skip: number = 0,
    activePlatforms: string[] = ['google', 'facebook', 'linkedin', 'instagram']
  ): Observable<any> {
    const startStr = startDate.getTime()
    const endStr = endDate.getTime()
    return this.http
      .get<any>(
        `${
          this.apiUrl
        }/publications/group/${groupIds}/get-planned-by-date-range/${startStr}/${endStr}?limit=${limit}&offset=${skip}&platforms=${activePlatforms.join(
          ','
        )}`,
        this.httpOptions
      )
      .pipe(
        catchError((error) => {
          console.error('Error fetching posts:', error);
          return throwError(() => error);
        })
      );
  }

  getLastPost(
    groupIds: number[],
    limit: number = 3,
  ): Observable<any> {
    return this.http
      .get<any>(
        `${
          this.apiUrl
        }/publications/group/${groupIds}/get-last-post?limit=${limit}`,
        this.httpOptions
      )
      .pipe(
        catchError((error) => {
          console.error('Error fetching posts:', error);
          return throwError(() => error);
        })
      );
  }

  getByTimeFrame(
    groupIds: number[],
    dateStr: string,
    timeframe: string,
  ): Observable<any> {
    return this.http
      .get<any>(
        `${this.apiUrl}/publications/group/${groupIds}/timeframe?timeFrame=${timeframe}&date=${dateStr}`,
        this.httpOptions
      )
      .pipe(
        catchError((error) => {
          console.error('Error fetching groups:', error);
          return throwError(() => error);
        })
      );
  }

  getMonthHighLight(
    groupIds: number[],
    dateStr: string,
  ): Observable<any> {
    return this.http
      .get<any>(
        `${this.apiUrl}/publications/group/${groupIds}/date?date=${dateStr}`,
        this.httpOptions
      )
      .pipe(
        catchError((error) => {
          console.error('Error fetching groups:', error);
          return throwError(() => error);
        })
      );
  }
  
  getGroupPosts(id: number): Observable<any> {
    return this.http
      .get<any>(
        `${this.apiUrl}/publications/group/${id}?page=1&pageSize=10`,
        this.httpOptions
      )
      .pipe(
        catchError((error) => {
          console.error('Error fetching groups:', error);
          return throwError(() => error);
        })
      );
  }

  getPost(postId: number): Observable<any> {
    // const groupParams = groupIds.length > 0 ? `?groupIds=${groupIds}` : ""
    return this.http
    .get<any>(
      `${this.apiUrl}/posts/${postId}`,
      this.httpOptions
    )
    .pipe(
      catchError((error) => {
        console.error('Error fetching groups:', error);
        return throwError(() => error);
      })
    );
  }

  uploadFile(formData: any): Observable<UploadResponse> {
    Debug.log('formData before uploading img: ', formData);
    return this.http.post(`${this.apiUrl}/images/upload`, formData);
  }

  getGroupScopesFromLocalStorage(): any[] {
    let sessionDataStr = localStorage.getItem(environment.sessionData);
    if (sessionDataStr != undefined) {
      return JSON.parse(sessionDataStr).groupScopes;
    }
    return [];
  }

  saveAdminGroupToLocalStorage(data: any[]) {
    let sessionDataStr = localStorage.getItem(environment.sessionData);
    let sessionData: { adminGroup: any[] } = { adminGroup: [] };
    if (sessionDataStr) {
      sessionData = JSON.parse(sessionDataStr);
    }
    localStorage.setItem(environment.sessionData, JSON.stringify({...sessionData,adminGroup:data}));
  }

  getAdmniGroupFromLocalStorage(): any[] {
    let sessionDataStr = localStorage.getItem(environment.sessionData);
    if (sessionDataStr != undefined) {
      return JSON.parse(sessionDataStr).adminGroup;
    }
    return [];
  }

  saveGroupScopesToLocalStorage(data: any[]) {
    let sessionDataStr = localStorage.getItem(environment.sessionData);
    let sessionData: { groupScopes: any[] } = { groupScopes: [] };
    if (sessionDataStr) {
      sessionData = JSON.parse(sessionDataStr);
    }
    localStorage.setItem(environment.sessionData, JSON.stringify({...sessionData,groupScopes:data}));
  }

  private eventSource = new BehaviorSubject<any>(null);  // Initialisez avec une valeur par défaut (null)
  event$ = this.eventSource.asObservable();
  emitEvente(data: any) {
    this.eventSource.next(data);  // Émet une nouvelle valeur
  }

	duplicatePost(postId: number): Observable<any> {
		return this.http.post<any>(`${this.apiUrl}/publications/duplicate/${postId}`, {});
	}

	extractSocialMediaInfo(post: any, filterInstagram: boolean = false): { id: number; name: string; source: string, postUrl: string }[] | null {
		if (post) {
			let displayAs;
			if (
				post.publishingDatetime == null &&
				post.expectedPublishingDatetime == null
			) {
				displayAs = 'draft';
			} else if (new Date(post.expectedPublishingDatetime) > new Date()) {
				displayAs = 'planned';
			} else {
				displayAs = 'post';
			}
	
			// If draft, return null
			if (displayAs == 'draft') return null;
	
			if (!post?.SocialPlatforms || !Array.isArray(post.SocialPlatforms)) {
				return [];
			}
	
			return post.SocialPlatforms
			.filter((platform: any) => 
				platform.id && platform.pageName && platform.source && 
				(!filterInstagram || platform.source === 'instagram') // Filter if filterInstagram is true
			).map((platform: any) => ({
				id: platform.id,
				name: platform.pageName,
				source: platform.source,
				postUrl: platform.postUrl
			}));
		} else {
			return null;
		}
	}

	getFirstAdditionalData(post: any): any {
		const platformWithAdditionalData = post.SocialPlatforms.find(
			(platform: any) => platform.additionalData
		); // Find the first platform with additionalData
	
		return platformWithAdditionalData ? platformWithAdditionalData.additionalData : null;
	}
	
	changePostStatus(postId: any) {
		console.log("changePostStatus JS")
		return this.http.patch<any>(`${this.apiUrl}/publications/change-status/${postId}`, {});
	}
}