import { Component } from '@angular/core';
import { AuthService } from './services/utilities/auth.service';
import {TranslationService} from './services/utilities/translation.service';
import {WebSocketService} from './services/utilities/websocket.service';
import { Router } from '@angular/router';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  constructor(
		private translationService: TranslationService,
		public router: Router,
		private webSocketService: WebSocketService,
	) {
    this.translationService.setLanguage('fr'); // Set your default language
  }
  title = 'Cliking Social Media';
	ngOnInit(): void {
    this.webSocketService.connect();
  }

  ngOnDestroy(): void {
    this.webSocketService.disconnect();
  }
}
