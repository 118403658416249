import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'extractHourFromPost'
})
export class ExtractHourFromPostPipe implements PipeTransform {

  transform(value: any): string {
    const date = value.publishingDatetime ? new Date(value.publishingDatetime): new Date(value.expectedPublishingDatetime);
    if(date){
      return date.getUTCHours().toString().padStart(2,'0')+":"+date.getUTCMinutes().toString().padStart(2,'0')
    }
    return ""
  }

}
