import { Component, ElementRef, HostListener, ViewChild } from '@angular/core';
import { AuthService } from 'src/app/services/utilities/auth.service';
import { TranslationService } from 'src/app/services/utilities/translation.service';
import { PostService } from 'src/app/services/repositories/post.service';
import { ScreenSizeService } from 'src/app/services/utilities/screen-size.service';
import { DatePipe } from '@angular/common';
import { StatsIcon } from 'src/app/enums/statsSVGIcon';
import { SocialMediaAPIService } from 'src/app/services/utilities/socialMediaApi.service';
import { environment } from 'src/environments/environment';
import { Subscription } from 'rxjs';


@Component({
  selector: 'app-home-page',
  templateUrl: './home-page.component.html',
  styleUrls: ['./home-page.component.scss'],
  providers: [DatePipe]  
})
export class HomePageComponent {
  @ViewChild('container') container!: ElementRef;
  height: number = 0;
  private observer!: MutationObserver;

  user: any;
  post: any = {}; 
  plannedPosts: any = []; 
  linkedin : boolean = false;
  google : boolean = false;
  facebook : boolean = false;
  instagram : boolean = false;
  linkedinData: any = null;
  linkedinMedias: any[] = []
  googleData: any = null;
  googleMedias: any[] = []
  facebookData: any = null;
  facebookMedias: any[] = []
  instagramData: any = null;
  instagramMedias: any[] = []
  mediaAllpreview: any =  null;
  screenWidth: number = 0;
  screenHeight: number = 0;
  dates: string[] = []
  currentGroups: number[] = [];
  isLoading: boolean = false;
  doneLoadingPosts: boolean = false;
  previousScrollPosition = 0;
  allData: any = {};
  navigation: any = {
    limit: 3,
    itemsPerPage: 3,
    page: 0,
    offset: 0
  };
  groups: any[] = [];
  env = environment;
  isGroupNamesToolTip: boolean = false;
  isTruncated: boolean = false;
  postHour: string = '00';
  postMinute: string = '00';

  SetIconShare() : StatsIcon { return StatsIcon.SHARE }
  SetIconReactions() : StatsIcon {return StatsIcon.REACTIONS}
  SetIconInteraction() : StatsIcon {return StatsIcon.INTERACTION}
  SetIconImpression() : StatsIcon {return StatsIcon.IMPRESSION}
  SetIconChatBubble() : StatsIcon {return StatsIcon.CHAT_BUBBLE}
  SetIconEngagement() : StatsIcon { return StatsIcon.ENGAGEMENT }


  constructor(protected authService: AuthService, protected translationService:TranslationService, protected postService: PostService, private datePipe: DatePipe,
    private screenSizeService: ScreenSizeService,
    private socialMediaAPIService: SocialMediaAPIService,
    private elementRef: ElementRef
  ){}
  subscriptions:Subscription[] = [];
  ngOnInit(): void {
    this.screenSizeService.screenWidth$.subscribe(
      (width: number) => (this.screenWidth = width)
    );
    this.screenSizeService.screenHeight$.subscribe(
      (height: number) => (this.screenHeight = height)
    );
    this.user = this.authService.loadUserfromLs();
    this.currentGroups =this.postService.getGroupScopesFromLocalStorage();
    this.subscriptions.push( this.postService.currentGroups.subscribe(
      (g) => {
        console.log('g', g);
        
        if (g.length == 0)
          g = this.postService.getGroupScopesFromLocalStorage();
        
        if (g.length > 0) {
          this.currentGroups = g;
          console.log('g', this.currentGroups);
          this.getLastPost();
          this.getLastPlannedPosts();
        }
      }
    ));
    
    // console.log('allData',this.allData);
  }



  externLinks(link: string){
    window.location.href = link
  }
  tr(key: string){
    return this.translationService.t(key)
  }

  getUserFirstName(): string {
    return this.authService.loadUserfromLs()?.firstName
  }

  getLastPost(){
    this.subscriptions.push(this.postService.getLastPost(this.currentGroups, 1).subscribe((res: any)=>{
      if(this.isNotEmptyObject(this.post)){
        this.resetIntialData();
        this.getLastPlannedPosts();
      }
      if(res.data.length>0){
        this.post =  res.data[0];
        console.log('this.posts', this.post);
        this.fetchInsights()
        this.post.SocialPlatforms.forEach((item: {group: any;postAnalytics: any; source: string; medias: any})=>{
          if (!this.groups.some(group => group.id === item.group.id) && this.currentGroups.includes(item.group.id)){
            this.groups.push({id: item.group.id, name: item.group.name, logo: item.group.logoUrl})
          }
          if(item.source == "linkedin"){
            if(item.medias.length>0)
              this.googleMedias = item.medias;
            this.linkedin = true;
          }
          if(item.source == "google"){
            if(item.medias.length>0)
              this.linkedinMedias = item.medias;
            this.google = true;
          }
         if(item.source == "facebook"){
            if(item.medias.length>0)
              this.facebookMedias = item.medias;
            this.facebook = true;
          }
         if(item.source == "instagram"){
            if(item.medias.length>0)
              this.instagramMedias = item.medias;
            this.instagram = true;
          }
        })
        
        this.choiceMediaPreview();
      }
    }));
    
  }

  choiceMediaPreview(){
    if(this.googleMedias.length > 0){
      this.mediaAllpreview = this.googleMedias[0];
      return
    }
    
    if(this.linkedinMedias.length > 0){
      this.mediaAllpreview = this.linkedinMedias[0];
      return
    }
    
    if(this.facebookMedias.length > 0){
      this.mediaAllpreview = this.facebookMedias[0];
      return
    }
    
    if(this.instagramMedias.length > 0){
      this.mediaAllpreview = this.instagramMedias[0];
      return
    }
    this.mediaAllpreview = null;
  }

  timeSince(dateString: string): string {
    if (dateString == null) {
        return '';
    }
    const date = new Date(dateString);
    const now = new Date();
    const seconds = Math.floor((now.getTime() - date.getTime()) / 1000);

    if (seconds < 0) {
        const remainingSeconds = Math.abs(seconds);
        let interval = Math.floor(remainingSeconds / 31536000);
        if (interval >= 1) {
            return interval === 1 ? 'Il sera publié dans 1 an' : `Il sera publié dans ${interval} ans`;
        }
        interval = Math.floor(remainingSeconds / 2592000);
        if (interval >= 1) {
            return interval === 1 ? 'Il sera publié dans 1 mois' : `Il sera publié dans ${interval} mois`;
        }
        interval = Math.floor(remainingSeconds / 86400);
        if (interval >= 1) {
            return interval === 1 ? 'Il sera publié dans 1 jour' : `Il sera publié dans ${interval} jours`;
        }
        interval = Math.floor(remainingSeconds / 3600);
        if (interval >= 1) {
            return interval === 1 ? 'Il sera publié dans 1 heure' : `Il sera publié dans ${interval} heures`;
        }
        interval = Math.floor(remainingSeconds / 60);
        if (interval >= 1) {
            return interval === 1 ? 'Il sera publié dans 1 minute' : `Il sera publié dans ${interval} minutes`;
        }
        return 'Il sera publié dans quelques secondes';
    }

    let interval = Math.floor(seconds / 31536000);
    if (interval >= 1) {
        return interval === 1 ? 'Il y a 1 an' : `Il y a ${interval} ans`;
    }
    interval = Math.floor(seconds / 2592000);
    if (interval >= 1) {
        return interval === 1 ? 'Il y a 1 mois' : `Il y a ${interval} mois`;
    }
    interval = Math.floor(seconds / 86400);
    if (interval >= 1) {
        return interval === 1 ? 'Il y a 1 jour' : `Il y a ${interval} jours`;
    }
    interval = Math.floor(seconds / 3600);
    if (interval >= 1) {
        return interval === 1 ? 'Il y a 1 heure' : `Il y a ${interval} heures`;
    }
    interval = Math.floor(seconds / 60);
    if (interval >= 1) {
        return interval === 1 ? 'Il y a 1 minute' : `Il y a ${interval} minutes`;
    }
    return 'Il y a quelques secondes';
  }

  getLastPlannedPosts(){
    // Get the current date
    const currentDate = new Date();
    // Subtract one year from the current date
    const nextYear = new Date();
    nextYear.setFullYear(currentDate.getFullYear() + 1);
    if(this.screenHeight > 1000)
      this.navigation.limit = Number(this.navigation.limit)+1;
    this.subscriptions.push(this.postService.getPlannedByDateRange(currentDate, nextYear, this.currentGroups, this.navigation.limit,
    this.navigation.offset ).subscribe((res: any)=>{
      this.navigation = res.navigation;
      this.navigation.limit = Number(this.navigation.limit);
      this.navigation.offset = Number(this.navigation.offset);
      if (res.data.length > 0) {
        this.navigation.offset += this.navigation.limit;
      }
      this.plannedPosts = [...this.plannedPosts, ...res.data];
      this.plannedPosts = Array.from(new Map(this.plannedPosts.map((post: any) => [post.id, post])).values());

      this.dates = []

      this.plannedPosts.forEach((item: any)=>{
        this.getFormattedDate(item) 
			})
    }));
  }

  getFormattedDate(planned: any){
    let formatDate = this.datePipe.transform(planned?.expectedPublishingDatetime, 'EEEE dd M y') || ''
    if(!this.dates.includes(formatDate)){
      this.dates.push(formatDate)
      const dateElement = formatDate.split(' ')
      planned.day = dateElement[0]
      planned.dayNumb = dateElement[1]
      planned.month = dateElement[2]
      planned.year = dateElement[3]
    }
  }
  

  infinitScroll(event: any) {
    const element = event.target;
    // console.log('scrollTop', element.scrollTop, 'clientHeight', element.clientHeight, 'element.scrollHeight', element.scrollHeight);
    const isScrollingDown = element.scrollTop > this.previousScrollPosition;
    const isBottomReached = element.scrollHeight - element.scrollTop <= element.clientHeight+2;
    // console.log('isScrollingDown', isScrollingDown, 'isBottomReached', isBottomReached)
    if (isScrollingDown && isBottomReached) {
        this.getLastPlannedPosts(); // Load more posts
        // console.log("You've reached the end of this chunk");
    }
  
    // Update the previous scroll position
    this.previousScrollPosition = element.scrollTop;
  }

  addLinkedinData(linkedinInsights: any){
    this.allData.impression = this.toNumber(this.allData.impression) + this.toNumber(linkedinInsights?.postAnalytics?.impressionCount)
    this.allData.reactions = this.toNumber(this.allData.reactions) + this.toNumber(linkedinInsights?.postAnalytics?.likeCount)
    this.allData.interactions = this.toNumber(this.allData.interactions) + this.toNumber(linkedinInsights?.postAnalytics?.totalEngagement)
    this.allData.shares = this.toNumber(this.allData.shares) + this.toNumber(linkedinInsights?.postAnalytics?.shareCount)
    this.allData.comments = this.toNumber(this.allData.comments) + this.toNumber(linkedinInsights?.postAnalytics?.commentCount)
    this.allData.AffectedMembers = this.toNumber(this.allData.AffectedMembers) + this.toNumber(linkedinInsights?.postAnalytics?.uniqueImpressionsCount)

  }

  addFbData(fbInsights: any){
    this.allData.impression = this.toNumber(this.allData.impression) + this.toNumber(fbInsights?.postAnalytics?.metrics[0]?.value)
    this.allData.reactions = this.toNumber(this.allData.reactions) + this.toNumber(fbInsights?.postAnalytics?.reactions)
    this.allData.interactions = this.toNumber(this.allData.interactions) + this.toNumber(fbInsights?.postAnalytics?.reactions) + this.toNumber(fbInsights?.postAnalytics?.comments) + this.toNumber(fbInsights?.postAnalytics?.shares)
    this.allData.shares = this.toNumber(this.allData.shares) + this.toNumber(fbInsights?.postAnalytics?.shares)
    this.allData.comments = this.toNumber(this.allData.comments) + this.toNumber(fbInsights?.postAnalytics?.comments)
    this.allData.AffectedMembers = this.toNumber(this.allData.AffectedMembers) + this.toNumber(fbInsights?.postAnalytics?.metrics[1]?.value)
  }

  addInstagramData(instagramInsights: any){
    this.allData.impression = this.toNumber(this.allData.impression) + this.toNumber(this.renderInstaVal("impressions", instagramInsights))
    this.allData.reactions = this.toNumber(this.allData.reactions) + this.toNumber(this.renderInstaVal("likes", instagramInsights))
    this.allData.interactions = this.toNumber(this.allData.interactions) + this.toNumber(this.renderInstaVal("total_interactions", instagramInsights))
    this.allData.shares = this.toNumber(this.allData.shares) + this.toNumber(this.renderInstaVal("shares", instagramInsights))
    this.allData.comments = this.toNumber(this.allData.comments) + this.toNumber(this.renderInstaVal("comments", instagramInsights))
    this.allData.AffectedMembers = this.toNumber(this.allData.AffectedMembers) + this.toNumber(this.renderInstaVal("reach", instagramInsights))
  }
  
  gVal(data: any){
    return data && Number(data)? Number(data): '-'
  }
  
  toNumber(data: any){
    return data && Number(data)? Number(data): 0
  }

  renderInstaVal(name:string, instagramInsights: any){
    const insight=instagramInsights?.postAnalytics?.find((insight:any)=>insight.name == name)
    return insight?insight.value:'-'
  }


  isNotEmptyObject(obj: any): boolean {
    return obj && typeof obj === 'object' && Object.keys(obj).length !== 0;
  }

  fetchInsights() {
    if(!this.isEmptyObject(this.post)){
      let postId = this.post.id;
      this.post.SocialPlatforms.forEach((socialPlatform: any) => {
        const platform = socialPlatform.source.toLowerCase();
        if(this.currentGroups.includes(socialPlatform.group.id)){
          if (platform === "facebook" || platform === "linkedin" || platform === "instagram") {
            const socialMediaId = socialPlatform.id; // Define `socialMediaId` locally
            if (platform === "facebook") {
             this.subscriptions.push( this.socialMediaAPIService.fetchPostInsights(postId, socialMediaId).subscribe((res: any) => {
                socialPlatform.postAnalytics = res.data.postAnalytics
                if(socialPlatform.postAnalytics){
                  this.addFbData(socialPlatform)
                }
                // console.log('res.data fb',res.data);
              }));
            }
            if (platform === "linkedin") {
             this.subscriptions.push( this.socialMediaAPIService.fetchPostInsights(postId, socialMediaId).subscribe((res: any) => {
                socialPlatform.postAnalytics = res.data.postAnalytics
                if(socialPlatform.postAnalytics){
                  this.addLinkedinData(socialPlatform)
                }
                // console.log('linkedin data', res.data);
              }));
            }
            if (platform === "instagram") {
              this.subscriptions.push(this.socialMediaAPIService.fetchPostInsights(postId, socialMediaId).subscribe((res: any) => {
                // console.log('intagram data', res.data);
                socialPlatform.postAnalytics = res.data.postAnalytics
                if(socialPlatform.postAnalytics){
                  this.addInstagramData(socialPlatform)
                }
              }));
            }
          }
        }
      });
    }
	}

  isEmptyObject(obj: any): boolean {
    if (obj === null || typeof obj !== 'object') {
      return false;
    }
    return Object.getOwnPropertyNames(obj).length === 0;
  }

  resetIntialData(){
    this.post = {}; 
    this.plannedPosts = []; 
    this.linkedin  = false;
    this.google  = false;
    this.facebook  = false;
    this.instagram  = false;
    this.linkedinData = null;
    this.linkedinMedias = []
    this.googleData = null;
    this.googleMedias = []
    this.facebookData = null;
    this.facebookMedias = []
    this.instagramData = null;
    this.instagramMedias = []
    this.mediaAllpreview =  null;
    this.dates = []
    this.isLoading = false;
    this.doneLoadingPosts = false;
    this.previousScrollPosition = 0;
    this.allData = {};
    this.navigation = {
      limit: 3,
      itemsPerPage: 3,
      page: 0,
      offset: 0
    };
    this.groups = [];
  }
  
  ngAfterViewInit() {
    // Observer pour surveiller les changements de la taille de l'élément
    this.observeHeightChanges();
  }

  ngOnDestroy() {
    this.subscriptions.forEach(sub => sub.unsubscribe());
    if (this.observer) {
      this.observer.disconnect(); // Nettoyage lorsque le composant est détruit
    }
   
  }

  // Méthode pour observer les changements
  private observeHeightChanges() {
    this.observer = new MutationObserver(() => {
      this.updateHeight();
    });

    // Configurer l'observation des changements d'attributs et de style
    this.observer.observe(this.container.nativeElement, {
      attributes: true,
      childList: true,
      subtree: true
    });

    // Initialisation de la hauteur
    this.updateHeight();
  }

  // Mise à jour de la hauteur
  private updateHeight() {
    this.height = this.container.nativeElement.offsetHeight;
  }

  checkTruncation() : boolean{
    const element = this.elementRef.nativeElement.querySelector('.truncate-text');
    if (element) {
      return element.scrollWidth > element.clientWidth;
    }
    else 
      return false
  }

  getHour(date: any): string {
    if(date){
      const d = new Date(date);
      let postHour = String(d.getUTCHours()).padStart(2, '0');
      let postMinute = String(d.getUTCMinutes()).padStart(2, '0');
      return postHour+':'+postMinute
    }
    return '--:--'
  }
}
