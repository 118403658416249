import { Injectable } from '@angular/core';
import { io, Socket } from 'socket.io-client';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class WebSocketService {
  private socket!: Socket;

  // Method to connect to the WebSocket server
  connect(): void {
    if (!this.socket || !this.socket.connected) {

      const BASE_URL = environment.baseUrl; // Backend server URL
			this.socket = io(BASE_URL, {
				path: '/socket.io',
				transports: ['websocket'], // Ensure WebSocket transport is used
			});

      this.socket.on('connect', () => {
        console.log('WebSocket connected to server');
      });

      this.socket.on('connect_error', (error) => {
        console.error('WebSocket connection error:', error);
      });

      this.socket.on('disconnect', () => {
        console.log('WebSocket disconnected');
      });
    }
  }

  // Generic method to listen to WebSocket events
  listen(eventName: string, callback: (data: any) => void): void {
    if (this.socket) {
      this.socket.on(eventName, callback);
    } else {
      console.error(`Cannot listen to event '${eventName}': Socket is not connected`);
    }
  }

  // Optional: Disconnect the socket
  disconnect(): void {
    if (this.socket) {
      this.socket.disconnect();
      console.log('WebSocket disconnected');
    }
  }
}
