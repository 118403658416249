<h1 class="p-2 text-cliking_blue">{{"socialMedia.socialMediaConection"|translate}}</h1>
  
<div class="flex justify-evenly py-3">
	<!-- Linkedin Button Container -->
	<div class="flex flex-col items-center justify-center mb-4">
		<!-- Logo and Name Div -->
		<div [ngClass]="{'w-48': isMobileView, 'w-56': !isMobileView}" class="flex items-center justify-center bg-linkedin text-white font-bold py-4 px-4  rounded-t-lg h-20 flex-shrink-0">
			<img src="assets/img/linkedin-logo.png" alt="Linkedin Logo" class="object-contain max-w-full max-h-full">
		</div>
		<!-- Connection Button Div -->
		<div [ngClass]="{'w-48': isMobileView, 'w-56': !isMobileView}" class="flex items-center justify-center bg-white border border-t-0 rounded-b-lg h-14">
			<button (click)="linkedinConnect()" class="w-42 hover:bg-gradient-to-r from-linkedin to-linkedin btn-blue">{{"socialMedia.connectAPage"|translate}}</button>
		</div>
	</div>

	<!-- Google Button Container -->
	<div class="flex flex-col items-center justify-center mb-4">
		<!-- Logo and Name Div -->
		<div [ngClass]="{'w-48': isMobileView, 'w-56': !isMobileView}" class="flex items-center justify-center bg-orange-600 text-white font-bold py-4 px-4 rounded-t-lg h-20 flex-shrink-0">
			<img src="assets/img/google-logo.png" alt="Google Logo" class="object-contain max-w-full max-h-full">
		</div>
		<!-- Connection Button Div -->
		<div [ngClass]="{'w-48': isMobileView, 'w-56': !isMobileView}" class="flex items-center justify-center bg-white border border-t-0 rounded-b-lg h-14">
			<button (click)="googleConnect()" class="w-42 hover:bg-gradient-to-r from-orange-600 to-orange-600 btn-blue">{{"socialMedia.connectAPage"|translate}}</button>
		</div>
	</div>

	<!-- Facebook Button Container -->
	<!-- <div class="flex flex-col items-center justify-center mb-4">
		<div [ngClass]="{'w-48': isMobileView, 'w-56': !isMobileView}" class="flex items-center justify-center bg-facebook text-white font-bold py-4 px-4 rounded-t-lg h-20 flex-shrink-0">
			<img src="assets/img/facebook-logo.png" alt="Facebook Logo" class="object-contain max-w-full max-h-full">
		</div>
		<div [ngClass]="{'w-48': isMobileView, 'w-56': !isMobileView}" class="flex items-center justify-center bg-white border border-t-0 rounded-b-lg h-14">
			<button (click)="facebookConnect()" class="w-42 hover:bg-gradient-to-r from-facebook to-facebook btn-blue">{{"socialMedia.connectAPage"|translate}}</button>

		</div>
	</div> -->
	
	<div class="flex flex-col items-center justify-center mb-4">
    <!-- Logo and Name Div -->
    <div [ngClass]="{'w-48': isMobileView, 'w-56': !isMobileView}" class="flex items-center justify-center bg-facebook text-white font-bold py-4 px-4 rounded-t-lg h-20 flex-shrink-0">
      <img src="assets/img/facebook-logo.png" alt="Facebook Logo" class="object-contain max-w-full max-h-full">
    </div>
    <!-- Connection Button Div -->
    <div [ngClass]="{'w-48': isMobileView, 'w-56': !isMobileView}" class="flex items-center justify-center bg-white border border-t-0 rounded-b-lg h-14">
      <button (click)="openFacebookModal()" class="w-42 hover:bg-gradient-to-r from-facebook to-facebook btn-blue">
        {{ "socialMedia.connectAPage" | translate }}
      </button>
    </div>
  </div>

	<!-- Facebook Modal -->
	<div 
		*ngIf="showFacebookModal" 
		(click)="closeFacebookModal()"
		class="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50"
	>
		<div class="bg-white rounded-lg shadow-lg p-6" (click)="$event.stopPropagation()">
			<h2 class="text-lg font-bold text-gray-800 mb-4">{{ "socialMedia.facebookConnectOptions" | translate }}</h2>
			<p class="text-gray-600 mb-6">{{ "socialMedia.chooseOption" | translate }}</p>
			<!-- Buttons in the Modal -->
			<div class="flex justify-between gap-3">
				<button (click)="facebookConnect('user')" class="w-42 hover:bg-gradient-to-r from-facebook to-facebook btn-blue">
					{{ "socialMedia.connectUser" | translate }}
				</button>
				<button (click)="facebookConnect('business')" class="w-42 hover:bg-gradient-to-r from-facebook to-facebook btn-blue">
					{{ "socialMedia.connectBusiness" | translate }}
				</button>
				
			</div>
			<!-- Close Modal -->
			<div class="mt-4 text-right">
				<button (click)="closeFacebookModal()" class="text-gray-500 hover:text-gray-700">{{ "generic.cancel" | translate }}</button>
			</div>
		</div>
	</div>	

	<!-- Instagram Button Container -->
	<div class="flex flex-col items-center justify-center mb-4">
		<!-- Logo and Name Div -->
		<div [ngClass]="{'w-48': isMobileView, 'w-56': !isMobileView}" class="flex items-center justify-center bg-gradient-to-r from-pink-500 to-yellow-500 text-white font-bold py-4 px-4 rounded-t-lg h-20 flex-shrink-0">
			<img src="assets/img/instagram-logo.png" alt="Instagram Logo" class="object-contain max-w-full max-h-full">
		</div>
		<!-- Connection Button Div -->
		<div [ngClass]="{'w-48': isMobileView, 'w-56': !isMobileView}" class="flex items-center justify-center bg-white border border-t-0 rounded-b-lg h-14">
			<button (click)="instagramConnect()" class="w-42 hover:bg-gradient-to-r from-pink-500 to-yellow-500 btn-blue">{{"socialMedia.connectAPage"|translate}}</button>
		</div>
	</div>
</div>