import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { GoogleErrorsService } from 'src/app/services/utilities/google-errors.service';

@Component({
  selector: 'app-api-errors',
	templateUrl: './api-errors.component.html',
  styleUrls: ['./api-errors.component.scss']
})
export class ApiErrorsComponent implements OnInit {
  errors: any;
  subscriptions: Subscription[]=[]
  constructor(private googleErrorsService: GoogleErrorsService) { }

  ngOnInit(): void {
    this.subscriptions.push(this.googleErrorsService.getApiErrors().subscribe(
      (data) => {
        this.errors = data.data;
      },
      (error) => {
        console.error('Error fetching API errors:', error);
      }
    ));
  }
  ngOnDestroy(): void {
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }
}
