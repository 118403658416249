<div class="mx-auto  text-3xl  overflow-auto h-[95vh] ">
    <div
      class="text-sm font-medium text-center text-gray-500 dark:text-gray-400 dark:border-gray-700 p-5 rounded-md max-w-full"
    >
    <div role="tablist" class="tabs tabs-lifted">
        <!-- LoginActivities -->
        <input
        type="radio"
        name="my_tabs_2"
        role="tab"
        class="tab [--tab-border-color:#5d78ff]"
        aria-label="LoginActivities"
        checked
        />
        <div
        role="tabpanel"
        class="tab-content bg-base-100 border-cliking_blue rounded-box p-6 max-w-full overflow-auto"
        >
            <app-login-activities-manager />
        </div>
        <!-- ConnectedUsers -->
        <input
        type="radio"
        name="my_tabs_2"
        role="tab"
        class="tab [--tab-border-color:#5d78ff]"
        aria-label="ConnectedUsers"
        />
        <div
        role="tabpanel"
        class="tab-content bg-base-100 border-cliking_blue rounded-box p-6"
        >
            <app-connected-users-manager/>
        </div>
    </div>
    </div>
</div>