import { Component } from '@angular/core';
import { Subscription } from 'rxjs';
import { ScreenSizeService } from 'src/app/services/utilities/screen-size.service';

@Component({
  selector: 'app-sociabble-page',
  templateUrl: './sociabble-page.component.html',
  styleUrls: ['./sociabble-page.component.scss']
})
export class SociabblePageComponent {
	screenWidth: number = 0;
	constructor(private screenSizeService: ScreenSizeService) {}
	subscriptions: Subscription[] =[]
	ngOnInit(): void {
		this.subscriptions.push(this.screenSizeService.screenWidth$.subscribe((width: number) => this.screenWidth = width));
	}
	ngOnDestroy(): void {
		this.subscriptions.forEach(sub => sub.unsubscribe());
	}
}
