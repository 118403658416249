import {
  Component,
  ElementRef,
  Input,
  Renderer2,
  SimpleChanges,
} from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { AuthService } from 'src/app/services/utilities/auth.service';
import { ScreenSizeService } from 'src/app/services/utilities/screen-size.service';
import { TranslationService } from 'src/app/services/utilities/translation.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-inner-settings',
  templateUrl: './inner-settings.component.html',
  styleUrls: ['./inner-settings.component.scss'],
})
export class InnerSettingsComponent {
  @Input() accreditedGroups!: any;
  @Input() auth!: any;
  @Input() uiMaps!: any;
  @Input() windowWidth: any;
  @Input() isMobileView: any;
  user: any;
  constructor(
    private eRef: ElementRef,
    private renderer: Renderer2,
    private translationService: TranslationService,
    private authService: AuthService,
    private router: Router,
    private screenSizeService: ScreenSizeService
  ) {}
  isDropdownOpen: boolean = false;
  canAccessSocialPages: boolean = false;
  screenWidth: number = 0;
  screenHeight: number = 0;
 subscriptions: Subscription[] = [];
  ngOnInit() {
   this.user = this.authService.loadUserfromLs();
   this.subscriptions.push( this.screenSizeService.screenWidth$.subscribe(
      (width: number) => (this.screenWidth = width)
    ));
    this.subscriptions.push(this.screenSizeService.screenHeight$.subscribe(
      (height: number) => (this.screenHeight = height)
    ));
  }

  toggleDropdown() {
    this.isDropdownOpen = !this.isDropdownOpen;

    // If the dropdown is open, add a click listener to the document
    if (this.isDropdownOpen) {
      this.renderer.listen('document', 'click', (event) => {
        if (!this.eRef.nativeElement.contains(event.target)) {
          this.isDropdownOpen = false;
        }
      });
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    
  }

  getTranslation(key: string): string {
    return this.translationService.t(key);
  }

  logout() {
    const token = localStorage.getItem('token') 
    this.subscriptions.push(this.authService.deleteCurrentConnectedUser(token).subscribe())
    if (token) localStorage.removeItem('token');
    localStorage.removeItem(environment.sessionData);
    this.router.navigate(['/login']);
  }
  ngDestroy() {
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }
}
