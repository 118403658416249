import { Component, Input, Renderer2, ElementRef, ViewChild, SimpleChanges, Output, EventEmitter  } from '@angular/core';
import { Subscription } from 'rxjs';
import { ScreenSizeService } from 'src/app/services/utilities/screen-size.service';

@Component({
  selector: 'app-tooltip-left',
  templateUrl: './tooltip-left.component.html',
  styleUrls: ['./tooltip-left.component.scss']
})
export class TooltipLeftComponent {
  @Input() validationsList:string[] = [];
  @Input() MediaForInstagram:boolean = false;
  @Input() AdditionalDataValidation:string = '';
  @Input() facebookLinkedinMessageVideo:boolean = false;
  @Input() visibilityFlag:boolean = false;
  @Input() down:boolean = false;
  @Input() msg: string = '';
  @Output() onToolTipHeightCalculated = new EventEmitter<any>();


  tooltipLeft: number = 0;
  @ViewChild('tooltip') tooltipElement!: ElementRef;
  screenWidth: number = 0;
  screenHeight: number = 0;
  constructor(private renderer: Renderer2, private screenSizeService: ScreenSizeService
  ) {
  }
  subscriptions: Subscription[] =[]
  ngOnInit(){
    this.subscriptions.push( this.screenSizeService.screenWidth$.subscribe((width: number) => this.screenWidth = width));
    this.subscriptions.push(this.screenSizeService.screenHeight$.subscribe((height: number) => this.screenHeight = height));
  }

  ngAfterViewInit() {
    // This is necessary to ensure the view is initialized and we have access to tooltipElement
    if (this.visibilityFlag) {
      this.applyFadeOutEffect();
    }
    setTimeout(() => {
      this.calculateHeight();
    }, 100);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['visibilityFlag'] && this.visibilityFlag) {

      setTimeout(() => {

      //   // Only try to manipulate the element if it exists
        if (this.tooltipElement) {
          this.applyFadeOutEffect();
        }
      }, 4000);
    }
  }

  applyFadeOutEffect() {
    // Safely apply class if the element is defined
    if (this.tooltipElement && this.tooltipElement.nativeElement) {
      this.renderer.addClass(this.tooltipElement.nativeElement, 'animate-fadeOut');
      setTimeout(() => {
        this.visibilityFlag = false;
      }, 800);
    }
  }

  calculateHeight() {
    if (this.tooltipElement) {
      const height = this.tooltipElement.nativeElement.getBoundingClientRect().height;
      this.onToolTipHeightCalculated.emit(height)
    }
  }

  ngOnDestroy() {
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }
}
