<div class="fixed z-50  top-0 left-1/2 transform -translate-x-1/2 ml-[9%] w-[57%] mr-64 grid grid-rows-1 grid-cols-12 gap-0 c-bg-blue rounded-b-3xl" [style.height.px]="screenHeight*6.78/100">
	<!-- Group Dropdown -->
	<div class="ml-5 mr-5 col-span-5 mt-0">  
		<div class="relative w-[100%] text-white" [ngClass]="{'text-gray-600':groupsFlatList.length==0, 'cursor-pointer':groupsFlatList.length>0 }">
			<div (click)="toggleDropdown()" type="button" aria-haspopup="listbox" aria-expanded="true" aria-labelledby="listbox-label" class="z-40 flex items-center" [style.margin-top.px]="screenHeight*0.78/100">
		
				<svg [attr.width]="screenWidth * 1.95/100" [attr.height]="screenHeight * 5.1/100" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
					<g id="SVGRepo_bgCarrier" stroke-width="0"></g>
					<g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
					<g id="SVGRepo_iconCarrier">
						<path d="M4 10.1433C4 5.64588 7.58172 2 12 2C16.4183 2 20 5.64588 20 10.1433C20 14.6055 17.4467 19.8124 13.4629 21.6744C12.5343 22.1085 11.4657 22.1085 10.5371 21.6744C6.55332 19.8124 4 14.6055 4 10.1433Z" stroke="currentColor" stroke-width="1.5"></path>
						<circle cx="12" cy="10" r="3" stroke="#ffffff" stroke-width="1.5"></circle>
					</g>
				</svg>
				
				<span *ngIf="screenHeight>1025" class="block w-96 px-3 py-2 rounded-md focus:outline-none focus:ring-cliking_blue focus:c-border-blue c-bg-blue text-white truncate max-w-full" [ngStyle]="{'font-size': screenHeight/150 + screenWidth/150 + 'px'}" >{{ caption }}</span>
				<span *ngIf="screenHeight<1025" class="block w-96 px-3 py-2 rounded-md focus:outline-none focus:ring-cliking_blue focus:c-border-blue c-bg-blue text-white truncate max-w-full" [ngStyle]="{'font-size': screenHeight/140 + screenWidth/140 + 'px'}" >{{ caption }}</span>
				<span class="ml-auto flex items-center">
					<i class="ml-5 fa fa-caret-down"></i>
				</span>
			</div>
			<ul *ngIf="isDropdownOpen" @collapse class="absolute w-full pb-3 z-10 bg-white rounded-br-2xl rounded-bl-2xl border-b border-x border-cliking_blue" [style.top.px]="screenHeight*6/100">
				<span>
					<li class="font-normal ml-3 cursor-pointer text-sm  w-full">
						<div>
							<div class="mt-1 flex ">
								<div class="relative flex items-stretch flex-grow focus-within:z-10">
									<input   type="search" class="px-4 py-10" (keyup)="searchItems(searchPattern)" [(ngModel)]="searchPattern" name="search" id="search" class="focus:ring-indigo-500 focus:border-indigo-500 block w-[94%] h-[3vh]  mt-2 pl-2 rounded-md sm:text-sm border border-1 border-gray-300 text-black" />
								</div>
							</div>
						</div>
					</li>
				</span>
				<li  (click)="toggleSelectAll()" class="cursor-pointer text-sm select-none h-10 relative py-1 pl-3 pr-9 hover:bg-gray-100 flex items-center">
					<span class="text-black mt-1">{{'generic.selectAll' | translate}}</span>
					<input type="checkbox" [checked]="isAllSelected()" id="selectAll" class="absolute inset-y-3 right-2 h-3 w-3 mr-3" />
				</li>
				<div class="overflow-y-auto overflow-x-hidden "  [style.max-height.px]="screenHeight*6/10">
					<diV *ngFor="let item of groupTree">
						<li *ngIf="(item.isVisible)" class="cursor-pointer text-sm select-none relative py-1 pl-3 pr-9 hover:bg-gray-100 item-center">
							<div  (click)="toggleOpen(item, $event)" class="flex items-center" [style.margin-left.px]="item.parent === null && item.parentId === null ? 0 : item.level">
								<span *ngIf="item.children.length == 0" style="width: 23px; height: 29px;">
									<svg width="23px" height="29px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" >
										<g id="SVGRepo_bgCarrier" stroke-width="0"></g>
										<g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
										<g id="SVGRepo_iconCarrier">
											<path d="M4 10.1433C4 5.64588 7.58172 2 12 2C16.4183 2 20 5.64588 20 10.1433C20 14.6055 17.4467 19.8124 13.4629 21.6744C12.5343 22.1085 11.4657 22.1085 10.5371 21.6744C6.55332 19.8124 4 14.6055 4 10.1433Z" stroke="#5C78FF" stroke-width="1.5"></path>
											<circle cx="12" cy="10" r="3" stroke="#5C78FF" stroke-width="1.5"></circle>
										</g>
									</svg>
								</span>
								<span *ngIf="item.children.length > 0 " class=" text-cliking_blue text-xl h-8 pt-[3px] ml-2 mr-[5px] rounded-full">|</span>
								<span *ngIf="searchPattern==''" class="font-normal hover:text-cliking_blue mr-3 hover:overflow-visible hover:whitespace-normal text-gray-700 font-sans  font-black block truncate rounded-sm">
									{{item.name }}
								</span>
								<span *ngIf="searchPattern!=''" class="font-normal hover:text-cliking_blue mr-3 text-gray-700 font-sans font-black block truncate rounded-sm" [innerHTML]="item.highlightedName"></span>
							</div>
							<input type="checkbox" (change)="toggleSelected(item, $event)" [checked]="groupScopes.includes(item.id)" class="form-checkbox absolute inset-y-3 right-2 flex items-center h-3 w-3 mr-3" /> 
						</li>
					</diV>
				</div>
				<li class="flex justify-end pr-3 mt-2">
					<button (click)="setAsCurrentGroup(groupScopes,$event)" [disabled]="groupScopes.length == 0" [ngClass]="{'cursor-not-allowed':groupScopes.length == 0}" class="w-11/12 h-8 flex mr-2 items-center justify-center px-4 py-2 bg-cliking_blue text-lg text-white rounded-md hover:bg-blue-700">
						{{'generic.submit' | translate}}
					</button>  
				</li>
			</ul>
		</div>
	</div>
	<!-- Logo -->
	<div class="col-span-2">
		<img class="absolute border-4 c-border-blue top-1 rounded-full" [style.width.px]="screenWidth * 6.25/100" [src]="loadfirstRootGroupLogoUrl()" (error)="getDefaultGroupLogo()" alt="">
	</div>
	<!-- Right div -->
	<div class="col-span-4  flex justify-between">
		<span class="flex ml-auto -mr-[18%]">
			<!--Language -->
			<app-language [isMobileView]="isMobileView" [windowWidth]="windowWidth" />
			<!--Settings -->
			<app-inner-settings [uiMaps]="uiMaps" [auth]="auth" [accreditedGroups]="accreditedGroups" [isMobileView]="isMobileView" [windowWidth]="windowWidth" />
		</span>
	</div>
</div>
  