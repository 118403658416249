<!-- day posts preview -->
<div class="fixed overflow-hidden shadow-2xl rounded-2xl border-[1px] border-[#eaecef] bg-white w-[60vh] px-2 z-50" [style.height.px]="previewHeight"  [style.left.px]="xpos" [style.top.px]="ypos" *ngIf="isVisible">
    <div class="relative text-[#544e61] grid grid-cols-9 items-center mr-2 ml-4 mt-2 overflow-hidden" [style.height.px]="40">
        <div (click)="switchToDayView(date)" class="text-4xl text-center font-semibold leading-none col-span-1 cursor-pointer rounded-lg hover:bg-[#e6e6e6] hover:rounded-lg">
                {{date.getDate().toString().padStart(2, '0')}} 
        </div>
        <div class="col-span-4 ml-2 mt-[5px]">
            <div class="uppercase text-xs tracking-widest leading-[1.1] mb-0">{{'dates.days.shortNumber.'+date.getDay() | translate}}.</div>
            <div class="text-sm font-semibold leading-[1.1] mt-0 cursor-pointer">
                <span (click)="switchToMonthView(date.getMonth())">
                    <span class="hover:bg-[#e6e6e6] hover:rounded-lg">
                        {{'dates.months.full.'+(date.getMonth()+1) | translate}} 
                    </span>
                </span>
                <span (click)="switchToYearView(date.getFullYear())" class="hover:bg-[#e6e6e6] hover:rounded-lg">
                    {{date.getFullYear()}}
                </span>
            </div>
        </div>
        <div class="absolute top-0 right-0 text-2xl font-semibold cursor-pointer hover:text-pink-600" style="pointer-events: all;" (click)="close()">×</div>
    </div>
    <div class="w-[57vh] relative overflow-y-auto bg-[#fafafa] py-2" [style.height.px]="previewHeight-60">
        <div
				[@fadeAnimation]="loadPost"
				class="absolute rounded-3xl top-0 bottom-0 left-0 right-0 bg-[#fafafa] z-20 flex items-center justify-center"
				(@fadeAnimation.done)="onAnimationDone($event)"
			>
				<!-- Bouncing dots -->
				<div class="flex justify-center items-center space-x-1">
					<div class="w-2 h-2 rounded-full bg-gray-400 animate-dot1"></div>
					<div class="w-2 h-2 rounded-full bg-gray-400 animate-dot2"></div>
					<div class="w-2 h-2 rounded-full bg-gray-400 animate-dot3"></div>
				</div>
			</div>
        <div *ngFor="let post of data ; index as i" class="pt-2 ml-3 mr-2">
            <span class="col-span-2 flex items-center py-[2px]" *ngIf="allOff(post)">
                <!-- <svg *ngIf="post?.publishingDatetime" width="14" height="15" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M3.66658 2.21118C4.65328 1.55189 5.81331 1.2 7 1.2C7.78793 1.2 8.56815 1.35519 9.2961 1.65672C10.0241 1.95825 10.6855 2.4002 11.2426 2.95736C11.7998 3.51451 12.2417 4.17594 12.5433 4.9039C12.8448 5.63185 13 6.41207 13 7.2C13 8.38668 12.6481 9.54672 11.9888 10.5334C11.3295 11.5201 10.3925 12.2891 9.2961 12.7433C8.19975 13.1974 6.99335 13.3162 5.82946 13.0847C4.66557 12.8532 3.59648 12.2818 2.75736 11.4426C1.91825 10.6035 1.3468 9.53442 1.11529 8.37054C0.88378 7.20665 1.0026 6.00025 1.45673 4.9039C1.91085 3.80754 2.67989 2.87047 3.66658 2.21118Z" fill="#1DC9B7"/>
                    <path d="M6.04573 7.95447L8.61973 5.37447C8.73214 5.26272 8.88421 5.2 9.04273 5.2C9.20124 5.2 9.35331 5.26272 9.46572 5.37447C9.52196 5.43025 9.5666 5.49661 9.59706 5.56973C9.62752 5.64284 9.6432 5.72126 9.6432 5.80047C9.6432 5.87968 9.62752 5.9581 9.59706 6.03122C9.5666 6.10433 9.52196 6.17069 9.46572 6.22647L6.46573 9.22647C6.35397 9.33732 6.20313 9.39981 6.04573 9.40047C5.96676 9.40093 5.88848 9.38579 5.81538 9.35593C5.74228 9.32608 5.67579 9.28208 5.61973 9.22647L4.20973 7.81047C4.14692 7.75668 4.09591 7.69049 4.05989 7.61605C4.02388 7.54162 4.00364 7.46054 4.00045 7.3779C3.99726 7.29527 4.01118 7.21287 4.04134 7.13588C4.07151 7.05888 4.11726 6.98895 4.17574 6.93048C4.23421 6.87201 4.30414 6.82625 4.38113 6.79609C4.45813 6.76593 4.54053 6.752 4.62316 6.75519C4.70579 6.75838 4.78687 6.77862 4.86131 6.81464C4.93575 6.85065 5.00194 6.90166 5.05573 6.96447L6.04573 7.95447Z" fill="white"/>
                </svg>
                <svg *ngIf="post?.expectedPublishingDatetime && !post?.publishingDatetime" width="14" height="15" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M7 1.39999C5.81331 1.39999 4.65328 1.75189 3.66658 2.41118C2.67989 3.07046 1.91085 4.00754 1.45673 5.10389C1.0026 6.20025 0.88378 7.40665 1.11529 8.57053C1.3468 9.73442 1.91825 10.8035 2.75736 11.6426C3.59648 12.4817 4.66557 13.0532 5.82946 13.2847C6.99335 13.5162 8.19975 13.3974 9.2961 12.9433C10.3925 12.4891 11.3295 11.7201 11.9888 10.7334C12.6481 9.74672 13 8.58668 13 7.39999C13 6.61206 12.8448 5.83185 12.5433 5.10389C12.2417 4.37594 11.7998 3.7145 11.2426 3.15735C10.6855 2.6002 10.0241 2.15824 9.2961 1.85672C8.56815 1.55519 7.78793 1.39999 7 1.39999Z" fill="#FFB822"/>
                    <path d="M7.2 6.39999H8.4C8.55913 6.39999 8.71174 6.46321 8.82426 6.57573C8.93678 6.68825 9 6.84086 9 6.99999C9 7.15912 8.93678 7.31174 8.82426 7.42426C8.71174 7.53678 8.55913 7.59999 8.4 7.59999H6.6C6.44087 7.59999 6.28826 7.53678 6.17574 7.42426C6.06321 7.31174 6 7.15912 6 6.99999V3.99999C6 3.84086 6.06321 3.68825 6.17574 3.57573C6.28826 3.46321 6.44087 3.39999 6.6 3.39999C6.75913 3.39999 6.91174 3.46321 7.02426 3.57573C7.13679 3.68825 7.2 3.84086 7.2 3.99999V6.39999Z" fill="white"/>
                </svg> -->
                <span class="text-sm ml-1">{{post | extractHourFromPost}}</span>
            </span>
            <app-post-preview 
            *ngIf="allOff(post)"
                [user]="user" 
                [index]="i" 
                [displayAs]="displayAs(post)"
                [postIndex]="i"
                [post]="post"
                [insights]="false"
            />
        </div>
    </div>
</div>
<!-- <app-loader [isLoading]="isLoading"/> -->
