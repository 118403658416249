import {
  Component,
  EventEmitter,
  HostListener,
  Input,
  Output,
  QueryList,
  ViewChildren,
} from '@angular/core';
import { Subscription } from 'rxjs';
import { CalenderBaseComponent } from 'src/app/components/base/calendar.component';
import { IResponse } from 'src/app/interfaces/IResponse';
import { PostService } from 'src/app/services/repositories/post.service';
import { CalendarService } from 'src/app/services/utilities/calendar.service';
import { TranslationService } from 'src/app/services/utilities/translation.service';
import { UiStateService } from 'src/app/services/utilities/ui-state.service';
import { AuthService } from 'src/app/services/utilities/auth.service';
import { CALENDERMODE } from 'src/app/interfaces/CalendarMode';
import { Debug } from 'src/app/utils/debug';

@Component({
  selector: 'app-calendar-month-view',
  templateUrl: './calendar-month-view.component.html',
  styleUrls: ['./calendar-month-view.component.scss'],
})
export class CalendarMonthViewComponent extends CalenderBaseComponent {
  constructor(
    protected override postService: PostService,
    protected override uiStateService: UiStateService,
    protected override translationService: TranslationService,
    protected override calendarService: CalendarService, 
    protected authService: AuthService
  ) {
    super(translationService, calendarService, postService, uiStateService);
  }

  @Input() screenWidth: number = 0;
  @Input() screenHeight: number = 0;
  @Output() switchToYearView = new EventEmitter<any>();
  @Output() labelClicked = new EventEmitter();
  currentCalenderDays: number[] = [];
  currentMonthData: { day: number; data: any; previewIndex: number }[] = [];
  currentMonthDataTemporary: { day: number; data: any; previewIndex: number }[] = [];
  monthData: { day: number; data: any; previewIndex: number }[] = [];

  savedCurrentMonthData: any = null;
  plannedSvg: boolean = false;
  postedSvg: boolean = false;
  isLoading: boolean = false;
  temporary: boolean = true;
  isDayPostsPannelVisible: boolean = false;
  selectedDay: number|null = null;
  postsOfDay: any;
  selectedDayDate: string = '';
  calculatedHeight!: number;
  pannelPositions: any = {};
  user: any ;
  subscriptions: Subscription[] = [];

  override handleVisibilityChange(isVisible: boolean) {
    Debug.log('visibility', isVisible);
    this.isFullHourPannelVisible = isVisible;
  }

  override ngOnInit(): void {
    this.user = this.authService.loadUserfromLs();
    super.ngOnInit();

    const lsData = this.postService.getGroupScopesFromLocalStorage();
    this.groupScopes = lsData.length > 0 ? lsData : this.groupScopes;

    this.subscriptions.push(this.groupSubscription = this.postService.currentGroups.subscribe(
      (groups) => {
        Debug.log('group scopes updated month view', groups);
        this.groupScopes = groups;
        this.getMonthHighLight();
      }
    ));

    this.currentCalenderDays = this.GetCalanderDays(this.currentDate);
    for (let d of this.currentCalenderDays) {
      this.currentMonthData.push({ day: d, data: null, previewIndex: 0 });
    }
  }

  gotoYear(year: number) {
    Debug.log('switching to year ...', year);
    this.calendarService.setCurrentYear(year);
    this.switchToYearView.emit(year);
    //emit event to calender mode
  }

  goToDay(data: any, i: number) {
    if (!this.idDayFromThisMonth(data.day, i)) {
      //change current date
      let dateStr =
        this.currentDate.getFullYear() +
        '-' +
        (this.currentDate.getMonth() + 1) +
        '-' +
        data.day;
      this.calendarService.setCurrentDate(new Date(dateStr));
      this.calendarService.setCurrentCalendarMode(CALENDERMODE.DAY);
      this.mode = CALENDERMODE.DAY;
    }
  }

  idDayFromThisMonth(day: number, i: number) {
    return (day > 20 && i < 6) || (day < 10 && i > 29);
  }

  prevMonth() {
    this.currentDate.setMonth(this.currentDate.getMonth() - 1);
    this.calendarService.setCurrentMonth(this.currentDate.getMonth());
    this.getMonthHighLight();
  }

  nextMonth() {
    this.currentDate.setMonth(this.currentDate.getMonth() + 1);
    this.calendarService.setCurrentMonth(this.currentDate.getMonth());
    this.getMonthHighLight();
  }

  getMonthHighLight() {
    this.isLoading = true;
    const lsData = this.postService.getGroupScopesFromLocalStorage();
    this.groupScopes = lsData.length > 0 ? lsData : this.groupScopes;
    
    Debug.log('😀', lsData, this.groupScopes);
    
    const dateStr =
    this.calendarService.currentDate.getFullYear() +
    '-' +
    (this.calendarService.currentDate.getMonth() + 1) +
    '-' +
    this.calendarService.currentDate.getUTCDate();
    console.log('dateStr', dateStr);
    
    this.subscriptions.push(this.postService
    .getMonthHighLight(this.groupScopes, dateStr)
    .subscribe((res: IResponse) => {
      this.data = res.data;
      console.log('res.data', res.data);
      console.log('this.currentDate', this.currentDate);
      
      // Debug.log(this.data)
      this.currentMonthDataTemporary = [];
      this.currentMonthDataTemporary = this.GetCalenderData(this.currentDate, this.data);
      console.log('this.currentMonthDataTemporary', this.currentMonthDataTemporary);
      
      // this.savedCurrentMonthData = JSON.parse(JSON.stringify(this.currentMonthData));
      this.subscriptions.push(this.uiStateService.platformsFilterUpdated$.subscribe((data) => {
        this.monthData = this.isPostPlatformInFilter(this.currentMonthDataTemporary)
      }));
      console.log('currentMonthData', this.currentMonthData);
      
      this.isLoading = false;
      this.getPostsByTimeFrame(dateStr);
      
    }));
  }

  getPostsByTimeFrame(dateStr: string){
    this.postService
    .getByTimeFrame(this.groupScopes, dateStr, 'month')
    .subscribe((res: IResponse) => {
      this.data = res.data;
      console.log('res.data', res.data);
      console.log('this.currentDate', this.currentDate);
      
      // Debug.log(this.data)
      this.currentMonthData = [];
      this.currentMonthData = this.GetCalenderData(this.currentDate, this.data);
      console.log('this.currentMonthData', this.currentMonthData);
      
      this.savedCurrentMonthData = JSON.parse(JSON.stringify(this.currentMonthData));
      this.subscriptions.push(this.uiStateService.platformsFilterUpdated$.subscribe((data) => {
        this.monthData = this.isPostPlatformInFilter(this.currentMonthDataTemporary)
      }));
      console.log('currentMonthData', this.currentMonthData);
      
      this.temporary = false;
    })
  }

  GetCalanderDays(date: Date): number[] {
    function getDaysInMonth(year: number, month: number) {
      return new Date(year, month + 1, 0).getDate();
    }

    const startOfMonth = new Date(date.getFullYear(), date.getMonth(), 1);
    const endOfMonth = new Date(
      date.getFullYear(),
      date.getMonth(),
      getDaysInMonth(date.getFullYear(), date.getMonth())
    );

    // Adjust startOfWeek to the previous Monday
    let startOfWeek = new Date(startOfMonth);
    let dayOfWeek = startOfMonth.getDay();
    startOfWeek.setDate(
      startOfWeek.getDate() - (dayOfWeek === 0 ? 6 : dayOfWeek - 1)
    );

    // Ensure endOfWeek is the following Sunday after endOfMonth
    let endOfWeek = new Date(endOfMonth);
    dayOfWeek = endOfWeek.getDay();
    // This ensures it always extends to the next Sunday, even if endOfMonth is Sunday
    endOfWeek.setDate(endOfWeek.getDate() + (7 - dayOfWeek));

    const weeks = [];
    let currentDate = new Date(startOfWeek);
    while (currentDate <= endOfWeek) {
      weeks.push(currentDate.getDate());
      currentDate.setDate(currentDate.getDate() + 1);
    }

    // Ensure we have a complete set of 35 or 42 days
    // If the calculation above doesn't give us enough days to fill 6 weeks (42 days),
    // it means we're missing the last Sunday because it's considered part of the next week.
    if (weeks.length < 42) {
      // Add days to include the last Sunday
      while (weeks.length < 42) {
        weeks.push(currentDate.getDate());
        currentDate.setDate(currentDate.getDate() + 1);
      }
    }

    return weeks;
  }

  isCurrentDay(d: any): boolean {
    // Check if it's the current day
    let isDayFromAnotherMonth: boolean = !(
      (d.day < 7 && this.currentMonthData.indexOf(d) > 27) ||
      (d.day > 7 && this.currentMonthData.indexOf(d) < 7)
    );
    return (
      isDayFromAnotherMonth &&
      this.currentDate.getFullYear() ===
        this.calendarService.toDay.getFullYear() &&
      this.currentDate.getMonth() === this.calendarService.toDay.getMonth() &&
      this.calendarService.toDay.getDate() === d.day
    );
  }

  getTodayMonthString() {
    return this.getTranslation(
      'dates.months.full.' + (this.currentDate.getMonth() + 1)
    );
  }

  GetCalenderData(
    date: Date,
    data: any
  ): { day: number; data: any[]; previewIndex: number }[] {
    const result: { day: number; data: any[]; previewIndex: number }[] = [];

    function getDaysInMonth(year: number, month: number) {
      return new Date(year, month + 1, 0).getDate();
    }

    const startOfMonth = new Date(date.getFullYear(), date.getMonth(), 1);
    const endOfMonth = new Date(
      date.getFullYear(),
      date.getMonth(),
      getDaysInMonth(date.getFullYear(), date.getMonth())
    );

    // Adjust startOfWeek to the previous Monday
    let startOfWeek = new Date(startOfMonth);
    let dayOfWeek = startOfMonth.getDay();
    startOfWeek.setDate(
      startOfMonth.getDate() - (dayOfWeek === 0 ? 6 : dayOfWeek - 1)
    );

    // Ensure endOfWeek is the next Sunday after endOfMonth, even if it goes into the next month
    let endOfWeek = new Date(endOfMonth);
    dayOfWeek = endOfWeek.getDay();
    if (dayOfWeek !== 0) {
      // If not Sunday
      endOfWeek.setDate(endOfMonth.getDate() + (7 - dayOfWeek));
    } else {
      // If endOfMonth is a Sunday, no need to adjust
      endOfWeek = endOfMonth;
    }

    let currentDate = new Date(startOfWeek);

    // Iterate over each day in the calendar range
    while (currentDate <= endOfWeek) {
      let dailyPosts = [];
      // Your existing logic to gather daily posts
      for (let post of data) {
        let publishDate = post.publishingDatetime
          ? new Date(post.publishingDatetime)
          : null;
        let plannedDate = post.expectedPublishingDatetime
          ? new Date(post.expectedPublishingDatetime)
          : null;

        if (
          (publishDate && this.isSameDay(publishDate, currentDate)) ||
          (plannedDate && this.isSameDay(plannedDate, currentDate))
        ) {
          dailyPosts.push(post);
        }
      }
      result.push({
        day: currentDate.getDate(),
        data: dailyPosts,
        previewIndex: 0,
      });
      currentDate.setDate(currentDate.getDate() + 1);
    }

    // Sort currentMonthData each day's data by date ascending or descending
    for (let item of result) {
      if (item.data.length > 1) {
        item.data = this.sortPostsByTime(item.data);
      }
    }

    return result;
  }

  isSameDay(date1: Date, date2: Date): boolean {
    return (
      date1.getFullYear() === date2.getFullYear() &&
      date1.getMonth() === date2.getMonth() &&
      date1.getDate() === date2.getDate()
    );
  }

  isPostPlatformInFilter(currentMonthData: any) {
    if(this.savedCurrentMonthData!= null)
      currentMonthData = JSON.parse(JSON.stringify(this.savedCurrentMonthData));
  
    if (currentMonthData.length > 0) {
      currentMonthData.forEach((dayData: any) => {
        if (dayData.data.length > 0) {
          dayData.data = dayData.data.filter((posts: any) => {
            const validPlatforms = posts.SocialPlatforms.filter((platform: any) =>
              (platform.source === 'facebook' && this.currentPlaTformsFilters.isFacebookON)
              || (platform.source === 'google' && this.currentPlaTformsFilters.isGoogleON)
              || (platform.source === 'linkedin' && this.currentPlaTformsFilters.isLinkedinON)
              || (platform.source === 'instagram' && this.currentPlaTformsFilters.isInstagramON)
            );
            return validPlatforms.length > 0;
          });
        }
      });
    }
    return currentMonthData;
  }
  
  updatePreviewIndex(d: any) {
    if (d.data.length > 1) {
      d.previewIndex =
        d.previewIndex + 1 == d.data.length ? 0 : d.previewIndex + 1;
    }
  }

	getUniquePlateforms(data: any[]) {
    // console.log('uniquePlatforms', data);
    const filteredPlatforms = data
    const uniquePlatforms = Array.from(new Set(filteredPlatforms.map(item => item.source)));
    
    return uniquePlatforms;
	}

  extractHour(data: any): string{
    const date = data.publishingDatetime ? new Date(data.publishingDatetime): new Date(data.expectedPublishingDatetime);
    const postHour = date.getUTCHours().toString().padStart(2,'0');
    const postMinute = date.getUTCMinutes().toString().padStart(2,'0');
    return postHour+":"+postMinute
  }

  getDayStatus(d: any): string {
    const today = new Date().setHours(0, 0, 0, 0);
    const givenDate = new Date(this.currentDate.getFullYear() , this.currentDate.getMonth(), d.day).setHours(0, 0, 0, 0);
    if (givenDate < today) {
      return 'Passed';
    } else if (givenDate > today) {
      return 'notYetArrived';
    } else {
      d?.data?.forEach((element: any) => {
        if(element.publishingDatetime)
          this.postedSvg = true
        if(element.expectedPublishingDatetime && !element.publishingDatetime)
          this.plannedSvg = true
      });
      return 'Today';
    }
  }
  
  updatePlatforms(data: string) {
    if(data.toLowerCase()==='facebook')
      this.currentPlaTformsFilters.isFacebookON = !this.currentPlaTformsFilters.isFacebookON
    if(data.toLowerCase()==='google')
      this.currentPlaTformsFilters.isGoogleON = !this.currentPlaTformsFilters.isGoogleON
    if(data.toLowerCase()==='linkedin')
      this.currentPlaTformsFilters.isLinkedinON = !this.currentPlaTformsFilters.isLinkedinON
    if(data.toLowerCase()==='instagram')
      this.currentPlaTformsFilters.isInstagramON = !this.currentPlaTformsFilters.isInstagramON

    this.uiStateService.updatePlatformsFilter(this.currentPlaTformsFilters);
  }

  onCalendarModeChanged(data: any){
    console.log('data', data);

    if(data == 'date.year')
      this.mode = CALENDERMODE.YEAR 
    if(data == 'date.month')
      this.mode = CALENDERMODE.MONTH 
    if(data == 'date.week')
      this.mode = CALENDERMODE.WEEK 
    if(data == 'date.day')
      this.mode = CALENDERMODE.DAY 

    this.calendarService.setCurrentCalendarMode(this.mode);
  }

  showPostsDay(i: number, d: any){
    this.selectedDay = i
    if(d.data.length>0){
      this.selectedDayDate =
        this.currentDate.getFullYear() +
        '-' +
        (this.currentDate.getMonth() + 1) +
        '-' +
        d.day;
      this.postsOfDay = d.data;
      this.isDayPostsPannelVisible = true;
      if(d.data.length == 1)
        this.calculatedHeight = 240
      if(d.data.length == 2)
        this.calculatedHeight = 400
      if(d.data.length > 2 )
        this.calculatedHeight = 512
    }
  }

  popoverPostsVisibility(isVisible: boolean){
    this.isDayPostsPannelVisible = isVisible
  }
  
  @HostListener('click', ['$event'])
  onDocumentClick(event: MouseEvent) {
    if ((event.target as HTMLElement).classList.contains('day-preview') || (event.target as HTMLElement).parentElement?.classList.contains('day-preview')) {
      let width: number = document.documentElement.clientWidth;
      let height: number = document.documentElement.clientHeight;
      const spaceFromTop = 100;  
      let offsetX: number = width - event.clientX > 550 ? 0 : 500;
      let offsetY: number = height - event.clientY > this.calculatedHeight ? 0 : this.calculatedHeight + 20;
      
      this.pannelPositions.x = event.clientX - offsetX;
      this.pannelPositions.y = event.clientY - offsetY < 0 ? event.clientY - Math.abs(offsetY) / 10 : event.clientY - offsetY;

      if (this.pannelPositions.y + this.calculatedHeight > height - spaceFromTop) {
        this.pannelPositions.y = height - this.calculatedHeight - spaceFromTop - 10; 
      }

      if (this.pannelPositions.y < spaceFromTop) {
        this.pannelPositions.y = spaceFromTop; 
      }
    }
  }

  override ngOnDestroy() {
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }

}
